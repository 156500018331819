import { useLazyQuery } from "@apollo/client";
import { useIntl } from "react-intl";
import {
  MachineCategoryDocument,
  MachineCategoryQuery,
  MachineCategoryQueryVariables,
} from "../../graphql";

export function useMachineCategoryFormat() {
  const intl = useIntl();
  return (type: string) =>
    intl.formatMessage({ id: `machines.categories.${type}` });
}

export function useMachineCategoryOptions() {
  const formatMachineCategory = useMachineCategoryFormat();

  const [load, { data, loading }] = useLazyQuery<
    MachineCategoryQuery,
    MachineCategoryQueryVariables
  >(MachineCategoryDocument);

  const options =
    data?.__type?.enumValues
      ?.map((t) => ({
        key: t.name,
        label: formatMachineCategory(t.name),
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) || [];

  return { load, loading, options };
}
