import { ColumnGroupType, ColumnType, ColumnsType } from "antd/lib/table";
import { isArray, mergeWith, debounce as _debounce } from "lodash";

const DEBOUNCE_WAIT = 300;

export function mergeWithArray<T1 extends Object, T2 extends Object>(
  obj: T1 = {} as T1,
  src: T2
) {
  return mergeWith({}, obj, src, (objValue, srcValue) => {
    if (isArray(objValue)) return objValue.concat(srcValue);
  });
}

export function filterArray<T>(
  ...array: Array<T | undefined | null | false>
): T[] {
  return filterFalse(array);
}

export function filterFalse<T>(
  array: Array<T | undefined | null | false>
): T[] {
  return array.filter((t): t is T => !!t);
}

export function filterColumns<TColumn>(
  array: Array<
    ColumnType<TColumn> | ColumnGroupType<TColumn> | undefined | null | false
  >
): ColumnsType<TColumn> {
  return filterFalse(array);
}

export function debounce<T extends (...args: any) => any>(func: T) {
  return _debounce(func, DEBOUNCE_WAIT);
}

export function isDemo() {
  return process.env.ARAGRO_ENV === "demo";
}
