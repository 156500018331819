import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Upload as $Upload } from "antd";
import { UploadProps as $UploadProps } from "antd/lib/upload";
import { UploadListType } from "antd/lib/upload/interface";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

export type UploadProps = Omit<$UploadProps, "onChange"> & {
  children?: React.ReactNode;
  label?: React.ReactNode;
  defaultPreview?: string;
  value?: string;
  onChange?(file: File | Blob | undefined): void;
  accept?: string;
  listType?: UploadListType;
  showUploadList?: boolean;
  currentFileList?: any;
  setCurrentFileList?: any;
};

export function Upload({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  value,
  children,
  label,
  defaultPreview,
  accept,
  listType,
  showUploadList,
  onChange,
  ...restProps
}: UploadProps) {
  const [uploading, setUploading] = useState(false);
  const [previewImg, setPreviewImg] = useState(defaultPreview);
  const [currentFileList, setCurrentFileList] = useState<Array<any>>([]);

  const uploadButton = previewImg ? (
    <img src={previewImg} style={{ width: "100%" }} />
  ) : (
    <div>
      {uploading ? <LoadingOutlined /> : <PlusOutlined />}
      {label || (
        <div>
          <FormattedMessage id="upload" defaultMessage="upload" />
        </div>
      )}
    </div>
  );

  return (
    <$Upload
      multiple={false}
      listType={listType || "picture-card"}
      fileList={currentFileList}
      showUploadList={showUploadList || false}
      accept={accept || "image/*"}
      customRequest={() => {}}
      onChange={(info) => {
        if (info.file.status === "uploading" && info.file.originFileObj) {
          setUploading(true);

          if (showUploadList && setCurrentFileList) {
            setCurrentFileList([info.file.originFileObj]);
          }

          const reader = new FileReader();
          reader.addEventListener(
            "load",
            () => reader.result && setPreviewImg(reader.result as string)
          );
          reader.readAsDataURL(info.file.originFileObj);

          if (onChange) onChange(info.file.originFileObj);
        }

        if (info.file.status === "removed") {
          if (showUploadList && setCurrentFileList) {
            setCurrentFileList([]);
          }

          if (onChange) onChange(undefined);
        }
      }}
      {...restProps}
    >
      {children || uploadButton}
    </$Upload>
  );
}
