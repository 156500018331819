import { CheckOutlined, EditOutlined } from "@ant-design/icons";
import { Button, ButtonProps } from "antd";
import { useState } from "react";
import { Space } from "../shared";
import { InputNumberProps } from "./InputNumber";

export type EditDisabledProps = {
  buttonProps?: ButtonProps;
  render(disabled: boolean): React.ReactElement;
  onChange?(): void;
} & InputNumberProps;

export function EditDisabledInput({
  render,
  buttonProps,
  onChange,
}: EditDisabledProps) {
  const [editMode, setEditMode] = useState(false);

  return (
    <Space align="start">
      <div style={{ flexGrow: 1 }}>{render(!editMode)}</div>

      <Button
        type={editMode ? "primary" : "default"}
        icon={editMode ? <CheckOutlined /> : <EditOutlined />}
        onClick={() => {
          setEditMode(!editMode);
          if (onChange && !editMode) onChange();
        }}
        {...buttonProps}
      />
    </Space>
  );
}
