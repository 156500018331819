import { List, Typography } from "antd";
import Icon, { ProjectTwoTone } from "@ant-design/icons";
import { defineMessages, useIntl } from "react-intl";
import BasicLayout from "../../layouts/BasicLayout";
import { useCurrentUser } from "../../lib/hooks";
import { PageConfig, visibleModules } from "../../lib/modules";
import routes from "../../lib/routes";
import Link from "next/link";
import { Route } from "antd/lib/breadcrumb/Breadcrumb";
import { groupBy } from "lodash";
import { Space } from "../../components/shared";
import { MenuIcons } from "../../lib/images";

const messages = defineMessages({
  reports: {
    id: "reports",
    defaultMessage: "Reports",
  },
  description: {
    id: "reports.description",
    defaultMessage: "description",
  },
  stock: { id: "stockReport", defaultMessage: "Stock Report" },
  prePayroll: { id: "prePayroll", defaultMessage: "prePayroll" },
  inventoryMovements: {
    id: "inventoryMovements",
    defaultMessage: "inventoryMovements",
  },
  inventoryEntries: {
    id: "inventoryEntries",
    defaultMessage: "Inventory Entries",
  },
  journalReport: {
    id: "journalReport",
    defaultMessage: "journalReport",
  },
  generalLedger: { id: "generalLedger", defaultMessage: "generalLedger" },
  trialBalance: { id: "trialBalance", defaultMessage: "trialBalance" },
  balanceSheet: { id: "balanceSheet", defaultMessage: "Balance Sheet" },
  operations: { id: "reports.operations", defaultMessage: "Operations" },
  inventory: { id: "inventory" },
  finance: { id: "finance" },
});

const groups = {
  operations: {
    title: messages.operations,
    icon: MenuIcons.PlanningIcon,
  },
  inventory: {
    title: messages.inventory,
    icon: MenuIcons.InventoryIcon,
  },
  finance: {
    title: messages.finance,
    icon: MenuIcons.FinanceIcon,
  },
};

type group = keyof typeof groups;

export const reports: Array<
  Omit<PageConfig, "module" | "menus"> & {
    group: group;
  }
> = [
  {
    title: { id: "activities", defaultMessage: "activities" },
    description: { id: "activityReport.description" },
    route: routes.reports.activities,
    group: "operations",
    visible: (p) => p.report?.activity,
  },
  {
    title: { id: "cropFields", defaultMessage: "cropFields" },
    description: { id: "cropFieldReport.description" },
    route: routes.reports.cropFields,
    group: "operations",
    visible: (p) => p.report?.cropField,
  },
  {
    title: { id: "laborReport.pageTitle", defaultMessage: "Labor Report" },
    description: { id: "laborReport.description" },
    route: routes.reports.labor,
    group: "operations",
    visible: (p, t) => p.report?.labor && !!t.features.employees,
  },
  {
    title: {
      id: "expenseReport.pageTitle",
      defaultMessage: "Expense",
    },
    description: { id: "expenseReport.description" },
    route: routes.reports.expense,
    group: "operations",
    visible: (p) => p.report?.expense,
  },
  {
    title: messages.generalLedger,
    description: { id: "generalLedger.description" },
    route: routes.reports.generalLedger,
    group: "finance",
    visible: (p, t) => !!t.features.accounting && p.account?.write,
  },
  {
    title: { id: "ledgerReport.pageTitle" },
    description: { id: "ledgerReport.description" },
    route: routes.reports.ledger(),
    group: "finance",
    visible: (p, t) => !!t.features.accounting && p.account?.write,
  },
  {
    title: { id: "reports.harvest.pageTitle", defaultMessage: "harvestReport" },
    description: { id: "reports.harvest.description" },
    route: routes.reports.harvest,
    group: "operations",
    visible: (p, t) => p.report?.harvest && !!t.features.advancedActivities,
  },
  {
    title: {
      id: "inputCostCenterReport.pageTitle",
      defaultMessage: "Input per Cost Center",
    },
    description: { id: "inputCostCenterReport.description" },
    route: routes.reports.inputCostCenter,
    group: "inventory",
    visible: (p) => p.report?.inputCostCenter,
  },
  {
    title: {
      id: "incomeStatementReport.pageTitle",
      defaultMessage: "Income Statement",
    },
    description: { id: "incomeStatementReport.description" },
    route: routes.reports.incomeStatement,
    group: "finance",
    visible: (p, t) => !!t.features.accounting && p.report?.incomeStatement,
  },
  {
    title: messages.inventoryMovements,
    description: { id: "inventoryMovements.description" },
    route: routes.reports.inventoryMovements(),
    group: "inventory",
    visible: (p) => p.report?.inventoryMovement,
  },
  {
    title: messages.inventoryEntries,
    description: { id: "inventoryEntries.description" },
    route: routes.reports.inventoryEntries,
    group: "inventory",
    visible: (p, t) =>
      p.report?.inventoryMovement && !!t.features.inventoryStock,
  },
  {
    title: messages.journalReport,
    description: { id: "journalReport.description" },
    route: routes.reports.journal,
    group: "finance",
    visible: (p, t) => !!t.features.accounting && p.account?.write,
  },
  {
    title: { id: "machines", defaultMessage: "machines" },
    description: { id: "machinesReport.description" },
    route: routes.reports.machines,
    group: "operations",
    visible: (p, t) => p.report?.machine && !!t.features.machinery,
  },
  {
    title: messages.prePayroll,
    description: { id: "payrollReport.description" },
    route: routes.reports.prePayroll,
    group: "operations",
    visible: (p, t) => p.report?.payroll && !!t.features.employees,
  },
  {
    title: messages.stock,
    description: { id: "stockReport.description" },
    route: routes.reports.stock,
    group: "inventory",
    visible: (p, t) => p.report?.stock && !!t.features.inventoryStock,
  },
  {
    title: messages.trialBalance,
    description: { id: "trialBalance.description" },
    route: routes.reports.trialBalance,
    group: "finance",
    visible: (p, t) => !!t.features.accounting && p.account?.write,
  },
  {
    title: messages.balanceSheet,
    description: { id: "balanceSheet.description" },
    route: routes.reports.balanceSheet,
    group: "finance",
    visible: (p, t) =>
      !!t.features.accounting && p.account?.write && p.report?.balanceSheet,
  },
  {
    title: { id: "tokenReport.pageTitle", defaultMessage: "tokenReport" },
    description: { id: "tokenReport.description" },
    route: routes.reports.token,
    group: "operations",
    visible: (p, t) => p.report?.token && !!t.features.tokens,
  },
  {
    title: {
      id: "workOrderDailyReport.pageTitle",
      defaultMessage: "Work Orders Daily",
    },
    description: { id: "workOrderDailyReport.description" },
    route: routes.reports.workOrderDaily,
    group: "operations",
    visible: (p) => p.report?.workOrder,
  },
  {
    title: {
      id: "reports.accountsPayable.pageTitle",
    },
    description: { id: "reports.accountsPayable.description" },
    route: routes.reports.accountsPayable,
    group: "finance",
    visible: (p, t) => p.financeInvoice?.read && !!t.features.inventoryStock,
  },
  {
    title: {
      id: "reports.accountsReceivable.pageTitle",
    },
    description: { id: "reports.accountsReceivable.description" },
    route: routes.reports.accountsReceivable,
    group: "finance",
    visible: (p, t) => p.financeInvoice?.read && !!t.features.inventoryStock,
  },
];

export function useReportBreadcrumbs(currentBreadcrumb?: Route) {
  const intl = useIntl();

  const { user } = useCurrentUser();

  const reports = user ? visibleModules(user).reports : [];

  const breadcrumbs = [
    {
      path: routes.reports.index,
      breadcrumbName: intl.formatMessage({ id: "reports" }),
      children: reports
        .filter((r) => r.route !== routes.reports.index)
        .sort((a, b) =>
          intl.formatMessage(a.title).localeCompare(intl.formatMessage(b.title))
        )
        .map(({ route, title }) => ({
          path: route,
          breadcrumbName: intl.formatMessage(title),
        })),
    },
  ] as Route[];

  if (currentBreadcrumb) breadcrumbs.push(currentBreadcrumb);

  return breadcrumbs;
}

function ReportIndex() {
  const intl = useIntl();
  const { user } = useCurrentUser();

  const breadcrumbs = useReportBreadcrumbs();

  if (!user) return null;

  const groupedReports = Object.entries(
    groupBy(
      reports.filter((r) => r.visible(user.permissions, user.currentTenant)),
      "group"
    )
  ) as [group, (typeof reports)[number][]][];

  return (
    <BasicLayout
      title={intl.formatMessage(messages.reports)}
      breadcrumbs={breadcrumbs}
      subTitle={intl.formatMessage(messages.description)}
      authorize={(p) => !!p?.report}
    >
      <Space vertical size="large">
        {groupedReports.map(([group, reports]) => (
          <List
            key={`reports-group-${group}`}
            header={
              <Space align="center">
                {!!groups[group].icon && (
                  <Icon
                    component={groups[group].icon}
                    style={{ verticalAlign: "text-top", fontSize: "20px" }}
                  />
                )}
                <Typography.Title level={3} style={{ marginBottom: 0 }}>
                  {intl.formatMessage(groups[group].title)}
                </Typography.Title>
              </Space>
            }
            itemLayout="horizontal"
            dataSource={reports.sort((a, b) =>
              intl
                .formatMessage(a.title)
                .localeCompare(intl.formatMessage(b.title))
            )}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <Link href={item.route} as={item.route}>
                      {intl.formatMessage(item.title)}
                    </Link>
                  }
                  avatar={<ProjectTwoTone />}
                  description={
                    item.description && intl.formatMessage(item.description)
                  }
                />
              </List.Item>
            )}
          />
        ))}
      </Space>
    </BasicLayout>
  );
}

export default ReportIndex;
