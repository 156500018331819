import { defineMessages, useIntl } from "react-intl";
import { Input, Row, Col, List, Typography, Popover, InputRef } from "antd";
import Link from "next/link";
import { useCurrentUser } from "../../../lib/hooks";
import { visibleModules } from "../../../lib/modules";
import { useEffect, useRef, useState } from "react";
import Icon from "@ant-design/icons";
import BlockedTooltip from "../OnboardingProgress/BlockedTooltip";

const messages = defineMessages({
  search: { id: "module.search", defaultMessage: "module.search" },
});

export const ModuleList = () => {
  const { user, forceOnboarding } = useCurrentUser();

  const intl = useIntl();
  const [query, setQuery] = useState<string>();
  const [opened, setOpened] = useState(false);
  const searchRef = useRef<InputRef>(null);

  useEffect(() => {
    if (opened) searchRef.current?.focus();
  }, [opened]);

  const icon = (
    <Icon component={() => <img src="/images/menu/module.svg" width="18" />} />
  );

  if (forceOnboarding) return <BlockedTooltip>{icon}</BlockedTooltip>;

  if (!user) return null;

  const modules = visibleModules(user, "top");

  const content = (
    <Row style={{ maxWidth: "800px" }}>
      <Col xs={24} style={{ marginBottom: "10px" }}>
        <Input.Search
          ref={searchRef}
          value={query}
          placeholder={intl.formatMessage(messages.search)}
          onChange={(e) => setQuery(e.target.value)}
        />
      </Col>

      <Col xs={24}>
        <List
          grid={{ gutter: 16, xs: 1, sm: 2, md: 3, column: 5 }}
          dataSource={Object.keys(modules)}
          renderItem={(item) => {
            return (
              <List.Item>
                <Typography.Text strong underline>
                  {intl.formatMessage(modules[item][0].module.title)}
                </Typography.Text>

                {modules[item]
                  .filter(
                    (p) =>
                      !query ||
                      intl
                        .formatMessage(p.title)
                        .toLowerCase()
                        .includes(query.toLowerCase())
                  )
                  .map((page) => (
                    <div
                      key={`${item}-${page.route}`}
                      className="top-page-link"
                    >
                      <Link
                        href={page.route}
                        as={page.route}
                        prefetch={false}
                        onClick={() => {
                          setOpened(false);
                          setQuery("");
                        }}
                      >
                        {intl.formatMessage(page.title)}
                      </Link>
                    </div>
                  ))}
              </List.Item>
            );
          }}
        />
      </Col>
    </Row>
  );

  return (
    <Popover
      open={opened}
      onOpenChange={setOpened}
      trigger="click"
      placement="bottom"
      content={content}
    >
      {icon}
    </Popover>
  );
};
