import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  ServerError,
  ServerParseError,
} from "@apollo/client";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import fetch from "isomorphic-unfetch";
import { NextPageContext } from "next";
import { showError } from "../messages";
import Router from "next/router";
import * as Sentry from "@sentry/nextjs";
import { sha256 } from "crypto-hash";
import routes from "../routes";

let apolloClient: ApolloClient<any> | null = null;

const isBrowser = typeof window !== "undefined";

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { __typename, ...vars } = operation.variables;
    operation.variables = vars;
  }
  return forward ? forward(operation) : null;
});

import { SentryLink } from "apollo-link-sentry";

const sentryLink = new SentryLink({
  setTransaction: false,
  setFingerprint: false,
  attachBreadcrumbs: {
    includeError: true,
  },
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  Sentry.withScope((scope) => {
    scope.setTransactionName(operation.operationName);
    scope.setContext("apolloGraphQLOperation", {
      operationName: operation.operationName,
      variables: JSON.stringify(operation.variables),
      extensions: JSON.stringify(operation.extensions),
    });

    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        Sentry.captureMessage(error.message, {
          level: "error",
          fingerprint: ["{{ default }}", "{{ transaction }}"],
          contexts: {
            apolloGraphQLError: {
              error,
              message: error.message,
              extensions: error.extensions,
            },
          },
        });

        // eslint-disable-next-line no-console
        console.error(
          `[ARA API error]: Message: ${error.message}, Path: ${error.path}`
        );
        if (!isBrowser) return;

        showError(error.message);

        if (error.extensions?.error == "404") {
          // Router.push("/");
        } else if (error.extensions?.tenant) {
          Router.reload();
        }
      });
    }

    if (networkError) {
      // eslint-disable-next-line no-console
      console.error(`[ARA Network error]: ${networkError}`);

      if (isBrowser) {
        const { result, statusCode } = networkError as ServerError;

        if (statusCode === 401) {
          if (typeof result === "object") {
            result.forEach((err: Record<string, any>) => {
              showError(err.message);
            });
          } else {
            showError(result);
          }

          apolloClient?.clearStore()?.then(() => {
            Router.push(routes.users.login);
          });
        } else {
          const bodyText = (networkError as ServerParseError).bodyText;

          Sentry.captureMessage(networkError.message, {
            level: "error",
            contexts: {
              apolloNetworkError: {
                error: networkError,
                statusCode: (networkError as ServerError).statusCode,
                bodyText:
                  typeof bodyText === "object"
                    ? JSON.stringify(bodyText)
                    : bodyText,
                result: (networkError as ServerError).result,
                extensions: (networkError as any).extensions,
              },
            },
          });

          showError("Network error");
        }
      }
    }
  });
});

function merge(_: any, incoming: any) {
  return incoming;
}

function create(initialState: any, ctx?: NextPageContext) {
  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient

  const headers: any = {};
  let appFetch = isBrowser ? undefined : fetch;

  if (ctx && ctx.req) {
    headers.cookie = ctx.req.headers.cookie;

    // SSR: set cookie back to client (used for server signout)
    appFetch = (input: RequestInfo | URL, init?: RequestInit) =>
      fetch(input, init).then((response) => {
        const cookie = response.headers.get("Set-Cookie");
        if (ctx.res && cookie) ctx.res.setHeader("Set-Cookie", cookie);
        return response;
      });
  }

  const persistedQueriesLink = createPersistedQueryLink({ sha256 });

  const uploadLink: any = createUploadLink({
    uri: process.env.API_URL, // Server URL (must be absolute)
    credentials: "include", // Additional fetch() options like `credentials` or `headers`
    headers,
    fetch: appFetch,
  });

  return new ApolloClient({
    connectToDevTools: isBrowser,
    ssrMode: !isBrowser, // Disables forceFetch on the server (so queries are only run once)
    link: ApolloLink.from([
      cleanTypeName,
      sentryLink,
      errorLink,
      persistedQueriesLink,
      uploadLink,
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            // necessary to resolve issues when using directives in DashboardReport query
            dashboardReport: {
              merge(existing = {}, incoming = {}) {
                return { ...existing, ...incoming };
              },
            },
          },
        },
        CropField: {
          keyFields: (f: any) => ["CropField", f.id, f.cropStage?.id].join(":"),
        },
        ExpenseRecord: {
          //do not cache key for update currency change
          keyFields: false,
          fields: {
            columns: { merge: false },
          },
        },
        GeneralLedger: { keyFields: false },
        IncomeStatementRecord: { keyFields: false },
        InventoryEntry: { keyFields: false },
        TrialBalance: { keyFields: false },
        BalanceSheet: { keyFields: false },
        FinanceInvoice: {
          keyFields: (o) => ["FinanceInvoice", o.id, o.currencyCode].join("-"),
        },
        InventoryMovement: { keyFields: false },
        HarvestCropField: { keyFields: false },
        HarvestDaily: { keyFields: false },
        WorkOrderDaily: { keyFields: false },
        PlanningCostCenter: {
          keyFields: (cc: any) =>
            [
              "PlanningCostCenter",
              cc.id,
              cc.costCenter.id,
              cc.profitableId,
            ].join("-"),
        },
        PlanningPosition: {
          keyFields: (p: any) =>
            ["PlanningPosition", p.id, p.position.id].join("-"),
        },
        PlanningVariant: {
          keyFields: (v: any) =>
            ["PlanningVariant", v.id, v.variant.id].join("-"),
        },
        PlanningMachine: {
          keyFields: (m: any) =>
            ["PlanningMachine", m.id, m.machineKind.id].join("-"),
        },
        PlanningCostCenterVariant: {
          keyFields: false,
        },
        PlanningCostCenterPosition: {
          keyFields: false,
        },
        PlanningCostCenterMachine: {
          keyFields: false,
        },
        WorkOrder: {
          fields: {
            employees: { merge },
            costCenters: { merge },
            machines: { merge },
            inputs: { merge },
            outputs: { merge },
            tools: { merge },
            tokens: { merge },
          },
        },
        WorkOrderCostCenter: {
          fields: {
            employees: { merge },
            machines: { merge },
            variants: { merge },
          },
        },
        WorkOrderVariant: {
          fields: {
            sources: { merge },
          },
        },
        SalaryStructure: {
          fields: {
            salaryComponents: { merge },
          },
        },
        PayrollEntry: {
          fields: {
            employees: { merge },
          },
        },
        HolidayList: {
          fields: { holidays: { merge } },
        },
        Activity: {
          fields: {
            activityMetrics: { merge },
          },
        },
        InventoryOrder: {
          fields: {
            taxValues: { merge },
          },
        },
        Tenant: {
          fields: {
            features: { merge },
          },
        },
      },
    }).restore(initialState || {}),
  });
}

export default function initApollo(initialState: any, ctx?: NextPageContext) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!isBrowser) {
    return create(initialState, ctx);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState, ctx);
  }

  return apolloClient;
}

export const omitTypename = <T>(vars: T): Omit<T, "__typename"> => {
  return JSON.parse(JSON.stringify(vars), (key: string, value: T[keyof T]) =>
    key === "__typename" ? undefined : value
  );
};
