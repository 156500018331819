import { Button, Progress, Space } from "antd";
import Link from "next/link";
import { FormattedMessage } from "react-intl";
import { colors } from "../../../lib/formats";
import { useOnboardingProgress } from "../../../lib/hooks/onboarding";
import routes from "../../../lib/routes";
import { useRouter } from "next/router";

export default function OnboardingProgress() {
  const router = useRouter();
  const onboardingProgress = useOnboardingProgress();

  if (typeof onboardingProgress === "undefined") return null;

  const isOnboarding = routes.onboarding == router.pathname;

  return (
    <Space align="center">
      <Progress
        type="circle"
        percent={onboardingProgress}
        width={40}
        strokeColor={{
          "0%": colors.aragroColor,
          "100%": colors.successColor,
        }}
      />

      {isOnboarding ? (
        <Link href={routes.dashboard} legacyBehavior>
          <Button href={routes.dashboard} data-action="continue-onboarding">
            <FormattedMessage
              id="onboarding.dashboard"
              defaultMessage="Go to dashboard"
            />
          </Button>
        </Link>
      ) : (
        <Link href={routes.onboarding} legacyBehavior>
          <Button href={routes.onboarding} data-action="continue-onboarding">
            <FormattedMessage
              id="onboarding.pageTitle"
              defaultMessage="Onboarding checklist"
            />
          </Button>
        </Link>
      )}
    </Space>
  );
}
