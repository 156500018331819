import { ApolloClient, useQuery } from "@apollo/client";
import {
  JobStatusQuery,
  JobStatusQueryVariables,
  JobStatusDocument,
} from "../../graphql";

export function useJobStatus(jobId: string) {
  const { data, startPolling, stopPolling } = useQuery<
    JobStatusQuery,
    JobStatusQueryVariables
  >(JobStatusDocument, { variables: { jobId } });

  return { data: data && data.jobStatus, startPolling, stopPolling };
}

export function watchJobStatus(
  client: ApolloClient<object>,
  jobId: string,
  onResult: (jobStatus: any) => boolean
) {
  const query = client.watchQuery<JobStatusQuery, JobStatusQueryVariables>({
    query: JobStatusDocument,
    variables: { jobId },
    pollInterval: 2000,
  });
  return query.subscribe((observer) => {
    const jobStatus = observer.data?.jobStatus;

    if (onResult(jobStatus)) {
      query.stopPolling();
    }
  });
}
