import { Input } from "antd";
import { usePhoneCodeOptions } from "../../../lib/hooks";
import { Form, SelectField } from "../../form";

interface PhoneProps {
  name?: string;
}

export function PhoneInput({ name }: PhoneProps) {
  return (
    <Input.Group compact>
      <Form.Item name={name ? [name, "phoneCode"] : "phoneCode"} noStyle>
        <SelectField
          optionsHook={usePhoneCodeOptions}
          style={{ width: "30%" }}
        />
      </Form.Item>

      <Form.Item name={name ? [name, "phoneNumber"] : "phoneNumber"} noStyle>
        <Input style={{ width: "70%" }} />
      </Form.Item>
    </Input.Group>
  );
}
