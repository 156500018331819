import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";

import {
  CropFieldNotesDocument,
  CropFieldNotesQuery,
  CropFieldNotesQueryVariables,
  CropFieldNoteUpdateMutation,
  CropFieldNoteUpdateMutationVariables,
  CropFieldNoteUpdateDocument,
  CropFieldNoteCreateMutation,
  CropFieldNoteCreateMutationVariables,
  CropFieldNoteCreateDocument,
  CropFieldNoteQuery,
  CropFieldNoteQueryVariables,
  CropFieldNoteDocument,
  CropFieldNoteTypeDocument,
  CropFieldNoteTypeQuery,
  CropFieldNoteTypeQueryVariables,
  CropFieldNoteType,
  SampleSection,
  SampleSectionQuery,
  SampleSectionDocument,
  SampleSectionQueryVariables,
  CropFieldNoteDiscardDocument,
  CropFieldNoteDiscardMutation,
  CropFieldNoteDiscardMutationVariables,
} from "../../graphql";
import { useTranslate } from "../basic/basic";

export function useCropFieldNoteFormats() {
  const translate = useTranslate();

  return {
    formatCropFieldNoteType: (type: CropFieldNoteType) =>
      translate(`cropFieldNotes.kinds.${type}`),
    formatSampleSection: (section: SampleSection) =>
      translate(`diseases.sections.${section}`),
  };
}

export function useCropFieldNoteTypeOptions() {
  const { formatCropFieldNoteType } = useCropFieldNoteFormats();

  const [load, { loading, data }] = useLazyQuery<
    CropFieldNoteTypeQuery,
    CropFieldNoteTypeQueryVariables
  >(CropFieldNoteTypeDocument);

  const options = data?.__type?.enumValues?.map((s) => ({
    key: s.name,
    value: s.name,
    label: formatCropFieldNoteType(s.name as CropFieldNoteType),
  }));

  return { load, loading, options };
}

export function useSampleSectionOptions() {
  const { formatSampleSection } = useCropFieldNoteFormats();

  const [load, { loading, data }] = useLazyQuery<
    SampleSectionQuery,
    SampleSectionQueryVariables
  >(SampleSectionDocument);

  const options = data?.__type?.enumValues?.map((s) => ({
    key: s.name,
    value: s.name,
    label: formatSampleSection(s.name as SampleSection),
  }));

  return { load, loading, options };
}

export function useCropFieldNotes(
  options?: QueryHookOptions<CropFieldNotesQuery, CropFieldNotesQueryVariables>
) {
  const { loading, data, refetch } = useQuery(CropFieldNotesDocument, options);

  const items = data?.cropFieldNotes?.items || [];
  const totalCount = data?.cropFieldNotes?.totalCount;

  return { loading, items, totalCount, refetch };
}

export function useCropFieldNote(id: string) {
  const { data } = useQuery<CropFieldNoteQuery, CropFieldNoteQueryVariables>(
    CropFieldNoteDocument,
    {
      variables: { id },
    }
  );

  return data && data.cropFieldNote;
}

export function useCropFieldNoteUpdate(
  options?: MutationHookOptions<
    CropFieldNoteUpdateMutation,
    CropFieldNoteUpdateMutationVariables
  >
) {
  return useMutation(CropFieldNoteUpdateDocument, options);
}

export function useCropFieldNoteCreate(
  options?: MutationHookOptions<
    CropFieldNoteCreateMutation,
    CropFieldNoteCreateMutationVariables
  >
) {
  return useMutation(CropFieldNoteCreateDocument, options);
}

export function useCropFieldNoteDiscard(
  options?: MutationHookOptions<
    CropFieldNoteDiscardMutation,
    CropFieldNoteDiscardMutationVariables
  >
) {
  return useMutation(CropFieldNoteDiscardDocument, options);
}
