import { Popover as AntPopover, PopoverProps as AntPopoverProps } from "antd";

export interface PopoverProps extends AntPopoverProps {
  readonly?: boolean;
}

export function Popover({
  readonly,
  trigger = "click",
  ...props
}: PopoverProps) {
  return <AntPopover trigger={readonly ? "none" : trigger} {...props} />;
}
