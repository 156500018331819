import { Tag, Typography } from "antd";
import { Space } from "../../components/shared";
import { MachineFragment, MachineShortFragment } from "../graphql";
import routes from "../routes";
import { formatDetailsLink } from "./common";
import { formatUnitValue } from "./units";
import { FormattedMessage } from "react-intl";

export function formatMachine(
  machine: MachineShortFragment & { discardedAt?: boolean }
) {
  return (
    <div>
      {formatDetailsLink({
        id: machine.id,
        route: routes.machines.details,
        title: machine.name,
        discarded: !!machine.discardedAt,
      })}
      {machine.kind.name}
    </div>
  );
}

export function formatMachineOption(machine: MachineFragment) {
  return (
    <Space>
      {machine.name}-
      <Typography.Text type="secondary">{machine.model}</Typography.Text>
      <Tag color="blue">{machine.brand.name}</Tag>
    </Space>
  );
}

export function formatMachineCapacity(
  machine: Pick<
    MachineFragment,
    "capacityTime" | "capacityTimeUnit" | "capacityUnit" | "capacityValue"
  >
) {
  return (
    <>
      {formatUnitValue(machine.capacityValue, machine.capacityUnit)}{" "}
      {machine.capacityTimeUnit && (
        <FormattedMessage
          id="per"
          values={{
            value: formatUnitValue(
              machine.capacityTime,
              machine.capacityTimeUnit
            ),
          }}
        />
      )}
    </>
  );
}
