import { createContext, FC, Fragment } from "react";
import { useApolloClient } from "@apollo/client";
import { ConfigProvider, message } from "antd";
import { useCookies } from "react-cookie";
import { IntlProvider } from "react-intl";
import { DefaultCookieOptions, LOCALE_COOKIE } from ".";
import localeConfig, { AvailableLocales } from "../../../lib/locale/config";
import { Locale } from "antd/lib/locale-provider";
import { MessageInstance } from "antd/lib/message";
import { UserError } from "../../../lib/graphql";
import { startCase } from "lodash";

interface LocaleContextType {
  currentLocale: AvailableLocales;
  setLocale(locale: AvailableLocales): void;
  antLocale: Locale;
  showError: MessageInstance["error"];
  showSuccess: MessageInstance["success"];
  showErrors(errors: Array<Pick<UserError, "message" | "path">>): void;
}

export const LocaleContext = createContext<LocaleContextType>({
  currentLocale: "es",
  setLocale: () => {},
  antLocale: localeConfig("es"),
  showError: undefined as any,
  showSuccess: undefined as any,
  showErrors: undefined as any,
});

export const LocaleProvider: FC<
  React.PropsWithChildren<{
    locale?: AvailableLocales;
    language?: string;
    timezone?: string;
  }>
> = ({ locale, language, timezone, children }) => {
  const [cookies, setCookie] = useCookies();
  const currentLocale = locale || cookies[LOCALE_COOKIE] || language;
  const currentLocaleConfig = localeConfig(currentLocale);
  const apolloClient = useApolloClient();
  const [messageApi, contextHolder] = message.useMessage();

  function setLocale(locale: AvailableLocales) {
    setCookie(LOCALE_COOKIE, locale, DefaultCookieOptions);
    apolloClient.resetStore();
  }

  function showErrors(errors: Array<Pick<UserError, "message" | "path">>) {
    if (!errors.length) return;

    message.error(
      <>
        {errors.map((error, i) => (
          <Fragment key={i}>
            {startCase(error.path)}: {error.message}
            <br />
          </Fragment>
        ))}
      </>
    );
  }

  return (
    <LocaleContext.Provider
      value={{
        currentLocale,
        setLocale,
        antLocale: currentLocaleConfig.ant,
        showError: messageApi.error,
        showSuccess: messageApi.success,
        showErrors,
      }}
    >
      <IntlProvider
        locale={currentLocale === "es" ? "es-NI" : currentLocale}
        timeZone={timezone}
        messages={currentLocaleConfig.messages}
      >
        <ConfigProvider locale={currentLocaleConfig.ant}>
          {contextHolder}

          {children}
        </ConfigProvider>
      </IntlProvider>
    </LocaleContext.Provider>
  );
};
