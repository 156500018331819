import { QueryHookOptions, useQuery } from "@apollo/client";
import {
  TrialBalanceReportQuery,
  TrialBalanceReportQueryVariables,
  TrialBalanceReportDocument,
} from "../../graphql";

export function useTrialBalanceReport(
  options?: QueryHookOptions<
    TrialBalanceReportQuery,
    TrialBalanceReportQueryVariables
  >
) {
  const { loading, data } = useQuery(TrialBalanceReportDocument, options);

  if (!data || !data.trialBalanceReport || !data.trialBalanceReport.items) {
    return { loading };
  }

  const items = data.trialBalanceReport.items;
  const totalCount = data.trialBalanceReport.totalCount;

  return { loading, items, totalCount };
}
