import { Row, Col, Button } from "antd";
import { SelectField, SelectFieldProps, SelectOption } from "../../form";
import { useState, ReactNode, Dispatch, SetStateAction } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";

export interface TableSelectProps<T> extends SelectFieldProps {
  entityById?(
    id: string,
    option: any
  ): T | Array<T> | Promise<T[]> | null | undefined;
  onAdd?(selected: Array<T | T[]>): void;
  onSelectedChanged?(selected: Array<T | T[]>): void;
  beforeAdd?(selected: Array<T | T[]>): void;
  afterAdd?(selected: Array<T | T[]>): void;
  render?(props: {
    selectedItems: SelectOption[] | undefined;
    onChange: (value: SelectOption | SelectOption[], options: any) => void;
  }): ReactNode;
  loading?: boolean;
  prefix?(props: {
    setSelectedItems: Dispatch<SetStateAction<SelectOption[] | undefined>>;
  }): ReactNode;
}

export function TableSelect<T>({
  entityById,
  onAdd,
  onSelectedChanged,
  beforeAdd,
  afterAdd,
  render,
  prefix,
  ...selectFieldProps
}: TableSelectProps<T>) {
  const [selectedItems, setSelectedItems] = useState<SelectOption[]>();
  const [selectedEntities, setSelectedEntities] = useState<
    Record<string, T | T[]>
  >({});
  const [loadingItems, setLoadingItems] = useState(false);

  const onSelectChange = async (
    value: SelectOption | SelectOption[],
    options: any
  ) => {
    setLoadingItems(true);

    const arrVal = Array.isArray(value) ? value : [value];

    setSelectedItems(arrVal);

    if (!value) {
      setSelectedEntities({});
      return;
    }

    const entities = {} as Record<string, T | T[]>;

    for (const val of arrVal) {
      const option = options.find((o: any) => o.key == val.key);
      const key = String(val.key);

      const entity =
        selectedEntities[key] ||
        (entityById && (await entityById(key, option || {})));

      if (entity) {
        entities[key] = entity;
      }
    }

    setSelectedEntities(entities);
    if (onSelectedChanged) onSelectedChanged(Object.values(entities));

    setLoadingItems(false);
  };

  return (
    <Row gutter={16} wrap={false} style={{ flex: 1 }}>
      <Col flex="auto" style={{ display: "flex" }}>
        {prefix ? (
          <div style={{ marginRight: "16px" }}>
            {prefix({ setSelectedItems })}
          </div>
        ) : undefined}

        {render ? (
          render({ selectedItems, onChange: onSelectChange })
        ) : (
          <SelectField
            labelInValue
            value={selectedItems}
            style={{ width: "100%" }}
            onChange={onSelectChange}
            {...selectFieldProps}
          />
        )}
      </Col>

      <Col>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          block
          loading={loadingItems}
          onClick={() => {
            const selected = Object.values(selectedEntities);
            beforeAdd && beforeAdd(selected);
            onAdd && onAdd(selected);
            afterAdd && afterAdd(selected);

            setSelectedEntities({});
            setSelectedItems([]);
          }}
        >
          <span className="hide-xs">
            <FormattedMessage id="add" defaultMessage="add" />
          </span>
        </Button>
      </Col>
    </Row>
  );
}
