import {
  QueryHookOptions,
  useQuery,
  MutationHookOptions,
  useMutation,
  useLazyQuery,
} from "@apollo/client";

import {
  ExpenseItemsQuery,
  ExpenseItemsQueryVariables,
  ExpenseItemsDocument,
  ExpenseItemUpdateMutation,
  ExpenseItemUpdateMutationVariables,
  ExpenseItemUpdateDocument,
  ExpenseItemCreateMutation,
  ExpenseItemCreateMutationVariables,
  ExpenseItemCreateDocument,
  ExpenseItemDiscardMutation,
  ExpenseItemDiscardMutationVariables,
  ExpenseItemDiscardDocument,
  ExpenseItemQuery,
  ExpenseItemQueryVariables,
  ExpenseItemDocument,
  SortDirection,
  ExpenseItemDestroyMutation,
  ExpenseItemDestroyMutationVariables,
  ExpenseItemDestroyDocument,
} from "../../graphql";

export function useExpenseItemOptions() {
  const [load, { loading, data }] = useLazyQuery<
    ExpenseItemsQuery,
    ExpenseItemsQueryVariables
  >(ExpenseItemsDocument, {
    variables: { sort: { name: SortDirection.Asc } },
  });

  const search = (name?: string) => {
    load(
      name
        ? {
            variables: {
              filter: { name: { match: name } },
              sort: { name: undefined },
            },
          }
        : undefined
    );
  };

  const options = data?.expenseItems?.items?.map((i) => ({
    key: i.id,
    label: i.name,
    group: i.expenseCategory.name,
    expenseItem: i,
  }));

  return { load, search, loading, options };
}

export function useExpenseItems(
  options?: QueryHookOptions<ExpenseItemsQuery, ExpenseItemsQueryVariables>
) {
  const { loading, data } = useQuery(ExpenseItemsDocument, options);

  if (!data || !data.expenseItems || !data.expenseItems.items) {
    return { loading };
  }

  const items = data.expenseItems.items;
  const totalCount = data.expenseItems.totalCount;

  return { loading, items, totalCount };
}

export function useExpenseItem(id: string) {
  const { data } = useQuery<ExpenseItemQuery, ExpenseItemQueryVariables>(
    ExpenseItemDocument,
    {
      variables: { id },
    }
  );

  return data && data.expenseItem;
}

export function useExpenseItemUpdate(
  options?: MutationHookOptions<
    ExpenseItemUpdateMutation,
    ExpenseItemUpdateMutationVariables
  >
) {
  return useMutation(ExpenseItemUpdateDocument, options);
}

export function useExpenseItemCreate(
  options?: MutationHookOptions<
    ExpenseItemCreateMutation,
    ExpenseItemCreateMutationVariables
  >
) {
  return useMutation(ExpenseItemCreateDocument, options);
}

export function useExpenseItemDiscard(
  options?: MutationHookOptions<
    ExpenseItemDiscardMutation,
    ExpenseItemDiscardMutationVariables
  >
) {
  return useMutation(ExpenseItemDiscardDocument, options);
}

export function useExpenseItemDestroy(
  options?: MutationHookOptions<
    ExpenseItemDestroyMutation,
    ExpenseItemDestroyMutationVariables
  >
) {
  return useMutation(ExpenseItemDestroyDocument, options);
}
