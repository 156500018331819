import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";

import {
  ItemCategoriesDocument,
  ItemCategoriesQuery,
  ItemCategoriesQueryVariables,
  ItemCategoryUpdateMutation,
  ItemCategoryUpdateMutationVariables,
  ItemCategoryUpdateDocument,
  ItemCategoryCreateMutation,
  ItemCategoryCreateMutationVariables,
  ItemCategoryCreateDocument,
  ItemCategoryDiscardMutation,
  ItemCategoryDiscardMutationVariables,
  ItemCategoryDiscardDocument,
  ItemCategoryUnlockMutation,
  ItemCategoryUnlockMutationVariables,
  ItemCategoryUnlockDocument,
  ItemCategoryQuery,
  ItemCategoryQueryVariables,
  ItemCategoryDocument,
  ItemCategoryDestroyDocument,
  ItemCategoryDestroyMutation,
  ItemCategoryDestroyMutationVariables,
  ItemCategoryOptionsQuery,
  ItemCategoryOptionsQueryVariables,
  ItemCategoryOptionsDocument,
  ItemCategoryLocalUpdateDocument,
  ItemCategoryLocalUpdateMutation,
  ItemCategoryLocalUpdateMutationVariables,
} from "../../graphql";

export function useItemCategories(
  options?: QueryHookOptions<ItemCategoriesQuery, ItemCategoriesQueryVariables>
) {
  const { loading, data, refetch } = useQuery(ItemCategoriesDocument, options);

  const items = data?.itemCategories?.items;

  return { loading, items, refetch };
}

export function useItemCategoryOptions(
  queryOptions?: QueryHookOptions<
    ItemCategoryOptionsQuery,
    ItemCategoryOptionsQueryVariables
  >
) {
  const [load, { loading, data, refetch }] = useLazyQuery(
    ItemCategoryOptionsDocument,
    queryOptions
  );

  const search = (name?: string) => {
    load(
      name
        ? {
            variables: {
              filter: { name: { match: name } },
            },
          }
        : undefined
    );
  };

  const items = data?.itemCategories?.items;

  const options = items
    ? items.map((itemCategory) => ({
        key: itemCategory.id,
        label: itemCategory.name,
        itemCategory,
      }))
    : [];

  return { loading, items, options, load, search, refetch };
}

export function useItemCategory(id: string) {
  const { data } = useQuery<ItemCategoryQuery, ItemCategoryQueryVariables>(
    ItemCategoryDocument,
    {
      variables: { id },
    }
  );

  return data && data.itemCategory;
}

export function useItemCategoryUpdate(
  options?: MutationHookOptions<
    ItemCategoryUpdateMutation,
    ItemCategoryUpdateMutationVariables
  >
) {
  return useMutation(ItemCategoryUpdateDocument, options);
}

export function useItemCategoryLocalUpdate(
  options?: MutationHookOptions<
    ItemCategoryLocalUpdateMutation,
    ItemCategoryLocalUpdateMutationVariables
  >
) {
  return useMutation(ItemCategoryLocalUpdateDocument, options);
}

export function useItemCategoryCreate(
  options?: MutationHookOptions<
    ItemCategoryCreateMutation,
    ItemCategoryCreateMutationVariables
  >
) {
  return useMutation(ItemCategoryCreateDocument, options);
}

export function useItemCategoryDiscard(
  options?: MutationHookOptions<
    ItemCategoryDiscardMutation,
    ItemCategoryDiscardMutationVariables
  >
) {
  return useMutation(ItemCategoryDiscardDocument, options);
}

export function useItemCategoryDestroy(
  options?: MutationHookOptions<
    ItemCategoryDestroyMutation,
    ItemCategoryDestroyMutationVariables
  >
) {
  return useMutation(ItemCategoryDestroyDocument, options);
}

export function useItemCategoryUnlock(
  options?: MutationHookOptions<
    ItemCategoryUnlockMutation,
    ItemCategoryUnlockMutationVariables
  >
) {
  return useMutation(ItemCategoryUnlockDocument, options);
}
