import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import {
  AntDatePicker,
  DatePicker,
  RangePickerFormItemProps,
} from "./DatePicker";
import { PickerTimeProps } from "antd/lib/date-picker/generatePicker";
import { useCurrentLocale } from "../../lib/hooks";
import { Input } from "antd";

export interface TimePickerProps
  extends Omit<PickerTimeProps<Dayjs>, "picker" | "value"> {
  value?: string;
}

export const TIME_FORMAT = "h:mm a";

export const TimePicker = React.forwardRef<any, TimePickerProps>(
  ({ value, ...props }, ref) => {
    const { antLocale } = useCurrentLocale();

    return (
      <AntDatePicker
        format={TIME_FORMAT}
        minuteStep={15}
        locale={antLocale.DatePicker}
        use12Hours
        value={value ? dayjs(value).tz() : undefined}
        {...props}
        picker="time"
        mode={undefined}
        ref={ref}
      />
    );
  }
);

export function TimeRangePickerFormItem({
  addonBefore,
  addonAfter,
  ...props
}: {
  addonBefore?: React.ReactNode;
  addonAfter?: React.ReactNode;
} & Omit<RangePickerFormItemProps, "minuteStep">) {
  const { antLocale } = useCurrentLocale();

  return (
    <Input.Group style={{ minWidth: "300px" }}>
      {addonBefore && (
        <span className="ant-input-group-addon">{addonBefore}</span>
      )}

      <DatePicker.RangePickerFormItem
        picker="time"
        format={TIME_FORMAT}
        minuteStep={15}
        locale={antLocale.DatePicker}
        use12Hours
        {...props}
      />

      {addonAfter && (
        <span className="ant-input-group-addon">{addonAfter}</span>
      )}
    </Input.Group>
  );
}
