import {
  QueryHookOptions,
  useQuery,
  MutationHookOptions,
  useMutation,
  useLazyQuery,
} from "@apollo/client";
import { merge } from "lodash";
import { useInfrastructureCategoryFormat } from "..";
import { BLANK_SYMBOL } from "../../formats";
import {
  WarehousesQuery,
  WarehousesQueryVariables,
  WarehousesDocument,
  WarehouseUpdateMutation,
  WarehouseUpdateMutationVariables,
  WarehouseUpdateDocument,
  WarehouseCreateMutation,
  WarehouseCreateMutationVariables,
  WarehouseCreateDocument,
  WarehouseDiscardMutation,
  WarehouseDiscardMutationVariables,
  WarehouseDiscardDocument,
  WarehouseDocument,
  WarehouseQuery,
  WarehouseQueryVariables,
  StockWarehousesQuery,
  StockWarehousesQueryVariables,
  StockWarehousesDocument,
  SortDirection,
} from "../../graphql";

export function useWarehouseOptions(
  queryOptions?: QueryHookOptions<WarehousesQuery, WarehousesQueryVariables>
) {
  const categoryFormat = useInfrastructureCategoryFormat();
  const [load, { loading, data }] = useLazyQuery(
    WarehousesDocument,
    merge({ variables: { sort: { name: SortDirection.Asc } } }, queryOptions)
  );

  const options =
    data?.warehouses?.items.map((w) => ({
      key: w.id,
      label: w.name,
      group: w.infrastructure
        ? categoryFormat(w.infrastructure.kind.category)
        : BLANK_SYMBOL,
      warehouse: w,
    })) || [];

  const search = (val?: string) =>
    load(
      merge({ variables: { sort: undefined }, pageSize: 75 }, queryOptions, {
        variables: {
          filter: {
            name: { match: val },
          },
        },
      })
    );

  return {
    load,
    search,
    loading,
    options,
  };
}

export function useStockWarehouseOptions(
  queryOptions?: QueryHookOptions<
    StockWarehousesQuery,
    StockWarehousesQueryVariables
  >,
  groupByLocality?: boolean
) {
  const [load, { loading, data }] = useLazyQuery(
    StockWarehousesDocument,
    queryOptions
  );
  const categoryFormat = useInfrastructureCategoryFormat();

  const warehouses = (data && data.warehouses && data.warehouses.items) || [];
  // eslint-disable-next-line sonarjs/no-identical-functions
  const options = warehouses.map((w) => ({
    key: w.id,
    label: w.name,
    group: groupByLocality
      ? w.locality.name
      : w.infrastructure
      ? categoryFormat(w.infrastructure.kind.category)
      : BLANK_SYMBOL,
    warehouse: w,
  }));

  return {
    load,
    loading,
    options,
  };
}

export function useWarehouses(
  options?: QueryHookOptions<WarehousesQuery, WarehousesQueryVariables>
) {
  const { loading, data } = useQuery(WarehousesDocument, options);

  if (!data || !data.warehouses || !data.warehouses.items) {
    return { loading };
  }

  const items = data.warehouses.items;
  const totalCount = data.warehouses.totalCount;

  return { loading, items, totalCount };
}

export function useWarehouse(id: string) {
  const { data } = useQuery<WarehouseQuery, WarehouseQueryVariables>(
    WarehouseDocument,
    { variables: { id } }
  );

  return data && data.warehouse;
}

export function useWarehouseUpdate(
  options?: MutationHookOptions<
    WarehouseUpdateMutation,
    WarehouseUpdateMutationVariables
  >
) {
  return useMutation(WarehouseUpdateDocument, options);
}

export function useWarehouseCreate(
  options?: MutationHookOptions<
    WarehouseCreateMutation,
    WarehouseCreateMutationVariables
  >
) {
  return useMutation(WarehouseCreateDocument, options);
}

export function useWarehouseDiscard(
  options?: MutationHookOptions<
    WarehouseDiscardMutation,
    WarehouseDiscardMutationVariables
  >
) {
  return useMutation(WarehouseDiscardDocument, options);
}
