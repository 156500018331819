import { Badge, Layout, Spin } from "antd";
import React, { ReactNode } from "react";
import {
  ItemSidebarProvider,
  ContentHeader,
  ContentHeaderProps,
} from "../components/shared";
import { useCurrentUser, useMediaXs } from "../lib/hooks";
import { PermissionsFragment } from "../lib/graphql";
import { useCheckNewVersion } from "../lib/hooks/version-check";
import Head from "./Head";
import { FormattedMessage } from "react-intl";

const { Content } = Layout;

export interface BasicLayoutProps extends Omit<ContentHeaderProps, "title"> {
  contentStyle?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
  authorize?: (p?: PermissionsFragment) => boolean;
  loading?: boolean;
  loadingTip?: ReactNode;
  fullContentHeight?: boolean;
  title?: string;
}

const BasicLayout: React.FunctionComponent<
  React.PropsWithChildren<BasicLayoutProps>
> = ({
  children,
  contentStyle,
  headerStyle,
  authorize,
  loading: layoutLoading,
  fullContentHeight = true,
  title = "",
  loadingTip,
  ...headerProps
}) => {
  const { useAuthorized, currentTenant } = useCurrentUser();
  const { user } = useAuthorized(authorize);
  const isXs = useMediaXs();

  useCheckNewVersion({ skip: !user });

  const content = (
    <Content
      style={{
        margin: isXs ? "16px 4px" : "16px",
        padding: isXs ? 10 : 24,
        background: "#fff",
        minHeight: "75vh",
        position: "relative",
        flex: fullContentHeight ? "auto" : "none",
        display: "flex",
        flexDirection: "column",
        ...contentStyle,
      }}
    >
      {children}
    </Content>
  );

  return (
    <>
      {user && <Head title={title} />}

      {!user || layoutLoading ? (
        <Spin size="large" tip={loadingTip} style={{ marginTop: "40vh" }} />
      ) : (
        <ItemSidebarProvider>
          <ContentHeader style={headerStyle} {...headerProps} />
          {currentTenant.liveTenantId ? (
            <Badge.Ribbon
              text={
                <FormattedMessage
                  id="tenants.testMode"
                  defaultMessage="Test Mode"
                />
              }
              color="#b42424"
              style={{ right: 8, top: 12 }}
            >
              {content}
            </Badge.Ribbon>
          ) : (
            content
          )}
        </ItemSidebarProvider>
      )}
    </>
  );
};

export default BasicLayout;
