import { Tag } from "antd";
import { FormattedMessage } from "react-intl";
import { ImportKindType, ImportModulesType } from "../graphql";
import { formatAssetPath } from "./assets";

const statusColors: Record<string, string> = {
  created: "blue",
  previewQueued: "blue",
  previewInProgress: "blue",
  preview: "geekblue",
  queued: "blue",
  inProgress: "blue",
  done: "green",
  canceled: "red",
};

export function formatImportStatusColor(status: string) {
  return statusColors[status];
}

export function formatImportStatus(status: string, type?: "tag") {
  const text = <FormattedMessage {...{ id: `imports.statuses.${status}` }} />;
  if (type === "tag")
    return <Tag color={formatImportStatusColor(status)}>{text}</Tag>;
  return text;
}

export function formatImportTemplatePath(
  moduleName: ImportModulesType,
  localeCode: String,
  kind: ImportKindType
) {
  return formatAssetPath(
    `/templates/download/${moduleName}.xlsx?locale_code=${localeCode}&mode=${kind}`
  );
}
