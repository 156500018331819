import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";
import {
  ActivitiesDocument,
  ActivitiesQuery,
  ActivitiesQueryVariables,
  ActivityUpdateMutation,
  ActivityUpdateMutationVariables,
  ActivityUpdateDocument,
  ActivityCreateMutation,
  ActivityCreateMutationVariables,
  ActivityCreateDocument,
  ActivityDiscardMutation,
  ActivityDiscardMutationVariables,
  ActivityDiscardDocument,
  ActivityDocument,
  ActivityQuery,
  ActivityQueryVariables,
  ActivityProgressMeasureTypeQuery,
  ActivityProgressMeasureTypeDocument,
  ActivityLaborForceTypeQuery,
  ActivityLaborForceTypeDocument,
  WorkOrderCategory,
  SortDirection,
  ActivityDestroyDocument,
  ActivityDestroyMutation,
  ActivityDestroyMutationVariables,
} from "../../graphql";
import { useIntl } from "react-intl";
import { merge } from "lodash";
import { usePermissions } from "..";

export function useCycleNumberOptions() {
  const options = [...Array(150).keys()].map((a) => ({
    key: ++a,
    label: a.toString(),
  }));

  return { options };
}

export function useActivityProgressMeasureTypesFormat() {
  const intl = useIntl();

  return (type: string) =>
    intl.formatMessage({ id: `activities.progressMeasureTypes.${type}` });
}

export function useActivityProgressMeasureTypes() {
  const formatActivityProgressMeasureType =
    useActivityProgressMeasureTypesFormat();

  // used as radio group options, load on mount
  const { data } = useQuery<ActivityProgressMeasureTypeQuery>(
    ActivityProgressMeasureTypeDocument
  );

  return {
    options: data?.__type?.enumValues?.map((t) => ({
      key: t.name,
      value: t.name,
      label: formatActivityProgressMeasureType(t.name),
    })),
  };
}

export function useActivityLaborForceTypesFormat() {
  const intl = useIntl();

  return (type: string) =>
    intl.formatMessage({ id: `activities.laborForceTypes.${type}` });
}

export function useActivityLaborForceTypes() {
  const formatActivityLaborForceType = useActivityLaborForceTypesFormat();

  const { data } = useQuery<ActivityLaborForceTypeQuery>(
    ActivityLaborForceTypeDocument
  );

  return {
    options: data?.__type?.enumValues?.map((t) => ({
      key: t.name,
      value: t.name,
      label: formatActivityLaborForceType(t.name),
    })),
  };
}

export function useActivityOptions(
  queryOptions?: QueryHookOptions<ActivitiesQuery, ActivitiesQueryVariables>
) {
  const [load, { loading, data }] = useLazyQuery(
    ActivitiesDocument,
    merge({ variables: { sort: { name: SortDirection.Asc } } }, queryOptions)
  );

  const options =
    data?.activities?.items?.map((a) => ({
      key: a.id,
      label: a.name,
      group: a.category.name,
      activity: a,
    })) || [];

  const search = (val?: string) => {
    load(
      merge({ variables: { sort: undefined } }, queryOptions, {
        variables: { filter: { name: { match: val } } },
      })
    );
  };

  return {
    load,
    search,
    options,
    loading,
  };
}

export function useActivities(
  options?: QueryHookOptions<ActivitiesQuery, ActivitiesQueryVariables>
) {
  const { loading, data, refetch } = useQuery(ActivitiesDocument, options);

  if (!data || !data.activities || !data.activities.items) {
    return { loading };
  }

  const items = data.activities.items;
  const totalCount = data.activities.totalCount;

  return { loading, items, totalCount, refetch };
}

export function useActivity(id: string) {
  const showWage = usePermissions((p) => p.settings?.showWage);
  const { data } = useQuery<ActivityQuery, ActivityQueryVariables>(
    ActivityDocument,
    {
      variables: { id, showWage },
    }
  );

  return data && data.activity;
}

export function useActivityUpdate(
  options?: MutationHookOptions<
    ActivityUpdateMutation,
    ActivityUpdateMutationVariables
  >
) {
  const showWage = usePermissions((p) => p.settings?.showWage);
  const showVars = {
    variables: { showWage } as ActivityUpdateMutationVariables,
  };
  return useMutation(ActivityUpdateDocument, merge(options, showVars));
}

export function useActivityCreate(
  options?: MutationHookOptions<
    ActivityCreateMutation,
    ActivityCreateMutationVariables
  >
) {
  return useMutation(ActivityCreateDocument, options);
}

export function useActivityDiscard(
  options?: MutationHookOptions<
    ActivityDiscardMutation,
    ActivityDiscardMutationVariables
  >
) {
  return useMutation(ActivityDiscardDocument, options);
}

export function useActivityDestroy(
  options?: MutationHookOptions<
    ActivityDestroyMutation,
    ActivityDestroyMutationVariables
  >
) {
  return useMutation(ActivityDestroyDocument, options);
}

export const WO_CATEGORY_COLORS_MAP: any = {
  [WorkOrderCategory.Agricultural]: "#00d0a6",
  [WorkOrderCategory.Machinery]: "#2654c7",
  [WorkOrderCategory.General]: "#4b11d3",
  [WorkOrderCategory.Infrastructural]: "#00beb3",
};
