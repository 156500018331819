import { QueryHookOptions, useQuery } from "@apollo/client";
import { useIntl } from "react-intl";
import {
  JournalReportQuery,
  JournalReportQueryVariables,
  JournalReportDocument,
  FinanceDocumentType,
} from "../../graphql";

export function useJournalReport(
  options?: QueryHookOptions<JournalReportQuery, JournalReportQueryVariables>
) {
  const { loading, data } = useQuery(JournalReportDocument, options);

  if (!data || !data.journalReport || !data.journalReport.items) {
    return { loading };
  }

  const items = data.journalReport.items;
  const totalCount = data.journalReport.totalCount;

  return { loading, items, totalCount };
}

export function useFinanceDocumentTypeOptions() {
  const intl = useIntl();

  return [
    {
      key: "WOW",
      label: intl.formatMessage({
        id: "expenseOrders.workOrders.wages",
        defaultMessage: "Work Order - Wages",
      }),
    },
    {
      key: "WOI",
      label: intl.formatMessage({
        id: "expenseOrders.workOrders.inputs",
        defaultMessage: "Work Order - Inputs",
      }),
    },
    {
      key: "PU",
      label: intl.formatMessage({ id: "inventoryPurchaseOrders" }),
    },
    {
      key: "SO",
      label: intl.formatMessage({ id: "inventorySaleOrders" }),
    },
    {
      key: FinanceDocumentType.InventoryTransfer,
      label: intl.formatMessage({ id: "stockAdjustments" }),
    },
    // Cost Adjustments?
    {
      key: FinanceDocumentType.FinanceOrder,
      label: intl.formatMessage({ id: "expenseOrders.entityName" }),
    },
    {
      key: FinanceDocumentType.FinanceJournalEntry,
      label: intl.formatMessage({ id: "journalEntries.entityName" }),
    },
    {
      key: FinanceDocumentType.FinancePayment,
      label: intl.formatMessage({ id: "payments" }),
    },
    {
      key: FinanceDocumentType.HrPayrollEntry,
      label: intl.formatMessage({ id: "payrollEntries" }),
    },
  ];
}
