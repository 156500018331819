import React, { useState } from "react";
import { useCopyToClipboard } from "react-use";
import { Comment, Tooltip, Modal, Row, Col } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  LinkOutlined,
  DownloadOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Avatar } from "../Avatar";
import CommentForm from "./CommentForm";
import {
  useComments,
  useCommentDiscard,
  useCurrentUser,
  useCurrentLocale,
} from "../../../lib/hooks";
import { CommentableType, SortDirection } from "../../../lib/graphql";
import {
  formatDateTime,
  formatDateFromNow,
  formatCommentContent,
  formatAssetPath,
  formatCommentFile,
} from "../../../lib/formats";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import { Dropdown } from "../Dropdown";
import { formatUsername } from "../../../lib/formats/users";

interface CommentListProps {
  commentableType: CommentableType;
  commentableId: string;
}

const messages = defineMessages({
  comment: { id: "comment", defaultMessage: "comment" },
  discardSuccess: { id: "discard.success", defaultMessage: "success" },
  discardConfirm: { id: "discard.confirm", defaultMessage: "confirm" },
  reply: { id: "reply", defaultMessage: "reply" },
  copied: { id: "copyLink.success", defaultMessage: "copied" },
});

const { confirm } = Modal;

export function Comments({ commentableType, commentableId }: CommentListProps) {
  const { showSuccess } = useCurrentLocale();
  const intl = useIntl();
  const entityName = intl.formatMessage(messages.comment);
  const [currentComment, setCurrentComment] = useState<string>();
  const { user } = useCurrentUser();
  const [, copyToClipboard] = useCopyToClipboard();
  const { items } = useComments({
    variables: {
      filter: {
        commentableType: [commentableType],
        commentableId,
        discardedAt: null,
      },
      sort: { createdAt: SortDirection.Asc },
    },
  });
  const [discard] = useCommentDiscard({ refetchQueries: ["Comments"] });
  const currentUserId = user ? user.id : "";
  const onDiscard = (commentId: string) => {
    confirm({
      title: intl.formatMessage(messages.discardConfirm, { entityName }),
      onOk() {
        return discard({ variables: { id: commentId } })
          .then((result) => {
            if (!result.data) return null;

            return result.data.commentDiscard;
          })
          .then((result) => {
            if (result && result.result) {
              showSuccess(
                intl.formatMessage(messages.discardSuccess, { entityName })
              );
            }
          });
      },
      onCancel() {},
    });
  };

  return (
    <div className="comment-list">
      {items &&
        items.map((comment) =>
          comment.id === currentComment ? (
            <CommentForm
              key={comment.id}
              commentableType={commentableType}
              commentableId={commentableId}
              commentId={comment.id}
              content={comment.content}
              defaultFiles={comment.files}
              onChanged={() => setCurrentComment(undefined)}
            />
          ) : (
            <div id={`comment-${comment.id}`} key={comment.id}>
              <Comment
                key={comment.id}
                actions={[
                  <span
                    key="comment-basic-reply-to"
                    onClick={() => {
                      const textArea = document.querySelector(
                        "textarea"
                      ) as HTMLTextAreaElement;
                      const nativeInputValueSetter = (
                        Object.getOwnPropertyDescriptor(
                          window.HTMLTextAreaElement.prototype,
                          "value"
                        ) as any
                      ).set;
                      nativeInputValueSetter.call(
                        textArea,
                        `@${comment.user.email} `
                      );

                      const inputEvent = new Event("input", { bubbles: true });
                      textArea.dispatchEvent(inputEvent);
                      textArea.focus();
                    }}
                  >
                    {intl.formatMessage(messages.reply)}
                  </span>,
                  <span
                    key="comment-basic-copy-link"
                    onClick={() => {
                      copyToClipboard(`${location.href}#comment-${comment.id}`);
                      showSuccess(intl.formatMessage(messages.copied));
                    }}
                  >
                    <LinkOutlined style={{ marginRight: "2px" }} />
                    <FormattedMessage id="copyLink" defaultMessage="copyLink" />
                  </span>,
                ]}
                author={
                  <Tooltip title={comment.user.email}>
                    <span>{formatUsername(comment.user)}</span>
                  </Tooltip>
                }
                avatar={<Avatar user={comment.user} />}
                content={
                  <div>
                    <p>
                      {formatCommentContent({
                        comment,
                        canReadUsers: user?.permissions?.user?.read,
                      })}
                    </p>
                    <div className="ant-upload-list ant-upload-list-picture">
                      {comment.files?.map((file) => (
                        <div style={{ width: "50%" }} key={file.id}>
                          <div
                            style={{ height: "60px" }}
                            className="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture"
                          >
                            <div className="ant-upload-list-item-info">
                              <span className="ant-upload-span">
                                <a
                                  href={formatAssetPath(file.url)}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="ant-upload-list-item-thumbnail"
                                  style={{ top: "5px" }}
                                >
                                  {formatCommentFile(
                                    file.url,
                                    file.contentType
                                  )}
                                </a>
                                <span
                                  className="ant-upload-list-item-name"
                                  style={{ lineHeight: "41px" }}
                                >
                                  <a
                                    href={formatAssetPath(file.url)}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ color: "rgba(0, 0, 1, 0.65)" }}
                                  >
                                    {file.filename}
                                  </a>
                                </span>
                                <span
                                  className="ant-upload-list-item-card-actions picture"
                                  style={{ width: "25px" }}
                                >
                                  <a
                                    href={formatAssetPath(file.url)}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <DownloadOutlined />
                                  </a>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                }
                datetime={
                  <Row justify="space-between">
                    <Col>
                      <Tooltip title={formatDateTime(comment.createdAt)}>
                        <span>{formatDateFromNow(comment.createdAt)}</span>
                      </Tooltip>
                    </Col>
                    {comment.user.id === currentUserId && (
                      <Col>
                        <Dropdown
                          placement="bottomRight"
                          menu={{
                            triggerSubMenuAction: "click",
                            items: [
                              {
                                key: "edit",
                                icon: <EditOutlined />,
                                onClick: () => setCurrentComment(comment.id),
                                label: (
                                  <FormattedMessage
                                    id="edit"
                                    defaultMessage="edit"
                                  />
                                ),
                              },
                              { type: "divider" },
                              {
                                key: "delete",
                                danger: true,
                                icon: <DeleteOutlined />,
                                onClick: () => onDiscard(comment.id),
                                label: (
                                  <FormattedMessage
                                    id="delete"
                                    defaultMessage="delete"
                                  />
                                ),
                              },
                            ],
                          }}
                          trigger={["click"]}
                        >
                          <a
                            className="text-link"
                            style={{ fontSize: "16px" }}
                            onClick={(e) => e.preventDefault()}
                          >
                            <MoreOutlined />
                          </a>
                        </Dropdown>
                      </Col>
                    )}
                  </Row>
                }
              />
            </div>
          )
        )}
      <style jsx global>{`
        .ant-comment-avatar,
        .ant-avatar-string {
          cursor: default;
        }
      `}</style>
    </div>
  );
}
export default Comments;
