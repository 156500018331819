import { QueryHookOptions, useQuery } from "@apollo/client";
import { useIntl } from "react-intl";
import {
  InventoryEntryReportQuery,
  InventoryEntryReportQueryVariables,
  InventoryEntryReportDocument,
  InventoryDocumentFilterType,
} from "../../graphql";

export function useInventoryEntryReport(
  options?: QueryHookOptions<
    InventoryEntryReportQuery,
    InventoryEntryReportQueryVariables
  >
) {
  const { loading, data } = useQuery(InventoryEntryReportDocument, options);

  if (!data || !data.inventoryEntryReport || !data.inventoryEntryReport.items) {
    return { loading };
  }

  const items = data.inventoryEntryReport.items;
  const totalCount = data.inventoryEntryReport.totalCount;

  return { loading, items, totalCount };
}

export function useInventoryDocumentTypeFilterOptions() {
  const intl = useIntl();

  return [
    {
      key: InventoryDocumentFilterType.Adjustment,
      label: intl.formatMessage({ id: "stockAdjustments" }),
    },
    {
      key: InventoryDocumentFilterType.AgroWorkOrder,
      label: intl.formatMessage({ id: "workOrders.entityName" }),
    },
    {
      key: InventoryDocumentFilterType.Movement,
      label: intl.formatMessage({ id: "stockTransfers.entityName" }),
    },
    {
      key: InventoryDocumentFilterType.Purchase,
      label: intl.formatMessage({ id: "inventoryPurchaseOrders.entityName" }),
    },
    {
      key: InventoryDocumentFilterType.Reconciliation,
      label: intl.formatMessage({ id: "stockReconciliations.entityName" }),
    },
    {
      key: InventoryDocumentFilterType.Sale,
      label: intl.formatMessage({ id: "inventorySaleOrders.entityName" }),
    },
  ].sort((a, b) => a.label.localeCompare(b.label));
}
