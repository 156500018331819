import enMessages from "./en.json";
import esMessages from "./es.json";
import ruMessages from "./ru.json";
import ptMessages from "./pt.json";

import dynamicEnMessages from "./dynamic_en.json";
import dynamicEsMessages from "./dynamic_es.json";
import dynamicRuMessages from "./dynamic_ru.json";
import dynamicPtMessages from "./dynamic_pt.json";

require("dayjs/locale/es");
require("dayjs/locale/ru");
require("dayjs/locale/pt-br");

import enUS from "antd/lib/locale-provider/en_US";
import esES from "antd/lib/locale-provider/es_ES";
import ruRU from "antd/lib/locale-provider/ru_RU";
import ptBR from "antd/lib/locale-provider/pt_BR";

const messages = {
  en: { messages: { ...enMessages, ...dynamicEnMessages }, ant: enUS },
  es: { messages: { ...esMessages, ...dynamicEsMessages }, ant: esES },
  ru: { messages: { ...ruMessages, ...dynamicRuMessages }, ant: ruRU },
  pt: { messages: { ...ptMessages, ...dynamicPtMessages }, ant: ptBR },
};

export type AvailableLocales = keyof typeof messages;

const Config = (locale: keyof typeof messages) => {
  return {
    locale,
    ...messages[locale],
  };
};

export default Config;
