import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";
import { merge } from "lodash";
import { useEffectOnce } from "react-use";
import { formatDiseaseOption } from "../../formats";
import {
  DiseasesDocument,
  DiseasesQuery,
  DiseasesQueryVariables,
  DiseaseUpdateMutation,
  DiseaseUpdateMutationVariables,
  DiseaseUpdateDocument,
  DiseaseCreateMutation,
  DiseaseCreateMutationVariables,
  DiseaseCreateDocument,
  DiseaseDiscardMutation,
  DiseaseDiscardMutationVariables,
  DiseaseDiscardDocument,
  DiseaseUnlockMutation,
  DiseaseUnlockMutationVariables,
  DiseaseUnlockDocument,
  DiseaseQuery,
  DiseaseQueryVariables,
  DiseaseDocument,
  SortDirection,
  DiseaseTypeDocument,
  DiseaseTypeQuery,
  DiseaseTypeQueryVariables,
  DiseaseSourceDocument,
  DiseaseSourceQuery,
  DiseaseSourceQueryVariables,
  DiseaseType,
  DiseaseSource,
  DiseaseUpdateRisksMutation,
  DiseaseUpdateRisksMutationVariables,
  DiseaseUpdateRisksDocument,
  DiseaseDestroyMutation,
  DiseaseDestroyDocument,
  DiseaseDestroyMutationVariables,
} from "../../graphql";
import { useTranslate } from "../basic/basic";

export function useDiseaseFormats() {
  const translate = useTranslate();

  return {
    formatDiseaseType: (type: DiseaseType) =>
      translate(`diseases.kinds.${type}`),
    formatDiseaseSource: (source: DiseaseSource) =>
      translate(`diseases.sources.${source}`),
  };
}

export function useDiseaseTypeOptions() {
  const { formatDiseaseType } = useDiseaseFormats();

  const [load, { loading, data }] = useLazyQuery<
    DiseaseTypeQuery,
    DiseaseTypeQueryVariables
  >(DiseaseTypeDocument);

  const options = data?.__type?.enumValues?.map((s) => ({
    key: s.name,
    value: s.name,
    label: formatDiseaseType(s.name as DiseaseType),
  }));

  return { load, loading, options };
}

export function useDiseaseSourceOptions() {
  const { formatDiseaseSource } = useDiseaseFormats();

  const [load, { loading, data }] = useLazyQuery<
    DiseaseSourceQuery,
    DiseaseSourceQueryVariables
  >(DiseaseSourceDocument);

  const options = data?.__type?.enumValues?.map((s) => ({
    key: s.name,
    value: s.name,
    label: formatDiseaseSource(s.name as DiseaseSource),
  }));

  useEffectOnce(() => {
    load();
  });

  return { load, loading, options };
}

export function useDiseases(
  options?: QueryHookOptions<DiseasesQuery, DiseasesQueryVariables>
) {
  const { loading, data, refetch } = useQuery(DiseasesDocument, options);

  const items = data?.diseases?.items || [];
  const totalCount = data?.diseases?.totalCount;

  return { loading, items, totalCount, refetch };
}

export function useDiseasesOptions(
  queryOptions?: QueryHookOptions<DiseasesQuery, DiseasesQueryVariables>
) {
  const { formatDiseaseType } = useDiseaseFormats();

  const [load, { loading, data }] = useLazyQuery(
    DiseasesDocument,
    merge({ variables: { sort: { name: SortDirection.Asc } } }, queryOptions)
  );

  const search = (val?: string) => {
    load(
      merge({ variables: { sort: undefined } }, queryOptions, {
        variables: {
          filter: { name: { match: val } },
        },
      })
    );
  };

  const options =
    data?.diseases?.items?.map((d) => ({
      key: d.id,
      label: d.name,
      group: formatDiseaseType(d.kind),
      children: formatDiseaseOption(d),
      disease: d,
    })) || [];

  return { load, search, loading, options };
}

export function useDisease(id: string) {
  const { data } = useQuery<DiseaseQuery, DiseaseQueryVariables>(
    DiseaseDocument,
    {
      variables: { id },
    }
  );

  return data && data.disease;
}

export function useDiseaseUpdate(
  options?: MutationHookOptions<
    DiseaseUpdateMutation,
    DiseaseUpdateMutationVariables
  >
) {
  return useMutation(DiseaseUpdateDocument, options);
}

export function useDiseaseRiskUpdate(
  options?: MutationHookOptions<
    DiseaseUpdateRisksMutation,
    DiseaseUpdateRisksMutationVariables
  >
) {
  return useMutation(DiseaseUpdateRisksDocument, options);
}

export function useDiseaseCreate(
  options?: MutationHookOptions<
    DiseaseCreateMutation,
    DiseaseCreateMutationVariables
  >
) {
  return useMutation(DiseaseCreateDocument, options);
}

export function useDiseaseDiscard(
  options?: MutationHookOptions<
    DiseaseDiscardMutation,
    DiseaseDiscardMutationVariables
  >
) {
  return useMutation(DiseaseDiscardDocument, options);
}

export function useDiseaseDestroy(
  options?: MutationHookOptions<
    DiseaseDestroyMutation,
    DiseaseDestroyMutationVariables
  >
) {
  return useMutation(DiseaseDestroyDocument, options);
}

export function useDiseaseUnlock(
  options?: MutationHookOptions<
    DiseaseUnlockMutation,
    DiseaseUnlockMutationVariables
  >
) {
  return useMutation(DiseaseUnlockDocument, options);
}
