import { QueryHookOptions, useQuery } from "@apollo/client";
import {
  BalanceSheetReportQuery,
  BalanceSheetReportQueryVariables,
  BalanceSheetReportDocument,
} from "../../graphql";

export function useBalanceSheetReport(
  options?: QueryHookOptions<
    BalanceSheetReportQuery,
    BalanceSheetReportQueryVariables
  >
) {
  const { loading, data } = useQuery(BalanceSheetReportDocument, options);

  if (!data || !data.balanceSheetReport || !data.balanceSheetReport.items) {
    return { loading };
  }

  const items = data.balanceSheetReport.items;
  const totalCount = data.balanceSheetReport.totalCount;

  return { loading, items, totalCount };
}
