import { CSSProperties, ReactNode } from "react";
import { Col, Row, Space, Tooltip } from "antd";
import Link from "next/link";
import Breadcrumb, { Route } from "antd/lib/breadcrumb/Breadcrumb";
import { useCurrentUser } from "../../../lib/hooks";
import { useIntl, defineMessages } from "react-intl";
import { formatTenantName } from "../../../lib/formats";
import { QuestionCircleOutlined } from "@ant-design/icons";

export interface ContentHeaderProps {
  style?: CSSProperties;
  title?: ReactNode;
  subTitle?: React.ReactNode;
  breadcrumbs?: Route[];
  headerContent?: ReactNode;
}

const messages = defineMessages({
  home: { id: "homeTenant", defaultMessage: "homeTenant" },
});

function breadcrumbRender(route: Route, _params: any, routes: Route[]) {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? (
    <span style={{ fontWeight: 600 }}>{route.breadcrumbName}</span>
  ) : (
    <Link href={route.path}>{route.breadcrumbName}</Link>
  );
}

export function ContentHeader({
  subTitle,
  breadcrumbs,
  headerContent,
  style,
}: ContentHeaderProps) {
  const intl = useIntl();
  const { user } = useCurrentUser();

  // otherwise it renders blank white div with paddings
  if (!subTitle && !breadcrumbs && !headerContent) return null;

  return (
    <div className="page-header" style={style}>
      <Row justify="space-between" align="top">
        {breadcrumbs && (
          <Col span={24}>
            <Space>
              <Breadcrumb
                style={{ fontSize: 16 }}
                itemRender={breadcrumbRender}
                routes={[
                  {
                    path: "/",
                    breadcrumbName: intl.formatMessage(messages.home, {
                      tenantName: user && formatTenantName(user.currentTenant),
                    }),
                  },
                  ...breadcrumbs,
                ]}
              />
              {subTitle && (
                <Tooltip title={subTitle}>
                  <QuestionCircleOutlined style={{ opacity: 0.5 }} />
                </Tooltip>
              )}
            </Space>
          </Col>
        )}

        {headerContent && (
          <Col span={24} style={{ marginTop: 8 }}>
            {headerContent}
          </Col>
        )}
      </Row>
    </div>
  );
}
