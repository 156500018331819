import { FormattedMessage } from "react-intl";
import { ChangeList } from ".";
import { SidebarHeader } from "../Sidebar";

interface ChangeLogSidebarProps {
  id: string;
  type: string;
  onClose: () => void;
}

export function ChangeLogSidebar({ id, type, onClose }: ChangeLogSidebarProps) {
  return (
    <>
      <SidebarHeader
        title={<FormattedMessage id="history" defaultMessage="history" />}
        back
        onClose={onClose}
      />

      <ChangeList id={id} type={type} />
    </>
  );
}
