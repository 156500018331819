import { MutationHookOptions, useMutation, useQuery } from "@apollo/client";
import {
  FinancePaymentDocument,
  FinancePaymentQuery,
  FinancePaymentCreateMutation,
  FinancePaymentCreateMutationVariables,
  FinancePaymentCreateDocument,
  FinancePaymentQueryVariables,
  FinancePaymentUpdateMutation,
  FinancePaymentUpdateMutationVariables,
  FinancePaymentUpdateDocument,
} from "../../graphql";

export function useFinancePayment(id: string) {
  const { data } = useQuery<FinancePaymentQuery, FinancePaymentQueryVariables>(
    FinancePaymentDocument,
    { variables: { id } }
  );

  return data && data.financePayment;
}

export function useFinancePaymentCreate(
  options?: MutationHookOptions<
    FinancePaymentCreateMutation,
    FinancePaymentCreateMutationVariables
  >
) {
  return useMutation(FinancePaymentCreateDocument, options);
}

export function useFinancePaymentUpdate(
  options?: MutationHookOptions<
    FinancePaymentUpdateMutation,
    FinancePaymentUpdateMutationVariables
  >
) {
  return useMutation(FinancePaymentUpdateDocument, options);
}
