import {
  Warehouse,
  Locality,
  Unit,
  Variant,
  Item,
  ItemCategory,
  Stock,
  InventoryStatus,
  VariantShortFragment,
  ItemIngredientFragment,
  ItemShortFragment,
  AdjustmentKind,
  RecipeFragment,
  UnitShortFragment,
} from "../graphql";
import { FormattedMessage } from "react-intl";
import {
  InfoCircleOutlined,
  ClockCircleTwoTone,
  CheckCircleTwoTone,
  MinusCircleTwoTone,
  RightCircleTwoTone,
  LeftCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import { Tag, Typography, Tooltip, SpaceProps } from "antd";
import {
  colors,
  formatDetailsLink,
  formatNumber,
  formatPercent,
  statusColors,
  translate,
} from "./common";
import routes from "../routes";
import { formatUnitValue, formatUnitValueConversion } from "./units";
import { SelectField } from "../../components/form";
import { ReactNode } from "react";
import { NumberFormatOptions } from "@formatjs/ecma402-abstract";
import { formatCropVarieties } from "./agro";
import { Space } from "../../components/shared";

export function formatVariant(
  variant: Pick<Variant, "name" | "variationValue"> & {
    variationUnit: Pick<Unit, "abbr">;
  }
) {
  return (
    <>
      {variant.name} - {formatNumber(variant.variationValue)}{" "}
      {variant.variationUnit.abbr}
    </>
  );
}

type VariantToFormat = {
  variationValue: number;
  variationUnit: Pick<Unit, "abbr">;
};

export function formatVariantQuantity(
  variant: VariantToFormat,
  quantity?: number,
  inUnits?: boolean,
  numberOpts?: NumberFormatOptions
) {
  const qty = quantity || 0;
  const opts: NumberFormatOptions = { maximumFractionDigits: 4, ...numberOpts };

  if (inUnits)
    return (
      <>
        {formatNumber(qty / variant.variationValue, opts)}{" "}
        <FormattedMessage id="units.lowercase" defaultMessage="units.l" /> (
        {formatNumber(qty, opts)} {variant.variationUnit.abbr})
      </>
    );

  return (
    <>
      {formatNumber(qty, opts)}{" "}
      <FormattedMessage id="units.lowercase" defaultMessage="units.l" /> (
      {formatNumber(variant.variationValue * qty, opts)}{" "}
      {variant.variationUnit.abbr} )
    </>
  );
}

export function formatWarehouse(
  warehouse: Pick<Warehouse, "name"> & { locality: Pick<Locality, "name"> }
) {
  return `${warehouse.locality.name} / ${warehouse.name}`;
}

export function formatItemCategory(
  category: Pick<ItemCategory, "name"> & {
    parentCategory?: Pick<ItemCategory, "name"> | null;
  }
) {
  return category.name;
}

export function formatStockVariantOption(
  variant: Pick<Variant, "name" | "variationValue" | "internalId"> & {
    item: ItemShortFragment;
    stock?: Pick<Stock, "onHand">;
    variationUnit: UnitShortFragment;
  },
  suffix?: ReactNode
) {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis" }}>
        {formatVariant(variant)} /{" "}
        {formatItemCategory(variant.item.itemCategory)}{" "}
        {formatItemIngredients(variant.item, 3)}
        {formatItemCropVarieties(variant.item, 3)}
        {variant.internalId && <Tag>{variant.internalId}</Tag>}
        {suffix}
      </div>
      {variant.stock &&
        formatStock(variant.stock.onHand, variant.variationUnit)}
    </div>
  );
}

export function formatVariantLink(
  variant: Pick<
    VariantShortFragment,
    "item" | "name" | "variationValue" | "variationUnit" | "internalId"
  >
) {
  return (
    <div>
      {formatDetailsLink({
        id: variant.item.id,
        route: routes.items.details,
        title: formatVariant(variant),
      })}
      <div>
        {formatItemCategory(variant.item.itemCategory)}{" "}
        {formatItemIngredients(variant.item, 3)}{" "}
        {variant.internalId && <Tag>{variant.internalId}</Tag>}
      </div>
    </div>
  );
}

export function formatStock(
  stock: number,
  unit: UnitShortFragment,
  conversionUnit?: UnitShortFragment
) {
  return (
    <Typography.Text
      type={stock > 0 ? undefined : "danger"}
      style={{ float: "right" }}
    >
      {conversionUnit
        ? formatUnitValueConversion(stock, {
            unit,
            conversionUnit,
            hideConversion: true,
          })
        : formatUnitValue(stock, unit)}
    </Typography.Text>
  );
}

export function formatItemOption(
  item: Pick<Item, "name"> & { itemCategory: Pick<ItemCategory, "name"> } & {
    itemIngredients: ItemIngredientFragment[];
  }
) {
  return (
    <>
      {item.name}
      <Typography.Text type="secondary">
        {" "}
        / {formatItemCategory(item.itemCategory)}
      </Typography.Text>{" "}
      {formatItemIngredients(item)}
    </>
  );
}

export function formatItemIngredients(
  { itemIngredients }: Pick<ItemShortFragment, "itemIngredients">,
  limit?: number
) {
  return itemIngredients.slice(0, limit || itemIngredients.length).map((i) => (
    <Tag key={i.id} color="green">
      {i.ingredient.name} - {formatPercent(i.rate)}
    </Tag>
  ));
}

export function formatItemCropVarieties(
  { itemCropVarieties }: Pick<ItemShortFragment, "itemCropVarieties">,
  limit?: number
) {
  return formatCropVarieties(
    ...itemCropVarieties
      .slice(0, limit || itemCropVarieties.length)
      .map((i) => i.cropVariety)
  );
}

export function formatInitialStockColumnTitle(
  status: string,
  warehouseName: string
) {
  return (
    <>
      {status === "open" ? (
        <FormattedMessage
          id="variants.runningStock"
          defaultMessage="runningStock"
        />
      ) : (
        <FormattedMessage
          id="variants.initialStock"
          defaultMessage="initialStock"
        />
      )}
      <Tooltip
        title={
          status === "open" ? (
            <FormattedMessage
              id="variants.runningStockInWarehouse"
              defaultMessage="runningStockInWarehouse"
              values={{ name: warehouseName }}
            />
          ) : (
            <FormattedMessage
              id="variants.totalStockInWarehouse"
              defaultMessage="totalStockInWarehouse"
              values={{
                name: warehouseName,
                preposition: "before",
              }}
            />
          )
        }
      >
        <InfoCircleOutlined style={{ marginLeft: "8px" }} />
      </Tooltip>
    </>
  );
}

export function formatFinalStockColumnTitle(
  status: string,
  warehouseName: string
) {
  return (
    <>
      <FormattedMessage id="variants.finalStock" defaultMessage="finalStock" />

      <Tooltip
        title={
          status === "open" ? (
            <FormattedMessage
              id="variants.targetStockInWarehouse"
              defaultMessage="totalStockInWarehouse"
              values={{ name: warehouseName }}
            />
          ) : (
            <FormattedMessage
              id="variants.totalStockInWarehouse"
              defaultMessage="totalStockInWarehouse"
              values={{
                name: warehouseName,
                preposition: "after",
              }}
            />
          )
        }
      >
        <InfoCircleOutlined style={{ marginLeft: "8px" }} />
      </Tooltip>
    </>
  );
}

export function formatFinalStock(
  initialStock: number,
  quantity: number,
  adjustmentKind: AdjustmentKind
) {
  if (adjustmentKind == AdjustmentKind.Decremental)
    return initialStock - quantity;
  else return initialStock + quantity;
}

export function formatAdjustment(
  variant: VariantToFormat & { stock: Pick<Stock, "onHand"> },
  quantity: number
) {
  if (typeof quantity === "undefined" || quantity === null) return null;

  const value = quantity * variant.variationValue - variant.stock.onHand;

  return (
    <Typography.Text type={value > 0 ? "success" : "danger"}>
      {formatVariantQuantity(variant, value, true, {
        signDisplay: "exceptZero",
      })}
    </Typography.Text>
  );
}

export function formatInventoryStatus(status: string) {
  return (
    <span>
      {status === InventoryStatus.NotRequested && (
        <MinusCircleTwoTone twoToneColor={colors.disabledColor} />
      )}
      {status === InventoryStatus.Requesting && (
        <ClockCircleTwoTone twoToneColor={colors.warningColor} />
      )}
      {status === InventoryStatus.Returning && (
        <ClockCircleTwoTone twoToneColor={colors.warningColor} />
      )}
      {status === InventoryStatus.Assigned && (
        <RightCircleTwoTone twoToneColor={colors.successColor} />
      )}
      {status === InventoryStatus.Canceled && (
        <CloseCircleTwoTone twoToneColor={statusColors.canceled} />
      )}
      {status === InventoryStatus.Reverted && (
        <LeftCircleTwoTone twoToneColor="#c41d7f" />
      )}
      {status === InventoryStatus.Used && (
        <CheckCircleTwoTone twoToneColor="#117" />
      )}{" "}
      {status === "delivered" && (
        <LeftCircleTwoTone twoToneColor={colors.brandColor} />
      )}{" "}
      {translate(`inventoryStatuses.${status}`)}
    </span>
  );
}

export function formatInventoryRequestLink(id: string, status: string) {
  if (
    id &&
    (status === InventoryStatus.Requesting ||
      status === InventoryStatus.Returning)
  ) {
    return formatDetailsLink({
      id,
      route: routes.agro.inventoryRequests.details,
      title: formatInventoryStatus(status),
    });
  }

  return formatInventoryStatus(status);
}

export function variantSelectDropdown({
  left,
  right,
  hideRight,
}: {
  left?: ReactNode;
  right?: ReactNode;
  hideRight?: boolean | null;
} = {}) {
  return (menu?: React.ReactElement) => (
    <SelectField.DropdownHeader
      menu={menu}
      left={
        left || (
          <FormattedMessage
            id="variants.selectNames"
            defaultMessage="selectNames"
          />
        )
      }
      right={
        right ||
        (!hideRight && (
          <FormattedMessage
            id="variants.runningStock"
            defaultMessage="runningStock"
          />
        ))
      }
    />
  );
}

const InventoryCodes: Record<string, string> = {
  WOA: "cyan",
  WOC: "magenta",
  WOR: "lime",
  WPR: "blue",
  WOP: "green",
  LS: "red",
  MS: "volcano",
  CIR: "yellow",
  TR: "lime",
  AC: "purple",
  OS: "green",
  PU: "geekblue",
  SO: "gold",
  ED: "volcano",
  RC: "purple",
  R: "cyan",
  F: "green",
  MV: "",
  RV: "red",
};

export function formatInventoryEntryCode(code: string, noTag?: boolean) {
  const message = (
    <FormattedMessage
      id={`inventoryEntryCodes.${code}`}
      defaultMessage={code}
    />
  );

  return noTag ? message : <Tag color={InventoryCodes[code]}>{message}</Tag>;
}

export function formatRecipeOption(recipe: RecipeFragment) {
  return (
    <Space>
      {recipe.name} {formatRecipeVariants(recipe)}
    </Space>
  );
}

export function formatRecipeVariants(
  recipe: RecipeFragment,
  props?: SpaceProps
) {
  return (
    <Space {...props}>
      {recipe.variants.map((v) => (
        <Tag color="purple" key={v.id}>
          {v.variant.name}
          {" - "}
          {formatUnitValue(v.rate, v.unit, v.applicationUnit?.abbr)}
        </Tag>
      ))}
    </Space>
  );
}
