import React from "react";
import {
  ArrowLeftOutlined,
  CloseOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Row, Col, Button, MenuProps } from "antd";
import { Dropdown } from "../Dropdown";

interface HeaderProps {
  back?: boolean;
  title: React.ReactNode;
  right?: React.ReactNode;
  extraMenu?: MenuProps;
  onClose?: () => void;
}

export function SidebarHeader({
  back = false,
  title,
  extraMenu,
  right,
  onClose,
}: HeaderProps) {
  return (
    <div className="ant-drawer-header">
      <Row justify="space-between" align="middle" style={{ width: "100%" }}>
        <Col>
          <Button
            type="default"
            icon={back ? <ArrowLeftOutlined /> : <CloseOutlined />}
            onClick={onClose}
            style={{
              border: "none",
              paddingLeft: 0,
              paddingRight: "16px",
              background: "transparent",
              boxShadow: "none",
            }}
          />
          <span className="ant-drawer-title">{title}</span>
        </Col>

        {right && <Col>{right}</Col>}
        {extraMenu && (
          <Col>
            <Dropdown menu={extraMenu}>
              <Button
                type="default"
                icon={<MoreOutlined />}
                style={{ border: "none", fontSize: "16px" }}
                data-action="entity-extra-btn"
              />
            </Dropdown>
          </Col>
        )}
      </Row>
    </div>
  );
}
