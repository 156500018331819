import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";
import { merge } from "lodash";
import {
  CropsDocument,
  CropsQuery,
  CropsQueryVariables,
  CropUpdateMutation,
  CropUpdateMutationVariables,
  CropUpdateDocument,
  CropCreateMutation,
  CropCreateMutationVariables,
  CropCreateDocument,
  CropDiscardMutation,
  CropDiscardMutationVariables,
  CropDiscardDocument,
  CropUnlockMutation,
  CropUnlockMutationVariables,
  CropUnlockDocument,
  CropQuery,
  CropQueryVariables,
  CropDocument,
  SortDirection,
  CropOptionsDocument,
  CropOptionsQuery,
  CropOptionsQueryVariables,
} from "../../graphql";

export function useCrops(
  options?: QueryHookOptions<CropsQuery, CropsQueryVariables>
) {
  const { loading, data, refetch } = useQuery(CropsDocument, options);

  const items = data?.crops?.items || [];
  const totalCount = data?.crops?.totalCount;

  return { loading, items, totalCount, refetch };
}

export function useCropsOptions(
  queryOptions?: QueryHookOptions<CropOptionsQuery, CropOptionsQueryVariables>
) {
  const [load, { loading, data }] = useLazyQuery(
    CropOptionsDocument,
    merge({ variables: { sort: { name: SortDirection.Asc } } }, queryOptions)
  );

  const search = (val?: string) => {
    load(
      merge({ variables: { sort: undefined } }, queryOptions, {
        variables: {
          filter: { name: { match: val } },
        },
      })
    );
  };

  const options =
    data?.crops?.items?.map((k) => ({
      key: k.id,
      label: k.name,
    })) || [];

  return { load, search, loading, options };
}

export function useCrop(id: string) {
  const { data } = useQuery<CropQuery, CropQueryVariables>(CropDocument, {
    variables: { id },
  });

  return data && data.crop;
}

export function useCropUpdate(
  options?: MutationHookOptions<CropUpdateMutation, CropUpdateMutationVariables>
) {
  return useMutation(CropUpdateDocument, options);
}

export function useCropCreate(
  options?: MutationHookOptions<CropCreateMutation, CropCreateMutationVariables>
) {
  return useMutation(CropCreateDocument, options);
}

export function useCropDiscard(
  options?: MutationHookOptions<
    CropDiscardMutation,
    CropDiscardMutationVariables
  >
) {
  return useMutation(CropDiscardDocument, options);
}

export function useCropUnlock(
  options?: MutationHookOptions<CropUnlockMutation, CropUnlockMutationVariables>
) {
  return useMutation(CropUnlockDocument, options);
}
