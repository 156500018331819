import { QueryHookOptions, useQuery } from "@apollo/client";
import {
  ActivitySummaryReportQuery,
  ActivitySummaryReportQueryVariables,
  ActivitySummaryReportDocument,
  ActivityCostCenterReportQuery,
  ActivityCostCenterReportQueryVariables,
  ActivityCostCenterReportDocument,
  ActivityOverviewReportQuery,
  ActivityOverviewReportQueryVariables,
  ActivityOverviewReportDocument,
} from "../../graphql";

export function useActivityOverviewReport(
  options?: QueryHookOptions<
    ActivityOverviewReportQuery,
    ActivityOverviewReportQueryVariables
  >
) {
  const { loading, data } = useQuery(ActivityOverviewReportDocument, options);

  return { overviewLoading: loading, overview: data?.activityOverviewReport };
}

export function useActivitySummaryReport(
  options?: QueryHookOptions<
    ActivitySummaryReportQuery,
    ActivitySummaryReportQueryVariables
  >
) {
  const { loading, data } = useQuery(ActivitySummaryReportDocument, options);

  if (
    !data ||
    !data.activitySummaryReport ||
    !data.activitySummaryReport.items
  ) {
    return { loading };
  }

  const items = data.activitySummaryReport.items;
  const totalCount = data.activitySummaryReport.totalCount;

  return { loading, items, totalCount };
}

export function useActivityCostCenterReport(
  options?: QueryHookOptions<
    ActivityCostCenterReportQuery,
    ActivityCostCenterReportQueryVariables
  >
) {
  const { loading, data } = useQuery(ActivityCostCenterReportDocument, options);

  if (
    !data ||
    !data.activityCostCenterReport ||
    !data.activityCostCenterReport.items
  ) {
    return { loading };
  }

  const items = data.activityCostCenterReport.items;
  const totalCount = data.activityCostCenterReport.totalCount;

  return { loading, items, totalCount };
}
