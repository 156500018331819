import { QueryHookOptions, useQuery } from "@apollo/client";
import {
  GeneralLedgerReportQuery,
  GeneralLedgerReportQueryVariables,
  GeneralLedgerReportDocument,
} from "../../graphql";

export function useGeneralLedgerReport(
  options?: QueryHookOptions<
    GeneralLedgerReportQuery,
    GeneralLedgerReportQueryVariables
  >
) {
  const { loading, data } = useQuery(GeneralLedgerReportDocument, options);

  if (!data || !data.generalLedgerReport || !data.generalLedgerReport.items) {
    return { loading };
  }

  const items = data.generalLedgerReport.items;
  const totalCount = data.generalLedgerReport.totalCount;

  return { loading, items, totalCount };
}
