import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";
import { merge } from "lodash";

import {
  SalaryComponentsDocument,
  SalaryComponentsQuery,
  SalaryComponentsQueryVariables,
  SalaryComponentUpdateMutation,
  SalaryComponentUpdateMutationVariables,
  SalaryComponentUpdateDocument,
  SalaryComponentCreateMutation,
  SalaryComponentCreateMutationVariables,
  SalaryComponentCreateDocument,
  SalaryComponentDiscardMutation,
  SalaryComponentDiscardMutationVariables,
  SalaryComponentDiscardDocument,
  SalaryComponentQuery,
  SalaryComponentQueryVariables,
  SalaryComponentDocument,
  SalaryComponentDestroyMutation,
  SalaryComponentDestroyMutationVariables,
  SalaryComponentDestroyDocument,
  SortDirection,
} from "../../graphql";

export function useSalaryComponents(
  options?: QueryHookOptions<
    SalaryComponentsQuery,
    SalaryComponentsQueryVariables
  >
) {
  const { loading, data } = useQuery(SalaryComponentsDocument, options);

  if (!data || !data.salaryComponents || !data.salaryComponents.items) {
    return { loading };
  }

  const items = data.salaryComponents.items;
  const totalCount = data.salaryComponents.totalCount;

  return { loading, items, totalCount };
}

export function useSalaryComponentOptions(
  queryOptions?: QueryHookOptions<
    SalaryComponentsQuery,
    SalaryComponentsQueryVariables
  >
) {
  const [load, { loading, data }] = useLazyQuery(
    SalaryComponentsDocument,
    merge({ variables: { sort: { name: SortDirection.Asc } } }, queryOptions)
  );

  const options =
    data?.salaryComponents?.items?.map((c) => ({
      key: c.id,
      label: c.name,
      component: c,
    })) || [];

  return { load, loading, options };
}

export function useSalaryComponent(id: string) {
  const { data } = useQuery<
    SalaryComponentQuery,
    SalaryComponentQueryVariables
  >(SalaryComponentDocument, {
    variables: { id },
  });

  return data && data.salaryComponent;
}

export function useSalaryComponentUpdate(
  options?: MutationHookOptions<
    SalaryComponentUpdateMutation,
    SalaryComponentUpdateMutationVariables
  >
) {
  return useMutation(SalaryComponentUpdateDocument, options);
}

export function useSalaryComponentCreate(
  options?: MutationHookOptions<
    SalaryComponentCreateMutation,
    SalaryComponentCreateMutationVariables
  >
) {
  return useMutation(SalaryComponentCreateDocument, options);
}

export function useSalaryComponentDiscard(
  options?: MutationHookOptions<
    SalaryComponentDiscardMutation,
    SalaryComponentDiscardMutationVariables
  >
) {
  return useMutation(SalaryComponentDiscardDocument, options);
}

export function useSalaryComponentDestroy(
  options?: MutationHookOptions<
    SalaryComponentDestroyMutation,
    SalaryComponentDestroyMutationVariables
  >
) {
  return useMutation(SalaryComponentDestroyDocument, options);
}
