import {
  Button,
  Empty,
  Mentions,
  Card,
  Row,
  Col,
  Typography,
  FormInstance,
  Space,
} from "antd";
import Icon from "@ant-design/icons";
import {
  Form,
  Dragger,
  mentionOptions,
  SubmitButton,
  Files,
  prepareUploadFiles,
} from "../../form";
import {
  useCommentCreate,
  useCommentUpdate,
  useCurrentUser,
  useUserOptions,
} from "../../../lib/hooks";
import { CommentableType } from "../../../lib/graphql";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { Avatar } from "../Avatar";
import { AttachIcon } from "../../../lib/images";

const messages = defineMessages({
  addComment: { id: "comments.add", defaultMessage: "addComment" },
  comment: { id: "comment", defaultMessage: "comment" },
  notes: { id: "notes", defaultMessage: "notes" },
  newSuccess: { id: "new.success", defaultMessage: "success" },
  editSuccess: { id: "edit.success", defaultMessage: "success" },
  attachInfo: { id: "attach.info", defaultMessage: "attachInfo" },
  update: { id: "update", defaultMessage: "update" },
  updating: { id: "updating", defaultMessage: "updating" },
});

interface CommentFormValues {
  content?: string | null;
  files?: Files;
  commentableId?: string;
}

interface CommentFormProps {
  commentableType: CommentableType;
  commentableId?: string;
  commentId?: string;
  content?: string | null;
  defaultFiles?: any;
  onChanged?: () => void;
  form?: FormInstance;
}

function CommentForm({
  commentableId,
  commentableType,
  commentId,
  content,
  defaultFiles,
  onChanged,
  form,
}: CommentFormProps) {
  const intl = useIntl();

  const initialValues: CommentFormValues = {
    content: content || "",
    commentableId,
    files: prepareUploadFiles(defaultFiles),
  };

  const {
    search: searchUsers,
    options: userOptions,
    loading: loadingUsers,
  } = useUserOptions();

  const [create] = useCommentCreate({ refetchQueries: ["Comments"] });
  const [update] = useCommentUpdate({ refetchQueries: ["Comments"] });
  const entityName = intl.formatMessage(messages.comment);
  const { user } = useCurrentUser();
  const [commentForm] = Form.useForm(form);

  if (!user) return <Empty />;

  return (
    <Form
      layout="vertical"
      form={commentForm}
      initialValues={initialValues}
      onSubmit={(
        { content, files, commentableId },
        { setSubmitting, form, showErrors, showSuccess }
      ) => {
        if (
          (!content && !files?.newFiles.length && !files?.purgeFiles.length) ||
          !commentableId
        )
          return setSubmitting(false);

        commentId
          ? update({
              variables: {
                id: commentId,
                changes: {
                  content,
                  newFiles: files?.newFiles,
                  purgeFiles: files?.purgeFiles,
                },
              },
            })
              .then((result) => {
                if (!result.data) return null;

                return result.data.commentUpdate;
              })
              .then((result) => {
                if (result?.result) {
                  showSuccess(
                    intl.formatMessage(messages.editSuccess, {
                      entityName,
                      id: commentId,
                    })
                  );
                } else if (result?.errors) {
                  showErrors(result.errors);
                }
                if (onChanged) onChanged();
              })
          : create({
              variables: {
                fields: {
                  content: content || "",
                  commentableType,
                  commentableId,
                  files: files?.newFiles,
                },
              },
            })
              .then((result) => {
                if (!result.data) return null;

                return result.data.commentCreate;
              })
              .then((result) => {
                if (result?.result) {
                  if (commentableId)
                    showSuccess(
                      intl.formatMessage(messages.newSuccess, {
                        entityName,
                      })
                    );

                  form.resetFields();
                } else if (result?.errors) {
                  showErrors(result.errors);
                }
                setSubmitting(false);
              });
      }}
    >
      <Form.Item
        name="files"
        label={commentId ? undefined : intl.formatMessage(messages.addComment)}
      >
        <Dragger>
          <Card
            className="comment-form-card"
            size="small"
            style={{ borderColor: "#d9d9d9" }}
          >
            <Row gutter={8}>
              <Col>
                <Avatar user={user} />
              </Col>
              <Col flex={1}>
                <Form.Item name="content" noStyle>
                  <Mentions
                    name="content"
                    style={{ textAlign: "left", border: "none" }}
                    placeholder={intl.formatMessage(messages.addComment)}
                    rows={4}
                    onSearch={searchUsers}
                    loading={loadingUsers}
                  >
                    {mentionOptions(userOptions)}
                  </Mentions>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between" align="middle">
              <Col>
                <Space
                  onClick={() => {
                    const fileInput = document.querySelector(
                      'input[type="file"]'
                    ) as HTMLInputElement;
                    fileInput.click();
                  }}
                >
                  <Icon component={AttachIcon} />
                  <Typography.Text
                    type="secondary"
                    style={{ fontSize: "12px" }}
                  >
                    {intl.formatMessage(messages.attachInfo)}
                  </Typography.Text>
                </Space>
              </Col>
              <Col>
                {commentId && (
                  <Button
                    style={{ marginRight: "8px" }}
                    onClick={() => onChanged && onChanged()}
                  >
                    <FormattedMessage id="cancel" defaultMessage="cancel" />
                  </Button>
                )}

                {commentableId && (
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => (
                      <SubmitButton
                        type="primary"
                        disabled={
                          !getFieldValue(["files", "newFiles"])?.length &&
                          !getFieldValue(["files", "purgeFiles"])?.length &&
                          !getFieldValue("content")?.length
                        }
                      >
                        {commentId ? (
                          <FormattedMessage
                            id="update"
                            defaultMessage="update"
                          />
                        ) : (
                          <FormattedMessage
                            id="postComment"
                            defaultMessage="postComment"
                          />
                        )}
                      </SubmitButton>
                    )}
                  </Form.Item>
                )}
              </Col>
            </Row>
          </Card>
        </Dragger>
      </Form.Item>
    </Form>
  );
}

export default CommentForm;
