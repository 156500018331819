import { WorkSheet, read, utils } from "xlsx";

export async function readSpreadsheet<T>(file: File) {
  const data = await file.arrayBuffer();
  const workbook = read(data);
  const sheet = workbook.Sheets[workbook.SheetNames[0]];

  return utils.sheet_to_json<T>(trimHeaders(sheet), { defval: null });
}

// explanation: https://github.com/SheetJS/sheetjs/issues/1529#issuecomment-915384519
function trimHeaders(ws: WorkSheet) {
  if (!ws || !ws["!ref"]) return ws;

  const ref = utils.decode_range(ws["!ref"]);

  for (let C = ref.s.c; C <= ref.e.c; ++C) {
    const cell = ws[utils.encode_cell({ r: ref.s.r, c: C })];

    if (!cell || cell.t !== "s") continue;

    cell.v = cell.v.trim();
    if (cell.w) cell.w = cell.w.trim();
  }

  return ws;
}
