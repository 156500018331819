import { QueryHookOptions, useQuery } from "@apollo/client";
import {
  TokenSummaryReportQuery,
  TokenSummaryReportQueryVariables,
  TokenSummaryReportDocument,
  TokenPaidReportQuery,
  TokenPaidReportQueryVariables,
  TokenPaidReportDocument,
  TokenRedeemedReportQuery,
  TokenRedeemedReportQueryVariables,
  TokenRedeemedReportDocument,
} from "../../graphql";

export function useTokenSummaryReport(
  options?: QueryHookOptions<
    TokenSummaryReportQuery,
    TokenSummaryReportQueryVariables
  >
) {
  const { loading, data } = useQuery(TokenSummaryReportDocument, options);

  if (!data || !data.tokenSummaryReport || !data.tokenSummaryReport.items) {
    return { loading };
  }

  const items = data.tokenSummaryReport.items;
  const totalCount = data.tokenSummaryReport.totalCount;

  return { loading, items, totalCount };
}

export function useTokenPaidReport(
  options?: QueryHookOptions<
    TokenPaidReportQuery,
    TokenPaidReportQueryVariables
  >
) {
  const { loading, data } = useQuery(TokenPaidReportDocument, options);

  if (!data || !data.tokenPaidReport || !data.tokenPaidReport.items) {
    return { loading };
  }

  const items = data.tokenPaidReport.items;
  const totalCount = data.tokenPaidReport.totalCount;

  return { loading, items, totalCount };
}

export function useTokenRedeemedReport(
  options?: QueryHookOptions<
    TokenRedeemedReportQuery,
    TokenRedeemedReportQueryVariables
  >
) {
  const { loading, data } = useQuery(TokenRedeemedReportDocument, options);

  if (!data || !data.tokenRedeemedReport || !data.tokenRedeemedReport.items) {
    return { loading };
  }

  const items = data.tokenRedeemedReport.items;
  const totalCount = data.tokenRedeemedReport.totalCount;

  return { loading, items, totalCount };
}
