import React, { FunctionComponent } from "react";

export const SidebarFooter: FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => (
  <div>
    {children}
    <style jsx>{`
      div {
        position: absolute;
        bottom: 0;
        background: #fff;
        width: 100%;
        border-top: 1px solid #e8e8e8;
        padding: 10px 16px;
        text-align: right;
        left: 0;
        z-index: 3;
      }
    `}</style>
  </div>
);
