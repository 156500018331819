import { Typography } from "antd";
import { TitleProps } from "antd/lib/typography/Title";

interface StatisticProps extends Omit<TitleProps, "title"> {
  title?: React.ReactNode;
  children: React.ReactNode;
}

export function Statistic({ title, children, ...props }: StatisticProps) {
  return (
    <>
      {title && (
        <Typography.Text type="secondary" ellipsis>
          {title}
        </Typography.Text>
      )}
      <Typography.Title level={4} style={{ margin: 0 }} ellipsis {...props}>
        {children}
      </Typography.Title>
    </>
  );
}
