import { QueryHookOptions, useLazyQuery } from "@apollo/client";
import { merge } from "lodash";
import {
  CropFieldGroupsDocument,
  CropFieldGroupsQuery,
  CropFieldGroupsQueryVariables,
  SortDirection,
} from "../../graphql";

export function useCropFieldGroupOptions(
  queryOptions: QueryHookOptions<
    CropFieldGroupsQuery,
    CropFieldGroupsQueryVariables
  >
) {
  const [load, { loading, data, refetch }] = useLazyQuery(
    CropFieldGroupsDocument,
    merge({ variables: { sort: { name: SortDirection.Asc } } }, queryOptions)
  );

  const options =
    data?.cropFieldGroups?.map((g) => ({
      key: g,
      label: g,
    })) || [];

  return {
    loading,
    options,
    load,
    refetch,
  };
}
