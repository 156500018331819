import { Typography } from "antd";
import { useCurrentUser, useMediaTablet } from "../../../lib/hooks";
import { FormattedMessage } from "react-intl";

export default function WorkOrderCounter() {
  const { currentTenant, forceOnboarding } = useCurrentUser();
  const isTablet = useMediaTablet();

  if (forceOnboarding || !currentTenant.woLimit || isTablet) return null;

  const left = Math.max(currentTenant.woLimit - currentTenant.woCount, 0);
  return (
    <Typography.Text>
      {left}/{currentTenant.woLimit}{" "}
      <FormattedMessage
        id="workOrders.limit.available"
        defaultMessage="work orders available"
      />
    </Typography.Text>
  );
}
