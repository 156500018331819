import { PlusOutlined, FilterOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Row, Col, Space, Badge, Divider } from "antd";
import { FormattedMessage } from "react-intl";
import { ButtonProps } from "antd/lib/button";
import { ImportSidebar, ImportProps } from "../ImportSidebar";
import { ReactNode, useContext } from "react";
import { useRouter } from "next/router";
import { ItemSidebarContext } from "../ItemSidebar";
import { filterCount } from "./FilterSidebar";
import { routerPush } from "../../../lib/routes";
import { ExportButton } from "../ExportButton";
import { useCurrentUser, useMediaXs } from "../../../lib/hooks";
import { ExportType } from "../../../lib/exports";
import { ItemType } from "antd/lib/menu/hooks/useItems";

export interface ListMenuProps {
  newButtonProps?: ButtonProps;
  newRoute?: string;
  importProps?: ImportProps;
  exportType?: ExportType;
  hideFilters?: boolean;
  left?: ReactNode;
  right?: ReactNode;
  exportParams?: any;
  allowNew?: boolean;
  exportItems?: (params: any) => ItemType[];
}

export function FilterButton({
  filterCount,
  ...props
}: ButtonProps & { filterCount: number }) {
  const isXs = useMediaXs();

  return (
    <Badge count={filterCount} style={{ backgroundColor: "#0177FF" }}>
      <Button data-action="filters" {...props}>
        {!isXs && (
          <span>
            <FormattedMessage id="filters" defaultMessage="filters" />
          </span>
        )}
        <FilterOutlined />
        <RightOutlined style={{ fontSize: "10px" }} />
      </Button>
    </Badge>
  );
}

export function ListMenu({
  newButtonProps,
  newRoute,
  importProps,
  exportType,
  hideFilters,
  left,
  right,
  exportParams,
  allowNew = true,
  exportItems,
}: ListMenuProps) {
  const { setCurrentAction } = useContext(ItemSidebarContext);
  const { user } = useCurrentUser();
  const router = useRouter();

  return (
    <Row justify="space-between" gutter={[8, 8]}>
      <Col>
        <Space direction="horizontal">
          {allowNew && (
            <Button
              data-new
              type="primary"
              icon={<PlusOutlined />}
              onClick={() =>
                newRoute ? routerPush(newRoute) : setCurrentAction("new")
              }
              {...newButtonProps}
            >
              <span>
                <FormattedMessage id="new" defaultMessage="new" />
              </span>
            </Button>
          )}

          {left}
        </Space>
      </Col>

      <Col>
        <Space direction="horizontal">
          {right}
          {right && <Divider type="vertical" />}
          {exportType && (
            <ExportButton
              exportType={exportType}
              params={exportParams}
              items={exportItems}
            />
          )}

          {user?.permissions.settings?.import && importProps?.moduleName && (
            <ImportSidebar {...importProps} />
          )}

          {!hideFilters && (
            <FilterButton
              filterCount={filterCount(router.query)}
              onClick={() => setCurrentAction("filters")}
            />
          )}
        </Space>
      </Col>
    </Row>
  );
}
