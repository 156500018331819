import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";
import {
  CropFieldsDocument,
  CropFieldsQuery,
  CropFieldsQueryVariables,
  CropFieldUpdateMutation,
  CropFieldUpdateMutationVariables,
  CropFieldUpdateDocument,
  CropFieldCreateMutation,
  CropFieldCreateMutationVariables,
  CropFieldCreateDocument,
  CropFieldDiscardMutation,
  CropFieldDiscardMutationVariables,
  CropFieldDiscardDocument,
  CropFieldDocument,
  CropFieldQuery,
  CropFieldQueryVariables,
  SortDirection,
  CropFieldDestroyDocument,
  CropFieldDestroyMutation,
  CropFieldDestroyMutationVariables,
  CropFieldsFilterInput,
  CropFieldBulkUpdateMutation,
  CropFieldBulkUpdateMutationVariables,
  CropFieldBulkUpdateDocument,
} from "../../graphql";
import {
  formatCropFieldOption,
  formatCropStage,
  formatTags,
  unaccentSearch,
} from "../../formats";
import { concat, merge } from "lodash";
import { useCropStageTypeFormat } from "..";
import { mergeWithArray } from "../../utils";
import { useCropStageOptions, useCropStageTypeOptions } from "./cropStages";
import { useIntl } from "react-intl";
import { useCallback, useState } from "react";

export function useCropFieldOptions(
  queryOptions?: QueryHookOptions<CropFieldsQuery, CropFieldsQueryVariables> & {
    useTotalArea?: boolean;
  }
) {
  const { useTotalArea, ...qOptions } = queryOptions || {};

  const { formatCropStageType } = useCropStageTypeFormat();

  const [load, { loading, data }] = useLazyQuery(
    CropFieldsDocument,
    merge({ variables: { sort: { name: SortDirection.Asc } } }, qOptions)
  );

  const options =
    data?.cropFields?.items.map((f) => ({
      key: f.id,
      label: f.name,
      group: formatCropStageType(f.cropStage.kind),
      children: formatCropFieldOption({ field: f, useTotalArea }),
      cropField: f,
    })) || [];

  const search = (val?: string) =>
    load(
      merge({ variables: { sort: undefined } }, qOptions, {
        variables: {
          filter: {
            query: val,
          },
          pageSize: 75,
        },
      })
    );

  return {
    load,
    loading,
    options,
    search,
  };
}

export function useCropFieldWithStagesOptions(
  queryOptions?: Parameters<typeof useCropFieldOptions>[0]
) {
  const intl = useIntl();

  const {
    load: cropFieldLoad,
    loading: cropFieldLoading,
    options: cropFieldOptions,
    search: cropFieldSearch,
  } = useCropFieldOptions(queryOptions);

  const { load: loadCropFieldsByStage } = useCropFieldOptions();

  const {
    load: cropStageLoad,
    loading: cropStageLoading,
    options: cropStageOptions,
    search: cropStageSearch,
  } = useCropStageOptions({ variables: { filter: undefined } });

  const { formatCropStageTypeTag } = useCropStageTypeFormat();
  const { options: cropStageTypeOptions } = useCropStageTypeOptions();
  const [query, setQuery] = useState<string>();

  const group = intl.formatMessage({
    id: "cropFields.withCropStage",
    defaultMessage: "With Crop Stage",
  });

  const allCropFields = intl.formatMessage({
    id: "cropFields.all",
    defaultMessage: "All Crop Fields",
  });

  const cropFieldLoader = useCallback(
    (filter?: CropFieldsFilterInput) => () =>
      loadCropFieldsByStage(
        merge({ variables: { filter, pageSize: 1000 } }, queryOptions)
      ).then((r) => r.data?.cropFields?.items || []),
    [loadCropFieldsByStage, queryOptions]
  );

  const fixedOptions = [
    {
      key: "all",
      label: allCropFields,
      group,
      children: formatTags([allCropFields], "gold"),
      cropFieldLoader: cropFieldLoader(),
    },
    ...cropStageTypeOptions.map((t) => ({
      ...t,
      group,
      children: formatCropStageTypeTag(t.key),
      cropFieldLoader: cropFieldLoader({ cropStageType: t.key }),
    })),
  ];

  const cropStageFilter = queryOptions?.variables?.filter?.cropStageId || [];
  const options = concat(
    cropFieldOptions,
    query
      ? fixedOptions.filter((o) => unaccentSearch(query, o.label))
      : fixedOptions,
    cropStageOptions
      .filter((s) => !cropStageFilter.length || cropStageFilter.includes(s.key))
      .map(
        (s) =>
          ({
            ...s,
            key: `cs-${s.key}`,
            children: formatCropStage(s.stage),
            group,
            cropFieldLoader: cropFieldLoader({ cropStageId: [s.key] }),
          } as any)
      )
  );

  return {
    loading: cropFieldLoading || cropStageLoading,
    load: () => {
      cropFieldLoad();
      cropStageLoad();
      setQuery("");
    },
    search: (v?: string) => {
      cropFieldSearch(v);
      cropStageSearch(v);
      setQuery(v);
    },
    options,
  };
}

export function useCropFields(
  options?: QueryHookOptions<CropFieldsQuery, CropFieldsQueryVariables>
) {
  const { loading, data, refetch } = useQuery(CropFieldsDocument, options);

  const items = data?.cropFields?.items || [];
  const totalCount = data?.cropFields?.totalCount;

  return { loading, items, totalCount, refetch };
}

export function useCropField(id: string) {
  const { data } = useQuery<CropFieldQuery, CropFieldQueryVariables>(
    CropFieldDocument,
    {
      variables: { id },
    }
  );

  return data && data.cropField;
}

export function useCropFieldUpdate(
  options?: MutationHookOptions<
    CropFieldUpdateMutation,
    CropFieldUpdateMutationVariables
  >
) {
  return useMutation(
    CropFieldUpdateDocument,
    mergeWithArray(options, { refetchQueries: ["CropFieldGroups"] })
  );
}

export function useCropFieldBulkUpdate(
  options?: MutationHookOptions<
    CropFieldBulkUpdateMutation,
    CropFieldBulkUpdateMutationVariables
  >
) {
  return useMutation(
    CropFieldBulkUpdateDocument,
    mergeWithArray(options, { refetchQueries: ["CropFieldGroups"] })
  );
}

export function useCropFieldCreate(
  options?: MutationHookOptions<
    CropFieldCreateMutation,
    CropFieldCreateMutationVariables
  >
) {
  return useMutation(
    CropFieldCreateDocument,
    mergeWithArray(options, { refetchQueries: ["CropFieldGroups"] })
  );
}

export function useCropFieldDiscard(
  options?: MutationHookOptions<
    CropFieldDiscardMutation,
    CropFieldDiscardMutationVariables
  >
) {
  return useMutation(
    CropFieldDiscardDocument,
    mergeWithArray(options, { refetchQueries: ["CropFieldGroups"] })
  );
}

export function useCropFieldDestroy(
  options?: MutationHookOptions<
    CropFieldDestroyMutation,
    CropFieldDestroyMutationVariables
  >
) {
  return useMutation(
    CropFieldDestroyDocument,
    mergeWithArray(options, { refetchQueries: ["CropFieldGroups"] })
  );
}
