import { useMutation, MutationHookOptions } from "@apollo/client";
import {
  ActivityPaymentUpdateDocument,
  ActivityPaymentCreateMutation,
  ActivityPaymentCreateMutationVariables,
  ActivityPaymentCreateDocument,
  ActivityPaymentDiscardMutation,
  ActivityPaymentDiscardMutationVariables,
  ActivityPaymentDiscardDocument,
  ActivityPaymentUpdateMutation,
  ActivityPaymentUpdateMutationVariables,
  WorkUnit,
  ActivityDetailsFragment,
  WorkOrderFragment,
  PositionFragment,
} from "../../graphql";
import { useIntl } from "react-intl";
import { sortBy } from "lodash";

export function useWorkUnitFormatters() {
  const intl = useIntl();
  const formatWorkUnit = (unit: string) =>
    intl.formatMessage({ id: `workUnits.${unit}` });
  const formatWorkUnitAbbr = (unit: string) =>
    intl.formatMessage({ id: `workUnits.abbr.${unit}` });

  return {
    formatWorkUnit,
    formatWorkUnitAbbr,
    dayUnit: formatWorkUnitAbbr(WorkUnit.Day),
    hourUnit: formatWorkUnitAbbr(WorkUnit.Hour),
  };
}

export function useWorkUnitOptions() {
  const { formatWorkUnit } = useWorkUnitFormatters();

  return {
    formatWorkUnit,
    options: [WorkUnit.Hour, WorkUnit.Day, WorkUnit.Month].map((unit) => ({
      key: unit,
      value: unit,
      label: formatWorkUnit(unit),
    })),
  };
}

export function matchPayment(
  activity: Pick<ActivityDetailsFragment, "payments">,
  document: Pick<WorkOrderFragment, "locality" | "cropCycle">,
  position: Pick<PositionFragment, "positionType"> & { id?: string }
) {
  return sortBy(
    activity.payments.filter(
      (p) =>
        !p.discardedAt &&
        (!p.position ||
          (position.id
            ? position.id == p.position.id
            : position.positionType == p.position.positionType)) &&
        (!p.locality || p.locality.id == document.locality.id) &&
        (!p.crop ||
          !document.cropCycle ||
          document.cropCycle.crops.some((c) => c.id == p.crop?.id))
    ),
    (p) =>
      (p.position?.id == position.id ? 4 : 0) +
      (p.position?.positionType == position.positionType ? 3 : 0) +
      (p.locality?.id == document.locality.id ? 2 : 0) +
      (document.cropCycle?.crops.some((c) => c.id == p.crop?.id) ? 1 : 0)
  ).slice(-1)[0];
}

export function useActivityPaymentUpdate(
  options?: MutationHookOptions<
    ActivityPaymentUpdateMutation,
    ActivityPaymentUpdateMutationVariables
  >
) {
  return useMutation(ActivityPaymentUpdateDocument, options);
}

export function useActivityPaymentCreate(
  options?: MutationHookOptions<
    ActivityPaymentCreateMutation,
    ActivityPaymentCreateMutationVariables
  >
) {
  return useMutation(ActivityPaymentCreateDocument, options);
}

export function useActivityPaymentDiscard(
  options?: MutationHookOptions<
    ActivityPaymentDiscardMutation,
    ActivityPaymentDiscardMutationVariables
  >
) {
  return useMutation(ActivityPaymentDiscardDocument, options);
}
