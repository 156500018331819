import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import { UploadProps } from "antd/lib/upload";
import { FormattedMessage } from "react-intl";
import { formatAssetPath } from "../../lib/formats";

interface FileInfo {
  filename: string;
  url: string;
  id: string;
}

export interface Files {
  files: any[];
  newFiles: any[];
  purgeFiles: any[];
  uploading?: boolean;
}

export type WithFiles<T> = T & { files?: Files };

export type DraggerProps = UploadProps & {
  value?: Files;
  children: React.ReactNode;
  onChange?(value: Files): void;
};

export function prepareUploadFiles(files?: FileInfo[] | null) {
  return {
    files:
      files?.map((file) => ({
        uid: file.id,
        name: file.filename,
        url: formatAssetPath(file.url),
        status: "done",
      })) || [],
    newFiles: [],
    purgeFiles: [],
  };
}

export function Dragger({
  value = { files: [], newFiles: [], purgeFiles: [] },
  onChange,
  name,
  children,
  ...restProps
}: DraggerProps) {
  return (
    <Upload.Dragger
      name={name}
      multiple={true}
      className="upload-list-inline"
      listType="picture"
      fileList={value.files}
      openFileDialogOnClick={false}
      // blank custom request bc real upload will be run on grapql call
      customRequest={() => {}}
      showUploadList={{
        showDownloadIcon: false,
      }}
      onChange={(info) => {
        if (!onChange) return;

        const newFiles = info.fileList
          .filter((f) => !parseInt(f.uid))
          .map((f) => f.originFileObj);
        const files = info.fileList.map((f) => ({ ...f, status: "done" }));
        const purgeFiles =
          info.file.status === "removed" && parseInt(info.file.uid)
            ? [...value.purgeFiles, info.file.uid]
            : value.purgeFiles;

        onChange({ newFiles, files, purgeFiles });
      }}
      {...restProps}
    >
      {children}
    </Upload.Dragger>
  );
}

Dragger.Images = (props: Omit<DraggerProps, "children">) => (
  <Dragger accept="image/*" openFileDialogOnClick {...props}>
    <Button type="dashed" icon={<UploadOutlined />} style={{ width: "100%" }}>
      <span>
        <FormattedMessage id="upload" defaultMessage="upload" />
      </span>
    </Button>
  </Dragger>
);
