import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";
import {
  PositionsDocument,
  PositionsQuery,
  PositionUpdateDocument,
  PositionsQueryVariables,
  PositionUpdateMutationVariables,
  PositionUpdateMutation,
  PositionCreateMutation,
  PositionCreateMutationVariables,
  PositionCreateDocument,
  PositionDiscardMutation,
  PositionDiscardMutationVariables,
  PositionDiscardDocument,
  PositionTypeQuery,
  PositionTypeDocument,
  PositionQuery,
  PositionQueryVariables,
  PositionDocument,
  PositionTypeQueryVariables,
  SortDirection,
  PositionDestroyDocument,
  PositionDestroyMutation,
  PositionDestroyMutationVariables,
} from "../../graphql";
import { useIntl } from "react-intl";
import { useEffectOnce } from "react-use";
import { useTranslate } from "./basic";

export function usePositionTypeFormat() {
  const intl = useIntl();
  return (type: string) =>
    intl.formatMessage({ id: `positions.types.${type}` });
}

export function usePositionTypeOptions() {
  const formatPositionType = usePositionTypeFormat();

  const [load, { loading, data }] = useLazyQuery<
    PositionTypeQuery,
    PositionTypeQueryVariables
  >(PositionTypeDocument);

  const options =
    data?.__type?.enumValues
      ?.map((t) => ({
        key: t.name,
        label: formatPositionType(t.name),
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) || [];

  return { load, loading, options };
}

export function usePositionOptions() {
  const intl = useIntl();
  const [load, { loading, data }] = useLazyQuery<
    PositionsQuery,
    PositionsQueryVariables
  >(PositionsDocument, { variables: { sort: { name: SortDirection.Asc } } });

  const options =
    data?.positions?.items?.map((p) => ({
      key: p.id,
      label: p.name,
      group: intl.formatMessage({ id: `positions.types.${p.positionType}` }),
      position: p,
    })) || [];

  const search = (name?: string) => {
    load(
      name
        ? {
            variables: {
              filter: { name: { match: name } },
              sort: {},
            },
          }
        : undefined
    );
  };

  return { load, search, loading, options };
}

export function usePositions(
  options?: QueryHookOptions<PositionsQuery, PositionsQueryVariables>
) {
  const { loading, data, refetch } = useQuery(PositionsDocument, options);

  const items = data?.positions?.items;
  const totalCount = data?.positions?.totalCount;

  return { loading, items, totalCount, refetch };
}

export function usePosition(id: string, preload = true) {
  const [load, { data }] = useLazyQuery<PositionQuery, PositionQueryVariables>(
    PositionDocument,
    { variables: { id } }
  );

  useEffectOnce(() => {
    if (preload) load();
  });

  return { position: data?.position, load };
}

export function usePositionUpdate(
  options?: MutationHookOptions<
    PositionUpdateMutation,
    PositionUpdateMutationVariables
  >
) {
  return useMutation(PositionUpdateDocument, options);
}

export function usePositionCreate(
  options?: MutationHookOptions<
    PositionCreateMutation,
    PositionCreateMutationVariables
  >
) {
  return useMutation(PositionCreateDocument, options);
}

export function usePositionDiscard(
  options?: MutationHookOptions<
    PositionDiscardMutation,
    PositionDiscardMutationVariables
  >
) {
  return useMutation(PositionDiscardDocument, options);
}

export function usePositionDestroy(
  options?: MutationHookOptions<
    PositionDestroyMutation,
    PositionDestroyMutationVariables
  >
) {
  return useMutation(PositionDestroyDocument, options);
}

export function useContractTypes() {
  const translate = useTranslate();

  const contractTypes = {
    byActivity: translate("employeeContractTypes.byActivity"),
    fullTime: translate("employeeContractTypes.fullTime"),
  };

  const contractTypeOptions = Object.entries(contractTypes).map(
    ([key, label]) => ({ key, label })
  );

  const formatContractType = (type: string) =>
    type && translate(`employeeContractTypes.${type}`);

  return { contractTypes, contractTypeOptions, formatContractType };
}
