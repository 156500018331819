import { QueryHookOptions, useQuery } from "@apollo/client";
import {
  CropFieldSummaryReportQuery,
  CropFieldSummaryReportQueryVariables,
  CropFieldSummaryReportDocument,
  CropFieldActivityReportQuery,
  CropFieldActivityReportQueryVariables,
  CropFieldActivityReportDocument,
} from "../../graphql";

export function useCropFieldSummaryReport(
  options?: QueryHookOptions<
    CropFieldSummaryReportQuery,
    CropFieldSummaryReportQueryVariables
  >
) {
  const { loading, data } = useQuery(CropFieldSummaryReportDocument, options);

  if (
    !data ||
    !data.cropFieldSummaryReport ||
    !data.cropFieldSummaryReport.items
  ) {
    return { loading };
  }

  const items = data.cropFieldSummaryReport.items;
  const totalCount = data.cropFieldSummaryReport.totalCount;

  return { loading, items, totalCount };
}

export function useCropFieldActivityReport(
  options?: QueryHookOptions<
    CropFieldActivityReportQuery,
    CropFieldActivityReportQueryVariables
  >
) {
  const { loading, data } = useQuery(CropFieldActivityReportDocument, options);

  if (
    !data ||
    !data.cropFieldActivityReport ||
    !data.cropFieldActivityReport.items
  ) {
    return { loading };
  }

  const items = data.cropFieldActivityReport.items;
  const totalCount = data.cropFieldActivityReport.totalCount;

  return { loading, items, totalCount };
}
