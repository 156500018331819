import { Rules, SelectField } from "../../form";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { useCountryOptions, useCountryRegionOptions } from "../../../lib/hooks";
import { Col, Form, Input, Row } from "antd";
import { PhoneInput } from "./PhoneInput";
import { shouldUpdate } from "../../../lib/formats";

interface AddressInputProps {
  name: string;
  compact?: boolean;
  onCountryChange?: (
    country?: ReturnType<typeof useCountryOptions>["options"][0]["country"]
  ) => void;
}

const messages = defineMessages({
  country: { id: "country", defaultMessage: "country" },
  region: { id: "region", defaultMessage: "region" },
  city: { id: "city", defaultMessage: "city" },
  addressLine: { id: "addressLine", defaultMessage: "addressLine" },
  address: { id: "address", defaultMessage: "address" },
});

export function AddressInput({
  name,
  compact,
  onCountryChange,
}: AddressInputProps) {
  const intl = useIntl();
  const form = Form.useFormInstance();

  if (compact)
    return (
      <>
        <Form.Item
          noStyle
          name={[name, "countryCode"]}
          rules={[Rules.required]}
        >
          <SelectField
            style={{ marginBottom: "8px" }}
            placeholder={intl.formatMessage(messages.country)}
            optionsHook={useCountryOptions}
            onChange={(
              value,
              options: ReturnType<typeof useCountryOptions>["options"]
            ) => {
              if (onCountryChange) {
                const country = options.find((c) => c.key === value)?.country;

                onCountryChange(country);
              }

              form.setFieldValue([name, "countryCode"], value);
            }}
          />
        </Form.Item>

        <Input.Group compact style={{ marginBottom: "8px" }}>
          <Form.Item noStyle name={[name, "region"]}>
            <Input
              style={{ width: "50%" }}
              placeholder={intl.formatMessage(messages.region)}
            />
          </Form.Item>
          <Form.Item noStyle name={[name, "city"]}>
            <Input
              style={{ width: "50%" }}
              placeholder={intl.formatMessage(messages.city)}
            />
          </Form.Item>
        </Input.Group>
        <Form.Item noStyle name={[name, "address"]}>
          <Input placeholder={intl.formatMessage(messages.addressLine)} />
        </Form.Item>
      </>
    );

  return (
    <>
      <Row gutter={32}>
        <Col xs={24} md={12}>
          <Form.Item noStyle shouldUpdate>
            {({ setFields }) => {
              return (
                <Form.Item
                  name={[name, "countryCode"]}
                  label={<FormattedMessage id="country" />}
                  rules={[Rules.required]}
                >
                  <SelectField
                    placeholder={
                      <FormattedMessage
                        id="select.country"
                        defaultMessage="selectCountry"
                      />
                    }
                    optionsHook={useCountryOptions}
                    onChange={(
                      value,
                      options: ReturnType<typeof useCountryOptions>["options"]
                    ) => {
                      if (value) {
                        const country = options.find(
                          (c) => c.key === value
                        )?.country;

                        setFields([
                          {
                            name: [name, "phoneCode"],
                            value: country?.phoneCode,
                          },
                        ]);

                        if (onCountryChange) onCountryChange(country);
                      }
                    }}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        <Col xs={24} md={12}>
          <Form.Item noStyle shouldUpdate={shouldUpdate([name, "countryCode"])}>
            {({ getFieldValue }) => {
              const countryCode = getFieldValue([name, "countryCode"]);

              return (
                <Form.Item
                  name={[name, "region"]}
                  label={<FormattedMessage id="region" />}
                >
                  <SelectField
                    optionsHook={useCountryRegionOptions}
                    optionsHookFilter={(o) => o.countryCode == countryCode}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        <Col xs={24} md={12}>
          <Form.Item
            name={[name, "city"]}
            label={<FormattedMessage id="city" />}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col xs={24} md={12}>
          <Form.Item
            name={[name, "address"]}
            label={<FormattedMessage id="address" />}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label={
          <FormattedMessage id="phoneNumber" defaultMessage="phoneNumber" />
        }
      >
        <PhoneInput name={name} />
      </Form.Item>
    </>
  );
}
