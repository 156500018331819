import { BLANK_SYMBOL } from ".";
import type { GeoFeatures } from "../mapbox/utils";

export function geoJsonCoordinates(geoJson: any) {
  const firstGeo = geoJson?.features?.[0]?.geometry;

  if (!firstGeo) return undefined;

  let coords: number[] = [];

  switch (firstGeo.type) {
    case "Point":
      coords = geoJson.features[0].geometry.coordinates;
      break;
    case "LineString":
      coords = geoJson.features[0].geometry.coordinates[0];
      break;
    case "Polygon":
      coords = geoJson.features[0].geometry.coordinates[0][0];
      break;
    default:
      return undefined;
  }

  if (!coords) return undefined;

  return coords.map((c: number) => Math.round(c * 10000) / 10000).join(", ");
}

export function geoJsonPoint(object?: {
  longitude?: number | null;
  latitude?: number | null;
}): GeoFeatures | undefined {
  if (!object || !object.latitude || !object.longitude) return undefined;

  return {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Point",
          coordinates: [object.longitude, object.latitude],
        },
      },
    ],
  };
}

export function formatMapCoordinate(
  longitude?: number | null,
  latitude?: number | null
) {
  if (!latitude || !longitude) return BLANK_SYMBOL;

  return [longitude, latitude]
    .map((c) => Math.round(c * 10000) / 10000)
    .join(", ");
}
