import { useChangeLogs } from "../../../lib/hooks";
import { Button, Empty, Space, Spin, Tag, Timeline, Typography } from "antd";
import { useEffect, useState } from "react";
import { Avatar } from "../Avatar";
import {
  colors,
  formatDateTime,
  humanize,
  statusColor,
} from "../../../lib/formats";
import { FormattedMessage } from "react-intl";
import { ChangeLogFragment } from "../../../lib/graphql";
import { formatUsername } from "../../../lib/formats/users";
import dayjs from "dayjs";

interface ChangeListProps {
  id: string;
  type: string;
}

function eventColor(event: string) {
  switch (event) {
    case "created":
      return "green";
    case "updated":
      return "blue";
    default:
      return statusColor(event);
  }
}

const excludeKeys = ["tenant_id"];

export function formatChanges(changes: any, key?: any): any {
  if (!changes) return null;

  if (Array.isArray(changes)) {
    if (
      (changes[0] == null || typeof changes[0] !== "object") &&
      (changes[1] == null || typeof changes[1] !== "object")
    ) {
      const color =
        changes[0] == null || key === "add"
          ? colors.successColor
          : changes[1] == null || key === "remove"
          ? colors.dangerColor
          : undefined;
      const delimiter = ["add", "remove"].includes(key) ? ", " : " → ";
      return (
        <span style={{ color }}>
          {changes
            .filter((c) => c)
            .map(formatChanges)
            .join(delimiter)}
        </span>
      );
    } else {
      return changes.map((c) => formatChanges(c, key));
    }
  } else if (typeof changes === "number") {
    return String(changes);
  } else if (!Number.isNaN(Date.parse(changes)) && changes.length == 29) {
    return dayjs(changes).format("L LT");
  } else if (typeof changes === "boolean" || typeof changes === "string") {
    return String(changes);
  } else {
    return Object.entries(changes).map(([key, value]) =>
      excludeKeys.includes(key) ? null : (
        <div key={key}>
          {humanize(key)}:
          <div style={{ paddingLeft: "10px", borderLeft: "1px dashed #ccc" }}>
            {formatChanges(value, key)}
          </div>
        </div>
      )
    );
  }
}

export function ChangeList({ id, type }: ChangeListProps) {
  let page = 1;

  const { items, totalCount, loading, refetch } = useChangeLogs({
    variables: {
      filter: { objId: Number(id), objType: type },
      page,
    },
    fetchPolicy: "no-cache",
  });

  const [changes, setChanges] = useState<ChangeLogFragment[]>([]);

  useEffect(() => {
    if (items && items.length > 0) {
      setChanges((c) => c.concat(items));
    }
  }, [items]);

  if (loading && changes.length === 0) {
    return (
      <div style={{ textAlign: "center", marginTop: "40vh" }}>
        <Spin />
      </div>
    );
  }

  if (!items || items.length === 0) return <Empty />;

  return (
    <>
      <Timeline>
        {changes &&
          changes.map((change) => (
            <Timeline.Item key={change.id} color={eventColor(change.event)}>
              <Space>
                <span>{formatDateTime(change.createdAt)}</span>
                <Tag color={eventColor(change.event)}>
                  {humanize(change.event)}
                </Tag>
              </Space>
              <div>
                <Typography.Text type="secondary" style={{ fontSize: "12px" }}>
                  <Avatar user={change.user} size={14} />{" "}
                  {formatUsername(change.user)}
                </Typography.Text>
              </div>
              <div>{formatChanges(change.objectChanges)}</div>
            </Timeline.Item>
          ))}
      </Timeline>

      {totalCount && totalCount > changes.length && (
        <div style={{ textAlign: "right" }}>
          <Button
            type="primary"
            onClick={() => {
              refetch && refetch({ page: ++page });
            }}
          >
            <FormattedMessage id="next" defaultMessage="Next" />
          </Button>
        </div>
      )}
    </>
  );
}

export * from "./Sidebar";
