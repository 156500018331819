import {
  QueryHookOptions,
  useQuery,
  MutationHookOptions,
  useMutation,
} from "@apollo/client";
import {
  TransfersQuery,
  TransfersQueryVariables,
  TransfersDocument,
  TransferUpdateMutation,
  TransferUpdateMutationVariables,
  TransferUpdateDocument,
  TransferCreateMutation,
  TransferCreateMutationVariables,
  TransferCreateDocument,
  TransferDocument,
  TransferQuery,
  TransferQueryVariables,
  TransferStatus,
  StockTransferQuery,
  StockTransferQueryVariables,
  StockTransferDocument,
  TransferCloneMutation,
  TransferCloneMutationVariables,
  TransferCloneDocument,
} from "../../graphql";

import { useTranslate } from "../basic/basic";

export function useTransfers(
  options?: QueryHookOptions<TransfersQuery, TransfersQueryVariables>
) {
  const { loading, data } = useQuery(TransfersDocument, options);

  if (!data || !data.stockTransfers || !data.stockTransfers.items) {
    return { loading };
  }

  const items = data.stockTransfers.items;
  const totalCount = data.stockTransfers.totalCount;

  return { loading, items, totalCount };
}

export function useStockTransfer(id: string) {
  const { data, startPolling, stopPolling } = useQuery<
    StockTransferQuery,
    StockTransferQueryVariables
  >(StockTransferDocument, { variables: { id } });

  return { transfer: data?.stockTransfer, startPolling, stopPolling };
}

export function useTransfer(id: string) {
  const { data } = useQuery<TransferQuery, TransferQueryVariables>(
    TransferDocument,
    { variables: { id } }
  );

  return data && data.stockTransfer;
}

export function useTransferStatusOptions() {
  const translate = useTranslate();
  return [
    {
      key: TransferStatus.Open,
      label: translate("statuses.open"),
    },
    {
      key: TransferStatus.Completed,
      label: translate("statuses.completed"),
    },
    {
      key: TransferStatus.Canceled,
      label: translate("statuses.canceled"),
    },
  ];
}

export function useTransferUpdate(
  options?: MutationHookOptions<
    TransferUpdateMutation,
    TransferUpdateMutationVariables
  >
) {
  return useMutation(TransferUpdateDocument, options);
}

export function useTransferCreate(
  options?: MutationHookOptions<
    TransferCreateMutation,
    TransferCreateMutationVariables
  >
) {
  return useMutation(TransferCreateDocument, options);
}

export function useTransferClone(
  options?: MutationHookOptions<
    TransferCloneMutation,
    TransferCloneMutationVariables
  >
) {
  return useMutation(TransferCloneDocument, options);
}
