import { MutationHookOptions, useMutation, useQuery } from "@apollo/client";
import {
  FinancePeriodCloseDocument,
  FinancePeriodCloseMutation,
  FinancePeriodCloseMutationVariables,
  FinancePeriodOpenDocument,
  FinancePeriodOpenMutation,
  FinancePeriodOpenMutationVariables,
  FinancePeriodsDocument,
  FinancePeriodsQuery,
  FinancePeriodsQueryVariables,
  SortDirection,
} from "../../graphql";

export function useLastClosedFinancePeriod() {
  const { loading, data } = useQuery<
    FinancePeriodsQuery,
    FinancePeriodsQueryVariables
  >(FinancePeriodsDocument, {
    variables: {
      filter: { closed: true },
      sort: { startDate: SortDirection.Desc },
      pageSize: 1,
    },
  });

  return { loading, period: data?.financePeriods?.items?.[0] };
}

export function useFinancePeriodClose(
  options?: MutationHookOptions<
    FinancePeriodCloseMutation,
    FinancePeriodCloseMutationVariables
  >
) {
  return useMutation(FinancePeriodCloseDocument, options);
}

export function useFinancePeriodOpen(
  options?: MutationHookOptions<
    FinancePeriodOpenMutation,
    FinancePeriodOpenMutationVariables
  >
) {
  return useMutation(FinancePeriodOpenDocument, options);
}
