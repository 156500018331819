import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";

import {
  InfrastructuresDocument,
  InfrastructuresQuery,
  InfrastructureUpdateDocument,
  InfrastructuresQueryVariables,
  InfrastructureUpdateMutationVariables,
  InfrastructureUpdateMutation,
  InfrastructureCreateMutation,
  InfrastructureCreateMutationVariables,
  InfrastructureCreateDocument,
  InfrastructureDiscardMutation,
  InfrastructureDiscardMutationVariables,
  InfrastructureDiscardDocument,
  InfrastructureQuery,
  InfrastructureQueryVariables,
  InfrastructureDocument,
  InfrastructureKindsQuery,
  InfrastructureKindsDocument,
  SortDirection,
  InfrastructureCategory,
  InfrastructureKindsQueryVariables,
  InfrastructureDestroyMutation,
  InfrastructureDestroyMutationVariables,
  InfrastructureDestroyDocument,
} from "../../graphql";
import { useIntl } from "react-intl";
import { merge } from "lodash";

export function useInfrastructureCategoryFormat() {
  const intl = useIntl();
  return (category: InfrastructureCategory) =>
    intl.formatMessage({
      id: `infrastructures.categories.${category}`,
    });
}

export function useInfrastructureKindOptions(
  queryOptions?: QueryHookOptions<
    InfrastructureKindsQuery,
    InfrastructureKindsQueryVariables
  >
) {
  const categoryFormat = useInfrastructureCategoryFormat();
  const [load, { data, loading }] = useLazyQuery(
    InfrastructureKindsDocument,
    merge({ variables: { sort: { name: SortDirection.Asc } } }, queryOptions)
  );

  const options =
    data?.infrastructureKinds?.items?.map((k) => ({
      key: k.id,
      label: k.name,
      group: categoryFormat(k.category),
      category: k.category,
    })) || [];

  return {
    load,
    options,
    loading,
  };
}

export function useInfrastructureOptions(
  queryOptions?: QueryHookOptions<
    InfrastructuresQuery,
    InfrastructuresQueryVariables
  >
) {
  const [load, { loading, data }] = useLazyQuery(
    InfrastructuresDocument,
    merge(
      { variables: { fetchActions: false, sort: { name: SortDirection.Asc } } },
      queryOptions
    )
  );

  const options =
    data?.infrastructures?.items.map((i) => ({
      key: i.id,
      label: i.name,
      group: i.kind.name,
      category: i.kind.category,
      infrastructure: i,
    })) || [];

  const search = (val?: string) =>
    load(
      merge({ variables: { sort: undefined } }, queryOptions, {
        variables: {
          filter: val ? { name: { match: val } } : undefined,
          fetchActions: false,
        },
      })
    );

  return {
    load,
    search,
    loading,
    options,
  };
}

export function useInfrastructures(
  options?: QueryHookOptions<
    InfrastructuresQuery,
    InfrastructuresQueryVariables
  >
) {
  const { loading, data } = useQuery(InfrastructuresDocument, options);

  if (!data || !data.infrastructures || !data.infrastructures.items) {
    return { loading };
  }

  const items = data.infrastructures.items;
  const totalCount = data.infrastructures.totalCount;

  return { loading, items, totalCount };
}

export function useInfrastructure(id: string) {
  const { data } = useQuery<InfrastructureQuery, InfrastructureQueryVariables>(
    InfrastructureDocument,
    { variables: { id } }
  );

  return data && data.infrastructure;
}

export function useInfrastructureUpdate(
  options?: MutationHookOptions<
    InfrastructureUpdateMutation,
    InfrastructureUpdateMutationVariables
  >
) {
  return useMutation(InfrastructureUpdateDocument, options);
}

export function useInfrastructureCreate(
  options?: MutationHookOptions<
    InfrastructureCreateMutation,
    InfrastructureCreateMutationVariables
  >
) {
  return useMutation(InfrastructureCreateDocument, options);
}

export function useInfrastructureDiscard(
  options?: MutationHookOptions<
    InfrastructureDiscardMutation,
    InfrastructureDiscardMutationVariables
  >
) {
  return useMutation(InfrastructureDiscardDocument, options);
}

export function useInfrastructureDestroy(
  options?: MutationHookOptions<
    InfrastructureDestroyMutation,
    InfrastructureDestroyMutationVariables
  >
) {
  return useMutation(InfrastructureDestroyDocument, options);
}
