import Icon, {
  CloseCircleOutlined,
  CopyOutlined,
  DownOutlined,
  FileExcelOutlined,
  FileOutlined,
  FilePdfOutlined,
  HistoryOutlined,
  LinkOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Button, Typography } from "antd";
import { ReactNode } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { useCopyToClipboard } from "react-use";
import { DropDownProps } from "antd/lib/dropdown";
import Link from "next/link";
import { Dropdown, exportUrl } from "..";
import { ItemType, MenuItemType } from "antd/lib/menu/hooks/useItems";
import { blue } from "@ant-design/colors";
import { useCurrentLocale } from "../../../lib/hooks";

interface ActionButtonProps extends Omit<DropDownProps, "overlay" | "menu"> {
  title?: ReactNode;
  menuItems: ItemType[];
}

const messages = defineMessages({
  copied: { id: "copyLink.success", defaultMessage: "copied" },
});

export function ActionButton({
  title,
  menuItems,
  ...dropDownProps
}: ActionButtonProps) {
  return (
    <Dropdown
      menu={{ triggerSubMenuAction: "click", items: menuItems }}
      {...dropDownProps}
    >
      <Button onClick={(e) => e.stopPropagation()}>
        {title || <FormattedMessage id="actions" defaultMessage="actions" />}{" "}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
}

const CopyLinkMenuItem = function (url?: string) {
  const { showSuccess } = useCurrentLocale();
  const intl = useIntl();
  const [, copyToClipboard] = useCopyToClipboard();

  return {
    key: "copy-link",
    icon: <LinkOutlined />,
    onClick: () => {
      copyToClipboard(url || location.href);
      showSuccess(intl.formatMessage(messages.copied));
    },
    label: <FormattedMessage id="copyLink" defaultMessage="copyLink" />,
  };
};
ActionButton.CopyLinkMenuItem = CopyLinkMenuItem;

interface IDownloadActionButton {
  downloadLink?: string;
  onClick?: () => void;
  children?: ReactNode;
}

ActionButton.DownloadPDF = (props?: IDownloadActionButton) =>
  ActionButton.DownloadButton({
    key: "pdf",
    icon: <FilePdfOutlined />,
    text: (
      <FormattedMessage
        id="pdfFileDefinition"
        defaultMessage="PDF document (.pdf)"
      />
    ),
    props,
  });

ActionButton.DownloadExcel = (props?: IDownloadActionButton) =>
  ActionButton.DownloadButton({
    key: "xlsx",
    icon: <FileExcelOutlined />,
    text: (
      <FormattedMessage
        id="excelFileDefinition"
        defaultMessage="Microsoft Excel (.xlsx)"
      />
    ),
    props,
  });

ActionButton.DownloadCSV = (props?: IDownloadActionButton) =>
  ActionButton.DownloadButton({
    key: "csv",
    icon: <FileOutlined />,
    text: (
      <FormattedMessage
        id="csvFileDefinition"
        defaultMessage="Comma separated values (.csv)"
      />
    ),
    props,
  });

ActionButton.DownloadButton = ({
  key,
  icon,
  text,
  props = {},
}: {
  key: string;
  icon?: ReactNode;
  text: string | ReactNode;
  props?: IDownloadActionButton;
}) => {
  const { downloadLink, onClick, children } = props;

  return {
    key,
    icon,
    disabled: !downloadLink && !onClick,
    onClick,
    label: downloadLink ? (
      <Link href={downloadLink || ""} target="_blank" rel="noopener noreferrer">
        {children || text}
      </Link>
    ) : (
      children || text
    ),
  };
};

ActionButton.Revert = function (onClick: () => void) {
  return {
    key: "revert",
    danger: true,
    icon: <CloseCircleOutlined />,
    onClick,
    label: <FormattedMessage id="revert" />,
  };
};

ActionButton.Cancel = function (onClick: () => void) {
  return {
    key: "cancel",
    danger: true,
    icon: <StopOutlined />,
    onClick,
    label: <FormattedMessage id="cancel.doc" defaultMessage="Cancel" />,
  };
};

ActionButton.ChangeLog = function (onClick: () => void) {
  return {
    key: "change-log",
    icon: <HistoryOutlined />,
    onClick,
    label: <FormattedMessage id="history" />,
  };
};

ActionButton.Clone = function ({
  onClick,
  entityName,
}: {
  onClick: () => void;
  entityName: string;
}) {
  return {
    key: "clone",
    icon: (
      <Typography.Text style={{ color: blue.primary }}>
        <CopyOutlined />
      </Typography.Text>
    ),
    onClick: onClick,
    label: (
      <Typography.Text style={{ color: blue.primary }}>
        <FormattedMessage
          id="duplicateEntity"
          defaultMessage="Copy & Clone {entityName}"
          values={{ entityName }}
        />
      </Typography.Text>
    ),
  };
};

ActionButton.Button = function ({
  icon,
  danger,
  onClick,
  label,
}: MenuItemType & { onClick?: () => void }) {
  return (
    <Button icon={icon} danger={danger} onClick={() => onClick && onClick()}>
      <span>{label}</span>
    </Button>
  );
};

export function ExactusDownloadButton(name: string, params: any) {
  return ActionButton.DownloadButton({
    key: "exactus",
    text: "Exactus (.xlsx)",
    icon: (
      <Icon
        component={() => <img src="/images/icons/exactus.png" width={16} />}
      />
    ),
    props: {
      downloadLink: exportUrl({ ...params, export: name }, "xlsx"),
    },
  });
}

export function AgroprimeDownloadButton(name: string, params: any) {
  return ActionButton.DownloadButton({
    key: "agroprime",
    text: "Agroprime (.csv)",
    icon: (
      <Icon
        component={() => <img src="/images/icons/agroprime.png" width={16} />}
      />
    ),
    props: {
      downloadLink: exportUrl({ ...params, export: name }, "csv"),
    },
  });
}
