import {
  QueryHookOptions,
  useQuery,
  MutationHookOptions,
  useMutation,
  useLazyQuery,
} from "@apollo/client";
import {
  LeaveAssignmentsQuery,
  LeaveAssignmentsQueryVariables,
  LeaveAssignmentsDocument,
  LeaveAssignmentUpdateMutation,
  LeaveAssignmentUpdateMutationVariables,
  LeaveAssignmentUpdateDocument,
  LeaveAssignmentCreateMutation,
  LeaveAssignmentCreateMutationVariables,
  LeaveAssignmentCreateDocument,
  LeaveAssignmentDocument,
  LeaveAssignmentQuery,
  LeaveAssignmentQueryVariables,
  LeaveAssignmentStatusDocument,
  LeaveAssignmentStatusQuery,
} from "../../graphql";

import { useIntl } from "react-intl";

export function useLeaveAssignmentStatusOptions() {
  const intl = useIntl();

  const [load, { loading, data }] = useLazyQuery<LeaveAssignmentStatusQuery>(
    LeaveAssignmentStatusDocument
  );

  const options = data?.__type?.enumValues?.map((s) => ({
    key: s.name,
    value: s.name,
    label: intl.formatMessage({ id: `statuses.${s.name}` }),
  }));

  return {
    load,
    loading,
    options,
  };
}

export function useLeaveAssignments(
  options?: QueryHookOptions<
    LeaveAssignmentsQuery,
    LeaveAssignmentsQueryVariables
  >
) {
  const { loading, data } = useQuery(LeaveAssignmentsDocument, options);

  if (!data || !data.leaveAssignments || !data.leaveAssignments.items) {
    return { loading };
  }

  const items = data.leaveAssignments.items;
  const totalCount = data.leaveAssignments.totalCount;

  return { loading, items, totalCount };
}

export function useLeaveAssignment(id: string) {
  const { data } = useQuery<
    LeaveAssignmentQuery,
    LeaveAssignmentQueryVariables
  >(LeaveAssignmentDocument, { variables: { id } });

  return data?.leaveAssignment;
}

export function useLeaveAssignmentUpdate(
  options?: MutationHookOptions<
    LeaveAssignmentUpdateMutation,
    LeaveAssignmentUpdateMutationVariables
  >
) {
  return useMutation(LeaveAssignmentUpdateDocument, options);
}

export function useLeaveAssignmentCreate(
  options?: MutationHookOptions<
    LeaveAssignmentCreateMutation,
    LeaveAssignmentCreateMutationVariables
  >
) {
  return useMutation(LeaveAssignmentCreateDocument, options);
}
