import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";
import {
  RolesDocument,
  RolesQuery,
  RolesQueryVariables,
  RoleUpdateMutation,
  RoleUpdateMutationVariables,
  RoleUpdateDocument,
  RoleCreateMutation,
  RoleCreateMutationVariables,
  RoleCreateDocument,
  RoleQuery,
  RoleQueryVariables,
  RoleDocument,
  RoleDiscardMutation,
  RoleDiscardDocument,
  RoleDiscardMutationVariables,
  SortDirection,
} from "../../graphql";

export function useRoleOptions() {
  const [load, { loading, data }] = useLazyQuery<
    RolesQuery,
    RolesQueryVariables
  >(RolesDocument, {
    variables: { sort: { name: SortDirection.Asc }, public: true },
  });

  const options =
    data?.roles?.items?.map((r) => ({
      key: r.id,
      label: r.name,
      permissions: r.permissions,
    })) || [];

  const search = (val?: string) => {
    load({
      variables: {
        sort: undefined,
        filter: val ? { name: { match: val } } : undefined,
      },
    });
  };

  return { load, loading, options, search };
}

export function useRoles(
  options?: QueryHookOptions<RolesQuery, RolesQueryVariables>
) {
  const { loading, data } = useQuery(RolesDocument, options);

  if (!data || !data.roles || !data.roles.items) {
    return { loading };
  }

  const items = data.roles.items;
  const totalCount = data.roles.totalCount;

  return { loading, items, totalCount };
}

export function useRole(id: string) {
  const { data } = useQuery<RoleQuery, RoleQueryVariables>(RoleDocument, {
    variables: { id },
  });

  return data && data.role;
}

export function useRoleUpdate(
  options?: MutationHookOptions<RoleUpdateMutation, RoleUpdateMutationVariables>
) {
  return useMutation(RoleUpdateDocument, options);
}

export function useRoleCreate(
  options?: MutationHookOptions<RoleCreateMutation, RoleCreateMutationVariables>
) {
  return useMutation(RoleCreateDocument, options);
}

export function useRoleDiscard(
  options?: MutationHookOptions<
    RoleDiscardMutation,
    RoleDiscardMutationVariables
  >
) {
  return useMutation(RoleDiscardDocument, options);
}
