import { Button } from "antd";
import { ImportSidebarForm } from "../ImportSidebarForm";
import { defineMessages, useIntl } from "react-intl";
import { UploadOutlined } from "@ant-design/icons";
import { ImportModulesType } from "../../../lib/graphql";
import { useItemSidebarContext, useMediaXs } from "../../../lib/hooks";
import { ItemSidebar } from "../ItemSidebar";

export interface ImportProps {
  moduleName: ImportModulesType;
  btnText?: string;
}

const messages = defineMessages({
  import: { id: "imports.model", defaultMessage: "Import {entityName}" },
});

export function ImportSidebar({ btnText, moduleName }: ImportProps) {
  const { setCurrentAction } = useItemSidebarContext();

  const intl = useIntl();
  const isXs = useMediaXs();

  return (
    <>
      <Button
        icon={<UploadOutlined />}
        onClick={() => setCurrentAction("import")}
        data-action="import-btn"
      >
        {!isXs && (
          <span>
            {intl.formatMessage(messages.import, { entityName: btnText })}
          </span>
        )}
      </Button>

      <ItemSidebar
        item={null}
        sidebarActions={{
          import: () => (
            <ImportSidebarForm
              moduleName={moduleName}
              onClose={() => setCurrentAction()}
            />
          ),
        }}
      />
    </>
  );
}
