import { MutationHookOptions, useMutation } from "@apollo/client";
import {
  BulkDestroyDocument,
  BulkDestroyMutation,
  BulkDestroyMutationVariables,
  BulkDiscardDocument,
  BulkDiscardMutation,
  BulkDiscardMutationVariables,
} from "../../graphql";

export function useBulkDestroy(
  options?: MutationHookOptions<
    BulkDestroyMutation,
    BulkDestroyMutationVariables
  >
) {
  return useMutation(BulkDestroyDocument, options);
}

export function useBulkDiscard(
  options?: MutationHookOptions<
    BulkDiscardMutation,
    BulkDiscardMutationVariables
  >
) {
  return useMutation(BulkDiscardDocument, options);
}
