import {
  AccountFragment,
  CostCenterFragment,
  AccountKindShortFragment,
  PaymentStatus,
  FinanceInvoiceFragment,
  InvoiceStatus,
  ExpenseItemFragment,
} from "../graphql";
import { ReactNode } from "react";
import {
  CheckCircleTwoTone,
  DollarCircleTwoTone,
  MinusCircleTwoTone,
} from "@ant-design/icons";
import { colors, statusColor, translate } from "./common";
import dayjs from "dayjs";
import { Tag, Typography } from "antd";
import { FormattedMessage, FormattedPlural } from "react-intl";

export function formatAccountName(
  account: Pick<AccountFragment, "accountNumber" | "name">
) {
  return [account.accountNumber, account.name].filter((a) => a).join(" - ");
}

export function formatAccountKind(
  kind?:
    | (Pick<AccountKindShortFragment, "name" | "parentKind"> & {
        parentKind?: Pick<
          AccountKindShortFragment,
          "name" | "parentKind"
        > | null;
      })
    | null
): ReactNode {
  if (!kind) return null;

  return [kind.name, formatAccountKind(kind.parentKind)]
    .filter((a) => a)
    .join(" / ");
}

export function formatCostCenterName(
  costCenter?: Pick<CostCenterFragment, "internalId" | "name"> | null,
  profitableName?: string | null
) {
  if (!costCenter) return null;

  return [costCenter.internalId, costCenter.name, profitableName]
    .filter((a) => a)
    .join(" - ");
}

export function formatProfitableName({
  costCenter,
  profitableName,
}: {
  costCenter?: Pick<CostCenterFragment, "internalId" | "name"> | null;
  profitableName?: string | null;
}) {
  if (profitableName) return profitableName;

  return formatCostCenterName(costCenter);
}

export function formatPaymentStatus(status: string) {
  return (
    <span>
      {status === PaymentStatus.NotPaid && (
        <MinusCircleTwoTone twoToneColor={colors.disabledColor} />
      )}
      {status === PaymentStatus.PartiallyPaid && (
        <DollarCircleTwoTone twoToneColor={colors.warningColor} />
      )}
      {status === PaymentStatus.Paid && (
        <CheckCircleTwoTone twoToneColor={colors.warningColor} />
      )}{" "}
      {translate(`paymentStatuses.${status}`)}
    </span>
  );
}

export function formatInvoiceStatus(invoice: FinanceInvoiceFragment) {
  const daysDiff = Math.abs(
    dayjs(invoice.dueDate).diff(dayjs(new Date()), "days")
  );
  const showDays = [
    InvoiceStatus.Due,
    InvoiceStatus.DueSoon,
    InvoiceStatus.Overdue,
  ].includes(invoice.invoiceStatus);

  const invoiceStatus = (
    <FormattedMessage id={`invoiceStatuses.${invoice.invoiceStatus}`} />
  );

  if (!showDays)
    return (
      <Tag color={statusColor(invoice.invoiceStatus)}>{invoiceStatus}</Tag>
    );

  return (
    <>
      {showDays && (
        <Typography.Text
          type={
            invoice.invoiceStatus === InvoiceStatus.Overdue
              ? "danger"
              : "success"
          }
        >
          {daysDiff}{" "}
          <FormattedPlural
            value={daysDiff}
            one={<FormattedMessage id="day" defaultMessage="day" />}
            other={<FormattedMessage id="days" defaultMessage="days" />}
          />
        </Typography.Text>
      )}

      <div>
        <small className="ant-typography ant-typography-secondary">
          {invoiceStatus}
        </small>
      </div>
    </>
  );
}

export function formatExpenseItem(
  item: Pick<ExpenseItemFragment, "name" | "expenseCategory">
) {
  return (
    <>
      {item.name}
      <Typography.Text type="secondary">
        {" "}
        / {item.expenseCategory.name}
      </Typography.Text>
    </>
  );
}
