import { Tooltip, TooltipProps } from "antd";
import { PropsWithChildren } from "react";
import { FormattedMessage } from "react-intl";

export default function BlockedTooltip({
  children,
  enabled = true,
  ...props
}: PropsWithChildren<TooltipProps & { enabled?: boolean }>) {
  if (!enabled) return <>{children}</>;

  return (
    <Tooltip
      title={
        <FormattedMessage
          id="onboarding.blockedMessage"
          defaultMessage="Please finish onboarding first."
        />
      }
      trigger={["hover", "click"]}
      mouseEnterDelay={0.05}
      {...props}
    >
      {children}
    </Tooltip>
  );
}
