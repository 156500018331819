import { ReactNode } from "react";
import { Tag } from "antd";
import { TagProps } from "antd/lib/tag";

export interface TagRenderProps extends TagProps {
  label: ReactNode;
  value: ReactNode;
}

export function formatTags(
  tags?: string[] | React.ReactNode[] | null,
  color?: string,
  maxCount?: number
) {
  if (!tags || tags.length === 0) return null;

  const tagsToShow = maxCount ? tags.slice(0, maxCount) : tags;

  return (
    <>
      {tagsToShow.map((t, index) => (
        <Tag key={index} color={color}>
          {t}
        </Tag>
      ))}
      {maxCount && tags.length > maxCount && "..."}
    </>
  );
}

export function tagRender(props: TagRenderProps) {
  const { label, closable, onClose } = props;

  return (
    <Tag
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
      {...props}
    >
      {label}
    </Tag>
  );
}
