import Guide, { IGuide, IStep as $IStep } from "byte-guide";
import { defineMessages, useIntl } from "react-intl";
import { useUserSettings } from "../../../lib/hooks";
import { useCallback } from "react";
import { useEffectOnce } from "react-use";

export interface IStep extends $IStep {
  fixedBody?: boolean;
}

const messages = defineMessages({
  step: {
    id: "onboarding.tour.step",
    defaultMessage: "Step {stepIndex} of {stepCount}",
  },
  ok: {
    id: "ok",
    defaultMessage: "OK",
  },
});

export default function ProductTour({
  beforeStepChange,
  afterStepChange,
  onClose,
  markAsShownOnOpen,
  stepsWithFixedBody,
  ...props
}: Omit<IGuide, "steps"> & {
  steps: IStep[];
  stepsWithFixedBody?: string[];
  markAsShownOnOpen?: boolean;
}) {
  const intl = useIntl();
  const { disabledProductTour, update } = useUserSettings();

  const markAsShown = useCallback(() => {
    if (!props.localKey) return;

    localStorage && localStorage.setItem(props.localKey, "true");

    update && update({ productTour: { [props.localKey]: true } });
  }, [props.localKey, update]);

  useEffectOnce(() => {
    if (markAsShownOnOpen) markAsShown();
  });

  if (disabledProductTour(props.localKey)) return null;

  // otherwise, the body will scroll when the guide between steps
  const setFixedBody = (fixed?: boolean) => {
    document.body.style.position = fixed ? "fixed" : "";
  };

  return (
    <Guide
      hotspot
      showPreviousBtn
      stepText={(stepIndex, stepCount) =>
        intl.formatMessage(messages.step, { stepIndex, stepCount })
      }
      nextText={intl.formatMessage({ id: "next" })}
      prevText={intl.formatMessage({ id: "back" })}
      okText={intl.formatMessage(messages.ok)}
      maskClassName="product-tour-mask"
      beforeStepChange={(idx: number, step: IStep) => {
        setFixedBody(props.steps[idx + 1]?.fixedBody);
        if (beforeStepChange) beforeStepChange(idx, step);
      }}
      afterStepChange={(idx: number, step: IStep) => {
        setFixedBody(step?.fixedBody);
        if (afterStepChange) afterStepChange(idx, step);
      }}
      onClose={() => {
        setFixedBody();
        markAsShown();
        if (onClose) onClose();
      }}
      {...props}
    />
  );
}
