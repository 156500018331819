import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";

import {
  CropVarietiesDocument,
  CropVarietiesQuery,
  CropVarietiesQueryVariables,
  CropVarietyUpdateMutation,
  CropVarietyUpdateMutationVariables,
  CropVarietyUpdateDocument,
  CropVarietyCreateMutation,
  CropVarietyCreateMutationVariables,
  CropVarietyCreateDocument,
  CropVarietyDiscardMutation,
  CropVarietyDiscardMutationVariables,
  CropVarietyDiscardDocument,
  CropVarietyDocument,
  CropVarietyQuery,
  CropVarietyQueryVariables,
  CropVarietyDestroyDocument,
  CropVarietyDestroyMutation,
  CropVarietyDestroyMutationVariables,
} from "../../graphql";

export function useCropVarietyOptions(
  queryOptions?: QueryHookOptions<
    CropVarietiesQuery,
    CropVarietiesQueryVariables
  >
) {
  const [load, { loading, data }] = useLazyQuery(
    CropVarietiesDocument,
    queryOptions
  );

  const options = data?.cropVarieties?.items?.map((v) => ({
    key: v.id,
    label: `${v.name} - ${v.crop.name}`,
    group: v.crop.name,
    cropVariety: v,
  }));

  return {
    load,
    loading,
    options,
  };
}

export function useCropVarieties(
  options?: QueryHookOptions<CropVarietiesQuery, CropVarietiesQueryVariables>
) {
  const { loading, data } = useQuery(CropVarietiesDocument, options);

  if (!data || !data.cropVarieties || !data.cropVarieties.items) {
    return { loading };
  }

  const items = data.cropVarieties.items;
  const totalCount = data.cropVarieties.totalCount;

  return { loading, items, totalCount };
}

export function useCropVariety(id: string) {
  const skip = !id;

  const { data } = useQuery<CropVarietyQuery, CropVarietyQueryVariables>(
    CropVarietyDocument,
    {
      variables: { id },
      // TODO: Workaround for a known bug in Apollo client that causes `skip` to be ignored
      // https://github.com/apollographql/react-apollo/issues/3492#issuecomment-622573677
      fetchPolicy: skip ? "cache-only" : "cache-first",
      skip,
    }
  );

  return data?.cropVariety;
}

export function useCropVarietyUpdate(
  options?: MutationHookOptions<
    CropVarietyUpdateMutation,
    CropVarietyUpdateMutationVariables
  >
) {
  return useMutation(CropVarietyUpdateDocument, options);
}

export function useCropVarietyCreate(
  options?: MutationHookOptions<
    CropVarietyCreateMutation,
    CropVarietyCreateMutationVariables
  >
) {
  return useMutation(CropVarietyCreateDocument, options);
}

export function useCropVarietyDiscard(
  options?: MutationHookOptions<
    CropVarietyDiscardMutation,
    CropVarietyDiscardMutationVariables
  >
) {
  return useMutation(CropVarietyDiscardDocument, options);
}

export function useCropVarietyDestroy(
  options?: MutationHookOptions<
    CropVarietyDestroyMutation,
    CropVarietyDestroyMutationVariables
  >
) {
  return useMutation(CropVarietyDestroyDocument, options);
}
