import {
  QueryHookOptions,
  useQuery,
  MutationHookOptions,
  useMutation,
  useLazyQuery,
} from "@apollo/client";
import {
  ItemsQuery,
  ItemsQueryVariables,
  ItemsDocument,
  ItemUpdateMutation,
  ItemUpdateMutationVariables,
  ItemUpdateDocument,
  ItemCreateMutation,
  ItemCreateMutationVariables,
  ItemCreateDocument,
  ItemDiscardMutation,
  ItemDiscardMutationVariables,
  ItemDiscardDocument,
  ItemQuery,
  ItemQueryVariables,
  ItemDocument,
  ItemKindQuery,
  ItemKindDocument,
  SortDirection,
  ItemKindQueryVariables,
  ItemDestroyMutation,
  ItemDestroyMutationVariables,
  ItemDestroyDocument,
  ToxicityCategoryDocument,
  ToxicityCategoryQuery,
  ToxicityCategoryQueryVariables,
  ItemBulkUpdateMutation,
  ItemBulkUpdateMutationVariables,
  ItemBulkUpdateDocument,
} from "../../graphql";
import { formatItemOption } from "../../formats";
import { useIntl } from "react-intl";
import { useCurrentUser } from "../basic/users";

export function useItemKindFormat() {
  const intl = useIntl();
  return (kind: string) => intl.formatMessage({ id: `items.kinds.${kind}` });
}

export function useItemKindOptions() {
  const { currentTenant } = useCurrentUser();

  const [load, { data, loading }] = useLazyQuery<
    ItemKindQuery,
    ItemKindQueryVariables
  >(ItemKindDocument);

  const formatItemKind = useItemKindFormat();

  const options = (data?.__type?.enumValues || [])
    .filter(
      (k) =>
        (currentTenant.features.tokens || k.name !== "token") &&
        (currentTenant.features.reusableItems || k.name !== "reusable")
    )
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((k) => ({
      key: k.name,
      value: k.name,
      label: formatItemKind(k.name),
    }));

  return {
    load,
    loading,
    options,
  };
}

export function useToxicityCategoryFormat() {
  const intl = useIntl();
  return (kind: string) =>
    intl.formatMessage({ id: `items.toxicityCategory.${kind}` });
}

export function useToxicityCategoryOptions() {
  const [load, { data, loading }] = useLazyQuery<
    ToxicityCategoryQuery,
    ToxicityCategoryQueryVariables
  >(ToxicityCategoryDocument);

  const formatToxicityCategory = useToxicityCategoryFormat();

  const options = (data?.__type?.enumValues || []).map((k) => ({
    key: k.name,
    value: k.name,
    label: formatToxicityCategory(k.name),
  }));

  return {
    load,
    loading,
    options,
  };
}

export function useItemOptions() {
  const [load, { loading, data }] = useLazyQuery<
    ItemsQuery,
    ItemsQueryVariables
  >(ItemsDocument, {
    variables: { sort: { name: SortDirection.Asc } },
  });

  const search = (name?: string) => {
    load(
      name
        ? {
            variables: {
              filter: { name: { match: name } },
              sort: { name: undefined },
            },
          }
        : undefined
    );
  };

  const options = data?.items?.items?.map((i) => ({
    key: i.id,
    label: i.name,
    children: formatItemOption(i),
  }));

  return { load, search, loading, options };
}

export function useItems(
  options?: QueryHookOptions<ItemsQuery, ItemsQueryVariables>
) {
  const { loading, data } = useQuery(ItemsDocument, options);

  if (!data || !data.items || !data.items.items) {
    return { loading };
  }

  const items = data.items.items;
  const totalCount = data.items.totalCount;

  return { loading, items, totalCount };
}

export function useItem(id: string) {
  const { data } = useQuery<ItemQuery, ItemQueryVariables>(ItemDocument, {
    variables: { id },
  });

  return data && data.item;
}

export function useItemUpdate(
  options?: MutationHookOptions<ItemUpdateMutation, ItemUpdateMutationVariables>
) {
  return useMutation(ItemUpdateDocument, options);
}

export function useItemCreate(
  options?: MutationHookOptions<ItemCreateMutation, ItemCreateMutationVariables>
) {
  return useMutation(ItemCreateDocument, options);
}

export function useItemDiscard(
  options?: MutationHookOptions<
    ItemDiscardMutation,
    ItemDiscardMutationVariables
  >
) {
  return useMutation(ItemDiscardDocument, options);
}

export function useItemDestroy(
  options?: MutationHookOptions<
    ItemDestroyMutation,
    ItemDestroyMutationVariables
  >
) {
  return useMutation(ItemDestroyDocument, options);
}

export function useItemBulkUpdate(
  options?: MutationHookOptions<
    ItemBulkUpdateMutation,
    ItemBulkUpdateMutationVariables
  >
) {
  return useMutation(ItemBulkUpdateDocument, options);
}
