import { Button, FormItemProps, Input, Tooltip } from "antd";
import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";
import { useLastClosedFinancePeriod } from "../../lib/hooks/finance/financePeriods";
import {
  Form,
  RuleBuilder,
  Rules,
  DatePickerProps,
  TimePicker,
  DatePicker,
  TimePickerProps,
  NamePath,
} from "../form";
import { ClockCircleOutlined } from "@ant-design/icons";
import { useState } from "react";

export interface DateTimeFormInputProps {
  name?: NamePath;
  checkFinancePeriod?: boolean;
  disabled?: boolean;
  dateProps?: DatePickerProps;
  timeProps?: TimePickerProps & { hidden?: boolean; expanded?: boolean };
  required?: boolean;
}

export function DateTimeFormInput({
  name,
  checkFinancePeriod,
  disabled,
  dateProps,
  timeProps,
}: DateTimeFormInputProps) {
  const [expanded, setExpanded] = useState(timeProps?.expanded);
  const { loading, period } = useLastClosedFinancePeriod();

  const closedFinancePeriodValidation = RuleBuilder.custom(
    (_, value: dayjs.Dayjs) => {
      if (!checkFinancePeriod) return true;
      if (!value || !period) return true;

      return (
        dayjs(period.endDate).isBefore(value, "date") || (
          <FormattedMessage
            id="financePeriods.invalidDocumentDate"
            defaultMessage="Invalid document date"
          />
        )
      );
    }
  );

  const disabledPicker = disabled || loading;

  return (
    <Input.Group compact>
      <Form.Item
        noStyle
        name={name}
        rules={[Rules.required, closedFinancePeriodValidation]}
      >
        <DatePicker
          style={{
            width: timeProps?.hidden ? "100%" : expanded ? "60%" : "85%",
          }}
          disabled={disabledPicker}
          {...dateProps}
        />
      </Form.Item>

      {!timeProps?.hidden &&
        (!expanded ? (
          <Tooltip
            title={
              <FormattedMessage
                id="documents.setTime"
                defaultMessage="Set Document Time"
              />
            }
          >
            <Button
              icon={<ClockCircleOutlined />}
              onClick={() => setExpanded(true)}
              style={{ width: "15%" }}
            />
          </Tooltip>
        ) : (
          <Form.Item noStyle name={name}>
            <TimePicker
              style={{ width: "40%" }}
              disabled={disabledPicker}
              {...timeProps}
            />
          </Form.Item>
        ))}
    </Input.Group>
  );
}

export default function DocumentDateFormInput({
  disabled,
  dateProps,
  timeProps,
  required,
  ...formItemProps
}: Omit<DateTimeFormInputProps, "name" | "checkFinancePeriod"> &
  FormItemProps) {
  return (
    <Form.Item
      label={<FormattedMessage id="documentDate" />}
      required={required}
      rules={required ? [Rules.required] : []}
      {...formItemProps}
    >
      <DateTimeFormInput
        name="documentDate"
        disabled={disabled}
        dateProps={dateProps}
        timeProps={timeProps}
        checkFinancePeriod={true}
      />
    </Form.Item>
  );
}
