import * as React from "react";
import { Cascader as $Cascader } from "antd";
import { CascaderProps } from "antd/lib/cascader";
import { SelectOption } from "./SelectField";

export interface CascaderOption<T> {
  value?: string | number | null;
  label?: React.ReactNode;
  disabled?: boolean;
  isLeaf?: boolean;
  children?: CascaderOption<T>[];
  item?: T;
}

function cascaderOptions(options: SelectOption[] | null | undefined) {
  return options
    ? options.reduce((opts: CascaderOption<any>[], selectOption) => {
        let option = opts.find((o) => o.value === selectOption.group);
        if (!option) {
          option = {
            label: selectOption.group,
            value: selectOption.group,
            isLeaf: false,
            children: [],
          };
          opts.push(option);
        }

        if (option.children)
          option.children.push({
            label: selectOption.label,
            value: selectOption.key.toString(),
          });
        return opts;
      }, [])
    : [];
}

export const Cascader = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  value,
  options,
  onChange,
  ...restProps
}: Omit<CascaderProps<any>, "options" | "onChange"> & {
  options?: SelectOption[] | null;
  onChange?: (value: string) => void;
}) => {
  return (
    <$Cascader
      options={cascaderOptions(options)}
      onChange={(value: any) =>
        onChange && onChange(String(value?.[value.length - 1]))
      }
      {...restProps}
    />
  );
};

export default Cascader;
