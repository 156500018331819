import {
  QueryHookOptions,
  useQuery,
  MutationHookOptions,
  useMutation,
} from "@apollo/client";
import {
  PayrollEntriesQuery,
  PayrollEntriesQueryVariables,
  PayrollEntriesDocument,
  PayrollEntryUpdateMutation,
  PayrollEntryUpdateMutationVariables,
  PayrollEntryUpdateDocument,
  PayrollEntryCreateMutation,
  PayrollEntryCreateMutationVariables,
  PayrollEntryCreateDocument,
  PayrollEntryDocument,
  PayrollEntryQuery,
  PayrollEntryQueryVariables,
  PayrollEntryStatus,
  PayrollEntryGenerateDocument,
  PayrollEntryGenerateMutation,
  PayrollEntryGenerateMutationVariables,
  PayrollEntryWorkOrdersQuery,
  PayrollEntryWorkOrdersDocument,
  PayrollEntryAccountingComponentsQuery,
  PayrollEntryAccountingComponentsDocument,
  PayrollEntryDestroyDocument,
  PayrollEntryDestroyMutation,
  PayrollEntryDestroyMutationVariables,
} from "../../graphql";

import { useTranslate } from "../basic/basic";

export function usePayrollEntries(
  options?: QueryHookOptions<PayrollEntriesQuery, PayrollEntriesQueryVariables>
) {
  const { loading, data } = useQuery(PayrollEntriesDocument, options);

  if (!data || !data.payrollEntries || !data.payrollEntries.items) {
    return { loading };
  }

  const items = data.payrollEntries.items;
  const totalCount = data.payrollEntries.totalCount;

  return { loading, items, totalCount };
}

export function usePayrollEntry(id: string) {
  const { data, refetch } = useQuery<
    PayrollEntryQuery,
    PayrollEntryQueryVariables
  >(PayrollEntryDocument, { variables: { id } });

  return { entry: data && data.payrollEntry, refetch };
}

export function usePayrollEntryWorkOrders(id: string) {
  const { data, refetch } = useQuery<
    PayrollEntryWorkOrdersQuery,
    PayrollEntryQueryVariables
  >(PayrollEntryWorkOrdersDocument, { variables: { id } });

  return { workOrders: data && data.payrollEntry?.workOrders, refetch };
}

export function usePayrollAccountingComponents(id: string) {
  const { data, refetch } = useQuery<
    PayrollEntryAccountingComponentsQuery,
    PayrollEntryQueryVariables
  >(PayrollEntryAccountingComponentsDocument, { variables: { id } });

  return {
    components: data && data.payrollEntry?.accountingComponents,
    refetch,
  };
}

export function usePayrollEntryStatusOptions() {
  const translate = useTranslate();
  return {
    options: [
      {
        key: PayrollEntryStatus.Open,
        label: translate("statuses.open"),
      },
      {
        key: PayrollEntryStatus.Completed,
        label: translate("statuses.completed"),
      },
      {
        key: PayrollEntryStatus.Canceled,
        label: translate("statuses.canceled"),
      },
      {
        key: PayrollEntryStatus.Reverted,
        label: translate("statuses.reverted"),
      },
    ],
  };
}

export function usePayrollEntryUpdate(
  options?: MutationHookOptions<
    PayrollEntryUpdateMutation,
    PayrollEntryUpdateMutationVariables
  >
) {
  return useMutation(PayrollEntryUpdateDocument, options);
}

export function usePayrollEntryCreate(
  options?: MutationHookOptions<
    PayrollEntryCreateMutation,
    PayrollEntryCreateMutationVariables
  >
) {
  return useMutation(PayrollEntryCreateDocument, options);
}

export function usePayrollEntryGenerate(
  options?: MutationHookOptions<
    PayrollEntryGenerateMutation,
    PayrollEntryGenerateMutationVariables
  >
) {
  return useMutation(PayrollEntryGenerateDocument, options);
}

export function usePayrollEntryDestroy(
  options?: MutationHookOptions<
    PayrollEntryDestroyMutation,
    PayrollEntryDestroyMutationVariables
  >
) {
  return useMutation(PayrollEntryDestroyDocument, options);
}
