import { FormattedMessage } from "react-intl";
import { TablePaginationConfig } from "antd/lib/table";
import { DEFAULT_PAGE_SIZE } from "../hooks";
import { useCallback, useState } from "react";

export function usePagination(options?: { pageSize: number }) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(
    options?.pageSize || DEFAULT_PAGE_SIZE
  );

  const onPaginationChange = useCallback(
    ({ current, pageSize }: { current?: number; pageSize?: number }) => {
      current && setPage(current);
      pageSize && setPageSize(pageSize);
    },
    []
  );

  return {
    page,
    pageSize,
    pagination: defaultPagination,
    onPaginationChange,
    innerPagination: defaultInnerPagination,
  };
}

export function defaultInnerPagination({
  pageSize = 15,
  totalCount,
}: {
  pageSize?: number;
  totalCount?: number;
}): TablePaginationConfig {
  return {
    pageSize,
    showSizeChanger: false,
    hideOnSinglePage: true,
    total: totalCount,
    position: ["bottomLeft"],
  };
}

export function defaultPagination({
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  totalCount,
  ...config
}: {
  page?: number;
  pageSize?: number;
  totalCount?: number;
} & TablePaginationConfig): TablePaginationConfig {
  return {
    showSizeChanger: true,
    defaultCurrent: 1,
    defaultPageSize: DEFAULT_PAGE_SIZE,
    current: page,
    pageSize: pageSize,
    pageSizeOptions: ["25", "50", "100", "500", "1000"],
    total: totalCount,
    responsive: true,
    showTotal: (total) => (
      <FormattedMessage
        id="list.total"
        defaultMessage="total"
        values={{ total }}
      />
    ),
    ...config,
  };
}
