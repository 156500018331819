import {
  ExchangeRateQuery,
  ExchangeRateQueryVariables,
  ExchangeRateDocument,
} from "../../graphql";
import { usePromiseQuery } from "..";

export function useExchangeRate() {
  return usePromiseQuery<ExchangeRateQuery, ExchangeRateQueryVariables>(
    ExchangeRateDocument
  );
}
