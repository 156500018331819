import { useContext } from "react";
import { LocaleContext } from "../../../components/users/ContextProvider/LocaleContext";
import { AvailableLocales, localeNameField } from "../../locale";

export interface LocaleInfo {
  name: string;
  emoji: string;
  suffix: string;
}

export const locales: Record<AvailableLocales | string, LocaleInfo> = {
  en: { name: "English", emoji: "🇺🇸", suffix: "En" },
  es: { name: "Español", emoji: "🇪🇸", suffix: "Es" },
};

export function useLocaleOptions() {
  return {
    options: Object.entries(locales).map(([key, value]) => ({
      key,
      label: value.name,
    })),
  };
}

export function useCurrentLocale() {
  return useContext(LocaleContext);
}

export function useLocaleNameField() {
  const { currentLocale } = useCurrentLocale();

  return localeNameField(currentLocale);
}
