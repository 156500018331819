import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";
import {
  LeaveTypesDocument,
  LeaveTypesQuery,
  LeaveTypeUpdateDocument,
  LeaveTypesQueryVariables,
  LeaveTypeUpdateMutationVariables,
  LeaveTypeUpdateMutation,
  LeaveTypeCreateMutation,
  LeaveTypeCreateMutationVariables,
  LeaveTypeCreateDocument,
  LeaveTypeDiscardMutation,
  LeaveTypeDiscardMutationVariables,
  LeaveTypeDiscardDocument,
  LeaveKindQuery,
  LeaveKindDocument,
  LeaveTypeQuery,
  LeaveTypeQueryVariables,
  LeaveTypeDocument,
  LeaveKindQueryVariables,
  SortDirection,
} from "../../graphql";
import { useIntl } from "react-intl";
import { formatAttendance, formatBinaryAttendance } from "../../formats";
import { useEffectOnce } from "react-use";

export function useLeaveKindsFormat() {
  const intl = useIntl();
  return (type: string) => intl.formatMessage({ id: `leaveKinds.${type}` });
}

export function useLeaveKindOptions() {
  const formatLeaveKind = useLeaveKindsFormat();

  const [load, { data, loading }] = useLazyQuery<
    LeaveKindQuery,
    LeaveKindQueryVariables
  >(LeaveKindDocument);

  const options =
    data?.__type?.enumValues?.map((t) => ({
      key: t.name,
      label: formatLeaveKind(t.name),
    })) || [];

  return { load, options, loading };
}

export function useLeaveTypeOptions({ preload }: { preload?: boolean } = {}) {
  const [load, { data, loading }] = useLazyQuery<
    LeaveTypesQuery,
    LeaveTypesQueryVariables
  >(LeaveTypesDocument, { variables: { sort: { name: SortDirection.Asc } } });

  useEffectOnce(() => {
    if (preload) load();
  });

  const options =
    data?.leaveTypes?.items?.map((t) => ({
      key: t.id,
      label: formatAttendance(t),
      leaveType: t,
    })) || [];

  return {
    load,
    options,
    loading,
  };
}

export function useBinaryAttendanceOptions() {
  const binaryAttendanceOptions = [
    {
      key: true,
      label: formatBinaryAttendance(true),
    },
    {
      key: false,
      label: formatBinaryAttendance(false),
    },
  ];

  return { loading: false, options: binaryAttendanceOptions };
}

export function useAttendanceOptions() {
  const {
    load,
    loading,
    options: leaveTypeOptions,
  } = useLeaveTypeOptions({ preload: true });

  const options = [{ key: "", label: formatAttendance() }, ...leaveTypeOptions];

  useEffectOnce(() => {
    load();
  });

  return {
    load,
    options,
    loading,
  };
}

export function useLeaveTypes(
  options?: QueryHookOptions<LeaveTypesQuery, LeaveTypesQueryVariables>
) {
  const { loading, data } = useQuery(LeaveTypesDocument, options);

  if (!data || !data.leaveTypes || !data.leaveTypes.items) {
    return { loading };
  }

  const items = data.leaveTypes.items;
  const totalCount = data.leaveTypes.totalCount;

  return { loading, items, totalCount };
}

export function useLeaveType(id: string) {
  const { data } = useQuery<LeaveTypeQuery, LeaveTypeQueryVariables>(
    LeaveTypeDocument,
    { variables: { id } }
  );

  return data && data.leaveType;
}

export function useLeaveTypeUpdate(
  options?: MutationHookOptions<
    LeaveTypeUpdateMutation,
    LeaveTypeUpdateMutationVariables
  >
) {
  return useMutation(LeaveTypeUpdateDocument, options);
}

export function useLeaveTypeCreate(
  options?: MutationHookOptions<
    LeaveTypeCreateMutation,
    LeaveTypeCreateMutationVariables
  >
) {
  return useMutation(LeaveTypeCreateDocument, options);
}

export function useLeaveTypeDiscard(
  options?: MutationHookOptions<
    LeaveTypeDiscardMutation,
    LeaveTypeDiscardMutationVariables
  >
) {
  return useMutation(LeaveTypeDiscardDocument, options);
}
