import dayjs from "dayjs";
import { useCallback, useEffect, useReducer } from "react";
import { useCookies } from "react-cookie";
import { CROP_CYCLE_COOKIE, DefaultCookieOptions, LOCALITY_COOKIE } from ".";
import {
  CurrentTenantFragment,
  CurrentTenantLocalityFragment,
} from "../../../lib/graphql";

export interface ILocalityState {
  localities: CurrentTenantFragment["localities"];
  locality?: CurrentTenantLocalityFragment;
  cropCycles?: CurrentTenantLocalityFragment["cropCycles"];
  cropCycle?: CurrentTenantLocalityFragment["cropCycles"][0];
}

type LocalityStateAction =
  | { type: "set-locality"; payload?: CurrentTenantLocalityFragment["id"] }
  | { type: "set-crop-cycle"; payload?: CurrentTenantLocalityFragment["id"] }
  | { type: "replace-state"; payload: ILocalityState };

interface LocalityStateReducerProps {
  tenant: CurrentTenantFragment;
  localityId?: string;
  cropCycleId?: string;
}

const findLocality = (
  localities: ILocalityState["localities"],
  localityId?: string
) => {
  const locality =
    localities.find(({ id }) => id === localityId) || localities[0];
  const cropCycles = locality
    ? locality.cropCycles
        .filter((c) => !c.discardedAt)
        .sort((a, b) => dayjs(b.startedAt).diff(a.startedAt))
    : undefined;
  return { locality, cropCycles };
};

const findCropCycle = (
  cropCycles: ILocalityState["cropCycles"],
  cropCycleId?: string
) =>
  !cropCycles
    ? undefined
    : cropCycles.find(({ id }) => id === cropCycleId) || cropCycles[0];

function initialState({
  tenant,
  localityId,
  cropCycleId,
}: LocalityStateReducerProps) {
  const localities = tenant.localities
    .slice()
    .sort((a, b) => b.effectiveArea - a.effectiveArea);
  const { locality, cropCycles } = findLocality(localities, localityId);

  return {
    localities,
    locality,
    cropCycles,
    cropCycle: findCropCycle(cropCycles, cropCycleId),
  };
}

const localityStateReducer = (
  state: ILocalityState,
  action: LocalityStateAction
): ILocalityState => {
  switch (action.type) {
    case "replace-state":
      return action.payload;

    case "set-locality": {
      const { locality, cropCycles } = findLocality(
        state.localities,
        action.payload
      );

      return {
        ...state,
        cropCycle: cropCycles?.[0],
        locality,
        cropCycles,
      };
    }
    case "set-crop-cycle": {
      return {
        ...state,
        cropCycle: findCropCycle(state.cropCycles, action.payload),
      };
    }
    default:
      return state;
  }
};

export function useLocalityStateReducer(
  initialProps: LocalityStateReducerProps
) {
  const [, setCookie, removeCookie] = useCookies();

  const [state, dispatch] = useReducer(
    localityStateReducer,
    initialState(initialProps)
  );

  useEffect(() => {
    if (initialProps.tenant) {
      dispatch({
        type: "replace-state",
        payload: initialState({
          tenant: initialProps.tenant,
          localityId: state.locality?.id,
          cropCycleId: state.cropCycle?.id,
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialProps.tenant]);

  const setOrRemoveCookie = useCallback(
    (key: string, value: any) => {
      if (value) {
        setCookie(key, value, DefaultCookieOptions);
      } else {
        removeCookie(key);
      }
    },
    [removeCookie, setCookie]
  );

  const setCurrentCropCycle = (id?: string) => {
    dispatch({ type: "set-crop-cycle", payload: id });
  };

  const setCurrentLocality = (id?: string) => {
    dispatch({ type: "set-locality", payload: id });
  };

  // keep cookies in sync
  useEffect(() => {
    setOrRemoveCookie(CROP_CYCLE_COOKIE, state.cropCycle?.id);
    setOrRemoveCookie(LOCALITY_COOKIE, state.locality?.id);
  }, [setOrRemoveCookie, state.cropCycle?.id, state.locality?.id]);

  return {
    ...state,
    setCurrentLocality,
    setCurrentCropCycle,
  };
}
