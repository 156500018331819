import { Button } from "antd";
import { ButtonProps } from "antd/lib/button/button";
import { ReactNode, useState } from "react";
import { FormattedMessage } from "react-intl";
import { SidebarFooter } from "./Footer";
import { SidebarHeader } from "./Header";
import { useCurrentLocale } from "../../../lib/hooks";
import { UserError } from "../../../lib/graphql";

export interface ConfirmSidebarProps {
  title: ReactNode;
  onCancel(): void;
  onOk({
    setLoading,
    showSuccess,
  }: {
    setLoading: (loading: boolean) => void;
    showErrors: (errors: Pick<UserError, "message" | "path">[]) => void;
    showSuccess: (message: ReactNode) => void;
  }): void;
  okButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  okText?: ReactNode;
  cancelText?: ReactNode;
}

export function ConfirmSidebar({
  title,
  onCancel,
  onOk,
  children,
  okButtonProps,
  cancelButtonProps,
  okText,
  cancelText,
}: React.PropsWithChildren<ConfirmSidebarProps>) {
  const { showErrors, showSuccess } = useCurrentLocale();
  const [loading, setLoading] = useState(false);

  return (
    <>
      <SidebarHeader back title={title} onClose={onCancel} />

      <div style={{ paddingBottom: "25px" }}>{children}</div>

      <SidebarFooter>
        <Button
          onClick={onCancel}
          style={{ marginRight: "8px" }}
          {...cancelButtonProps}
        >
          <span>
            {cancelText || (
              <FormattedMessage id="cancel" defaultMessage="cancel" />
            )}
          </span>
        </Button>
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            setLoading(true);
            onOk({ setLoading, showErrors, showSuccess });
          }}
          {...okButtonProps}
        >
          <span>
            {okText || <FormattedMessage id="update" defaultMessage="update" />}
          </span>
        </Button>
      </SidebarFooter>
    </>
  );
}
