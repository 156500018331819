import { useCallback, useState } from "react";
import { Button, notification } from "antd";
import { defineMessages, useIntl } from "react-intl";
import { useInterval } from "react-use";
import { routerReload } from "../routes";

const messages = defineMessages({
  title: {
    id: "new-version.title",
    defaultMessage: "New version is available",
  },
  message: {
    id: "new-version.message",
    defaultMessage:
      "A new version of the application is available. Please refresh page to update.",
  },
  button: {
    id: "new-version.button",
    defaultMessage: "Refresh",
  },
});

export function useCheckNewVersion({ skip }: { skip?: boolean } = {}) {
  const intl = useIntl();

  const [notificationShown, setNotificationShown] = useState(false);

  const openNotification = useCallback(() => {
    if (notificationShown) return;

    notification.info({
      message: intl.formatMessage(messages.title),
      description: intl.formatMessage(messages.message),
      duration: 0,
      closeIcon: <></>,
      btn: (
        <Button type="primary" onClick={routerReload}>
          {intl.formatMessage(messages.button)}
        </Button>
      ),
    });

    setNotificationShown(true);
  }, [intl, notificationShown]);

  const disableFetch = skip || !process.env.ARAGRO_ENV || notificationShown;

  useInterval(
    async () => {
      fetch("/api/version")
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then(({ clientVersion }) => {
          if (clientVersion !== process.env.CLIENT_VERSION) openNotification();
        })
        // eslint-disable-next-line no-console
        .catch((error) => console.error(error));
    },
    disableFetch ? null : 300000
  );
}
