import {
  EditOutlined,
  PlusOutlined,
  EyeFilled,
  EyeInvisibleFilled,
} from "@ant-design/icons";

import { Button, Space } from "antd";
import { FormattedMessage } from "react-intl";
import { ActivityDetailsFragment, WorkUnit, UnitType } from "../graphql";
import { ColumnProps } from "antd/lib/table";
import { Popover } from "../../components/shared";

interface AddEditDiscardColumnProps<T> {
  onNew?: () => void;
  onEdit: (e: T) => void;
  onDiscard: (e: T) => void;
  activity?: ActivityDetailsFragment;
  wageUnit?: WorkUnit;
}

interface ButtonProps {
  disabled?: boolean;
}

export const AddButton = ({
  onNew,
  ...props
}: Pick<AddEditDiscardColumnProps<any>, "onNew"> & ButtonProps) => (
  <Button {...props} type="primary" icon={<PlusOutlined />} onClick={onNew}>
    <FormattedMessage id="add" />
  </Button>
);

export function tableAddEditDiscardColumn<T extends { discardedAt?: any }>({
  wageUnit,
  activity,
  onEdit,
  onDiscard,
  onNew,
  ...props
}: AddEditDiscardColumnProps<T>): ColumnProps<T> {
  const disabledButton =
    wageUnit === WorkUnit.Piecework &&
    activity?.progressUnit.unitType === UnitType.Time;

  return {
    key: "aed-column",
    title: onNew ? (
      disabledButton ? (
        <Popover
          overlayStyle={{ width: "300px" }}
          content={
            <FormattedMessage
              id="activities.paymentPiecework"
              defaultMessage="paymentPiecework"
            />
          }
        >
          <div>
            <AddButton disabled={disabledButton} onNew={onNew} {...props} />
          </div>
        </Popover>
      ) : (
        <AddButton disabled={disabledButton} onNew={onNew} {...props} />
      )
    ) : null,
    align: "right",
    width: "120px",
    render: (_: any, e: T) => (
      <Space>
        {!e.discardedAt && (
          <Button
            disabled={disabledButton}
            icon={<EditOutlined />}
            onClick={() => onEdit(e)}
          />
        )}

        <Button
          icon={e.discardedAt ? <EyeFilled /> : <EyeInvisibleFilled />}
          onClick={() => onDiscard(e)}
        />
      </Space>
    ),
  };
}
