import React, { ReactNode } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { List, Button, Tooltip } from "antd";
import { SidebarHeader } from "../Sidebar";
import { FormattedMessage } from "react-intl";
import { DiscardableType, useItemSidebarContext } from "../../../lib/hooks";
import Link from "next/link";
import { RwdPermissionsInput } from "../../../lib/graphql";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { filterFalse } from "../../../lib/utils";
import { ActionButton } from "../ActionButton";

export interface ItemFieldDetailsConfig<T> {
  label?: React.ReactNode;
  name?: keyof T;
  render?(item: T): React.ReactNode;
}

interface ItemDetailsProps<T> {
  fields: Array<ItemFieldDetailsConfig<T> | null | undefined | false>;
  item: T;
  entityName: ReactNode;
  entityUrl?: string;
  onClose(): void;
  onEdit?(): void;
  permissions?: Pick<RwdPermissionsInput, "write" | "delete"> | null;
  menuItems?: Array<ItemType | null | undefined | false>;
  destroy?: boolean;
  changeLog?: boolean;
}

export function ItemDetails<T extends DiscardableType>({
  item,
  entityName,
  fields,
  menuItems,
  permissions,
  onEdit,
  onClose,
  entityUrl,
  destroy,
  changeLog,
  children,
}: React.PropsWithChildren<ItemDetailsProps<T>>) {
  const { setCurrentAction } = useItemSidebarContext();
  const visibleItems = (menuItems?.filter((i) => !!i) as ItemType[]) || [];

  if (permissions?.delete) {
    if (item.mayDiscard !== false) {
      visibleItems.push({
        key: "discard",
        icon: <EyeInvisibleOutlined />,
        onClick: () => setCurrentAction("discard"),
        label: item.discardedAt ? (
          <FormattedMessage id="undiscard" defaultMessage="undiscard" />
        ) : (
          <FormattedMessage id="discard" defaultMessage="discard" />
        ),
      });
    }

    if (destroy || item.mayDestroy) {
      visibleItems.push({
        danger: true,
        key: "destroy-action",
        icon: <DeleteOutlined />,
        onClick: () => setCurrentAction("destroy"),
        label: <FormattedMessage id="destroy" defaultMessage="destroy" />,
      });
    }
  }

  if (changeLog) {
    visibleItems.push(
      { type: "divider" },
      ActionButton.ChangeLog(() => setCurrentAction("changeLog"))
    );
  }

  const canEdit = permissions && permissions.write;

  return (
    <>
      <SidebarHeader
        title={
          <>
            {item.discardedAt && (
              <Tooltip
                placement="bottom"
                title={
                  <FormattedMessage id="discarded" defaultMessage="discarded" />
                }
              >
                <EyeInvisibleOutlined
                  style={{ marginRight: "8px", color: "rgba(0,0,0,.5)" }}
                />
              </Tooltip>
            )}
            {entityUrl ? (
              <Link
                href={`${entityUrl}/[id]`}
                as={`${entityUrl}/${item.id}`}
                prefetch={false}
                className="text-link"
              >
                <FormattedMessage
                  id="details.header"
                  defaultMessage="details"
                  values={{ entityName }}
                />
              </Link>
            ) : (
              <FormattedMessage
                id="details.header"
                defaultMessage="details"
                values={{ entityName }}
              />
            )}
            {canEdit && (
              <Button
                type="default"
                icon={<EditOutlined />}
                onClick={onEdit || (() => setCurrentAction("edit"))}
                style={{ border: "none" }}
                data-action="entity-edit-btn"
              />
            )}
          </>
        }
        extraMenu={
          visibleItems.length > 0
            ? { triggerSubMenuAction: "click", items: visibleItems }
            : undefined
        }
        back={false}
        onClose={onClose}
      />

      <List
        itemLayout="horizontal"
        dataSource={filterFalse(fields)}
        renderItem={(field) => {
          let label = field.label;

          if (!label && field.name === "name") label = entityName;

          return (
            <List.Item className="ant-list-item-no-flex">
              <List.Item.Meta title={label} />
              {field.render
                ? field.render(item)
                : field.name && <>{item[field.name]}</>}
            </List.Item>
          );
        }}
      />

      {children}
    </>
  );
}
