import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";
import { merge } from "lodash";

import {
  SalaryStructuresDocument,
  SalaryStructuresQuery,
  SalaryStructuresQueryVariables,
  SalaryStructureUpdateMutation,
  SalaryStructureUpdateMutationVariables,
  SalaryStructureUpdateDocument,
  SalaryStructureCreateMutation,
  SalaryStructureCreateMutationVariables,
  SalaryStructureCreateDocument,
  SalaryStructureDiscardMutation,
  SalaryStructureDiscardMutationVariables,
  SalaryStructureDiscardDocument,
  SalaryStructureQuery,
  SalaryStructureQueryVariables,
  SalaryStructureDocument,
  SalaryStructureDestroyMutation,
  SalaryStructureDestroyMutationVariables,
  SalaryStructureDestroyDocument,
  SortDirection,
  PayrollInputVariables,
} from "../../graphql";

export function useSalaryStructures(
  options?: QueryHookOptions<
    SalaryStructuresQuery,
    SalaryStructuresQueryVariables
  >
) {
  const { loading, data } = useQuery(SalaryStructuresDocument, options);

  if (!data || !data.salaryStructures || !data.salaryStructures.items) {
    return { loading };
  }

  const items = data.salaryStructures.items;
  const totalCount = data.salaryStructures.totalCount;

  return { loading, items, totalCount };
}

export function useSalaryStructureOptions(
  queryOptions?: QueryHookOptions<
    SalaryStructuresQuery,
    SalaryStructuresQueryVariables
  >
) {
  const [load, { loading, data }] = useLazyQuery(
    SalaryStructuresDocument,
    merge({ variables: { sort: { name: SortDirection.Asc } } }, queryOptions)
  );

  const options =
    data?.salaryStructures?.items?.map((a) => ({
      key: a.id,
      label: a.name,
      salaryStructure: a,
    })) || [];

  return { load, loading, options };
}

export const DefaultPayrollInputVariables: PayrollInputVariables = {
  hourlyWage: 173.21 / 8,
  dailyWage: 173.21,
  monthlyWage: 173.21 * 30,
  basePay: 1732.11,
  hours: 80,
  overtimeHours: 8,
  overtimePay: 100.34,
  attendedDays: 10,
  absentDays: 1,
  absencePay: 80.35,
  seventhDays: 1,
  holidays: 0,
  leaves: { "On Vacation": 1 },
};

export function useSalaryStructure(id: string) {
  const { data } = useQuery<
    SalaryStructureQuery,
    SalaryStructureQueryVariables
  >(SalaryStructureDocument, {
    variables: { id, inputVariables: DefaultPayrollInputVariables },
  });

  return data && data.salaryStructure;
}

export function useSalaryStructureUpdate(
  options?: MutationHookOptions<
    SalaryStructureUpdateMutation,
    SalaryStructureUpdateMutationVariables
  >
) {
  return useMutation(SalaryStructureUpdateDocument, options);
}

export function useSalaryStructureCreate(
  options?: MutationHookOptions<
    SalaryStructureCreateMutation,
    SalaryStructureCreateMutationVariables
  >
) {
  return useMutation(SalaryStructureCreateDocument, options);
}

export function useSalaryStructureDiscard(
  options?: MutationHookOptions<
    SalaryStructureDiscardMutation,
    SalaryStructureDiscardMutationVariables
  >
) {
  return useMutation(SalaryStructureDiscardDocument, options);
}

export function useSalaryStructureDestroy(
  options?: MutationHookOptions<
    SalaryStructureDestroyMutation,
    SalaryStructureDestroyMutationVariables
  >
) {
  return useMutation(SalaryStructureDestroyDocument, options);
}
