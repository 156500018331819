import {
  FilePdfTwoTone,
  FileWordTwoTone,
  FileExcelTwoTone,
  FilePptTwoTone,
  FileTextTwoTone,
  FileZipTwoTone,
  FileUnknownTwoTone,
  FileTextOutlined,
} from "@ant-design/icons";
import { formatAssetPath } from ".";
import React, { createElement } from "react";
import { CommentFragment } from "../graphql";

export function formatCommentContent({
  comment,
  canReadUsers,
}: {
  comment: CommentFragment;
  canReadUsers?: boolean;
}) {
  const processedContent = !comment.mentions
    ? comment.content
    : comment.content
        .split(" ")
        .map(function (word) {
          const matches = word.match(/@(.+@.+)/);

          if (matches && matches.length > 1) {
            const user = comment.mentions?.find(
              (user) => user.email === matches[1]
            );

            if (user) {
              return canReadUsers ? (
                <a href={`/users/${user.id}`} key={user.id}>
                  @{user.email}
                </a>
              ) : (
                <span key={user.id}>@{user.email}</span>
              );
            }
          }

          return word;
        })
        .flatMap((value, i, arr) =>
          arr.length - 1 !== i ? [value, " "] : value
        );

  return <span className="mention">{processedContent}</span>;
}

const fileIcons: Record<string, React.ExoticComponent> = {
  // Documents
  "application/pdf": FilePdfTwoTone,
  "application/msword": FileWordTwoTone,
  "application/vnd.ms-word": FileWordTwoTone,
  "application/vnd.oasis.opendocument.text": FileWordTwoTone,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    FileWordTwoTone,
  "application/vnd.ms-excel": FileExcelTwoTone,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    FileExcelTwoTone,
  "application/vnd.oasis.opendocument.spreadsheet": FileExcelTwoTone,
  "text/csv": FileTextOutlined,
  "application/vnd.ms-powerpoint": FilePptTwoTone,
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    FilePptTwoTone,
  "application/vnd.oasis.opendocument.presentation": FilePptTwoTone,
  "text/plain": FileTextTwoTone,
  // Archives
  "application/gzip": FileZipTwoTone,
  "application/zip": FileZipTwoTone,
};

export function formatCommentFile(url: string, contentType: string) {
  const isImage = contentType.includes("image");
  return isImage ? (
    <img src={formatAssetPath(url)} />
  ) : (
    createElement(fileIcons[contentType] || FileUnknownTwoTone)
  );
}
