import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";
import {
  ActivityCategoriesDocument,
  ActivityCategoriesQuery,
  ActivityCategoryUpdateDocument,
  ActivityCategoriesQueryVariables,
  ActivityCategoryUpdateMutationVariables,
  ActivityCategoryUpdateMutation,
  ActivityCategoryCreateMutation,
  ActivityCategoryCreateMutationVariables,
  ActivityCategoryCreateDocument,
  ActivityCategoryDiscardMutation,
  ActivityCategoryDiscardMutationVariables,
  ActivityCategoryDiscardDocument,
  ActivityCategoryQuery,
  ActivityCategoryQueryVariables,
  ActivityCategoryDocument,
  ActivityCategoryUnlockMutation,
  ActivityCategoryUnlockMutationVariables,
  ActivityCategoryUnlockDocument,
  SortDirection,
  ActivityCategoryDestroyDocument,
  ActivityCategoryDestroyMutation,
  ActivityCategoryDestroyMutationVariables,
} from "../../graphql";

export function useActivityCategoryOptions() {
  const [load, { loading, data }] = useLazyQuery<
    ActivityCategoriesQuery,
    ActivityCategoriesQueryVariables
  >(ActivityCategoriesDocument, {
    variables: { sort: { name: SortDirection.Asc } },
  });

  const options =
    data?.activityCategories?.items?.map((k) => ({
      key: k.id,
      label: k.name,
    })) || [];

  return {
    load,
    loading,
    options,
  };
}

export function useActivityCategories(
  options?: QueryHookOptions<
    ActivityCategoriesQuery,
    ActivityCategoriesQueryVariables
  >
) {
  const { loading, data } = useQuery(ActivityCategoriesDocument, options);

  if (!data || !data.activityCategories || !data.activityCategories.items) {
    return { loading };
  }

  const items = data.activityCategories.items;
  const totalCount = data.activityCategories.totalCount;

  return { loading, items, totalCount };
}

export function useActivityCategory(id: string) {
  const { data } = useQuery<
    ActivityCategoryQuery,
    ActivityCategoryQueryVariables
  >(ActivityCategoryDocument, { variables: { id } });

  return data && data.activityCategory;
}

export function useActivityCategoryUpdate(
  options?: MutationHookOptions<
    ActivityCategoryUpdateMutation,
    ActivityCategoryUpdateMutationVariables
  >
) {
  return useMutation(ActivityCategoryUpdateDocument, options);
}

export function useActivityCategoryCreate(
  options?: MutationHookOptions<
    ActivityCategoryCreateMutation,
    ActivityCategoryCreateMutationVariables
  >
) {
  return useMutation(ActivityCategoryCreateDocument, options);
}

export function useActivityCategoryDiscard(
  options?: MutationHookOptions<
    ActivityCategoryDiscardMutation,
    ActivityCategoryDiscardMutationVariables
  >
) {
  return useMutation(ActivityCategoryDiscardDocument, options);
}

export function useActivityCategoryDestroy(
  options?: MutationHookOptions<
    ActivityCategoryDestroyMutation,
    ActivityCategoryDestroyMutationVariables
  >
) {
  return useMutation(ActivityCategoryDestroyDocument, options);
}

export function useActivityCategoryUnlock(
  options?: MutationHookOptions<
    ActivityCategoryUnlockMutation,
    ActivityCategoryUnlockMutationVariables
  >
) {
  return useMutation(ActivityCategoryUnlockDocument, options);
}
