import { useQuery, QueryHookOptions } from "@apollo/client";

import {
  ChangeLogsDocument,
  ChangeLogsQuery,
  ChangeLogsQueryVariables,
} from "../../graphql";

export function useChangeLogs(
  options?: QueryHookOptions<ChangeLogsQuery, ChangeLogsQueryVariables>
) {
  const { loading, data, refetch } = useQuery(ChangeLogsDocument, options);

  if (!data || !data.changeLogs || !data.changeLogs.items) {
    return { loading };
  }

  const items = data.changeLogs.items;
  const totalCount = data.changeLogs.totalCount;

  return { loading, items, totalCount, refetch };
}
