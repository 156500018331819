import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
} from "@apollo/client";
import {
  FiscalYearsDocument,
  FiscalYearsQuery,
  FiscalYearsQueryVariables,
  FiscalYearUpdateMutation,
  FiscalYearUpdateMutationVariables,
  FiscalYearUpdateDocument,
  FiscalYearCreateMutation,
  FiscalYearCreateMutationVariables,
  FiscalYearCreateDocument,
  FiscalYearDestroyMutation,
  FiscalYearDestroyMutationVariables,
  FiscalYearDestroyDocument,
  FiscalYearQuery,
  FiscalYearQueryVariables,
  FiscalYearDocument,
} from "../../graphql";

export function useFiscalYears(
  options?: QueryHookOptions<FiscalYearsQuery, FiscalYearsQueryVariables>
) {
  const { loading, data } = useQuery(FiscalYearsDocument, options);

  if (!data || !data.fiscalYears || !data.fiscalYears.items) {
    return { loading };
  }

  const items = data.fiscalYears.items;
  const totalCount = data.fiscalYears.totalCount;

  return { loading, items, totalCount };
}

export function useFiscalYear(id: string) {
  const { data } = useQuery<FiscalYearQuery, FiscalYearQueryVariables>(
    FiscalYearDocument,
    {
      variables: { id },
    }
  );

  return data && data.fiscalYear;
}

export function useFiscalYearUpdate(
  options?: MutationHookOptions<
    FiscalYearUpdateMutation,
    FiscalYearUpdateMutationVariables
  >
) {
  return useMutation(FiscalYearUpdateDocument, options);
}

export function useFiscalYearCreate(
  options?: MutationHookOptions<
    FiscalYearCreateMutation,
    FiscalYearCreateMutationVariables
  >
) {
  return useMutation(FiscalYearCreateDocument, options);
}

export function useFiscalYearDestroy(
  options?: MutationHookOptions<
    FiscalYearDestroyMutation,
    FiscalYearDestroyMutationVariables
  >
) {
  return useMutation(FiscalYearDestroyDocument, options);
}
