import { DrawerProps } from "antd/lib/drawer";
import { ReactNode, useState, useContext, useEffect } from "react";
import { Sidebar } from "../Sidebar";
import { ItemSidebarContext } from "./Provider";

export interface ItemSidebarProps<T> {
  item: T;
  setAction: (action: string) => void;
  closeSidebar: () => void;
}

interface ItemSidebarConfig<T> {
  item: T;
  sidebarActions: Record<string, (props: ItemSidebarProps<T>) => ReactNode>;
  onClose?(item: T): void;
  afterClose?(): void;
  drawerProps?: DrawerProps;
}

export function ItemSidebar<T>({
  item,
  sidebarActions,
  onClose,
  afterClose,
  drawerProps,
}: ItemSidebarConfig<T>) {
  const { currentAction, setCurrentAction } = useContext(ItemSidebarContext);
  const [visibleSidebar, setVisibleSidebar] = useState(false);

  useEffect(() => setVisibleSidebar(!!currentAction), [currentAction]);

  const action = currentAction && sidebarActions[currentAction];

  if (!action) return null;

  return (
    <Sidebar
      destroyOnClose
      open={visibleSidebar}
      setVisible={setVisibleSidebar}
      onClosed={() => {
        setCurrentAction(undefined);
        if (afterClose) afterClose();
      }}
      className={`sidebar-${currentAction}`}
      {...drawerProps}
    >
      {action({
        item,
        setAction: setCurrentAction,
        closeSidebar: () => {
          setVisibleSidebar(false);
          setCurrentAction(undefined);
          if (onClose) onClose(item);
        },
      })}
    </Sidebar>
  );
}

export * from "./Provider";
