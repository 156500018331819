import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";
import {
  EmployeesDocument,
  EmployeesQuery,
  EmployeeUpdateDocument,
  EmployeesQueryVariables,
  EmployeeUpdateMutationVariables,
  EmployeeUpdateMutation,
  EmployeeCreateMutation,
  EmployeeCreateMutationVariables,
  EmployeeCreateDocument,
  EmployeeDiscardMutation,
  EmployeeDiscardMutationVariables,
  EmployeeDiscardDocument,
  EmployeeQuery,
  EmployeeQueryVariables,
  EmployeeDocument,
  EmployeeOptionsWithWagesQuery,
  EmployeeOptionsWithWagesQueryVariables,
  EmployeeOptionsWithWagesDocument,
  EmployeeGroupShortFragment,
  SortDirection,
  EmployeeDestroyDocument,
  EmployeeDestroyMutation,
  EmployeeDestroyMutationVariables,
  EmployeeBulkUpdateMutation,
  EmployeeBulkUpdateMutationVariables,
  EmployeeBulkUpdateDocument,
} from "../../graphql";
import { useIntl } from "react-intl";
import { formatEmployeeOption } from "../../formats";
import { merge } from "lodash";
import { useEmployeeGroupOptionsWithWages } from "./employeeGroups";
import { DEFAULT_PAGE_SIZE, usePermissions, useTranslate } from "..";

export function useGenders() {
  const translate = useTranslate();

  const genders = {
    female: {
      label: translate("gender.female"),
      emoji: "♀︎",
    },
    male: {
      label: translate("gender.male"),
      emoji: "♂︎",
    },
    other: {
      label: translate("gender.other"),
      emoji: "⚥",
    },
  };

  const genderOptions = Object.entries(genders).map(([key, gender]) => ({
    key,
    label: gender.label,
  }));

  return { genders, genderOptions };
}

export function useEmployeeOptions(
  queryOptions?: QueryHookOptions<EmployeesQuery, EmployeesQueryVariables>
) {
  const [load, { loading, data }] = useLazyQuery(
    EmployeesDocument,
    merge(
      { variables: { sort: { firstName: SortDirection.Asc } } },
      queryOptions
    )
  );

  const search = (val?: string) => {
    load(
      merge({ variables: { sort: undefined } }, queryOptions, {
        variables: {
          filter: val ? { fullName: val } : undefined,
        },
      })
    );
  };

  const options = (data?.employees?.items || []).map((e) => ({
    key: e.id,
    label: `${e.firstName} ${e.lastName}`,
    group: e.position.name,
    employee: e,
  }));

  return { load, search, loading, options };
}

export function useEmployeeOptionsWithWages(
  queryOptions?: QueryHookOptions<
    EmployeeOptionsWithWagesQuery,
    EmployeeOptionsWithWagesQueryVariables
  >
) {
  const intl = useIntl();
  const showWage = usePermissions((p) => p.settings?.showWage);

  const [load, { loading, data }] = useLazyQuery(
    EmployeeOptionsWithWagesDocument,
    merge(
      {
        variables: {
          sort: { firstName: SortDirection.Asc },
          pageSize: 15,
          showWage,
        },
      },
      queryOptions
    )
  );

  const search = (val?: string) => {
    load(
      merge({ variables: { sort: undefined } }, queryOptions, {
        variables: {
          pageSize: DEFAULT_PAGE_SIZE,
          filter: val ? { fullName: val } : undefined,
        },
      })
    );
  };

  const options =
    data?.employees?.items?.map((e) => ({
      key: e.id,
      label: [e.firstName, e.lastName].join(" "),
      group: intl.formatMessage({ id: "employees" }),
      children: formatEmployeeOption(e),
      disabled: !!e.leave,
      employee: e,
      employeeGroup: undefined as EmployeeGroupShortFragment | undefined,
    })) || [];

  return {
    load,
    search,
    options,
    loading,
  };
}

export type EmployeesAndGroupsOptionsType = {
  localityId: string;
  activityId?: string;
  cropIds?: [string];
  documentDate: any;
  positionId?: string;
};

export function useEmployeesAndGroupsOptions(
  queryOptions?: EmployeesAndGroupsOptionsType
) {
  const { localityId, activityId, cropIds, documentDate, positionId } =
    queryOptions || {};

  const {
    options: employeeGroupOptions,
    loading: employeeAndGroupsLoading,
    search: searchEmployeeGroups,
    load: loadEmployeeGroups,
  } = useEmployeeGroupOptionsWithWages({
    variables: {
      filter: { localityId },
      wagePaymentFilter: { activityId, localityId, cropIds },
    },
  });

  const {
    options: employeeOptions,
    loading: employeesLoading,
    search: searchEmployees,
    load: loadEmployees,
  } = useEmployeeOptionsWithWages({
    variables: {
      filter: {
        localityId: localityId ? [localityId] : undefined,
        positionId: positionId ? [positionId] : undefined,
      },
      wagePaymentFilter: { activityId, localityId, cropIds },
      documentDate,
    },
  });

  return {
    options: positionId
      ? employeeOptions
      : employeeGroupOptions.concat(employeeOptions),
    loading: employeeAndGroupsLoading || employeesLoading,
    search: (v?: string) => {
      searchEmployeeGroups(v);
      searchEmployees(v);
    },
    load: () => {
      loadEmployeeGroups();
      loadEmployees();
    },
  };
}

export function useEmployees(
  options?: QueryHookOptions<EmployeesQuery, EmployeesQueryVariables>
) {
  const { loading, data, refetch } = useQuery(EmployeesDocument, options);

  if (!data || !data.employees || !data.employees.items) {
    return { loading };
  }

  const items = data.employees.items;
  const totalCount = data.employees.totalCount;

  return { loading, items, totalCount, refetch };
}

export function useEmployee({
  id,
  showWage,
}: {
  id: string;
  showWage?: boolean;
}) {
  const { data } = useQuery<EmployeeQuery, EmployeeQueryVariables>(
    EmployeeDocument,
    { variables: { id, showWage } }
  );

  return data && data.employee;
}

export function useEmployeeUpdate(
  options?: MutationHookOptions<
    EmployeeUpdateMutation,
    EmployeeUpdateMutationVariables
  >
) {
  return useMutation(EmployeeUpdateDocument, options);
}

export function useEmployeeCreate(
  options?: MutationHookOptions<
    EmployeeCreateMutation,
    EmployeeCreateMutationVariables
  >
) {
  return useMutation(EmployeeCreateDocument, options);
}

export function useEmployeeDiscard(
  options?: MutationHookOptions<
    EmployeeDiscardMutation,
    EmployeeDiscardMutationVariables
  >
) {
  return useMutation(EmployeeDiscardDocument, options);
}

export function useEmployeeDestroy(
  options?: MutationHookOptions<
    EmployeeDestroyMutation,
    EmployeeDestroyMutationVariables
  >
) {
  return useMutation(EmployeeDestroyDocument, options);
}

export function useEmployeeBulkUpdate(
  options?: MutationHookOptions<
    EmployeeBulkUpdateMutation,
    EmployeeBulkUpdateMutationVariables
  >
) {
  return useMutation(EmployeeBulkUpdateDocument, options);
}
