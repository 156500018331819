import { QueryHookOptions, useQuery } from "@apollo/client";
import {
  StockReportQuery,
  StockReportQueryVariables,
  StockReportDocument,
} from "../../graphql";

export function useStockReport(
  options?: QueryHookOptions<StockReportQuery, StockReportQueryVariables>
) {
  const { loading, data } = useQuery(StockReportDocument, options);

  if (!data || !data.stockReport || !data.stockReport.items) {
    return { loading };
  }

  const items = data.stockReport.items;
  const totalCount = data.stockReport.totalCount;

  return { loading, items, totalCount };
}
