import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
} from "@apollo/client";

import {
  TokensDocument,
  TokensQuery,
  TokensQueryVariables,
  TokenUpdateMutation,
  TokenUpdateMutationVariables,
  TokenUpdateDocument,
  TokenCreateMutation,
  TokenCreateMutationVariables,
  TokenCreateDocument,
  TokenDiscardMutation,
  TokenDiscardMutationVariables,
  TokenDiscardDocument,
  TokenQuery,
  TokenQueryVariables,
  TokenDocument,
} from "../../graphql";

export function useTokens(
  options?: QueryHookOptions<TokensQuery, TokensQueryVariables>
) {
  const { loading, data } = useQuery(TokensDocument, options);

  if (!data || !data.tokens || !data.tokens.items) {
    return { loading };
  }

  const items = data.tokens.items;
  const totalCount = data.tokens.totalCount;

  return { loading, items, totalCount };
}

export function useToken(id: string) {
  const { data } = useQuery<TokenQuery, TokenQueryVariables>(TokenDocument, {
    variables: { id },
  });

  return data && data.token;
}

export function useTokenUpdate(
  options?: MutationHookOptions<
    TokenUpdateMutation,
    TokenUpdateMutationVariables
  >
) {
  return useMutation(TokenUpdateDocument, options);
}

export function useTokenCreate(
  options?: MutationHookOptions<
    TokenCreateMutation,
    TokenCreateMutationVariables
  >
) {
  return useMutation(TokenCreateDocument, options);
}

export function useTokenDiscard(
  options?: MutationHookOptions<
    TokenDiscardMutation,
    TokenDiscardMutationVariables
  >
) {
  return useMutation(TokenDiscardDocument, options);
}
