import { useState, FunctionComponent, createContext, useContext } from "react";

interface ItemSidebarContextType {
  currentAction?: string;
  currentState?: any;
  setCurrentAction(action?: string, state?: any): void;
}

export const ItemSidebarContext = createContext<ItemSidebarContextType>({
  setCurrentAction: () => {},
});

export function useItemSidebarContext() {
  return useContext(ItemSidebarContext);
}

export const ItemSidebarProvider: FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [currentState, setCurrentState] = useState();
  const [currentAction, setCurrentAction] = useState<string>();

  return (
    <ItemSidebarContext.Provider
      value={{
        currentAction,
        currentState,
        setCurrentAction: (action, state) => {
          setCurrentAction(action);
          setCurrentState(state);
        },
      }}
    >
      {children}
    </ItemSidebarContext.Provider>
  );
};
