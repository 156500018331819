import { Typography } from "antd";
import AntTable, {
  TableProps as AntTableProps,
  ColumnsType,
} from "antd/lib/table";
import {
  INTERNAL_SELECTION_ITEM,
  SELECTION_ALL,
} from "antd/lib/table/hooks/useSelection";
import { RowSelectionType, SelectionItem } from "antd/lib/table/interface";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";

type TableContextType = {
  selectedRowKeys: React.Key[];
  setSelectedRowKeys: Dispatch<SetStateAction<React.Key[]>>;
};

export interface TableProps<T>
  extends AntTableProps<T>,
    Partial<TableContextType> {
  allowSelectAll?: boolean;
  fixed?: boolean;
  rowSelectors?: Array<
    Omit<SelectionItem, "onSelect"> & {
      onSelect: (keys: React.Key[]) => React.Key[];
    }
  >;
  entityName?: string;
  selections?: INTERNAL_SELECTION_ITEM[];
  selectableFilter?: (records: readonly T[] | undefined) => boolean;
  onBulkRemove?: (keys: React.Key[]) => void;
  onBulkUpdate?: (keys: React.Key[]) => void;
}

export type { ColumnsType };

const FIXED_HEADER_TABLE_HEIGHT = "60vh";

export default function Table<T extends object>({
  selectedRowKeys,
  fixed,
  allowSelectAll,
  rowSelectors,
  entityName,
  onBulkRemove,
  onBulkUpdate,
  selectableFilter = () => true,
  setSelectedRowKeys,
  ...tableProps
}: TableProps<T>) {
  const [_selectedRowKeys, _setSelectedRowKeys] = useState<React.Key[]>([]);

  const currentRowKeys = selectedRowKeys || _selectedRowKeys;
  const setCurrentRowKeys = setSelectedRowKeys || _setSelectedRowKeys;

  const anyItems = !!tableProps?.dataSource?.length;

  const scroll = {
    x: "max-content",
    y: fixed && anyItems ? FIXED_HEADER_TABLE_HEIGHT : undefined,
  };

  const selections: INTERNAL_SELECTION_ITEM[] = [];

  if (anyItems && (allowSelectAll || onBulkRemove)) {
    selections.push(SELECTION_ALL);
  }

  if (currentRowKeys.length) {
    if (tableProps.selections) {
      selections.push(...tableProps.selections);
    }

    if (onBulkUpdate) {
      selections.push({
        key: "bulkEdit",
        text: <FormattedMessage id="edit" defaultMessage="edit" />,
        onSelect: () => onBulkUpdate(currentRowKeys),
      });
    }

    if (onBulkRemove) {
      selections.push({
        key: "bulkRemove",
        text: (
          <Typography.Text type="danger">
            <FormattedMessage id="delete" defaultMessage="Delete" />
          </Typography.Text>
        ),
        onSelect: () => onBulkRemove(currentRowKeys),
      });
    }
  }

  if (rowSelectors) {
    rowSelectors.forEach(({ key, text, onSelect }) => {
      selections?.push({
        key,
        text,
        onSelect: () =>
          setCurrentRowKeys((keys) => {
            const kk = onSelect(keys);
            return [...new Set(kk)];
          }),
      });
    });
  }

  const rowSelection =
    selectableFilter(tableProps.dataSource) && selections.length
      ? {
          type: "checkbox" as RowSelectionType,
          selectedRowKeys: currentRowKeys,
          selections,
          onChange: setCurrentRowKeys,
          getCheckboxProps: () => ({ tabIndex: -1 }),
        }
      : undefined;

  return (
    <AntTable rowSelection={rowSelection} scroll={scroll} {...tableProps} />
  );
}

Table.Summary = AntTable.Summary;

const TableContext = createContext<TableContextType>({
  selectedRowKeys: [],
  setSelectedRowKeys: () => {},
});

export function useTableContext() {
  return useContext(TableContext);
}

export function TableContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  return (
    <TableContext.Provider
      value={{
        selectedRowKeys,
        setSelectedRowKeys,
      }}
    >
      {children}
    </TableContext.Provider>
  );
}
