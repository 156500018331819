import React, { FunctionComponent } from "react";
import { Drawer } from "antd";
import { DrawerProps } from "antd/lib/drawer";

interface SidebarProps extends DrawerProps {
  setVisible?: (visible: boolean) => void;
  onClosed?(): void;
}

export const Sidebar: FunctionComponent<
  React.PropsWithChildren<SidebarProps>
> = ({ setVisible = () => {}, onClosed, children, ...drawerProps }) => {
  return (
    <Drawer
      closable={false}
      width={400}
      onClose={() => setVisible(false)}
      bodyStyle={{ paddingTop: "76px" }}
      afterOpenChange={(v) => {
        if (!v && onClosed) onClosed();
      }}
      {...drawerProps}
    >
      {children}
    </Drawer>
  );
};

export * from "./Header";
export * from "./Footer";
export * from "./ConfirmSidebar";
export * from "./RevertSidebar";
