import { Row, Col, Badge, Space, Tooltip } from "antd";
import { PropsWithChildren, ReactNode } from "react";
import { formatStatus, statusColor } from "../../../lib/formats";
import { FormattedMessage } from "react-intl";

interface StatusPanelProps {
  status?: string;
  node?: ReactNode;
  extra?: ReactNode;
  actions?: ReactNode;
  format?(status: string): ReactNode;
  formatColor?(status: string): string;
}

export function StatusPanel({
  status,
  node,
  extra,
  actions,
  format = (s) => formatStatus(s, "text"),
  formatColor = statusColor,
  children,
}: PropsWithChildren<StatusPanelProps>) {
  return (
    <Row gutter={[8, 8]}>
      <Col xs={{ span: 24, order: 1 }} sm={{ span: 20, order: 0 }}>
        {children}
      </Col>

      <Col
        xs={{ span: 24, order: 0 }}
        sm={{ span: 4, order: 1 }}
        style={{ textAlign: "right" }}
      >
        <Space
          direction="vertical"
          size={2}
          style={{ height: "100%", justifyContent: "space-between" }}
        >
          {actions}

          {(node || status) && (
            <div style={{ fontSize: 18, fontWeight: 500, marginBottom: 0 }}>
              {node}
              {status && (
                <Tooltip
                  title={<FormattedMessage id="status" />}
                  placement="bottom"
                >
                  <Badge color={formatColor(status)} text=" " />
                  {format(status)}
                </Tooltip>
              )}
            </div>
          )}
          {extra && <span style={{ margin: 0 }}>{extra}</span>}
        </Space>
      </Col>
    </Row>
  );
}
