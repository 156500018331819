import { useLazyQuery } from "@apollo/client";
import { SelectOption } from "../../../components/form";
import {
  CountriesQuery,
  CountriesDocument,
  CountriesQueryVariables,
} from "../../graphql";

export function useCountryOptions() {
  const [load, { loading, data }] = useLazyQuery<
    CountriesQuery,
    CountriesQueryVariables
  >(CountriesDocument);

  const countries = data?.countries || [];

  const options = countries
    .map((c) => ({
      key: c.code,
      label: `${c.emojiFlag} ${c.name}`,
      country: c,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return { load, loading, options, countries };
}

export function useCountryRegionOptions() {
  const countries = useCountryOptions();

  const options = countries.countries.reduce((result, c) => {
    c.regions.forEach((r) =>
      result.push({
        key: r,
        label: r,
        countryCode: c.code,
      })
    );

    return result;
  }, [] as SelectOption[]);

  return { ...countries, options };
}

export function useCurrencyOptions() {
  const { load, loading, countries } = useCountryOptions();

  const options = countries.map((c) => ({
    key: c.currency.isoCode,
    label: `${c.currency.isoCode} - ${c.currency.name}`,
  }));

  const currencies = Object.fromEntries(
    countries.map((c) => [c.currency.isoCode, c.currency])
  );

  return { load, loading, options, currencies };
}

export function usePhoneCodeOptions() {
  const { load, loading, countries } = useCountryOptions();

  const options = countries
    .map((c) => ({
      key: c.phoneCode,
      label: `${c.emojiFlag} +${c.phoneCode}`,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return { load, loading, options };
}
