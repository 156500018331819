import { Comments } from "./Comments";
import { CommentableType } from "../../../lib/graphql";
import CommentForm from "./CommentForm";
import { FormInstance } from "antd";

export interface CommentListProps {
  commentableType: CommentableType;
  commentableId?: string;
  form?: FormInstance;
}

export function CommentList({
  commentableId,
  commentableType,
  form,
}: CommentListProps) {
  return (
    <>
      {commentableId && (
        <Comments
          commentableType={commentableType}
          commentableId={commentableId}
        />
      )}

      <CommentForm
        commentableType={commentableType}
        commentableId={commentableId}
        form={form}
      />
    </>
  );
}
