import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import { CurrentUserFragment } from "../graphql";
import { useRouter } from "next/router";

export function useHubspot({
  user,
  currentTenant,
}: {
  user: CurrentUserFragment;
  currentTenant: CurrentUserFragment["currentTenant"];
}) {
  const { setPathPageView, setIdentity } = useTrackingCode();
  const { pathname } = useRouter();

  if (!process.env.ARAGRO_ENV) return;

  setPathPageView(pathname);
  setIdentity(user.email, {
    id: user.id,
    name: user.username || undefined,
    userId: user.id,
    companyId: currentTenant.id,
    companyCreatedAt: currentTenant.createdAt,
    companyName: currentTenant.name,
  });
}
