import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";
import { merge } from "lodash";

import {
  TaxComponentsDocument,
  TaxComponentsQuery,
  TaxComponentsQueryVariables,
  TaxComponentUpdateMutation,
  TaxComponentUpdateMutationVariables,
  TaxComponentUpdateDocument,
  TaxComponentCreateMutation,
  TaxComponentCreateMutationVariables,
  TaxComponentCreateDocument,
  TaxComponentDiscardMutation,
  TaxComponentDiscardMutationVariables,
  TaxComponentDiscardDocument,
  TaxComponentQuery,
  TaxComponentQueryVariables,
  TaxComponentDocument,
  TaxComponentDestroyMutation,
  TaxComponentDestroyMutationVariables,
  TaxComponentDestroyDocument,
  SortDirection,
} from "../../graphql";

export function useTaxComponents(
  options?: QueryHookOptions<TaxComponentsQuery, TaxComponentsQueryVariables>
) {
  const { loading, data } = useQuery(TaxComponentsDocument, options);

  if (!data || !data.taxComponents || !data.taxComponents.items) {
    return { loading };
  }

  const items = data.taxComponents.items;
  const totalCount = data.taxComponents.totalCount;

  return { loading, items, totalCount };
}

export function useTaxComponentOptions(
  queryOptions?: QueryHookOptions<
    TaxComponentsQuery,
    TaxComponentsQueryVariables
  >
) {
  const [load, { loading, data }] = useLazyQuery(
    TaxComponentsDocument,
    merge({ variables: { sort: { name: SortDirection.Asc } } }, queryOptions)
  );

  const options =
    data?.taxComponents?.items?.map((taxComponent) => ({
      key: taxComponent.id,
      label: taxComponent.name,
      taxComponent,
    })) || [];

  return { load, loading, options };
}

export function useTaxComponent(id: string) {
  const { data } = useQuery<TaxComponentQuery, TaxComponentQueryVariables>(
    TaxComponentDocument,
    {
      variables: { id },
    }
  );

  return data && data.taxComponent;
}

export function useTaxComponentUpdate(
  options?: MutationHookOptions<
    TaxComponentUpdateMutation,
    TaxComponentUpdateMutationVariables
  >
) {
  return useMutation(TaxComponentUpdateDocument, options);
}

export function useTaxComponentCreate(
  options?: MutationHookOptions<
    TaxComponentCreateMutation,
    TaxComponentCreateMutationVariables
  >
) {
  return useMutation(TaxComponentCreateDocument, options);
}

export function useTaxComponentDiscard(
  options?: MutationHookOptions<
    TaxComponentDiscardMutation,
    TaxComponentDiscardMutationVariables
  >
) {
  return useMutation(TaxComponentDiscardDocument, options);
}

export function useTaxComponentDestroy(
  options?: MutationHookOptions<
    TaxComponentDestroyMutation,
    TaxComponentDestroyMutationVariables
  >
) {
  return useMutation(TaxComponentDestroyDocument, options);
}
