import {
  CloseOutlined,
  EllipsisOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Col, Collapse, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useMediaTablet } from "../../../lib/hooks";
import { ActionButton, Dropdown } from "../../shared";
import { SectionBuilder, SectionProps } from "./sectionBuilder";

export interface SectionWizardProps {
  isReadonly?: boolean;
  disabled?: boolean;
  hideHiddenSections?: boolean;
  builder: SectionBuilder;
  sections: SectionProps[];
}

export function SectionWizard({
  isReadonly,
  disabled,
  hideHiddenSections,
  builder,
  sections,
}: SectionWizardProps) {
  const isTablet = useMediaTablet();

  // fill default keys to prevent expanding animation ((
  const [activeKeys, setActiveKeys] = useState<string | string[]>(
    sections.filter((s) => s.visible && !s.collapsed).map((s) => s.key)
  );

  useEffect(
    () =>
      setActiveKeys(
        sections.filter((s) => s.visible && !s.collapsed).map((s) => s.key)
      ),
    [sections]
  );

  return (
    <Collapse
      expandIconPosition="end"
      collapsible={disabled ? "disabled" : "header"}
      style={{ background: "none" }}
      activeKey={activeKeys}
      bordered={false}
      onChange={(keys) => {
        const expandedSections = Array.isArray(keys) ? keys : [keys];

        sections.forEach((section) => {
          if (expandedSections.includes(section.key)) {
            builder.onShowSection(section);
          } else {
            builder.onCollapseSection(section);
          }
        });

        setActiveKeys(keys);
      }}
    >
      {sections.map((s) => {
        if (s.hidden) return;

        const sectionView = s.views[s.currentViewIndex || 0];

        if (s.visible) {
          return (
            <Collapse.Panel
              className="wizard-section"
              key={s.key}
              data-section={s.key}
              header={
                <Row gutter={[16, 16]} align="middle" justify="end">
                  <Col>{s.icon}</Col>
                  <Col
                    style={{
                      maxWidth: s.extra || s.views.length > 1 ? "40vw" : "70vw",
                    }}
                  >
                    <Typography.Title level={3} style={{ marginBottom: 0 }}>
                      {sectionView.title}
                    </Typography.Title>
                    {!isTablet && (
                      <Typography.Text type="secondary">
                        {sectionView.description}
                      </Typography.Text>
                    )}
                  </Col>
                  <Col flex="auto" />
                  {!isReadonly && s.views.length > 1 && (
                    <Col>
                      <ActionButton
                        title={sectionView.menuItem}
                        menuItems={s.views.map(({ menuItem }, index) => ({
                          key: `menu-${index}`,
                          onClick: (e) => {
                            builder.onChangeSectionView(s, index);
                            e.domEvent.stopPropagation();
                          },
                          label: menuItem,
                        }))}
                        trigger={["click"]}
                      />
                    </Col>
                  )}
                  {s.extra && isReadonly && <Col>{s.extra()}</Col>}
                  {!s.disallowRemove && !isReadonly && (
                    <Col>
                      <Dropdown
                        menu={{
                          triggerSubMenuAction: "click",
                          items: [
                            {
                              key: "remove-section",
                              onClick: (e) => {
                                e.domEvent.stopPropagation();
                                builder.onHideSection(s);
                              },
                              danger: true,
                              label: (
                                <FormattedMessage
                                  id="workOrders.removeSection"
                                  defaultMessage="removeSection"
                                />
                              ),
                            },
                          ],
                        }}
                      >
                        <EllipsisOutlined
                          style={{ fontSize: "16px" }}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </Dropdown>
                    </Col>
                  )}
                </Row>
              }
            >
              {sectionView.render()}
            </Collapse.Panel>
          );
        }

        if (hideHiddenSections || isReadonly) return;

        return (
          <Collapse.Panel
            key={s.key}
            data-section={s.key}
            showArrow={false}
            className="wizard-section wizard-section-hidden"
            header={
              <Row gutter={16} align="middle" wrap={false}>
                <Col flex="50px">
                  <PlusOutlined style={{ fontSize: "24px" }} />
                </Col>
                <Col flex="50px">{s.icon}</Col>
                <Col flex="auto">
                  <Typography.Title level={3} style={{ marginBottom: 0 }}>
                    {sectionView.title}
                  </Typography.Title>
                  {!isTablet && (
                    <Typography.Text type="secondary">
                      {sectionView.description}
                    </Typography.Text>
                  )}
                </Col>
                <Col flex="30px">
                  {!disabled && (
                    <CloseOutlined
                      onClick={(e) => {
                        e.stopPropagation();
                        builder.onHideSection(s);
                      }}
                    />
                  )}
                </Col>
              </Row>
            }
          />
        );
      })}
    </Collapse>
  );
}
