import { ButtonProps, Descriptions, Space, Tooltip, Button } from "antd";
import { FormattedMessage } from "react-intl";
import { translate } from "../../lib/formats";
import { ReactNode } from "react";

interface FormulaComponent {
  name: string;
  title: ReactNode;
}

export function FormulaHelp({
  components = [],
  inputVariables,
  formulaText,
  onClick,
}: {
  components?: FormulaComponent[];
  inputVariables: FormulaComponent[];
  formulaText?: ReactNode;
  onClick: (code: string) => void;
}) {
  const operators = {
    "+, -, *, /, %, ^, |, &, <<, >>": (
      <FormattedMessage id="formula.math" defaultMessage="Math" />
    ),
    "<, >, <=, >=, <>, !=, =": (
      <FormattedMessage id="formula.comparison" defaultMessage="Comparison" />
    ),
    "IF, AND, OR, XOR, NOT, SWITCH, CASE": (
      <FormattedMessage id="formula.logic" defaultMessage="Logic" />
    ),
    "MIN, MAX, SUM, AVG, COUNT, ROUND, ROUNDDOWN, ROUNDUP, ABS, GETNUM": (
      <FormattedMessage id="formula.numeric" defaultMessage="Numeric" />
    ),
    "LEFT, RIGHT, MID, LEN, FIND, SUBSTITUTE, CONCAT, CONTAINS, GETSTRING": (
      <FormattedMessage id="formula.string" defaultMessage="String" />
    ),
    "MAP, FILTER, ALL, ANY, PLUCK": (
      <FormattedMessage id="formula.collection" defaultMessage="Collection" />
    ),
  };

  const buttonProps: ButtonProps = {
    size: "small",
    type: "ghost",
    style: { fontSize: 12, height: 20 },
  };

  return (
    <Descriptions
      column={1}
      size="small"
      title={
        <FormattedMessage
          id="formula.variables"
          defaultMessage="Available variables"
        />
      }
    >
      <Descriptions.Item label={<FormattedMessage id="inputVariables" />}>
        <Space wrap>
          {inputVariables.map(({ name, title }) => (
            <Tooltip key={name} title={title}>
              <Button {...buttonProps} onClick={() => onClick(name)}>
                {name}
              </Button>
            </Tooltip>
          ))}
        </Space>
      </Descriptions.Item>

      {components.length > 0 ? (
        <Descriptions.Item
          label={formulaText || <FormattedMessage id="formula" />}
        >
          <Space wrap>
            {components.map(({ name, title }) => (
              <Tooltip key={name} title={title}>
                <Button {...buttonProps} onClick={() => onClick(name)}>
                  {name}
                </Button>
              </Tooltip>
            ))}
          </Space>
        </Descriptions.Item>
      ) : null}

      {Object.entries(operators).map(([op, label]) => (
        <Descriptions.Item
          key={op}
          label={label}
          contentStyle={{ display: "inline" }}
        >
          <Space wrap>
            {op.split(", ").map((s) => (
              <Tooltip
                key={s}
                title={translate(`formula.operators.${s}`, {
                  defaultMessage: s,
                })}
              >
                <Button {...buttonProps} onClick={() => onClick(s)}>
                  {s}
                </Button>
              </Tooltip>
            ))}
          </Space>
        </Descriptions.Item>
      ))}
    </Descriptions>
  );
}
