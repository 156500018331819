import {
  QueryHookOptions,
  useQuery,
  MutationHookOptions,
  useMutation,
} from "@apollo/client";
import {
  JournalEntriesQuery,
  JournalEntriesQueryVariables,
  JournalEntriesDocument,
  JournalEntryUpdateMutation,
  JournalEntryUpdateMutationVariables,
  JournalEntryUpdateDocument,
  JournalEntryCreateMutation,
  JournalEntryCreateMutationVariables,
  JournalEntryCreateDocument,
  JournalEntryDocument,
  JournalEntryQuery,
  JournalEntryQueryVariables,
  JournalEntryStatus,
  JournalEntryCloneMutation,
  JournalEntryCloneMutationVariables,
  JournalEntryCloneDocument,
} from "../../graphql";

import { useTranslate } from "../basic/basic";

export function useJournalEntries(
  options?: QueryHookOptions<JournalEntriesQuery, JournalEntriesQueryVariables>
) {
  const { loading, data } = useQuery(JournalEntriesDocument, options);

  if (!data || !data.journalEntries || !data.journalEntries.items) {
    return { loading };
  }

  const items = data.journalEntries.items;
  const totalCount = data.journalEntries.totalCount;

  return { loading, items, totalCount };
}

export function useJournalEntry(id: string) {
  const { data } = useQuery<JournalEntryQuery, JournalEntryQueryVariables>(
    JournalEntryDocument,
    { variables: { id } }
  );

  return data && data.journalEntry;
}

export function useJournalEntryStatusOptions() {
  const translate = useTranslate();
  return [
    {
      key: JournalEntryStatus.Open,
      label: translate("statuses.open"),
    },
    {
      key: JournalEntryStatus.Completed,
      label: translate("statuses.completed"),
    },
    {
      key: JournalEntryStatus.Canceled,
      label: translate("statuses.canceled"),
    },
  ];
}

export function useJournalEntryUpdate(
  options?: MutationHookOptions<
    JournalEntryUpdateMutation,
    JournalEntryUpdateMutationVariables
  >
) {
  return useMutation(JournalEntryUpdateDocument, options);
}

export function useJournalEntryCreate(
  options?: MutationHookOptions<
    JournalEntryCreateMutation,
    JournalEntryCreateMutationVariables
  >
) {
  return useMutation(JournalEntryCreateDocument, options);
}

export function useJournalEntryClone(
  options?: MutationHookOptions<
    JournalEntryCloneMutation,
    JournalEntryCloneMutationVariables
  >
) {
  return useMutation(JournalEntryCloneDocument, options);
}
