import { SymbolLayout, SymbolPaint } from "mapbox-gl";

export const symbolLayout: SymbolLayout = {
  "text-field": "{name}",
  "text-offset": [0, 0],
  "text-size": 12,
};

export const symbolPaint: SymbolPaint = {
  "text-color": "white",
};
