import { QueryHookOptions, useQuery } from "@apollo/client";
import {
  LedgerReportQuery,
  LedgerReportQueryVariables,
  LedgerReportDocument,
} from "../../graphql";

export function useLedgerReport(
  options?: QueryHookOptions<LedgerReportQuery, LedgerReportQueryVariables>
) {
  const { loading, data } = useQuery(LedgerReportDocument, options);

  if (!data || !data.ledgerReport || !data.ledgerReport.items) {
    return { loading };
  }

  const items = data.ledgerReport.items;
  const totalCount = data.ledgerReport.totalCount;

  return { loading, items, totalCount };
}
