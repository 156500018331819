import {
  EmployeeShortFragment,
  EmployeeGroupShortFragment,
  LeaveKind,
  LeaveTypeShortFragment,
  EmployeeFragment,
  TimeConflictFragment,
  EmployeeLeaveFragment,
  UserShortFragment,
} from "../graphql";
import { Typography, Badge, Tag } from "antd";
import { FormattedMessage } from "react-intl";
import { formatDetailsLink } from "./common";
import routes from "../routes";
import {
  BLANK_SYMBOL,
  formatActivity,
  formatTimeRange,
  formatDate,
  formatWage,
} from ".";
import { ReactNode } from "react";
import Link from "next/link";

export function formatEmployeeGroupOption(group: EmployeeGroupShortFragment) {
  return (
    <>
      {group.name}{" "}
      <Typography.Text type="secondary">
        ({group.employeesCount})
      </Typography.Text>
    </>
  );
}

export function formatEmployeeName(
  employee?: Pick<
    EmployeeShortFragment | UserShortFragment,
    "firstName" | "lastName"
  > | null
) {
  if (!employee) return BLANK_SYMBOL;

  return (
    [employee.firstName, employee.lastName].filter(Boolean).join(" ") ||
    BLANK_SYMBOL
  );
}

export function formatEmployeeOption(
  employee: EmployeeShortFragment & {
    conflictUsage?: TimeConflictFragment | null;
    leave?: EmployeeLeaveFragment | null;
  }
) {
  return (
    <>
      {formatEmployeeName(employee)} -{" "}
      <Typography.Text type="secondary">
        {employee.position.name}
      </Typography.Text>{" "}
      {employee.internalId && <Tag color="blue">{employee.internalId}</Tag>}{" "}
      {formatEmployeeConflict(employee.conflictUsage)}
      {formatEmployeeLeave(employee.leave)}
    </>
  );
}

export function formatEmployeeConflict(
  conflictUsage?: TimeConflictFragment | null
) {
  if (!conflictUsage) return null;

  return (
    <Tag color="warning">
      <FormattedMessage
        id="workOrders.employeeConflict"
        defaultMessage="Worker used in WO##{id}"
        values={{
          id: conflictUsage.workOrder.id,
          activity: formatActivity(
            conflictUsage.workOrder.activity.name,
            conflictUsage.workOrder.cycleNumber
          ),
          date: formatDate(conflictUsage.startTime),
          time: formatTimeRange(conflictUsage.startTime, conflictUsage.endTime),
        }}
      />

      <Link
        target="_blank"
        href={routes.agro.workOrders.details(conflictUsage.workOrder.id)}
      >
        {" "}
        🔎
      </Link>
    </Tag>
  );
}

export function formatEmployeeLeave(leave?: EmployeeLeaveFragment | null) {
  if (!leave) return;

  return <Tag color="error">{leave.leaveType?.name}</Tag>;
}

const leaveKindColors: Record<LeaveKind, string> = {
  vacation: "purple",
  medical: "orange",
  unpaid: "cyan",
  paid: "cyan",
  legal: "yellow",
  compensation: "lime",
  maternity: "blue",
  pregnancy: "geekblue",
};

export function formatAttendance(leaveType?: LeaveTypeShortFragment | null) {
  return leaveType ? (
    <Badge
      color={leaveType.payable ? leaveKindColors[leaveType.kind] : "red"}
      text={leaveType.name}
    />
  ) : (
    formatBinaryAttendance()
  );
}

export function formatBinaryAttendance(attended = true) {
  return attended ? (
    <Badge
      color="green"
      text={
        <FormattedMessage id="employees.attended" defaultMessage="Attended" />
      }
    />
  ) : (
    <Badge
      color="#979797"
      text={<FormattedMessage id="employees.absent" defaultMessage="Absent" />}
    />
  );
}

export function formatEmployee(
  employee: EmployeeShortFragment & { discardedAt?: boolean },
  employeeGroup?: EmployeeGroupShortFragment | null,
  content?: {
    afterPosition?: ReactNode;
    hideInternalId?: boolean;
    idx?: number;
  }
) {
  return (
    <>
      {content?.idx ? <>{content?.idx}. </> : null}
      {formatDetailsLink({
        id: employee.id,
        route: routes.employees.details,
        title: `${employee.firstName} ${employee.lastName}`,
        discarded: !!employee.discardedAt,
      })}
      <div>
        {employee.position.name} {content?.afterPosition}
        {employeeGroup?.id && (
          <Tag>{formatEmployeeGroupOption(employeeGroup)}</Tag>
        )}{" "}
        {employee.internalId && !content?.hideInternalId && (
          <Tag color="blue">{employee.internalId}</Tag>
        )}
      </div>
    </>
  );
}

export function formatEmployeeWage(
  employee: Pick<EmployeeFragment, "basePayment" | "position">,
  currencyCode: string
) {
  const basePayment = employee.basePayment || employee.position.basePayment;

  if (!basePayment?.wage) return BLANK_SYMBOL;

  return formatWage(basePayment, currencyCode);
}
