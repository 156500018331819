import { Tooltip, TooltipProps } from "antd";
import { colors } from "../../lib/formats";
import { QuestionCircleOutlined } from "@ant-design/icons";

export function HelpTooltip({
  disabled,
  ...props
}: TooltipProps & { disabled?: boolean }) {
  if (disabled) return <>{props.children}</>;

  return (
    <Tooltip
      trigger={["focus"]}
      placement="right"
      color={colors.aragroColor}
      overlayStyle={{ maxWidth: "30vw", whiteSpace: "pre-line" }}
      {...props}
    />
  );
}

export function HintTooltip(props: TooltipProps) {
  return (
    <Tooltip {...props}>
      <QuestionCircleOutlined style={{ opacity: 0.5 }} />
    </Tooltip>
  );
}
