import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";

import {
  CostCentersDocument,
  CostCentersQuery,
  CostCentersQueryVariables,
  CostCenterUpdateMutation,
  CostCenterUpdateMutationVariables,
  CostCenterUpdateDocument,
  CostCenterCreateMutation,
  CostCenterCreateMutationVariables,
  CostCenterCreateDocument,
  CostCenterDiscardMutation,
  CostCenterDiscardMutationVariables,
  CostCenterDiscardDocument,
  CostCenterQuery,
  CostCenterQueryVariables,
  CostCenterDocument,
  CostCenterDestroyDocument,
  CostCenterDestroyMutation,
  CostCenterDestroyMutationVariables,
  ProfitableType,
} from "../../graphql";
import { useIntl } from "react-intl";
import { BLANK_SYMBOL } from "../../formats";

export function useProfitableTypeFormat() {
  const intl = useIntl();

  return (type: string) =>
    type
      ? intl.formatMessage({ id: `costCenters.profitableTypes.${type}` })
      : BLANK_SYMBOL;
}

export function useProfitableTypeOptions() {
  const formatProfitableType = useProfitableTypeFormat();

  const options =
    [
      ProfitableType.CropField,
      ProfitableType.Infrastructure,
      ProfitableType.Machine,
    ].map((t) => ({
      key: t,
      value: t,
      label: formatProfitableType(t),
    })) || [];

  return {
    options,
  };
}

export function useCostCenters(
  options?: QueryHookOptions<CostCentersQuery, CostCentersQueryVariables>
) {
  const [load, { loading, data, refetch }] = useLazyQuery(
    CostCentersDocument,
    options
  );

  const search = (name?: string) => {
    load(
      name
        ? {
            variables: {
              filter: { name: { match: name } },
            },
          }
        : undefined
    );
  };

  const items = data?.costCenters?.items;

  return { loading, items, load, search, refetch };
}

export function useCostCenter(id: string) {
  const { data } = useQuery<CostCenterQuery, CostCenterQueryVariables>(
    CostCenterDocument,
    {
      variables: { id },
    }
  );

  return data && data.costCenter;
}

export function useCostCenterUpdate(
  options?: MutationHookOptions<
    CostCenterUpdateMutation,
    CostCenterUpdateMutationVariables
  >
) {
  return useMutation(CostCenterUpdateDocument, options);
}

export function useCostCenterCreate(
  options?: MutationHookOptions<
    CostCenterCreateMutation,
    CostCenterCreateMutationVariables
  >
) {
  return useMutation(CostCenterCreateDocument, options);
}

export function useCostCenterDiscard(
  options?: MutationHookOptions<
    CostCenterDiscardMutation,
    CostCenterDiscardMutationVariables
  >
) {
  return useMutation(CostCenterDiscardDocument, options);
}

export function useCostCenterDestroy(
  options?: MutationHookOptions<
    CostCenterDestroyMutation,
    CostCenterDestroyMutationVariables
  >
) {
  return useMutation(CostCenterDestroyDocument, options);
}
