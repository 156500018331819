import { useLazyQuery } from "@apollo/client";
import { merge } from "lodash";
import {
  EquipmentListDocument,
  EquipmentListQuery,
  EquipmentListQueryVariables,
  SortDirection,
} from "../../graphql";
import { useEffectOnce } from "react-use";

export function useEquipmentOptions() {
  const [load, { loading, data }] = useLazyQuery<
    EquipmentListQuery,
    EquipmentListQueryVariables
  >(EquipmentListDocument, {
    variables: { sort: { name: SortDirection.Asc } },
  });

  useEffectOnce(() => {
    load();
  });

  const search = (val?: string) => {
    load(
      merge({
        variables: { sort: undefined, filter: { name: { match: val } } },
      })
    );
  };

  const options =
    data?.equipmentList?.items?.map((i) => ({
      key: i.id,
      label: i.name,
      waterSource: i,
    })) || [];

  return {
    load,
    search,
    loading,
    options,
  };
}
