import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";

import {
  PaymentMethodsDocument,
  PaymentMethodsQuery,
  PaymentMethodsQueryVariables,
  PaymentMethodUpdateMutation,
  PaymentMethodUpdateMutationVariables,
  PaymentMethodUpdateDocument,
  PaymentMethodCreateMutation,
  PaymentMethodCreateMutationVariables,
  PaymentMethodCreateDocument,
  PaymentMethodDiscardMutation,
  PaymentMethodDiscardMutationVariables,
  PaymentMethodDiscardDocument,
  PaymentMethodUnlockMutation,
  PaymentMethodUnlockMutationVariables,
  PaymentMethodUnlockDocument,
  PaymentMethodTypeQuery,
  PaymentMethodTypeDocument,
  PaymentMethodDocument,
  PaymentMethodQuery,
  PaymentMethodQueryVariables,
} from "../../graphql";
import { useIntl } from "react-intl";

export function usePaymentMethodTypeFormat() {
  const intl = useIntl();
  return (type: string) =>
    intl.formatMessage({ id: `paymentMethods.types.${type}` });
}

export function usePaymentMethodTypeOptions() {
  const [load, { data, loading }] = useLazyQuery<PaymentMethodTypeQuery>(
    PaymentMethodTypeDocument
  );

  const formatPaymentMethodType = usePaymentMethodTypeFormat();

  return {
    load,
    loading,
    options:
      data?.__type?.enumValues?.map((ut) => ({
        key: ut.name,
        label: formatPaymentMethodType(ut.name),
      })) || [],
  };
}

// TODO: lazy load
export function usePaymentMethodOptions(
  options?: QueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables>
) {
  const intl = useIntl();
  const { loading, data } = useQuery(PaymentMethodsDocument, options);

  const paymentMethods =
    (data && data.paymentMethods && data.paymentMethods.items) || [];
  const paymentMethodOptions = paymentMethods.map((p) => ({
    key: p.id,
    label: p.name,
    group: intl.formatMessage({
      id: `paymentMethods.types.${p.paymentMethodType}`,
    }),
  }));

  return {
    loadingPaymentMethods: loading,
    paymentMethods,
    paymentMethodOptions,
  };
}

export function usePaymentMethods(
  options?: QueryHookOptions<PaymentMethodsQuery, PaymentMethodsQueryVariables>
) {
  const { loading, data } = useQuery(PaymentMethodsDocument, options);

  if (!data || !data.paymentMethods || !data.paymentMethods.items) {
    return { loading };
  }

  const items = data.paymentMethods.items;
  const totalCount = data.paymentMethods.totalCount;

  return { loading, items, totalCount };
}

export function usePaymentMethod(id: string) {
  const { data } = useQuery<PaymentMethodQuery, PaymentMethodQueryVariables>(
    PaymentMethodDocument,
    {
      variables: { id },
    }
  );

  return data && data.paymentMethod;
}

export function usePaymentMethodUpdate(
  options?: MutationHookOptions<
    PaymentMethodUpdateMutation,
    PaymentMethodUpdateMutationVariables
  >
) {
  return useMutation(PaymentMethodUpdateDocument, options);
}

export function usePaymentMethodCreate(
  options?: MutationHookOptions<
    PaymentMethodCreateMutation,
    PaymentMethodCreateMutationVariables
  >
) {
  return useMutation(PaymentMethodCreateDocument, options);
}

export function usePaymentMethodDiscard(
  options?: MutationHookOptions<
    PaymentMethodDiscardMutation,
    PaymentMethodDiscardMutationVariables
  >
) {
  return useMutation(PaymentMethodDiscardDocument, options);
}

export function usePaymentMethodUnlock(
  options?: MutationHookOptions<
    PaymentMethodUnlockMutation,
    PaymentMethodUnlockMutationVariables
  >
) {
  return useMutation(PaymentMethodUnlockDocument, options);
}
