import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";
import {
  CropStagesDocument,
  CropStagesQuery,
  CropStageUpdateDocument,
  CropStagesQueryVariables,
  CropStageUpdateMutationVariables,
  CropStageUpdateMutation,
  CropStageCreateMutation,
  CropStageCreateMutationVariables,
  CropStageCreateDocument,
  CropStageDiscardMutation,
  CropStageDiscardMutationVariables,
  CropStageDiscardDocument,
  CropStageChangeEventsQuery,
  CropStageChangeEventsDocument,
  CropStageQuery,
  CropStageQueryVariables,
  CropStageDocument,
  CropStageType,
  CropStageChangeEventsQueryVariables,
  CropStageDestroyDocument,
  CropStageDestroyMutation,
  CropStageDestroyMutationVariables,
} from "../../graphql";
import { useIntl } from "react-intl";
import { formatTags } from "../../formats";
import { merge } from "lodash";
import { useCallback } from "react";

export function useFormatCropStageChangeEvent() {
  const intl = useIntl();

  return (event: string) =>
    intl.formatMessage({ id: `cropStages.changeEvents.${event}` });
}

export function useCropStageChangeEventOptions() {
  const formatCropStageChangeEvent = useFormatCropStageChangeEvent();

  const [load, { data, loading }] = useLazyQuery<
    CropStageChangeEventsQuery,
    CropStageChangeEventsQueryVariables
  >(CropStageChangeEventsDocument);

  const options =
    data?.__type?.enumValues
      ?.map((e) => ({
        key: e.name,
        label: formatCropStageChangeEvent(e.name),
      }))
      ?.sort((a, b) => a.label.localeCompare(b.label)) || [];

  return {
    load,
    loading,
    options,
  };
}

const kindTagColors = {
  [CropStageType.Development]: "cyan",
  [CropStageType.Production]: "geekblue",
};

export function useCropStageTypeFormat() {
  const intl = useIntl();

  const formatCropStageType = useCallback(
    (t: CropStageType) => intl.formatMessage({ id: `cropStages.kinds.${t}` }),
    [intl]
  );

  const formatCropStageTypeTag = useCallback(
    (t: CropStageType) =>
      formatTags([formatCropStageType(t)], kindTagColors[t]),
    [formatCropStageType]
  );

  return { formatCropStageType, formatCropStageTypeTag };
}

export function useCropStageTypeOptions() {
  const { formatCropStageType } = useCropStageTypeFormat();

  const options = [CropStageType.Development, CropStageType.Production].map(
    (t) => ({
      key: t,
      label: formatCropStageType(t),
    })
  );

  return { options };
}

export function useCropStageOptions(
  queryOptions?: QueryHookOptions<CropStagesQuery, CropStagesQueryVariables>
) {
  const { formatCropStageType } = useCropStageTypeFormat();
  const [load, { loading, data }] = useLazyQuery(
    CropStagesDocument,
    queryOptions
  );

  const options =
    data?.cropStages?.items?.map((s) => ({
      key: s.id,
      label: s.name,
      group: formatCropStageType(s.kind),
      stage: s,
    })) || [];

  const search = (val?: string) => {
    load(
      merge({ variables: { sort: undefined } }, queryOptions, {
        variables: {
          filter: {
            name: { match: val },
          },
          pageSize: 75,
        },
      })
    );
  };

  return {
    load,
    loading,
    options,
    search,
  };
}

export function useCropStages(
  options?: QueryHookOptions<CropStagesQuery, CropStagesQueryVariables>
) {
  const { loading, data, refetch } = useQuery(CropStagesDocument, options);

  if (!data || !data.cropStages || !data.cropStages.items) {
    return { loading };
  }

  const items = data.cropStages.items;
  const totalCount = data.cropStages.totalCount;

  return { loading, items, totalCount, refetch };
}

export function useCropStage(id: string) {
  const { data } = useQuery<CropStageQuery, CropStageQueryVariables>(
    CropStageDocument,
    { variables: { id } }
  );

  return data && data.cropStage;
}

export function useCropStageUpdate(
  options?: MutationHookOptions<
    CropStageUpdateMutation,
    CropStageUpdateMutationVariables
  >
) {
  return useMutation(CropStageUpdateDocument, options);
}

export function useCropStageCreate(
  options?: MutationHookOptions<
    CropStageCreateMutation,
    CropStageCreateMutationVariables
  >
) {
  return useMutation(CropStageCreateDocument, options);
}

export function useCropStageDiscard(
  options?: MutationHookOptions<
    CropStageDiscardMutation,
    CropStageDiscardMutationVariables
  >
) {
  return useMutation(CropStageDiscardDocument, options);
}

export function useCropStageDestroy(
  options?: MutationHookOptions<
    CropStageDestroyMutation,
    CropStageDestroyMutationVariables
  >
) {
  return useMutation(CropStageDestroyDocument, options);
}

export const CROP_STAGE_COLOR_MAP = {
  [CropStageType.Development]: "#4bf9d6",
  [CropStageType.Production]: "#293355",
};
