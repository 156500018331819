import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";
import { merge } from "lodash";
import { useIntl } from "react-intl";
import { formatEmployeeGroupOption } from "../../formats";
import {
  EmployeeGroupsDocument,
  EmployeeGroupsQuery,
  EmployeeGroupUpdateDocument,
  EmployeeGroupsQueryVariables,
  EmployeeGroupUpdateMutationVariables,
  EmployeeGroupUpdateMutation,
  EmployeeGroupCreateMutation,
  EmployeeGroupCreateMutationVariables,
  EmployeeGroupCreateDocument,
  EmployeeGroupDiscardMutation,
  EmployeeGroupDiscardMutationVariables,
  EmployeeGroupDiscardDocument,
  EmployeeGroupDocument,
  EmployeeGroupQuery,
  EmployeeGroupQueryVariables,
  EmployeeGroupOptionsQuery,
  EmployeeGroupOptionsQueryVariables,
  EmployeeGroupOptionsDocument,
  EmployeeGroupOptionsWithWagesDocument,
  EmployeeGroupOptionsWithWagesQuery,
  EmployeeGroupOptionsWithWagesQueryVariables,
  SortDirection,
  EmployeeGroupShortFragment,
} from "../../graphql";
import { usePermissions } from "./users";

export function useEmployeeGroupOptions(
  queryOptions?: QueryHookOptions<
    EmployeeGroupOptionsQuery,
    EmployeeGroupOptionsQueryVariables
  >
) {
  const [load, { loading, data }] = useLazyQuery(
    EmployeeGroupOptionsDocument,
    merge({ variables: { sort: { name: SortDirection.Asc } } }, queryOptions)
  );

  const search = (val?: string) => {
    load(
      merge(queryOptions, {
        variables: {
          pageSize: 25,
          filter: val ? { name: { match: val } } : undefined,
        },
      })
    );
  };

  const options = data?.employeeGroups?.items?.map((g) => ({
    key: g.id,
    label: g.name,
    group: g.locality.name,
  }));

  return { load, loading, options, search };
}

export function useEmployeeGroups(
  options?: QueryHookOptions<EmployeeGroupsQuery, EmployeeGroupsQueryVariables>
) {
  const { loading, data } = useQuery(EmployeeGroupsDocument, options);

  if (!data || !data.employeeGroups || !data.employeeGroups.items) {
    return { loading };
  }

  const items = data.employeeGroups.items;
  const totalCount = data.employeeGroups.totalCount;

  return { loading, items, totalCount };
}

export function useEmployeeGroupOptionsWithWages(
  queryOptions: QueryHookOptions<
    EmployeeGroupOptionsWithWagesQuery,
    EmployeeGroupOptionsWithWagesQueryVariables
  >
) {
  const intl = useIntl();
  const showWage = usePermissions((p) => p.settings?.showWage);

  const [load, { loading, data }] = useLazyQuery(
    EmployeeGroupOptionsWithWagesDocument,
    merge(
      {
        variables: {
          sort: { name: SortDirection.Asc },
          pageSize: 5,
          showWage,
        },
      },
      queryOptions
    )
  );

  const search = (val?: string) => {
    load(
      merge({ variables: { sort: undefined } }, queryOptions, {
        variables: {
          pageSize: 25,
          filter: val ? { name: { match: val } } : undefined,
        },
      })
    );
  };

  const options =
    data?.employeeGroups?.items?.map((g) => ({
      key: `${g.id}:group`,
      label: g.name,
      group: intl.formatMessage({ id: "employeeGroups" }),
      children: formatEmployeeGroupOption(g),
      employeeGroup: g as EmployeeGroupShortFragment | undefined,
    })) || [];

  return {
    search,
    load,
    loading,
    options,
  };
}

export function useEmployeeGroup(id: string) {
  const { data } = useQuery<EmployeeGroupQuery, EmployeeGroupQueryVariables>(
    EmployeeGroupDocument,
    { variables: { id } }
  );

  return data && data.employeeGroup;
}

export function useEmployeeGroupUpdate(
  options?: MutationHookOptions<
    EmployeeGroupUpdateMutation,
    EmployeeGroupUpdateMutationVariables
  >
) {
  return useMutation(EmployeeGroupUpdateDocument, options);
}

export function useEmployeeGroupCreate(
  options?: MutationHookOptions<
    EmployeeGroupCreateMutation,
    EmployeeGroupCreateMutationVariables
  >
) {
  return useMutation(EmployeeGroupCreateDocument, options);
}

export function useEmployeeGroupDiscard(
  options?: MutationHookOptions<
    EmployeeGroupDiscardMutation,
    EmployeeGroupDiscardMutationVariables
  >
) {
  return useMutation(EmployeeGroupDiscardDocument, options);
}
