import { defineMessages, useIntl } from "react-intl";
import {
  ItemFieldFormConfig,
  ItemForm,
  ItemFormProps,
} from "../ListView/ItemForm";
import { SidebarHeader } from "../Sidebar";

const messages = defineMessages({
  bulkUpdate: {
    id: "bulkActions.update",
    defaultMessage: "bulkActions.update",
  },
});

export interface BulkUpdateSidebarProps<T, TFormValues>
  extends Pick<ItemFormProps<T, TFormValues>, "onCancel" | "onSave"> {
  entityName?: string;
  fields: ItemFieldFormConfig<TFormValues>[];
}

export function BulkUpdateSidebar<T, TFormValues>({
  entityName,
  fields,
  onCancel,
  onSave,
}: BulkUpdateSidebarProps<T, TFormValues>) {
  const intl = useIntl();

  return (
    <>
      <SidebarHeader
        back
        title={intl.formatMessage(messages.bulkUpdate, {
          entityName,
        })}
        onClose={onCancel}
      />

      <ItemForm
        clearOnAction
        initialValues={{} as TFormValues}
        onCancel={onCancel}
        onSave={onSave}
        fields={fields}
      />
    </>
  );
}
