import {
  QueryHookOptions,
  useQuery,
  MutationHookOptions,
  useMutation,
  useLazyQuery,
} from "@apollo/client";
import {
  RecipesQuery,
  RecipesQueryVariables,
  RecipesDocument,
  RecipeUpdateMutation,
  RecipeUpdateMutationVariables,
  RecipeUpdateDocument,
  RecipeCreateMutation,
  RecipeCreateMutationVariables,
  RecipeCreateDocument,
  RecipeDiscardMutation,
  RecipeDiscardMutationVariables,
  RecipeDiscardDocument,
  RecipeQuery,
  RecipeQueryVariables,
  RecipeDocument,
  SortDirection,
  RecipeDestroyMutation,
  RecipeDestroyMutationVariables,
  RecipeDestroyDocument,
} from "../../graphql";
import { formatRecipeOption } from "../../formats";

export function useRecipeOptions() {
  const [load, { loading, data }] = useLazyQuery<
    RecipesQuery,
    RecipesQueryVariables
  >(RecipesDocument, {
    variables: { sort: { name: SortDirection.Asc } },
  });

  const search = (name?: string) => {
    load(
      name
        ? {
            variables: {
              filter: { name: { match: name } },
              sort: { name: undefined },
            },
          }
        : undefined
    );
  };

  const options = data?.recipes?.items?.map((i) => ({
    key: i.id,
    label: i.name,
    children: formatRecipeOption(i),
    recipe: i,
  }));

  return { load, search, loading, options };
}

export function useRecipes(
  options?: QueryHookOptions<RecipesQuery, RecipesQueryVariables>
) {
  const { loading, data } = useQuery(RecipesDocument, options);

  if (!data || !data.recipes || !data.recipes.items) {
    return { loading };
  }

  const items = data.recipes.items;
  const totalCount = data.recipes.totalCount;

  return { loading, items, totalCount };
}

export function useRecipe(id: string) {
  const { data } = useQuery<RecipeQuery, RecipeQueryVariables>(RecipeDocument, {
    variables: { id },
  });

  return data && data.recipe;
}

export function useRecipeUpdate(
  options?: MutationHookOptions<
    RecipeUpdateMutation,
    RecipeUpdateMutationVariables
  >
) {
  return useMutation(RecipeUpdateDocument, options);
}

export function useRecipeCreate(
  options?: MutationHookOptions<
    RecipeCreateMutation,
    RecipeCreateMutationVariables
  >
) {
  return useMutation(RecipeCreateDocument, options);
}

export function useRecipeDiscard(
  options?: MutationHookOptions<
    RecipeDiscardMutation,
    RecipeDiscardMutationVariables
  >
) {
  return useMutation(RecipeDiscardDocument, options);
}

export function useRecipeDestroy(
  options?: MutationHookOptions<
    RecipeDestroyMutation,
    RecipeDestroyMutationVariables
  >
) {
  return useMutation(RecipeDestroyDocument, options);
}
