import { Button } from "antd";
import * as React from "react";
import { ButtonProps } from "antd/lib/button";
import { useFormContext } from "./Form";

export const SubmitButton = ({ children, ...restProps }: ButtonProps) => {
  const { submitting } = useFormContext();

  return (
    <Button
      loading={submitting}
      type="primary"
      htmlType="submit"
      {...restProps}
    >
      <span>{children}</span>
    </Button>
  );
};
