import { SidebarHeader } from "../Sidebar";
import { FormattedMessage } from "react-intl";
import { FilterPanel, FilterProps } from "./FilterPanel";
import { useRouterFilter } from "../../../lib/hooks";
import { Button } from "antd";
import { ClearOutlined } from "@ant-design/icons";

interface FilterSidebarProps<TFilter> {
  entityName?: string;
  filters: FilterProps<TFilter>[];
  onClose: () => void;
}

export function queryFilter<TFilter>(
  filter: TFilter,
  filters: FilterProps<TFilter>[]
) {
  const query: any = {};
  filters.forEach((f) => {
    const val = filter[f.name];

    if (val) {
      if (f.type === "string") {
        query[f.name] = f.multiple ? { any: val } : { match: val };
      } else {
        if (f.type !== "date" || Object.keys(val).indexOf("rangeType") >= 0) {
          query[f.name] = val;
        } else {
          delete filter[f.name];
        }
      }
    }
  });

  return { query, filter };
}

export function filterCount(query: any) {
  if (!query?.filter) return 0;

  const filter = JSON.parse(query.filter.toString());
  return Object.keys(filter).length;
}

export function FilterSidebar<TFilter>({
  entityName,
  filters,
  onClose,
}: FilterSidebarProps<TFilter>) {
  const { router, filter } = useRouterFilter();

  // prefill with initial values from `filters` if no filters were applied
  const initialValues =
    typeof router.query.filter !== "undefined"
      ? filter
      : Object.fromEntries(filters.map((f) => [f.name, f.initialValue]));

  return (
    <>
      <SidebarHeader
        title={<FormattedMessage id="filters" defaultMessage="filters" />}
        back
        onClose={onClose}
        right={
          <Button
            icon={<ClearOutlined />}
            onClick={() => {
              onClose();
              router.push({
                pathname: location.pathname,
                hash: location.hash,
              });
            }}
          />
        }
      />
      <FilterPanel
        entityName={entityName}
        filters={filters}
        initialValues={initialValues}
        onChange={(rawFilter) => {
          const filter: any = queryFilter(rawFilter, filters).filter;

          router.push({
            pathname: router.pathname,
            hash: location.hash,
            query: {
              ...router.query,
              page: 1,
              filter:
                Object.keys(filter).length > 0 ? JSON.stringify(filter) : "",
            },
          });
        }}
      />
    </>
  );
}
