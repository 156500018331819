import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";

import {
  ExpenseCategoriesDocument,
  ExpenseCategoriesQuery,
  ExpenseCategoriesQueryVariables,
  ExpenseCategoryUpdateMutation,
  ExpenseCategoryUpdateMutationVariables,
  ExpenseCategoryUpdateDocument,
  ExpenseCategoryCreateMutation,
  ExpenseCategoryCreateMutationVariables,
  ExpenseCategoryCreateDocument,
  ExpenseCategoryDiscardMutation,
  ExpenseCategoryDiscardMutationVariables,
  ExpenseCategoryDiscardDocument,
  ExpenseCategoryUnlockMutation,
  ExpenseCategoryUnlockMutationVariables,
  ExpenseCategoryUnlockDocument,
  ExpenseCategoryQuery,
  ExpenseCategoryQueryVariables,
  ExpenseCategoryDocument,
  ExpenseCategoryDestroyDocument,
  ExpenseCategoryDestroyMutation,
  ExpenseCategoryDestroyMutationVariables,
} from "../../graphql";

export function useExpenseCategories(
  options?: QueryHookOptions<
    ExpenseCategoriesQuery,
    ExpenseCategoriesQueryVariables
  >
) {
  const [load, { loading, data, refetch }] = useLazyQuery(
    ExpenseCategoriesDocument,
    options
  );

  const search = (name?: string) => {
    load(
      name
        ? {
            variables: {
              filter: { name: { match: name } },
            },
          }
        : undefined
    );
  };

  const items = data?.expenseCategories?.items;

  return { loading, items, load, search, refetch };
}

export function useExpenseCategory(id: string) {
  const { data } = useQuery<
    ExpenseCategoryQuery,
    ExpenseCategoryQueryVariables
  >(ExpenseCategoryDocument, {
    variables: { id },
  });

  return data && data.expenseCategory;
}

export function useExpenseCategoryUpdate(
  options?: MutationHookOptions<
    ExpenseCategoryUpdateMutation,
    ExpenseCategoryUpdateMutationVariables
  >
) {
  return useMutation(ExpenseCategoryUpdateDocument, options);
}

export function useExpenseCategoryCreate(
  options?: MutationHookOptions<
    ExpenseCategoryCreateMutation,
    ExpenseCategoryCreateMutationVariables
  >
) {
  return useMutation(ExpenseCategoryCreateDocument, options);
}

export function useExpenseCategoryDiscard(
  options?: MutationHookOptions<
    ExpenseCategoryDiscardMutation,
    ExpenseCategoryDiscardMutationVariables
  >
) {
  return useMutation(ExpenseCategoryDiscardDocument, options);
}

export function useExpenseCategoryDestroy(
  options?: MutationHookOptions<
    ExpenseCategoryDestroyMutation,
    ExpenseCategoryDestroyMutationVariables
  >
) {
  return useMutation(ExpenseCategoryDestroyDocument, options);
}

export function useExpenseCategoryUnlock(
  options?: MutationHookOptions<
    ExpenseCategoryUnlockMutation,
    ExpenseCategoryUnlockMutationVariables
  >
) {
  return useMutation(ExpenseCategoryUnlockDocument, options);
}
