import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";
import {
  MachineKindsDocument,
  MachineKindsQuery,
  MachineKindUpdateDocument,
  MachineKindsQueryVariables,
  MachineKindUpdateMutationVariables,
  MachineKindUpdateMutation,
  MachineKindCreateMutation,
  MachineKindCreateMutationVariables,
  MachineKindCreateDocument,
  MachineKindDiscardMutation,
  MachineKindDiscardMutationVariables,
  MachineKindDiscardDocument,
  MachineKindQuery,
  MachineKindQueryVariables,
  MachineKindDocument,
  MachineKindUnlockMutation,
  MachineKindUnlockMutationVariables,
  MachineKindUnlockDocument,
} from "../../graphql";
import { useIntl } from "react-intl";
import { useEffectOnce } from "react-use";

export function useMachineKindOptions(
  queryOptions?: QueryHookOptions<
    MachineKindsQuery,
    MachineKindsQueryVariables
  >,
  preload?: boolean
) {
  const intl = useIntl();

  const [load, { loading, data }] = useLazyQuery(MachineKindsDocument, {
    variables: { pageSize: 1000 },
    ...queryOptions,
  });

  useEffectOnce(() => {
    if (preload) load();
  });

  const options =
    data?.machineKinds?.items?.map((k) => ({
      key: k.id,
      label: k.name,
      group: intl.formatMessage({ id: `machines.categories.${k.category}` }),
      machineKind: k,
    })) || [];

  return {
    load,
    loading,
    options: options.sort((a, b) => a.group.localeCompare(b.group)),
  };
}

export function useMachineKinds(
  options?: QueryHookOptions<MachineKindsQuery, MachineKindsQueryVariables>
) {
  const { loading, data } = useQuery(MachineKindsDocument, options);

  if (!data || !data.machineKinds || !data.machineKinds.items) {
    return { loading };
  }

  const items = data.machineKinds.items;
  const totalCount = data.machineKinds.totalCount;

  return { loading, items, totalCount };
}

export function useMachineKind(id: string) {
  const { data } = useQuery<MachineKindQuery, MachineKindQueryVariables>(
    MachineKindDocument,
    { variables: { id } }
  );

  return data && data.machineKind;
}

export function useMachineKindUpdate(
  options?: MutationHookOptions<
    MachineKindUpdateMutation,
    MachineKindUpdateMutationVariables
  >
) {
  return useMutation(MachineKindUpdateDocument, options);
}

export function useMachineKindCreate(
  options?: MutationHookOptions<
    MachineKindCreateMutation,
    MachineKindCreateMutationVariables
  >
) {
  return useMutation(MachineKindCreateDocument, options);
}

export function useMachineKindDiscard(
  options?: MutationHookOptions<
    MachineKindDiscardMutation,
    MachineKindDiscardMutationVariables
  >
) {
  return useMutation(MachineKindDiscardDocument, options);
}

export function useMachineKindUnlock(
  options?: MutationHookOptions<
    MachineKindUnlockMutation,
    MachineKindUnlockMutationVariables
  >
) {
  return useMutation(MachineKindUnlockDocument, options);
}
