import {
  MutationHookOptions,
  QueryHookOptions,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  AdjustmentTypesQuery,
  AdjustmentTypesQueryVariables,
  AdjustmentTypesDocument,
  AdjustmentTypeQuery,
  AdjustmentTypeQueryVariables,
  AdjustmentTypeDocument,
  AdjustmentTypeUpdateMutation,
  AdjustmentTypeUpdateMutationVariables,
  AdjustmentTypeCreateMutation,
  AdjustmentTypeCreateMutationVariables,
  AdjustmentTypeCreateDocument,
  AdjustmentTypeUpdateDocument,
  AdjustmentTypeDiscardMutation,
  AdjustmentTypeDiscardMutationVariables,
  AdjustmentTypeDiscardDocument,
  AdjustmentTypeDestroyMutation,
  AdjustmentTypeDestroyMutationVariables,
  AdjustmentTypeDestroyDocument,
  AdjustmentKindQuery,
  AdjustmentKindQueryVariables,
  AdjustmentKindDocument,
  AdjustmentKind,
} from "../../graphql";
import { useIntl } from "react-intl";

// TODO: lazy load
export function useAdjustmentTypeOptions(
  options?: QueryHookOptions<
    AdjustmentTypesQuery,
    AdjustmentTypesQueryVariables
  >
) {
  const intl = useIntl();
  const { loading, data } = useQuery(AdjustmentTypesDocument, {
    variables: { pageSize: 100 },
    ...options,
  });
  const adjustmentTypes =
    (data && data.adjustmentTypes && data.adjustmentTypes.items) || [];
  const adjustmentTypeOptions = adjustmentTypes.map((l) => ({
    key: l.id,
    label: l.name,
    group: intl.formatMessage({ id: `adjustmentKinds.${l.kind}` }),
    adjustmentType: l,
  }));
  return {
    adjustmentTypes,
    loadingAdjustmentTypes: loading,
    adjustmentTypeOptions,
  };
}

export function useAdjustmentKinds({
  excludeOther = false,
}: {
  excludeOther?: boolean;
}) {
  const intl = useIntl();
  const [load, { loading, data }] = useLazyQuery<
    AdjustmentKindQuery,
    AdjustmentKindQueryVariables
  >(AdjustmentKindDocument);

  const options =
    data?.__type?.enumValues
      ?.map((t) => ({
        key: t.name,
        label: intl.formatMessage({ id: `adjustmentKinds.${t.name}` }),
      }))
      .filter((t) => !excludeOther || t.key !== AdjustmentKind.Other) || [];

  return { load, loading, options };
}

export function useAdjustmentTypes(
  options?: QueryHookOptions<
    AdjustmentTypesQuery,
    AdjustmentTypesQueryVariables
  >
) {
  const { loading, data } = useQuery(AdjustmentTypesDocument, options);

  const items = data?.adjustmentTypes?.items;
  const totalCount = data?.adjustmentTypes?.totalCount;

  return { loading, items, totalCount };
}

export function useAdjustmentType(id: string) {
  const { data } = useQuery<AdjustmentTypeQuery, AdjustmentTypeQueryVariables>(
    AdjustmentTypeDocument,
    {
      variables: { id },
    }
  );

  return data && data.adjustmentType;
}

export function useAdjustmentTypeCreate(
  options?: MutationHookOptions<
    AdjustmentTypeCreateMutation,
    AdjustmentTypeCreateMutationVariables
  >
) {
  return useMutation(AdjustmentTypeCreateDocument, options);
}

export function useAdjustmentTypeUpdate(
  options?: MutationHookOptions<
    AdjustmentTypeUpdateMutation,
    AdjustmentTypeUpdateMutationVariables
  >
) {
  return useMutation(AdjustmentTypeUpdateDocument, options);
}
export function useAdjustmentTypeDiscard(
  options?: MutationHookOptions<
    AdjustmentTypeDiscardMutation,
    AdjustmentTypeDiscardMutationVariables
  >
) {
  return useMutation(AdjustmentTypeDiscardDocument, options);
}

export function useAdjustmentTypeDestroy(
  options?: MutationHookOptions<
    AdjustmentTypeDestroyMutation,
    AdjustmentTypeDestroyMutationVariables
  >
) {
  return useMutation(AdjustmentTypeDestroyDocument, options);
}
