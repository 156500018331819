import { QueryHookOptions, useQuery } from "@apollo/client";
import {
  PrePayrollReportQuery,
  PrePayrollReportQueryVariables,
  PrePayrollReportDocument,
  PrePayrollSummaryReportQuery,
  PrePayrollSummaryReportQueryVariables,
  PrePayrollSummaryReportDocument,
  PrePayrollOverviewReportDocument,
  PrePayrollOverviewReportQuery,
  PrePayrollOverviewReportQueryVariables,
} from "../../graphql";

export function usePrePayrollOverviewReport(
  options?: QueryHookOptions<
    PrePayrollOverviewReportQuery,
    PrePayrollOverviewReportQueryVariables
  >
) {
  const { loading, data } = useQuery(PrePayrollOverviewReportDocument, options);

  return { overviewLoading: loading, overview: data?.prePayrollOverviewReport };
}

export function usePrePayrollSummaryReport(
  options?: QueryHookOptions<
    PrePayrollSummaryReportQuery,
    PrePayrollSummaryReportQueryVariables
  >
) {
  const { loading, data } = useQuery(PrePayrollSummaryReportDocument, options);

  if (
    !data ||
    !data.prePayrollSummaryReport ||
    !data.prePayrollSummaryReport.items
  ) {
    return { loading };
  }

  const items = data.prePayrollSummaryReport.items;
  const totalCount = data.prePayrollSummaryReport.totalCount;

  return { loading, items, totalCount };
}

export function usePrePayrollReport(
  options?: QueryHookOptions<
    PrePayrollReportQuery,
    PrePayrollReportQueryVariables
  >
) {
  const { loading, data } = useQuery(PrePayrollReportDocument, options);

  if (!data || !data.prePayrollReport || !data.prePayrollReport.items) {
    return { loading };
  }

  const items = data.prePayrollReport.items;
  const totalCount = data.prePayrollReport.totalCount;

  return { loading, items, totalCount };
}
