import { QueryHookOptions, useQuery } from "@apollo/client";
import {
  ExpenseInventoryReportDocument,
  ExpenseInventoryReportQuery,
  ExpenseInventoryReportQueryVariables,
  ExpenseReportDocument,
  ExpenseReportQuery,
  ExpenseReportQueryVariables,
  ExpenseSalaryReportDocument,
  ExpenseSalaryReportQuery,
  ExpenseSalaryReportQueryVariables,
  ExpenseServiceReportDocument,
  ExpenseServiceReportQuery,
  ExpenseServiceReportQueryVariables,
} from "../../graphql";
import { buildTree } from "./treeBuilder";

export function useExpenseReport(
  options?: QueryHookOptions<ExpenseReportQuery, ExpenseReportQueryVariables>
) {
  const { loading, data } = useQuery(ExpenseReportDocument, options);

  if (!data || !data.expenseReport || !data.expenseReport.items) {
    return { loading };
  }

  const items = buildTree(data.expenseReport.items);
  const totalCount = data.expenseReport.totalCount;

  return { loading, items, totalCount };
}

export function useExpenseSalaryReport(
  options?: QueryHookOptions<
    ExpenseSalaryReportQuery,
    ExpenseSalaryReportQueryVariables
  >
) {
  const { loading, data } = useQuery(ExpenseSalaryReportDocument, options);

  if (!data || !data.expenseSalaryReport || !data.expenseSalaryReport.items) {
    return { loading };
  }

  const items = buildTree(data.expenseSalaryReport.items);
  const totalCount = data.expenseSalaryReport.totalCount;

  return { loading, items, totalCount };
}

export function useExpenseInventoryReport(
  options?: QueryHookOptions<
    ExpenseInventoryReportQuery,
    ExpenseInventoryReportQueryVariables
  >
) {
  const { loading, data } = useQuery(ExpenseInventoryReportDocument, options);

  if (
    !data ||
    !data.expenseInventoryReport ||
    !data.expenseInventoryReport.items
  ) {
    return { loading };
  }

  const items = buildTree(data.expenseInventoryReport.items);
  const totalCount = data.expenseInventoryReport.totalCount;

  return { loading, items, totalCount };
}

export function useExpenseServiceReport(
  options?: QueryHookOptions<
    ExpenseServiceReportQuery,
    ExpenseServiceReportQueryVariables
  >
) {
  const { loading, data } = useQuery(ExpenseServiceReportDocument, options);

  if (!data || !data.expenseServiceReport || !data.expenseServiceReport.items) {
    return { loading };
  }

  const items = buildTree(data.expenseServiceReport.items);
  const totalCount = data.expenseServiceReport.totalCount;

  return { loading, items, totalCount };
}
