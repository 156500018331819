import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";
import { merge } from "lodash";

import {
  TaxPlansDocument,
  TaxPlansQuery,
  TaxPlansQueryVariables,
  TaxPlanUpdateMutation,
  TaxPlanUpdateMutationVariables,
  TaxPlanUpdateDocument,
  TaxPlanCreateMutation,
  TaxPlanCreateMutationVariables,
  TaxPlanCreateDocument,
  TaxPlanDiscardMutation,
  TaxPlanDiscardMutationVariables,
  TaxPlanDiscardDocument,
  TaxPlanQuery,
  TaxPlanQueryVariables,
  TaxPlanDocument,
  TaxPlanDestroyMutation,
  TaxPlanDestroyMutationVariables,
  TaxPlanDestroyDocument,
  SortDirection,
  TaxPlanVariables,
  TaxPlanKind,
} from "../../graphql";
import { useIntl } from "react-intl";
import { useDebounce } from "react-use";

export function useTaxPlans(
  options?: QueryHookOptions<TaxPlansQuery, TaxPlansQueryVariables>
) {
  const { loading, data } = useQuery(TaxPlansDocument, options);

  if (!data || !data.taxPlans || !data.taxPlans.items) {
    return { loading };
  }

  const items = data.taxPlans.items;
  const totalCount = data.taxPlans.totalCount;

  return { loading, items, totalCount };
}

export function useTaxPlanOptions(
  queryOptions?: QueryHookOptions<TaxPlansQuery, TaxPlansQueryVariables>
) {
  const [load, { loading, data }] = useLazyQuery(
    TaxPlansDocument,
    merge({ variables: { sort: { name: SortDirection.Asc } } }, queryOptions)
  );

  const options =
    data?.taxPlans?.items?.map((taxPlan) => ({
      key: taxPlan.id,
      label: taxPlan.name,
      taxPlan,
    })) || [];

  return { load, loading, options };
}

export const DefaultTaxPlanVariables: TaxPlanVariables = {
  subtotal: 500,
  itemSubtotal: 500,
  orderSubtotal: 500,
  unitPrice: 500,
  quantity: 1,
};

export function useTaxPlanKindFormat() {
  const intl = useIntl();
  return (kind: string) => intl.formatMessage({ id: `taxPlans.kinds.${kind}` });
}

export function useTaxPlanKindOptions() {
  const formatKind = useTaxPlanKindFormat();

  const options =
    [TaxPlanKind.Vendor, TaxPlanKind.Customer, TaxPlanKind.Item].map((t) => ({
      key: t,
      value: t,
      label: formatKind(t),
    })) || [];

  return {
    options,
  };
}

export function useTaxPlan(id: string, inputVariables?: TaxPlanVariables) {
  const { data } = useQuery<TaxPlanQuery, TaxPlanQueryVariables>(
    TaxPlanDocument,
    {
      variables: {
        id,
        inputVariables: inputVariables || DefaultTaxPlanVariables,
      },
    }
  );

  return data && data.taxPlan;
}

export function useDebouncedTaxPlan(
  id: string,
  inputVariables: TaxPlanVariables
) {
  const [load, { data, loading }] = useLazyQuery<
    TaxPlanQuery,
    TaxPlanQueryVariables
  >(TaxPlanDocument);

  const [isReady] = useDebounce(
    () => {
      load({ variables: { id, inputVariables } });
    },
    300,
    [id, inputVariables]
  );

  return { taxPlan: data && data.taxPlan, loading: loading || !isReady() };
}

export function useTaxPlanUpdate(
  options?: MutationHookOptions<
    TaxPlanUpdateMutation,
    TaxPlanUpdateMutationVariables
  >
) {
  return useMutation(TaxPlanUpdateDocument, options);
}

export function useTaxPlanCreate(
  options?: MutationHookOptions<
    TaxPlanCreateMutation,
    TaxPlanCreateMutationVariables
  >
) {
  return useMutation(TaxPlanCreateDocument, options);
}

export function useTaxPlanDiscard(
  options?: MutationHookOptions<
    TaxPlanDiscardMutation,
    TaxPlanDiscardMutationVariables
  >
) {
  return useMutation(TaxPlanDiscardDocument, options);
}

export function useTaxPlanDestroy(
  options?: MutationHookOptions<
    TaxPlanDestroyMutation,
    TaxPlanDestroyMutationVariables
  >
) {
  return useMutation(TaxPlanDestroyDocument, options);
}
