import {
  QueryHookOptions,
  useQuery,
  MutationHookOptions,
  useMutation,
} from "@apollo/client";
import {
  InventoryRequestsQuery,
  InventoryRequestsQueryVariables,
  InventoryRequestsDocument,
  InventoryRequestUpdateMutation,
  InventoryRequestUpdateMutationVariables,
  InventoryRequestUpdateDocument,
  InventoryRequestDocument,
  InventoryRequestQuery,
  InventoryRequestQueryVariables,
} from "../../graphql";

export function useInventoryRequests(
  options?: QueryHookOptions<
    InventoryRequestsQuery,
    InventoryRequestsQueryVariables
  >
) {
  const { loading, data } = useQuery(InventoryRequestsDocument, options);

  if (!data || !data.inventoryRequests || !data.inventoryRequests.items) {
    return { loading };
  }

  const items = data.inventoryRequests.items;
  const totalCount = data.inventoryRequests.totalCount;

  return { loading, items, totalCount };
}

export function useInventoryRequest(id: string) {
  const { data } = useQuery<
    InventoryRequestQuery,
    InventoryRequestQueryVariables
  >(InventoryRequestDocument, { variables: { id } });

  return data && data.inventoryRequest;
}

export function useInventoryRequestUpdate(
  options?: MutationHookOptions<
    InventoryRequestUpdateMutation,
    InventoryRequestUpdateMutationVariables
  >
) {
  return useMutation(InventoryRequestUpdateDocument, options);
}
