import { useEffect } from "react";
import { CurrentUserFragment } from "../graphql";
import { captureException } from "@sentry/nextjs";

export function useIdentifyMicrosoftClarity(user?: CurrentUserFragment | null) {
  useEffect(() => {
    if (!process.env.NEXT_PUBLIC_MICROSOFT_CLARITY || !user) {
      return;
    }

    if (!window.clarity) {
      captureException(new Error("Clarity is not loaded"));
      return;
    }

    window.clarity("identify", user.email);
  }, [user]);
}
