import { QueryHookOptions, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useCurrentUser } from "..";
import {
  OnboardingStatusDocument,
  OnboardingStatusQuery,
  OnboardingStatusQueryVariables,
  Permissions,
  RwdPermissions,
} from "../../graphql";
import { IStep } from "../../../components/shared/ProductTour";

export function useOnboardingStatus(
  queryOptions?: QueryHookOptions<
    OnboardingStatusQuery,
    OnboardingStatusQueryVariables
  >
) {
  const { data, loading } = useQuery(OnboardingStatusDocument, queryOptions);

  return { steps: data?.onboardingStatus?.status || [], loading };
}

export function useOnboardingProgress() {
  const { user, currentTenant, forceOnboarding } = useCurrentUser();

  const onboardingProgress = currentTenant.onboardingProgress;
  const noOnboarding =
    typeof onboardingProgress === "undefined" || onboardingProgress === 100;
  const shouldSkip = !user || forceOnboarding || noOnboarding;

  const { steps } = useOnboardingStatus({
    skip: shouldSkip,
  });

  if (shouldSkip) return;

  const onboardingSteps = steps.filter((step) => {
    return (
      user?.permissions[step.permission as keyof Permissions] as RwdPermissions
    )?.write;
  });

  const calculatedProgress = Math.floor(
    (onboardingSteps.filter((s) => s.finished).length /
      onboardingSteps.length) *
      100
  );

  if (calculatedProgress === 100) return;

  return calculatedProgress;
}

const onboardingMessages = defineMessages({
  continueOnboardingTitle: {
    id: "onboarding.continueOnboarding.title",
    defaultMessage: "Continue onboarding",
  },
  continueOnboardingContent: {
    id: "onboarding.continueOnboarding.content",
    defaultMessage: "Continue onboarding",
  },
});

export function useContinueOnboardingStep() {
  const intl = useIntl();
  const onboardingProgress = useOnboardingProgress();
  const [shown, setShown] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const storage = Object.entries(localStorage);

    setShown(
      storage.some(
        ([key]) => key !== "onboarding:site" && /^onboarding:*/.test(key)
      )
    );
  }, []);

  if (shown || !onboardingProgress)
    return { loaded: typeof shown !== "undefined", step: null };

  return {
    loaded: true,
    step: {
      selector: "[data-action='continue-onboarding']",
      placement: "bottom",
      title: intl.formatMessage(onboardingMessages.continueOnboardingTitle),
      content: intl.formatMessage(onboardingMessages.continueOnboardingContent),
    } as IStep,
  };
}
