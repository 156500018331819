import { Radio, RadioProps } from "antd";
import { FormattedMessage } from "react-intl";

export function RadioYesNo(props: RadioProps) {
  return (
    <Radio.Group
      options={[
        {
          label: <FormattedMessage id="yes" />,
          value: true,
        },
        {
          label: <FormattedMessage id="no" defaultMessage="No" />,
          value: false,
        },
      ]}
      {...props}
    />
  );
}
