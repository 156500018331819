import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";
import { merge } from "lodash";
import { formatAccountName } from "../../formats";

import {
  AccountsDocument,
  AccountsQuery,
  AccountsQueryVariables,
  AccountUpdateMutation,
  AccountUpdateMutationVariables,
  AccountUpdateDocument,
  AccountCreateMutation,
  AccountCreateMutationVariables,
  AccountCreateDocument,
  AccountDiscardMutation,
  AccountDiscardMutationVariables,
  AccountDiscardDocument,
  AccountQuery,
  AccountQueryVariables,
  AccountDocument,
  AccountKindsQuery,
  AccountKindsDocument,
  AccountOptionsQuery,
  AccountOptionsQueryVariables,
  AccountOptionsDocument,
  AccountDestroyMutation,
  AccountDestroyMutationVariables,
  AccountDestroyDocument,
  SortDirection,
} from "../../graphql";

export const AccountMappingKinds = [
  "cash",
  "inventory",
  "input",
  "wage",
  "payable",
  "receivable",
  "freight",
  "vat",
  "withholding",
  "adjustment",
  "expense",
];

export function useAccountKinds() {
  const { data } = useQuery<AccountKindsQuery>(AccountKindsDocument);

  const accountKinds =
    (data && data.accountKinds && data.accountKinds.items) || [];

  return {
    accountKinds,
  };
}

export function useAccounts(
  options?: QueryHookOptions<AccountsQuery, AccountsQueryVariables>
) {
  const { loading, data } = useQuery(AccountsDocument, options);

  if (!data || !data.accounts || !data.accounts.items) {
    return { loading };
  }

  const items = data.accounts.items;
  const totalCount = data.accounts.totalCount;

  return { loading, items, totalCount };
}

export function useAccountOptions(
  queryOptions?: QueryHookOptions<
    AccountOptionsQuery,
    AccountOptionsQueryVariables
  >
) {
  const [load, { loading, data }] = useLazyQuery(
    AccountOptionsDocument,
    merge({ variables: { sort: { name: SortDirection.Asc } } }, queryOptions)
  );

  const options =
    data?.accounts?.items?.map((a) => ({
      key: a.id,
      label: formatAccountName(a),
      group: a.kind.name,
      isGroup: a.groupAccount,
    })) || [];

  return { load, loading, options };
}

export function useAccount(id: string) {
  const { data } = useQuery<AccountQuery, AccountQueryVariables>(
    AccountDocument,
    {
      variables: { id },
    }
  );

  return data && data.account;
}

export function useAccountUpdate(
  options?: MutationHookOptions<
    AccountUpdateMutation,
    AccountUpdateMutationVariables
  >
) {
  return useMutation(AccountUpdateDocument, options);
}

export function useAccountCreate(
  options?: MutationHookOptions<
    AccountCreateMutation,
    AccountCreateMutationVariables
  >
) {
  return useMutation(AccountCreateDocument, options);
}

export function useAccountDiscard(
  options?: MutationHookOptions<
    AccountDiscardMutation,
    AccountDiscardMutationVariables
  >
) {
  return useMutation(AccountDiscardDocument, options);
}

export function useAccountDestroy(
  options?: MutationHookOptions<
    AccountDestroyMutation,
    AccountDestroyMutationVariables
  >
) {
  return useMutation(AccountDestroyDocument, options);
}
