import { Avatar as $Avatar, AvatarProps as $AvatarProps } from "antd";
import { formatAssetPath } from "../../../lib/formats";
import { formatUsername } from "../../../lib/formats/users";
import { User } from "../../../lib/graphql";

interface AvatarProps extends $AvatarProps {
  user: Pick<User, "id" | "email" | "username" | "avatar">;
}

export function Avatar({ user, ...props }: AvatarProps) {
  const username = formatUsername(user);

  return (
    <$Avatar
      alt={username}
      src={user.avatar && formatAssetPath(user.avatar)}
      {...props}
    >
      {username.charAt(0).toUpperCase()}
    </$Avatar>
  );
}
