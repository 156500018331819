import {
  QueryHookOptions,
  useQuery,
  MutationHookOptions,
  useMutation,
} from "@apollo/client";
import {
  InventoryOrdersQuery,
  InventoryOrdersQueryVariables,
  InventoryOrdersDocument,
  InventoryOrderUpdateMutation,
  InventoryOrderUpdateMutationVariables,
  InventoryOrderUpdateDocument,
  InventoryOrderCreateMutation,
  InventoryOrderCreateMutationVariables,
  InventoryOrderCreateDocument,
  InventoryOrderDocument,
  InventoryOrderQuery,
  InventoryOrderQueryVariables,
  InventoryOrderStatus,
  InventoryOrderCloneMutation,
  InventoryOrderCloneMutationVariables,
  InventoryOrderCloneDocument,
} from "../../graphql";

import { useTranslate } from "../basic/basic";

export function useInventoryOrders(
  options?: QueryHookOptions<
    InventoryOrdersQuery,
    InventoryOrdersQueryVariables
  >
) {
  const { loading, data } = useQuery(InventoryOrdersDocument, options);

  if (!data || !data.inventoryOrders || !data.inventoryOrders.items) {
    return { loading };
  }

  const items = data.inventoryOrders.items;
  const totalCount = data.inventoryOrders.totalCount;

  return { loading, items, totalCount };
}

export function useInventoryOrder(id: string, nextDocuments = false) {
  const { data, startPolling, stopPolling } = useQuery<
    InventoryOrderQuery,
    InventoryOrderQueryVariables
  >(InventoryOrderDocument, {
    variables: { id, nextDocuments },
    fetchPolicy: nextDocuments ? "network-only" : undefined,
  });

  return { order: data?.inventoryOrder, startPolling, stopPolling };
}

export function useInventoryOrderStatusOptions() {
  const translate = useTranslate();
  return {
    options: [
      {
        key: InventoryOrderStatus.Open,
        label: translate("statuses.open"),
      },
      {
        key: InventoryOrderStatus.Completed,
        label: translate("statuses.completed"),
      },
      {
        key: InventoryOrderStatus.Canceled,
        label: translate("statuses.canceled"),
      },
    ],
  };
}

export function useInventoryOrderUpdate(
  options?: MutationHookOptions<
    InventoryOrderUpdateMutation,
    InventoryOrderUpdateMutationVariables
  >
) {
  return useMutation(InventoryOrderUpdateDocument, options);
}

export function useInventoryOrderCreate(
  options?: MutationHookOptions<
    InventoryOrderCreateMutation,
    InventoryOrderCreateMutationVariables
  >
) {
  return useMutation(InventoryOrderCreateDocument, options);
}

export function useInventoryOrderClone(
  options?: MutationHookOptions<
    InventoryOrderCloneMutation,
    InventoryOrderCloneMutationVariables
  >
) {
  return useMutation(InventoryOrderCloneDocument, options);
}
