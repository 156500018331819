import { Mentions } from "antd";

export function mentionOptions(
  options?: Array<{ key: string; email: string; label: string }>
) {
  return (
    options &&
    options.map((option) => (
      <Mentions.Option value={option.email} key={option.key}>
        {option.label === option.email ? (
          <span>{option.email}</span>
        ) : (
          <>
            <span>{option.label}</span>
            {" - "}
            <span className="mention-email">{option.email}</span>
          </>
        )}
      </Mentions.Option>
    ))
  );
}
