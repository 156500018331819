import {
  MutationHookOptions,
  QueryHookOptions,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  FinanceInvoicesQuery,
  FinanceInvoicesQueryVariables,
  FinanceInvoicesDocument,
  FinanceInvoiceDocument,
  FinanceInvoiceQuery,
  InvoiceStatusDocument,
  InvoiceStatusQuery,
  FinanceInvoiceQueryVariables,
  FinanceInvoiceUpdateMutation,
  FinanceInvoiceUpdateMutationVariables,
  FinanceInvoiceUpdateDocument,
} from "../../graphql";

import { useIntl } from "react-intl";

export function useInvoiceStatusFormat() {
  const intl = useIntl();
  return (status: string) =>
    intl.formatMessage({ id: `invoiceStatuses.${status}` });
}

export function useInvoiceStatusOptions() {
  const formatInvoiceStatus = useInvoiceStatusFormat();

  const [load, { data, loading }] = useLazyQuery<InvoiceStatusQuery>(
    InvoiceStatusDocument
  );

  const options =
    data?.__type?.enumValues?.map((s) => ({
      key: s.name,
      value: s.name,
      label: formatInvoiceStatus(s.name),
    })) || [];

  return {
    load,
    loading,
    options,
  };
}

export function useFinanceInvoices(
  options?: QueryHookOptions<
    FinanceInvoicesQuery,
    FinanceInvoicesQueryVariables
  >
) {
  const { loading, data } = useQuery(FinanceInvoicesDocument, options);

  if (!data || !data.financeInvoices || !data.financeInvoices.items) {
    return { loading };
  }

  const items = data.financeInvoices.items;
  const totalCount = data.financeInvoices.totalCount;

  return { loading, items, totalCount };
}

export function useFinanceInvoice(id: string) {
  const { data } = useQuery<FinanceInvoiceQuery, FinanceInvoiceQueryVariables>(
    FinanceInvoiceDocument,
    { variables: { id } }
  );

  return data && data.financeInvoice;
}

export function useFinanceInvoiceUpdate(
  options?: MutationHookOptions<
    FinanceInvoiceUpdateMutation,
    FinanceInvoiceUpdateMutationVariables
  >
) {
  return useMutation(FinanceInvoiceUpdateDocument, options);
}
