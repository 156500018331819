import { Maybe } from "../graphql";
import { AvailableLocales } from "./config";

export type { AvailableLocales };

export function localizedFieldName(
  name: string,
  locale: AvailableLocales
): any {
  return `${name}${locale.charAt(0).toUpperCase() + locale.slice(1)}`;
}

export function localeNameField(locale: AvailableLocales) {
  return locale === "en"
    ? "nameEn"
    : locale === "es"
    ? "nameEs"
    : locale === "pt"
    ? "namePt"
    : "nameRu";
}

export function localeCommonNamesField(locale: AvailableLocales) {
  return locale === "en"
    ? "commonNamesEn"
    : locale === "es"
    ? "commonNamesEs"
    : locale === "pt"
    ? "commonNamesPt"
    : "commonNamesRu";
}

export function localeAbbrField(locale: AvailableLocales) {
  return locale === "en"
    ? "abbrEn"
    : locale === "es"
    ? "abbrEs"
    : locale === "pt"
    ? "abbrPt"
    : "abbrRu";
}

export function localeNames({
  nameEn,
  nameEs,
  namePt,
  nameRu,
}: {
  nameEn?: Maybe<string>;
  nameEs?: Maybe<string>;
  namePt?: Maybe<string>;
  nameRu?: Maybe<string>;
}) {
  return { nameEn, nameEs, namePt, nameRu };
}

export function localeAbbrs({
  abbrEn,
  abbrEs,
  abbrPt,
  abbrRu,
}: {
  abbrEn?: Maybe<string>;
  abbrEs?: Maybe<string>;
  abbrPt?: Maybe<string>;
  abbrRu?: Maybe<string>;
}) {
  return { abbrEn, abbrEs, abbrPt, abbrRu };
}

export function localeCommonNames({
  commonNamesEn,
  commonNamesEs,
  commonNamesPt,
  commonNamesRu,
}: {
  commonNamesEn?: Maybe<string[]>;
  commonNamesEs?: Maybe<string[]>;
  commonNamesPt?: Maybe<string[]>;
  commonNamesRu?: Maybe<string[]>;
}) {
  return { commonNamesEn, commonNamesEs, commonNamesPt, commonNamesRu };
}

export function localeDescriptions({
  descriptionEn,
  descriptionEs,
  descriptionPt,
  descriptionRu,
}: {
  descriptionEn?: Maybe<string>;
  descriptionEs?: Maybe<string>;
  descriptionPt?: Maybe<string>;
  descriptionRu?: Maybe<string>;
}) {
  return { descriptionEn, descriptionEs, descriptionPt, descriptionRu };
}
