import {
  UnitFragment,
  UnitShortFragment,
  VariantShortFragment,
} from "../graphql";
import { FormattedMessage } from "react-intl";
import { sum, sumBy } from "lodash";
import { formatNumber, formatPer, formatPercent } from "./common";
import { NumberFormatOptions } from "@formatjs/ecma402-abstract";
import { Typography } from "antd";

export type FormatUnitProps = {
  value?: number | null;
  unit?: Pick<UnitShortFragment, "abbr"> | null;
  per?: string | null | "dh";
  numberOpts?: NumberFormatOptions;
};

export function formatUnitValue(
  value?: number | null,
  unit?: Pick<UnitShortFragment, "abbr"> | null,
  per?: string | null | "dh",
  numberOpts?: NumberFormatOptions
) {
  if (!unit || (!value && value !== 0)) return null;

  const perUnit =
    per === "dh" ? <FormattedMessage id="units.dh" defaultMessage="dh" /> : per;

  return formatPer({
    value: formatNumber(value, numberOpts),
    unit: unit.abbr,
    per: perUnit,
  });
}

export function formatUnitValueConversion(
  value: number | null,
  {
    unit,
    hideConversion,
    conversionFactor,
    conversionUnit,
    inverse,
    per,
  }: {
    unit?: Pick<UnitFragment, "id" | "abbr" | "conversionFactor"> | null | null;
    hideConversion?: boolean;
    conversionFactor?: number | null;
    conversionUnit?: Pick<
      UnitFragment,
      "id" | "abbr" | "conversionFactor"
    > | null | null;
    inverse?: boolean;
    per?: string;
  }
) {
  if (value == null || !unit || !conversionUnit || conversionUnit.id == unit.id)
    return formatUnitValue(value, unit, per, { maximumFractionDigits: 4 });

  const unitValue = formatUnitValue(value, unit, per, {
    maximumFractionDigits: 4,
  });
  const convertedValue =
    value *
    (conversionFactor || getUnitsConversionFactor(unit, conversionUnit));

  const conversionUnitValue = formatUnitValue(
    convertedValue,
    conversionUnit,
    per,
    {
      maximumFractionDigits: 4,
    }
  );

  return (
    <>
      {inverse ? unitValue : conversionUnitValue}
      {!hideConversion && (
        <Typography.Paragraph
          type="secondary"
          style={{ fontSize: "75%", marginBottom: 0 }}
        >
          {inverse ? conversionUnitValue : unitValue}
        </Typography.Paragraph>
      )}
    </>
  );
}

export function formatUnitPerDH(unit?: Pick<UnitShortFragment, "abbr"> | null) {
  return (
    <>
      {unit?.abbr} / <FormattedMessage id="units.dh" defaultMessage="dh" />
    </>
  );
}

export function roundUnit(value: number, precision = 100) {
  return Math.round(value * precision) / precision;
}

export function distributeEqually(
  value: number,
  count: number,
  precision = 100
) {
  const result = roundUnit((value * precision) / count, 1);
  let remainder = roundUnit(value * precision - result * count, 1);

  const distribution = new Array<number>();
  for (let index = 0; index < count; index++) {
    let currentValue = result;
    if (remainder > 0) {
      currentValue += 1;
      remainder -= 1;
    } else if (remainder < 0) {
      currentValue -= 1;
      remainder += 1;
    }
    distribution.push(currentValue / precision);
  }

  return distribution;
}

export function distributeProportionally(
  value: number,
  proportions: Array<number | null | undefined>,
  precision = 100
) {
  let toDistribute = value;
  let total = sumBy(proportions, (p) => (p || 0) * precision);

  return proportions.reduce((result, p, index) => {
    if (!total) return result;

    const weight = (p || 0) * precision;
    const distributed = roundUnit((toDistribute * weight) / total, precision);

    result[index] = distributed;
    total -= weight;
    toDistribute -= distributed;
    return result;
  }, Array(proportions.length).fill(0));
}

// solves one-cent distribution problem when 100% is distributed
export function distributeByPercent(
  value: number,
  proportions: Array<number | null | undefined>,
  precision = 100
) {
  const isDistributed = sum(proportions);

  if (isDistributed == 100) {
    return distributeProportionally(value, proportions, precision);
  } else {
    return proportions.map(
      (p) => roundUnit((value * (p || 0)) / 100.0),
      precision
    );
  }
}

// solves problem of distributing set of values between set of proportions
export function distributeMatrix(
  values: Array<number | null | undefined>,
  proportions: Array<number | null | undefined>,
  precision = 100
) {
  const total = sumBy(proportions, (p) => p || 0);

  const res = values.map((v) => {
    // calculate value distribution
    const distr = proportions.map((p) =>
      roundUnit(((v || 0) * (p || 0)) / total)
    );
    // apply rest to first entry
    distr[0] += roundUnit((v || 0) - sum(distr), precision);

    return distr;
  });

  // apply rests to match proportions
  proportions.forEach((p, i) => {
    res[0][i] += roundUnit((p || 0) - sumBy(res, (r) => r[i]));
  });

  return res;
}

export function formatConversionFactor(
  fromUnit: UnitShortFragment,
  toUnit: UnitShortFragment,
  factor?: number | null
) {
  return `1 ${fromUnit.abbr} = ${factor} ${toUnit.abbr}`;
}

export function formatDifferenceInUnits(
  first: number,
  second: number,
  unitProps: FormatUnitProps
) {
  const diff = first - second;

  if (diff === 0 || second === 0) return null;

  return (
    <Typography.Text type={diff > 0 ? "success" : "danger"}>
      {formatUnitValue(diff, unitProps.unit, unitProps.per, {
        signDisplay: "exceptZero",
      })}{" "}
      ({formatPercent(first / second)})
    </Typography.Text>
  );
}

export function getUnitsConversionFactor(
  fromUnit: Pick<UnitFragment, "id" | "conversionFactor">,
  toUnit: Pick<UnitFragment, "id" | "conversionFactor">
) {
  if (fromUnit.id == toUnit.id) return 1;

  return (fromUnit.conversionFactor || 1) / (toUnit.conversionFactor || 1);
}

export function getVariantConversionFactor(
  variant: Pick<VariantShortFragment, "variationUnit" | "variationValue">,
  toUnit: Pick<UnitFragment, "id" | "conversionFactor">
) {
  return (
    getUnitsConversionFactor(toUnit, variant.variationUnit) /
    variant.variationValue
  );
}
