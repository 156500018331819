export const Interactions = {
  SingleSelected() {
    return {
      type: "element-single-selected",
      cfg: {
        start: [
          {
            trigger: "element:mouseenter",
            action: "cursor:pointer",
          },
        ],
        end: [
          {
            trigger: "element:mouseleave",
            action: "cursor:default",
          },
        ],
      },
    };
  },
};
