import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
} from "@apollo/client";

import {
  HarvestEstimatesDocument,
  HarvestEstimatesQuery,
  HarvestEstimatesQueryVariables,
  HarvestEstimateUpdateMutation,
  HarvestEstimateUpdateMutationVariables,
  HarvestEstimateUpdateDocument,
  HarvestEstimateCreateMutation,
  HarvestEstimateCreateMutationVariables,
  HarvestEstimateCreateDocument,
  HarvestEstimateDestroyMutation,
  HarvestEstimateDestroyMutationVariables,
  HarvestEstimateDestroyDocument,
  HarvestEstimateDocument,
  HarvestEstimateQuery,
  HarvestEstimateQueryVariables,
} from "../../graphql";

export function useHarvestEstimates(
  options?: QueryHookOptions<
    HarvestEstimatesQuery,
    HarvestEstimatesQueryVariables
  >
) {
  const { loading, data } = useQuery(HarvestEstimatesDocument, options);

  if (!data || !data.harvestEstimates || !data.harvestEstimates.items) {
    return { loading };
  }

  const items = data.harvestEstimates.items;
  const totalCount = data.harvestEstimates.totalCount;

  return { loading, items, totalCount };
}

export function useHarvestEstimate(id: string) {
  const { data } = useQuery<
    HarvestEstimateQuery,
    HarvestEstimateQueryVariables
  >(HarvestEstimateDocument, {
    variables: { id },
  });

  return data?.harvestEstimate;
}

export function useHarvestEstimateUpdate(
  options?: MutationHookOptions<
    HarvestEstimateUpdateMutation,
    HarvestEstimateUpdateMutationVariables
  >
) {
  return useMutation(HarvestEstimateUpdateDocument, options);
}

export function useHarvestEstimateCreate(
  options?: MutationHookOptions<
    HarvestEstimateCreateMutation,
    HarvestEstimateCreateMutationVariables
  >
) {
  return useMutation(HarvestEstimateCreateDocument, options);
}

export function useHarvestEstimateDestroy(
  options?: MutationHookOptions<
    HarvestEstimateDestroyMutation,
    HarvestEstimateDestroyMutationVariables
  >
) {
  return useMutation(HarvestEstimateDestroyDocument, options);
}
