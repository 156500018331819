import { Currency } from "../../../lib/graphql";
import { useExchangeRate } from "../../../lib/hooks/basic/exchangeRates";
import { useUpdateEffect } from "react-use";
import {
  useFormContext,
  Form,
  Rules,
  InputNumber,
  InputNumberProps,
} from "../../form";

import { Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import { formatMoneyAmount } from "../../../lib/formats";
import { SwapOutlined, InfoCircleOutlined } from "@ant-design/icons";

interface ExchangeRateInputProps extends InputNumberProps {
  name: string;
  from?: Partial<Pick<Currency, "isoCode" | "symbol">>;
  to?: Partial<Pick<Currency, "isoCode" | "symbol">>;
  date?: string;
}

export function ExchangeRateInput({
  name,
  from,
  to,
  date,
  ...inputProps
}: ExchangeRateInputProps) {
  const loadExchangeRate = useExchangeRate();
  const { form } = useFormContext();
  const fromIsoCode = from?.isoCode;
  const toIsoCode = to?.isoCode;

  useUpdateEffect(() => {
    if (!fromIsoCode || !toIsoCode) return;

    if (fromIsoCode === toIsoCode) {
      form.setFields([{ name, value: 1 }]);
    } else if (date) {
      loadExchangeRate({ from: fromIsoCode, to: toIsoCode, date: date })
        .then((result) => {
          form.setFields([{ name, value: result?.data?.exchangeRate }]);
        })
        .catch(() => {
          form.setFields([{ name, value: undefined }]);
        });
    }
  }, [fromIsoCode, toIsoCode, date]);

  return (
    <Form.Item
      name={name}
      label={
        <FormattedMessage id="exchangeRate" defaultMessage="exchangeRate" />
      }
      rules={[Rules.gtZero]}
    >
      <InputNumber
        name={name}
        step={0.000001}
        disabled={!!fromIsoCode && !!toIsoCode && fromIsoCode === toIsoCode}
        addonBefore={to && to.symbol}
        addonAfter={
          <Tooltip
            title={
              <FormattedMessage
                id="exchangeRate.editHint"
                defaultMessage="editHint"
              />
            }
          >
            {fromIsoCode && toIsoCode && (
              <>
                <SwapOutlined />{" "}
                {formatMoneyAmount(1, fromIsoCode, {
                  maximumSignificantDigits: 1,
                })}{" "}
                {fromIsoCode}{" "}
              </>
            )}
            <InfoCircleOutlined />
          </Tooltip>
        }
        {...inputProps}
      />
    </Form.Item>
  );
}
