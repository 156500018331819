export function formatAssetPath(path?: any) {
  if (!path) return "";

  const url = process.env.API_URL || "";
  return url.replace("/graphql", path);
}

export type PdfModule =
  | "harvest_estimate"
  | "work_order"
  | "inventory_order"
  | "inventory_transfer"
  | "finance_order"
  | "finance_invoice"
  | "journal_entry";

export function formatPdfPath(module?: PdfModule, id?: string, params?: any) {
  if (!module || !id) return "";

  const query =
    params &&
    "?" +
      Object.keys(params)
        .map((k) => params[k] && `${k}=${params[k]}`)
        .filter((v) => v)
        .join("&");

  return formatAssetPath(
    [`/pdf/${module}/${id}.pdf`, query].filter((a) => a).join("")
  );
}

const imageSizes = {
  badge: 48,
  thumb: 64,
  small: 128,
};

type ImageSize = keyof typeof imageSizes;

export function formatImage(path?: any, size?: ImageSize) {
  if (!path) return null;
  return (
    <img
      src={formatAssetPath(path)}
      style={{ maxWidth: imageSizes[size || "thumb"] }}
    />
  );
}
