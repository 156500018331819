import { useLazyQuery } from "@apollo/client";
import {
  DateRangeTypeQuery,
  DateRangeTypeDocument,
  DateRangeTypeQueryVariables,
  DateRangeType,
} from "../../graphql";
import { useIntl } from "react-intl";
import { useContext } from "react";
import { ItemSidebarContext } from "../../../components/shared";
import { useRouter } from "next/router";
import { useEffectOnce } from "react-use";

export function useDateRangeTypeOptions() {
  const intl = useIntl();
  const [load, { loading, data }] = useLazyQuery<
    DateRangeTypeQuery,
    DateRangeTypeQueryVariables
  >(DateRangeTypeDocument);

  const options =
    data?.__type?.enumValues?.map((t) => ({
      key: t.name,
      label: intl.formatMessage({ id: `dateRanges.types.${t.name}` }),
    })) || [];

  return { load, loading, options };
}

export function useDateRangeFilterOption(key: string) {
  const intl = useIntl();
  const { options, load } = useDateRangeTypeOptions();

  useEffectOnce(() => {
    load();
  });

  const { filter } = useRouterFilter();

  if (!filter[key]) return;

  const rangeType = filter[key]["rangeType"];
  const range = filter[key]["range"];

  const option = options.find((o) => o.key === rangeType);

  let label = option?.label;

  if ([DateRangeType.MonthRange, DateRangeType.Range].includes(rangeType))
    label = [intl.formatDate(range[0]), intl.formatDate(range[1])].join(" - ");

  return label;
}

export function useItemSidebarContext() {
  return useContext(ItemSidebarContext);
}

export function useRouterFilter() {
  const router = useRouter();

  let filter: any = {};
  if (router.query.filter) {
    filter = JSON.parse(router.query.filter.toString());
  }

  return { filter, router };
}

export function useTranslate() {
  const intl = useIntl();
  return (id: string) => intl.formatMessage({ id });
}
