import { envDescription } from "../lib/formats";
import NextHead from "next/head";
import { ReactNode } from "react";

export default function Head({ title }: { title: ReactNode }) {
  return (
    <NextHead>
      <title>
        {title} － {envDescription()}
      </title>
    </NextHead>
  );
}
