import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";

import {
  MetricsDocument,
  MetricsQuery,
  MetricsQueryVariables,
  MetricUpdateMutation,
  MetricUpdateMutationVariables,
  MetricUpdateDocument,
  MetricCreateMutation,
  MetricCreateMutationVariables,
  MetricCreateDocument,
  MetricDiscardMutation,
  MetricDiscardMutationVariables,
  MetricDiscardDocument,
  MetricDocument,
  MetricQuery,
  MetricQueryVariables,
  MetricDestroyDocument,
  MetricDestroyMutation,
  MetricDestroyMutationVariables,
  ActivityMetricScope,
} from "../../graphql";
import { useTranslate } from "..";

export function useActivityMetricScopeOptions() {
  const translate = useTranslate();

  const formatActivityMetricScope = (scope: ActivityMetricScope) =>
    translate(`activities.metrics.scopes.${scope}`);

  const options = [
    ActivityMetricScope.CostCenter,
    ActivityMetricScope.Employee,
    ActivityMetricScope.Machine,
    ActivityMetricScope.Output,
  ].map((s) => ({
    key: s,
    label: formatActivityMetricScope(s),
  }));

  return { options, formatActivityMetricScope };
}

export function useMetricOptions(
  queryOptions?: QueryHookOptions<MetricsQuery, MetricsQueryVariables>
) {
  const [load, { loading, data }] = useLazyQuery(MetricsDocument, queryOptions);

  const options = data?.metrics?.items?.map((m) => ({
    key: m.id,
    label: `${m.name} (${m.unit.name})`,
    metric: m,
  }));

  return {
    load,
    loading,
    options,
  };
}

export function useMetrics(
  options?: QueryHookOptions<MetricsQuery, MetricsQueryVariables>
) {
  const { loading, data } = useQuery(MetricsDocument, options);

  if (!data || !data.metrics || !data.metrics.items) {
    return { loading };
  }

  const items = data.metrics.items;
  const totalCount = data.metrics.totalCount;

  return { loading, items, totalCount };
}

export function useMetric(id: string) {
  const { data } = useQuery<MetricQuery, MetricQueryVariables>(MetricDocument, {
    variables: { id },
  });

  return data?.metric;
}

export function useMetricUpdate(
  options?: MutationHookOptions<
    MetricUpdateMutation,
    MetricUpdateMutationVariables
  >
) {
  return useMutation(MetricUpdateDocument, options);
}

export function useMetricCreate(
  options?: MutationHookOptions<
    MetricCreateMutation,
    MetricCreateMutationVariables
  >
) {
  return useMutation(MetricCreateDocument, options);
}

export function useMetricDiscard(
  options?: MutationHookOptions<
    MetricDiscardMutation,
    MetricDiscardMutationVariables
  >
) {
  return useMutation(MetricDiscardDocument, options);
}

export function useMetricDestroy(
  options?: MutationHookOptions<
    MetricDestroyMutation,
    MetricDestroyMutationVariables
  >
) {
  return useMutation(MetricDestroyDocument, options);
}
