import {
  QueryHookOptions,
  useQuery,
  MutationHookOptions,
  useMutation,
} from "@apollo/client";
import {
  FinanceOrdersQuery,
  FinanceOrdersQueryVariables,
  FinanceOrdersDocument,
  FinanceOrderUpdateMutation,
  FinanceOrderUpdateMutationVariables,
  FinanceOrderUpdateDocument,
  FinanceOrderCreateMutation,
  FinanceOrderCreateMutationVariables,
  FinanceOrderCreateDocument,
  FinanceOrderDocument,
  FinanceOrderQuery,
  FinanceOrderQueryVariables,
  FinanceOrderStatus,
  FinanceOrderCloneMutation,
  FinanceOrderCloneMutationVariables,
  FinanceOrderCloneDocument,
} from "../../graphql";

import { useTranslate } from "../basic/basic";

export function useFinanceOrders(
  options?: QueryHookOptions<FinanceOrdersQuery, FinanceOrdersQueryVariables>
) {
  const { loading, data } = useQuery(FinanceOrdersDocument, options);

  if (!data || !data.financeOrders || !data.financeOrders.items) {
    return { loading };
  }

  const items = data.financeOrders.items;
  const totalCount = data.financeOrders.totalCount;

  return { loading, items, totalCount };
}

export function useFinanceOrder(id: string, nextDocuments = false) {
  const { data } = useQuery<FinanceOrderQuery, FinanceOrderQueryVariables>(
    FinanceOrderDocument,
    {
      variables: { id, nextDocuments },
      fetchPolicy: nextDocuments ? "network-only" : undefined,
    }
  );

  return data && data.financeOrder;
}

export function useFinanceOrderStatusOptions() {
  const translate = useTranslate();
  return {
    options: [
      {
        key: FinanceOrderStatus.Open,
        label: translate("statuses.open"),
      },
      {
        key: FinanceOrderStatus.Completed,
        label: translate("statuses.completed"),
      },
      {
        key: FinanceOrderStatus.Canceled,
        label: translate("statuses.canceled"),
      },
    ],
  };
}

export function useFinanceOrderUpdate(
  options?: MutationHookOptions<
    FinanceOrderUpdateMutation,
    FinanceOrderUpdateMutationVariables
  >
) {
  return useMutation(FinanceOrderUpdateDocument, options);
}

export function useFinanceOrderCreate(
  options?: MutationHookOptions<
    FinanceOrderCreateMutation,
    FinanceOrderCreateMutationVariables
  >
) {
  return useMutation(FinanceOrderCreateDocument, options);
}

export function useFinanceOrderClone(
  options?: MutationHookOptions<
    FinanceOrderCloneMutation,
    FinanceOrderCloneMutationVariables
  >
) {
  return useMutation(FinanceOrderCloneDocument, options);
}
