import {
  Address,
  Country,
  CountryFragment,
  FinanceDocumentType,
  InventoryDocumentType,
  Maybe,
} from "../graphql";
import {
  FormattedMessage,
  FormattedNumber,
  MessageDescriptor,
} from "react-intl";
import { Badge, Tag, Tooltip, Typography } from "antd";
import {
  LockFilled,
  UnlockFilled,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { ReactElement, ReactNode, isValidElement } from "react";
import Link from "next/link";
import routes from "../routes";
import { NamePath } from "../../components/form";
import { capitalize, get, snakeCase, trim } from "lodash";
import { roundUnit } from "./units";
import { NumberFormatOptions } from "@formatjs/ecma402-abstract";
import { formatEmployeeName } from "./employees";
import { presetPalettes } from "@ant-design/colors";
import { renderToString } from "react-dom/server";
import { BaseType } from "antd/lib/typography/Base";
import { Space } from "../../components/shared";

export const colors = {
  aragroColor: "#02c9ab",
  brandColor: "#007bff",
  successColor: "#52c41a",
  dangerColor: "#ff4d4f",
  warningColor: "#faad14",
  disabledColor: "#bfbfbf",
  completedColor: "#1a173b",
  alternateColor: "#f3f3f3",
};

export const statusColors = {
  new: "#9ecef5",
  open: "cyan",
  completed: "green",
  canceled: "red",
  closed: "#111111",
  submitted: "#13c2c2",
  inProgress: "blue",
  overdue: "volcano",
  due: "cyan",
  due_soon: "orange",
  awaitingInventory: "gold",
  reverted: "magenta",
};

export const rtfValues: Record<string, any> = {
  br: <br />,
  b: (txt: string) => <b>{txt}</b>,
  p: (txt: string) => <p>{txt}</p>,
  t: <span style={{ marginLeft: "30px" }} />,
  c: (txt: string) => <Typography.Text code>{txt}</Typography.Text>,
};

export function formatDetailsLink({
  id,
  route,
  title = id,
  target = "_blank",
  cache = true,
  help,
  discarded,
}: {
  id: string;
  route: (id: string) => string;
  title?: ReactNode;
  target?: "" | "_blank";
  cache?: boolean;
  help?: string;
  discarded?: boolean;
}) {
  return (
    <Link
      href={cache ? route("[id]") : route(id)}
      as={route(id)}
      target={target}
      onClick={(e) => e.stopPropagation()}
      title={help}
      tabIndex={-1}
      className={`details-link ${
        target == "_blank" ? "details-link-blank" : ""
      }`}
    >
      {formatDiscardedTag(discarded)}
      {title}
    </Link>
  );
}

export function formatDiscardedTag(discarded?: boolean) {
  return (
    discarded && (
      <Tag color="error">
        <FormattedMessage id="discarded" />
      </Tag>
    )
  );
}

export type DocumentType =
  | FinanceDocumentType
  | InventoryDocumentType
  | "financeInvoice";

function documentRoute(type: DocumentType, code?: string | null) {
  switch (type) {
    case FinanceDocumentType.FinanceJournalEntry:
      return {
        route: routes.finance.journalEntries.details,
        title: <FormattedMessage id="journalEntries.entityName" />,
      };
    case FinanceDocumentType.AgroWorkOrder:
    case InventoryDocumentType.AgroWorkOrder:
      return {
        route: routes.agro.workOrders.details,
        title: <FormattedMessage id="workOrders.entityName" />,
      };
    case FinanceDocumentType.FinanceOrder:
      return {
        route: routes.finance.expenseOrders.details,
        title: <FormattedMessage id="expenseOrders.entityName" />,
      };
    case FinanceDocumentType.FinancePayment:
      return {
        route: routes.finance.payments.details,
        title: <FormattedMessage id="payments.entityName" />,
      };
    case "financeInvoice":
      return {
        route: routes.finance.invoices.details,
        title: <FormattedMessage id="invoices.entityName" />,
      };
    case FinanceDocumentType.InventoryOrder:
    case InventoryDocumentType.InventoryOrder:
      return inventoryOrderRoute(code);

    case FinanceDocumentType.InventoryTransfer:
    case InventoryDocumentType.InventoryTransfer:
      return inventoryTransferRoute(code);
    case FinanceDocumentType.HrLeaveAssignment:
      return {
        route: routes.hr.leaveAssignments.details,
        title: <FormattedMessage id="leaveAssignments.entityName" />,
      };
    case FinanceDocumentType.HrPayrollEntry:
      return {
        route: routes.hr.payrollEntries.details,
        title: <FormattedMessage id="payrollEntries.entityName" />,
      };
  }
}

function inventoryOrderRoute(code?: string | null) {
  switch (code) {
    case "PU":
      return {
        route: routes.inventory.purchases.details,
        title: <FormattedMessage id="inventoryPurchaseOrders.entityName" />,
      };
    case "SO":
      return {
        route: routes.inventory.sales.details,
        title: <FormattedMessage id="inventorySaleOrders.entityName" />,
      };
    default:
      return {
        route: routes.inventory.purchases.details,
        title: (
          <FormattedMessage
            id="inventoryOrders.entityName"
            defaultMessage="Inventory Order"
          />
        ),
      };
  }
}

function inventoryTransferRoute(code?: string | null) {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (code) {
    case "MV":
      return {
        route: routes.inventory.transfers.details,
        title: <FormattedMessage id="stockTransfers.entityName" />,
      };
    default:
      return {
        route: routes.inventory.adjustments.details,
        title: <FormattedMessage id="stockAdjustments" />,
      };
  }
}

export function formatDocumentTitle(id: string, title: JSX.Element) {
  return (
    <>
      {title} #{id}
    </>
  );
}

export function formatDocumentLink(
  id: string,
  type: DocumentType,
  code?: string | null
) {
  const { route, title } = documentRoute(type, code);
  return formatDetailsLink({
    id,
    route,
    title: formatDocumentTitle(id, title),
  });
}

export const BLANK_SYMBOL = "--";

export function formatAddress(
  address?: Maybe<
    Pick<Address, "region" | "city" | "address"> & {
      country: Pick<Country, "name" | "emojiFlag">;
    }
  >
) {
  if (!address) return BLANK_SYMBOL;

  return [
    `${address.country.emojiFlag} ${address.country.name}`,
    address.region,
    address.city,
    address.address,
  ]
    .filter((p) => p)
    .join(", ");
}

export function formatPhoneNumber(
  phone?: Maybe<
    Pick<Address, "phoneCode" | "phoneNumber"> & {
      country: Pick<Country, "emojiFlag">;
    }
  >,
  displayFlag?: boolean
) {
  if (!phone || !phone.phoneCode || !phone.phoneNumber) return BLANK_SYMBOL;

  return [
    displayFlag ? phone.country.emojiFlag : null,
    `+${phone.phoneCode}`,
    phone.phoneNumber,
  ].join(" ");
}

export function statusColor(status: string) {
  return statusColors[status as keyof typeof statusColors] || statusColors.new;
}

export function formatStatus(
  status: string,
  type: "text" | "tag" | "badge",
  isDocument = true
) {
  const text = translate(
    `statuses.${isDocument && status == "open" ? "draft" : status}`
  );
  if (type === "tag") return <Tag color={statusColor(status)}>{text}</Tag>;
  else if (type === "badge")
    return (
      <>
        <Badge color={statusColor(status)} text=" " />
        {text}
      </>
    );
  return text;
}

export function formatLockedIcon(locked?: boolean) {
  return locked ? <LockFilled /> : <UnlockFilled />;
}

export function formatHours(hours?: number | null) {
  if (hours == undefined) return null;

  return (
    <>
      {roundUnit(hours)}{" "}
      <FormattedMessage id="workUnits.abbr.hour" defaultMessage="hour" />
    </>
  );
}

export function formatDay(value: string) {
  if (!value) return BLANK_SYMBOL;

  return (
    <>
      {value} <FormattedMessage id="abbr.day" defaultMessage="d" />
    </>
  );
}

export function unaccent(input: string) {
  return input
    .toString()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
}

export function unaccentSearch(input: string, content?: ReactElement | string) {
  if (!content) return true;

  return unaccent(
    typeof content === "string" ? content : renderToString(content)
  )
    .toLowerCase()
    .includes(input.toLowerCase());
}

export function envDescription() {
  return process.env.ARAGRO_ENV === "production"
    ? "Aragro"
    : process.env.ARAGRO_ENV === "staging"
    ? "Staging"
    : process.env.ARAGRO_ENV === "demo"
    ? "Demo"
    : "Development";
}

export function translate(id: string, props?: MessageDescriptor) {
  return <FormattedMessage {...{ id }} {...props} />;
}

export function shouldUpdate(...names: NamePath[]) {
  return (prev: any, next: any) =>
    !!names.find((name) => get(prev, name) != get(next, name));
}

export function shouldUpdateBy(...predicates: Array<(val: any) => any>) {
  return (prev: any, next: any) => !!predicates.find((p) => p(prev) != p(next));
}

export function formatNumber(
  value?: number | null,
  opts?: NumberFormatOptions
) {
  if (value == undefined) return null;

  return <FormattedNumber value={value} maximumFractionDigits={2} {...opts} />;
}

export function formatPercent(
  value?: number | null,
  opts?: NumberFormatOptions
) {
  if (value == undefined) return null;

  return formatNumber(value, { ...opts, style: "percent" });
}

export function formatPer({
  value,
  unit,
  per,
}: {
  value?: string | ReactNode | null;
  unit?: string | null;
  per?: string | ReactNode | null;
}) {
  return (
    <>
      {value} {unit}
      {per && (
        <>
          {" / "}
          {per}
        </>
      )}
    </>
  );
}

export function humanize(str?: string) {
  if (!str) return null;

  return capitalize(
    trim(snakeCase(str).replace(/_id$/, "").replace(/_/g, " "))
  );
}

export function formatPlaceholder(entity: MessageDescriptor) {
  return (
    <>
      <FormattedMessage
        id="select.name"
        values={{ name: <FormattedMessage {...entity} /> }}
      />
      ...
    </>
  );
}

export function formatInfoTooltip(content: ReactNode) {
  if (!content) return;

  return (
    <Tooltip title={content}>
      <InfoCircleOutlined />
    </Tooltip>
  );
}

export function formatEntityIdName(entityNameId: string) {
  return (
    <FormattedMessage
      id="id.entity"
      defaultMessage="{entityName} ID"
      values={{ entityName: <FormattedMessage {...{ id: entityNameId }} /> }}
    />
  );
}

export function formatEntityNameLabel(entityName?: ReactNode) {
  if (!entityName) return <FormattedMessage id="name" defaultMessage="Name" />;

  return (
    <FormattedMessage
      id="name.entity"
      defaultMessage="{entityName} name"
      values={{ entityName }}
    />
  );
}

export function formatEntityWithName(entityName: string, entity: any) {
  return [
    entityName,
    entity.name || (entity.lastName && formatEmployeeName(entity)) || entity.id,
  ].join(" ");
}

export function formatYesNo(value?: boolean | null) {
  return <FormattedMessage id={value ? "yes" : "no"} />;
}

type StatisticConfig = { statistic: ReactNode; type?: BaseType };

export function formatStatistic(
  title: ReactNode,
  ...statistics: Array<StatisticConfig | ReactNode | null>
) {
  return (
    <>
      {title}
      <br />
      <Space direction="vertical" size={0}>
        {statistics.map((statisticProp, i) => {
          if (!statisticProp) return null;

          const isNode = isValidElement(statisticProp);
          const statistic = isNode
            ? statisticProp
            : (statisticProp as StatisticConfig).statistic;
          const type = isNode
            ? "success"
            : (statisticProp as StatisticConfig).type;

          return (
            <Typography.Text key={`statistic-${i}`} type={type}>
              {statistic}
            </Typography.Text>
          );
        })}
      </Space>
    </>
  );
}

function spreadTextColor(percent: number, smallSuccess = false) {
  const spread = Math.abs(1 - percent);
  if (spread <= 0.1) return "success";
  if (spread <= 0.25) return "warning";
  if (smallSuccess && percent < 0.75) return "success";

  return "danger";
}

function normalTextColor(percent: number) {
  if (percent >= 1) return "danger";

  return "success";
}

function inverseTextColor(percent: number) {
  if (percent <= 1) return "danger";

  return "success";
}

export function formatPercentStatistic(
  value?: number | null,
  total?: number | null,
  opts?: {
    formatter?: (value: number) => ReactNode;
    onlyTotal?: boolean;
    colors?: "spread" | "normal" | "inverse";
    smallSuccess?: boolean;
  }
) {
  const {
    formatter,
    colors = "spread",
    onlyTotal,
    smallSuccess,
  } = {
    formatter: formatNumber,
    ...opts,
  };

  if (onlyTotal && total) return formatter(total);

  if (!total && value) return formatter(value);

  if (total == null || value == null) return;

  const percent = total ? value / total : 1;
  const type =
    colors == "spread"
      ? spreadTextColor(percent, smallSuccess)
      : colors == "normal"
      ? normalTextColor(percent)
      : inverseTextColor(percent);

  return (
    <>
      {formatter(value)}
      <Typography.Text type="secondary" style={{ fontSize: "75%" }}>
        <br />
        {formatter(total)}
      </Typography.Text>{" "}
      <Typography.Text type={type} style={{ fontSize: "75%" }}>
        ({formatPercent(percent)})
      </Typography.Text>
    </>
  );
}

const colorPallets = [
  presetPalettes.orange,
  presetPalettes.cyan,
  presetPalettes.lime,
  presetPalettes.blue,
  presetPalettes.blue,
  presetPalettes.magenta,
];
export function formatRandomColor(index: number | string, depth = 3) {
  const idx = Number.parseInt(index.toString());
  return colorPallets[idx % colorPallets.length][depth];
}

export function descriptionColumns(lg: number) {
  return { lg, md: 3, sm: 2, xs: 1 };
}

export function focusInput(id: string) {
  const input = document.querySelector(id) as HTMLInputElement;
  input.focus();
}

export function insertText(id: string, text: string) {
  const input = document.querySelector(id) as HTMLInputElement;
  input.setRangeText(
    text,
    input.selectionStart || 0,
    input.selectionEnd || 0,
    "end"
  );
  input.focus();
  return input.value;
}

export function formatNationalIDLabel(country?: CountryFragment) {
  return (
    country?.nationalId || (
      <FormattedMessage id="nationalId" defaultMessage="National ID" />
    )
  );
}
