import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";
import { merge } from "lodash";
import {
  IngredientsDocument,
  IngredientsQuery,
  IngredientsQueryVariables,
  IngredientUpdateMutation,
  IngredientUpdateMutationVariables,
  IngredientUpdateDocument,
  IngredientCreateMutation,
  IngredientCreateMutationVariables,
  IngredientCreateDocument,
  IngredientDiscardMutation,
  IngredientDiscardMutationVariables,
  IngredientDiscardDocument,
  IngredientDocument,
  IngredientQuery,
  IngredientQueryVariables,
  SortDirection,
} from "../../graphql";

export function useIngredientOptions() {
  const [load, { loading, data }] = useLazyQuery<
    IngredientsQuery,
    IngredientsQueryVariables
  >(IngredientsDocument, { variables: { sort: { name: SortDirection.Asc } } });

  const search = (val?: string) => {
    load(
      merge({
        variables: { sort: undefined, filter: { name: { match: val } } },
      })
    );
  };

  const options =
    data?.ingredients?.items?.map((i) => ({
      key: i.id,
      label: i.name,
      ingredient: i,
    })) || [];

  return {
    load,
    search,
    loading,
    options,
  };
}

export function useIngredients(
  options?: QueryHookOptions<IngredientsQuery, IngredientsQueryVariables>
) {
  const { loading, data } = useQuery(IngredientsDocument, options);

  if (!data || !data.ingredients || !data.ingredients.items) {
    return { loading };
  }

  const items = data.ingredients.items;
  const totalCount = data.ingredients.totalCount;

  return { loading, items, totalCount };
}

export function useIngredient(id: string) {
  const { data } = useQuery<IngredientQuery, IngredientQueryVariables>(
    IngredientDocument,
    {
      variables: { id },
    }
  );

  return data && data.ingredient;
}

export function useIngredientUpdate(
  options?: MutationHookOptions<
    IngredientUpdateMutation,
    IngredientUpdateMutationVariables
  >
) {
  return useMutation(IngredientUpdateDocument, options);
}

export function useIngredientCreate(
  options?: MutationHookOptions<
    IngredientCreateMutation,
    IngredientCreateMutationVariables
  >
) {
  return useMutation(IngredientCreateDocument, options);
}

export function useIngredientDiscard(
  options?: MutationHookOptions<
    IngredientDiscardMutation,
    IngredientDiscardMutationVariables
  >
) {
  return useMutation(IngredientDiscardDocument, options);
}
