import {
  QueryHookOptions,
  useQuery,
  MutationHookOptions,
  useMutation,
  useLazyQuery,
} from "@apollo/client";
import {
  PlanningsQuery,
  PlanningsQueryVariables,
  PlanningsDocument,
  PlanningUpdateMutation,
  PlanningUpdateMutationVariables,
  PlanningUpdateDocument,
  PlanningCreateMutation,
  PlanningCreateMutationVariables,
  PlanningCreateDocument,
  PlanningDocument,
  PlanningQuery,
  PlanningQueryVariables,
  PlanningStatusDocument,
  PlanningStatusQuery,
  PlanningStatusQueryVariables,
  PlanningCloneMutation,
  PlanningCloneMutationVariables,
  PlanningCloneDocument,
  PlanningStatus,
  PlanningDestroyDocument,
  PlanningDestroyMutation,
  PlanningDestroyMutationVariables,
} from "../../graphql";
import { useIntl } from "react-intl";
import { useEffectOnce } from "react-use";
import { useCycleNumberOptions } from "./activities";
import { useState } from "react";
import { NEW_PLANNING_KEY } from "../../../components/agro/plannings/ProductTour";
import { usePermissions } from "../basic/users";
import { merge } from "lodash";

export function usePlanningStatusOptions() {
  const intl = useIntl();

  const [load, { loading, data }] = useLazyQuery<
    PlanningStatusQuery,
    PlanningStatusQueryVariables
  >(PlanningStatusDocument);

  const options = data?.__type?.enumValues
    ?.map((s) => ({
      key: s.name,
      value: s.name,
      label: intl.formatMessage({ id: `statuses.${s.name}` }),
    }))
    ?.sort((a, b) => a.label.localeCompare(b.label));

  return {
    load,
    loading,
    options,
  };
}

export const ActivePlanningStatuses = [
  PlanningStatus.Open,
  PlanningStatus.Planned,
  PlanningStatus.InProgress,
  PlanningStatus.Completed,
];

export function usePlannings(
  options?: QueryHookOptions<PlanningsQuery, PlanningsQueryVariables>,
  lazy?: boolean
) {
  const [load, { loading, data }] = useLazyQuery(PlanningsDocument, options);

  useEffectOnce(() => {
    if (!lazy) load();
  });

  if (!data || !data.plannings || !data.plannings.items) {
    return { loading };
  }

  const items = data.plannings.items;
  const totalCount = data.plannings.totalCount;

  return { loading, items, totalCount, load };
}

export function useCycleNumberPlannedOptions(
  options?: QueryHookOptions<PlanningsQuery, PlanningsQueryVariables>
) {
  const { options: cycleNumbers } = useCycleNumberOptions();
  const { load, items, loading } = usePlannings(options);

  return {
    load,
    loading,
    options: cycleNumbers.map(({ key, label }) => {
      const disabled = items?.some((p) => p.cycleNumber == key);
      return { key, label, disabled };
    }),
  };
}

export function usePlanning(id: string) {
  const showWage = usePermissions((p) => p.settings?.showWage);
  const { data, startPolling, stopPolling } = useQuery<
    PlanningQuery,
    PlanningQueryVariables
  >(PlanningDocument, { variables: { id, showWage } });

  return {
    planning: data?.planning,
    startPolling,
    stopPolling,
  };
}

export function usePlanningUpdate(
  options?: MutationHookOptions<
    PlanningUpdateMutation,
    PlanningUpdateMutationVariables
  >
) {
  const showWage = usePermissions((p) => p.settings?.showWage);
  const showVars = {
    variables: { showWage } as PlanningUpdateMutationVariables,
  };
  return useMutation(PlanningUpdateDocument, merge(options, showVars));
}

export function usePlanningCreate(
  options?: MutationHookOptions<
    PlanningCreateMutation,
    PlanningCreateMutationVariables
  >
) {
  return useMutation(PlanningCreateDocument, options);
}

export function usePlanningClone(
  options?: MutationHookOptions<
    PlanningCloneMutation,
    PlanningCloneMutationVariables
  >
) {
  return useMutation(PlanningCloneDocument, options);
}

export function usePlanningDestroy(
  options?: MutationHookOptions<
    PlanningDestroyMutation,
    PlanningDestroyMutationVariables
  >
) {
  return useMutation(PlanningDestroyDocument, options);
}

export function useSeenPlanningTour() {
  const [seenTour, setSeenTour] = useState<boolean | null>(false);

  useEffectOnce(() => {
    setSeenTour(!!localStorage.getItem(NEW_PLANNING_KEY));
  });

  return seenTour;
}
