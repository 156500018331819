import dayjs from "dayjs";
import { DateRangeType, SortDirection } from "../graphql";

const currentLocalityConfig = {
  currentLocality: true,
};

const tokenReportConfig = {
  ...currentLocalityConfig,
  noStream: true,
};

export const ExportConfigs = {
  ActivitySummaryReport: currentLocalityConfig,
  CropFieldSummaryReport: currentLocalityConfig,
  MachineSummaryReport: currentLocalityConfig,
  GeneralLedgerReport: undefined,
  JournalReport: undefined,
  LedgerReport: undefined,
  InventoryEntryReport: currentLocalityConfig,
  InventoryMovementReport: currentLocalityConfig,
  StockReport: currentLocalityConfig,
  TrialBalanceReport: undefined,
  BalanceSheetReport: undefined,
  TokenSummaryReport: tokenReportConfig,
  TokenPaidReport: tokenReportConfig,
  TokenRedeemedReport: tokenReportConfig,
  HarvestSummaryReport: currentLocalityConfig,
  HarvestDailyReport: currentLocalityConfig,
  PrePayrollReport: {
    ...currentLocalityConfig,
    filter: {
      date: { rangeType: DateRangeType.Month },
    },
  },
  purchase: {
    ...currentLocalityConfig,
    export: "Inventory::Orders",
    filter: { kind: ["purchase"] },
  },
  sale: {
    ...currentLocalityConfig,
    export: "Inventory::Orders",
    filter: { kind: ["sale"] },
  },
  services: {
    export: "Finance::Orders",
    filter: { kind: ["expense"] },
  },
  invoices: {
    ...currentLocalityConfig,
    export: "Finance::Invoices",
    sort: { dueDate: SortDirection.Desc },
  },
  accounts: {
    export: "Finance::Accounts",
  },
  movements: {
    ...currentLocalityConfig,
    export: "Inventory::Transfers",
    filter: { transferType: "movement" },
  },
  adjustments: {
    ...currentLocalityConfig,
    export: "Inventory::Transfers",
    filter: { transferType: "adjustment" },
  },
  WorkOrders: {
    ...currentLocalityConfig,
    export: "Agro::WorkOrders",
    filter: {
      documentDate: {
        rangeType: "range",
        range: [dayjs().startOf("year"), dayjs()],
      },
    },
  },
  WorkOrderDailyReport: currentLocalityConfig,
  InputCostCenterReport: currentLocalityConfig,
  ExpenseReport: currentLocalityConfig,
  IncomeStatementReport: currentLocalityConfig,
  Employees: currentLocalityConfig,
  Activities: { export: "Agro::Activities" },
  ActivityCatalog: { export: "Agro::ActivityCatalog" },
  HarvestEstimate: {
    export: "Agro::HarvestEstimate",
  },
  CropFields: { ...currentLocalityConfig, export: "Agro::CropFields" },
  Variants: { export: "Inventory::Variants" },
  MachinesReport: { ...currentLocalityConfig, export: "Reports::Machines" },
  Invoices: { export: "Reports::Finance::Invoices" },
  WeatherMeasurements: { export: "Agro::WeatherMeasurements" },
  ItemsCatalog: { export: "Inventory::ItemsCatalog" },
  Counterparties: { export: "Inventory::Counterparties" },
  Plannings: { ...currentLocalityConfig, export: "Reports::Agro::Plannings" },
  PayrollEntry: { export: "HR::PayrollEntry" },
  Users: { export: "Users" },
};

export type ExportType = keyof typeof ExportConfigs;
