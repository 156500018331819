import { Space as AntSpace, SpaceProps as AntSpaceProps } from "antd";

interface SpaceProps extends AntSpaceProps {
  vertical?: boolean;
  justify?: React.CSSProperties["justifyContent"];
}

export function Space({
  children,
  style,
  vertical,
  justify,
  ...props
}: SpaceProps) {
  const direction = vertical ? "vertical" : "horizontal";

  return (
    <AntSpace
      style={{ display: "flex", justifyContent: justify, ...style }}
      direction={direction}
      {...props}
    >
      {children}
    </AntSpace>
  );
}
