import { unionBy, groupBy, debounce } from "lodash";
import { PlusOutlined } from "@ant-design/icons";
import { Spin, Empty, Divider, Row, Col, Typography, Select } from "antd";
import { EmptyProps } from "antd/lib/empty";
import { ReactNode, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { SelectProps } from "antd/lib/select";
import { unaccentSearch } from "../../lib/formats";
import { useEffectOnce, useUpdateEffect } from "react-use";
import { QueryLazyOptions } from "@apollo/client";
import classNames from "classnames";

export interface SelectOption {
  key: string | number | boolean;
  label: React.ReactNode;
  group?: string;
  children?: React.ReactNode;
  [key: string]: any;
}

interface NewItemProps {
  entityName: ReactNode;
  onClick(): void;
}

interface OptionsHookReturnType {
  load?: (options?: QueryLazyOptions<any>) => void;
  search?: (value?: string) => void;
  options?: any[];
  loading?: boolean;
}

export interface SelectFieldProps
  extends Omit<SelectProps<any>, "options" | "onChange"> {
  showGroups?: boolean;
  groupsSorter?(a: string, b: string): number;
  showSearch?: boolean;
  defaultValues?: SelectOption[] | null;
  emptyProps?: EmptyProps;
  newItemProps?: NewItemProps;
  options?: SelectOption[] | null | undefined;
  labelRenderer?: (label: ReactNode, option: SelectOption) => ReactNode;
  optionsHook?: (params?: any) => OptionsHookReturnType;
  optionsHookFilter?: (option: any) => boolean;
  onChange?: (value: any, options: any) => void;
  optionsHookParams?: any;
  formatOption?: (content: ReactNode, option: any) => ReactNode;
  refetchOnOpen?: boolean;
}

export function SelectField({
  onSearch,
  loading,
  options,
  defaultValues,
  showGroups,
  groupsSorter = (a, b) => a.localeCompare(b),
  showSearch = true,
  newItemProps,
  emptyProps,
  onChange,
  labelRenderer = (label) => label,
  optionsHook = () => ({}),
  optionsHookFilter = () => true,
  optionsHookParams,
  formatOption = (content) => content,
  refetchOnOpen,
  className,
  optionFilterProp = "label",
  ...restProps
}: SelectFieldProps) {
  const {
    load,
    search,
    options: hookOptions = [],
    loading: hookLoading,
  } = optionsHook(optionsHookParams);
  const [defaultOptionsFetched, setDefaultOptionsFetched] = useState(false);

  const dataLoading = loading || hookLoading;

  const searchFunc = onSearch || search;
  const debouncedSearch = searchFunc && debounce(searchFunc, 500);

  const opts = unionBy(hookOptions, options, defaultValues, "key").filter(
    optionsHookFilter
  );
  const defaultValue = defaultValues && defaultValues.map((v) => v.key);

  const fetchDefaultOptions = useCallback(() => {
    setDefaultOptionsFetched(true);

    // provide any variables to refetch data from network instead of fetching from cache
    // https://github.com/apollographql/apollo-client/blob/main/src/core/ObservableQuery.ts#L809
    if (load)
      load(optionsHookParams || { variables: { filter: { id: undefined } } });
    else if (searchFunc) searchFunc("");
  }, [load, optionsHookParams, searchFunc]);

  // preload selected options on render if value is present and no default value provided
  useEffectOnce(() => {
    if (
      !restProps.value ||
      restProps.labelInValue ||
      defaultValue ||
      !load ||
      (Array.isArray(restProps.value) && !restProps.value.length)
    ) {
      return;
    }

    load({ variables: { filter: { id: restProps.value } } });
  });

  // reload if optionHookParams are changed
  useUpdateEffect(() => {
    if (defaultOptionsFetched) fetchDefaultOptions();
  }, [optionsHookParams]);

  return (
    <Select
      showArrow={true}
      allowClear={true}
      notFoundContent={
        dataLoading ? (
          <Spin size="small" />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} {...emptyProps} />
        )
      }
      loading={dataLoading}
      showSearch={showSearch}
      defaultValue={restProps.mode && !defaultValue ? [] : defaultValue}
      onSearch={
        debouncedSearch &&
        ((v) => (v === "" ? fetchDefaultOptions() : debouncedSearch(v)))
      }
      filterOption={(input, option) =>
        !searchFunc && option
          ? unaccentSearch(input, option[optionFilterProp])
          : true
      }
      optionFilterProp={optionFilterProp}
      optionLabelProp="label"
      dropdownRender={
        newItemProps
          ? (menu) => (
              <SelectField.NewDropdown menu={menu} props={newItemProps} />
            )
          : undefined
      }
      onChange={(val) =>
        onChange &&
        onChange(val != undefined ? val : restProps.mode ? [] : null, opts)
      }
      onDropdownVisibleChange={(open) => {
        // load default options on open when not loaded
        // and when mode is set to `tags` or `multiple` (ex: WO employees selector)
        if ((open && !defaultOptionsFetched) || restProps.mode || refetchOnOpen)
          fetchDefaultOptions();
      }}
      onClear={fetchDefaultOptions}
      className={classNames("notranslate", className)}
      popupClassName="notranslate"
      {...restProps}
    >
      {showGroups &&
        Object.entries(groupBy(opts, "group"))
          .sort(([a], [b]) => groupsSorter(a, b))
          .map(([group, opts]) => (
            <Select.OptGroup key={group} label={group}>
              {opts.map((o) => (
                <Select.Option
                  key={o.key}
                  value={o.key}
                  label={labelRenderer(o.label, o)}
                  disabled={o.disabled}
                >
                  {formatOption(o.children || o.label, o)}
                </Select.Option>
              ))}
            </Select.OptGroup>
          ))}
      {!showGroups &&
        opts.map((o) => (
          <Select.Option
            key={o.key}
            value={o.key}
            disabled={o.disabled}
            label={labelRenderer(o.label, o)}
          >
            {formatOption(o.children || o.label, o)}
          </Select.Option>
        ))}
    </Select>
  );
}

SelectField.NewDropdown = ({
  menu,
  props: { entityName, onClick },
}: {
  menu: ReactNode;
  props: NewItemProps;
}) => {
  return (
    <SelectField.DropdownFooter menu={menu} onClick={onClick}>
      <PlusOutlined />{" "}
      <FormattedMessage id="new.header" values={{ entityName }} />
    </SelectField.DropdownFooter>
  );
};

SelectField.DropdownFooter = ({
  menu,
  onClick,
  mode = "footer",
  children,
}: {
  menu: ReactNode;
  onClick: () => void;
  mode?: "footer" | "header";
  children: ReactNode;
}) => {
  const content = (
    <div
      className="select-new-item"
      onMouseDown={(e) => e.preventDefault()}
      onClick={onClick}
    >
      {children}
    </div>
  );

  const [top, bottom] = mode === "footer" ? [menu, content] : [content, menu];

  return (
    <>
      {top}
      <Divider style={{ margin: "4px 0" }} />
      {bottom}
    </>
  );
};

SelectField.DropdownHeader = ({
  menu,
  left,
  right,
}: {
  menu: ReactNode;
  left?: ReactNode;
  right?: ReactNode;
}) => (
  <>
    <div style={{ margin: "8px 12px 0px 12px" }}>
      <Row justify="space-between">
        <Col span={12}>
          {left && (
            <Typography.Title style={{ fontSize: "12px" }}>
              {left}
            </Typography.Title>
          )}
        </Col>
        <Col span={12}>
          {right && (
            <Typography.Title
              style={{
                fontSize: "12px",
                textAlign: "right",
              }}
            >
              {right}
            </Typography.Title>
          )}
        </Col>
      </Row>
    </div>
    {menu}
  </>
);
