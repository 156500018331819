import { QueryHookOptions, useLazyQuery, useQuery } from "@apollo/client";

import {
  InventoryMovementReportQuery,
  InventoryMovementReportQueryVariables,
  InventoryMovementReportDocument,
  InventoryEntryCodeTypeQuery,
  InventoryEntryCodeTypeDocument,
} from "../../graphql";
import { useIntl } from "react-intl";

export function useInventoryEntryCodeOptions() {
  const intl = useIntl();
  const [load, { data, loading }] = useLazyQuery<InventoryEntryCodeTypeQuery>(
    InventoryEntryCodeTypeDocument
  );

  return {
    load,
    loading,
    options:
      data?.__type?.enumValues?.map((c) => ({
        key: c.name,
        label: intl.formatMessage({ id: `inventoryEntryCodes.${c.name}` }),
      })) || [],
  };
}

export function useInventoryMovementReport(
  options?: QueryHookOptions<
    InventoryMovementReportQuery,
    InventoryMovementReportQueryVariables
  >
) {
  const { loading, data } = useQuery(InventoryMovementReportDocument, options);

  if (
    !data ||
    !data.inventoryMovementReport ||
    !data.inventoryMovementReport.items
  ) {
    return { loading };
  }

  const items = data.inventoryMovementReport.items;
  const totalCount = data.inventoryMovementReport.totalCount;

  return { loading, items, totalCount };
}
