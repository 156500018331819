import { useDebounce } from "react-use";
import { useRef } from "react";
import { Form } from "antd";

function InternalSubmit({
  values,
  submit,
}: {
  values: any;
  submit: () => void;
}) {
  useDebounce(
    () => {
      if (values) submit();
    },
    300,
    [values]
  );

  return null;
}
export function AutoSubmit() {
  const values = useRef<any>();

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prev, next) => {
        if (prev != next) {
          values.current = next;
        }
        return prev != next;
      }}
    >
      {({ submit }) => (
        <InternalSubmit values={values.current} submit={submit} />
      )}
    </Form.Item>
  );
}
