import { QueryHookOptions, useQuery } from "@apollo/client";
import {
  IncomeStatementReportDocument,
  IncomeStatementReportQuery,
  IncomeStatementReportQueryVariables,
} from "../../graphql";
import { buildTree } from "./treeBuilder";

export function useIncomeStatementReport(
  options?: QueryHookOptions<
    IncomeStatementReportQuery,
    IncomeStatementReportQueryVariables
  >
) {
  const { loading, data } = useQuery(IncomeStatementReportDocument, options);

  if (
    !data ||
    !data.incomeStatementReport ||
    !data.incomeStatementReport.items
  ) {
    return { loading };
  }

  const items = buildTree(data.incomeStatementReport.items);

  return { loading, items };
}
