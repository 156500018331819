import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
} from "@apollo/client";

import {
  CommentsDocument,
  CommentsQuery,
  CommentUpdateDocument,
  CommentsQueryVariables,
  CommentUpdateMutationVariables,
  CommentUpdateMutation,
  CommentCreateMutation,
  CommentCreateMutationVariables,
  CommentCreateDocument,
  CommentDiscardMutation,
  CommentDiscardMutationVariables,
  CommentDiscardDocument,
  CommentQuery,
  CommentQueryVariables,
  CommentDocument,
} from "../../graphql";

export function useComments(
  options?: QueryHookOptions<CommentsQuery, CommentsQueryVariables>
) {
  const { loading, data } = useQuery(CommentsDocument, options);

  if (!data || !data.comments || !data.comments.items) {
    return { loading };
  }

  const items = data.comments.items;
  const totalCount = data.comments.totalCount;

  return { loading, items, totalCount };
}

export function useComment(id: string) {
  const { data } = useQuery<CommentQuery, CommentQueryVariables>(
    CommentDocument,
    { variables: { id } }
  );

  return data && data.comment;
}

export function useCommentUpdate(
  options?: MutationHookOptions<
    CommentUpdateMutation,
    CommentUpdateMutationVariables
  >
) {
  return useMutation(CommentUpdateDocument, options);
}

export function useCommentCreate(
  options?: MutationHookOptions<
    CommentCreateMutation,
    CommentCreateMutationVariables
  >
) {
  return useMutation(CommentCreateDocument, options);
}

export function useCommentDiscard(
  options?: MutationHookOptions<
    CommentDiscardMutation,
    CommentDiscardMutationVariables
  >
) {
  return useMutation(CommentDiscardDocument, options);
}
