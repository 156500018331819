import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { ActivityPaymentFragment, Currency, Unit, WorkUnit } from "../graphql";
import { NumberFormatOptions } from "@formatjs/ecma402-abstract";
import { Switch } from "antd";
import { useCurrentUser } from "../hooks";
import { useEffect, useState } from "react";
import { DateTimeType } from "./dates";
import { useExchangeRate } from "../hooks/basic/exchangeRates";

const DEFAULT_MONEY_OPTS: NumberFormatOptions = {
  style: "currency",
  currencyDisplay: "narrowSymbol",
  maximumFractionDigits: 2,
};

export function formatMoneyAmount(
  amount: number | null | undefined,
  currencyCode: string,
  opts?: NumberFormatOptions
) {
  if (amount == undefined) return null;

  return (
    <FormattedNumber
      value={amount}
      {...DEFAULT_MONEY_OPTS}
      currency={currencyCode}
      {...opts}
    />
  );
}

export function useFormatMoney() {
  const intl = useIntl();
  const { currentTenant } = useCurrentUser();

  return (
    amount: number,
    currency = currentTenant.currencyCode,
    opts?: NumberFormatOptions
  ) => intl.formatNumber(amount, { ...DEFAULT_MONEY_OPTS, currency, ...opts });
}

export function formatCurrency(
  currency: Pick<Currency, "isoCode" | "name" | "symbol">,
  format: "short" | "long" = "long"
) {
  if (format == "long") return `${currency.isoCode} - ${currency.name}`;

  return `${currency.isoCode} (${currency.symbol})`;
}

export function formatLabelInCurrency(
  label: React.ReactNode,
  currency: Pick<Currency, "symbol">
) {
  return (
    <>
      {label} ({currency.symbol})
    </>
  );
}

export function formatWage(
  payment: Pick<ActivityPaymentFragment, "wage" | "wageUnit">,
  currencyCode: string,
  progressUnit?: Pick<Unit, "abbr">
) {
  if (payment.wage == undefined) return;

  return (
    <>
      {formatMoneyAmount(payment.wage, currencyCode)}
      {" / "}
      {formatWageUnit(payment, progressUnit)}
    </>
  );
}

export function formatWageUnit(
  payment: Pick<ActivityPaymentFragment, "wage" | "wageUnit">,
  progressUnit?: Pick<Unit, "abbr">
) {
  return payment.wageUnit === WorkUnit.Piecework && progressUnit ? (
    progressUnit.abbr
  ) : (
    <FormattedMessage {...{ id: `workUnits.abbr.${payment.wageUnit}` }} />
  );
}

export function useCurrencySwitch(date?: DateTimeType) {
  const { currentTenant } = useCurrentUser();
  const secondaryCurrency = currentTenant.secondaryCurrency;
  const [currentCurrency, setCurrentCurrencyCode] = useState(
    currentTenant.currency
  );
  const loadExchangeRate = useExchangeRate();
  const [exchangeRate, setExchangeRate] = useState<number>(1);

  useEffect(() => {
    if (!date || !secondaryCurrency) return;

    loadExchangeRate({
      from: currentTenant.currencyCode,
      to: secondaryCurrency.isoCode,
      date,
    }).then((result) => setExchangeRate(result.data.exchangeRate || 1));
  }, [date, secondaryCurrency, currentTenant.currencyCode, loadExchangeRate]);

  const isChanged = currentCurrency.isoCode === secondaryCurrency?.isoCode;

  return {
    currentCurrency,
    currencySwitch: secondaryCurrency ? (
      <Switch
        checked={isChanged} // save switch state on unmount
        checkedChildren={secondaryCurrency.isoCode}
        unCheckedChildren={secondaryCurrency.isoCode}
        onChange={(val) =>
          setCurrentCurrencyCode(
            val ? secondaryCurrency : currentTenant.currency
          )
        }
      />
    ) : null,
    exchangeRate: isChanged ? exchangeRate : 1,
  };
}
