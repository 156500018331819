import { InputNumberProps as $InputNumberProps } from "antd/lib/input-number";
import { ReactNode, CSSProperties } from "react";
import { InputNumber as $InputNumber, Input } from "antd";
import { Popover, PopoverProps } from "../shared";

export type InputNumberProps = Omit<$InputNumberProps, "onChange"> & {
  addonBefore?: ReactNode;
  addonAfter?: ReactNode;
  per?: ReactNode;
  inputStyle?: CSSProperties;
  prefix?: ReactNode;
  suffix?: ReactNode;
  popoverProps?: PopoverProps | false;
  onChange?: (value?: number | null) => void;
};

export function InputNumber({
  style,
  inputStyle,
  className,
  addonBefore,
  addonAfter,
  per,
  prefix,
  suffix,
  popoverProps,
  onChange,
  ...restProps
}: InputNumberProps) {
  let input = (
    <$InputNumber
      style={{ width: "100%", ...inputStyle }}
      {...restProps}
      onChange={(val) =>
        // undefined values are dropped on submit, better to use null
        onChange && onChange(val == undefined ? null : Number(val))
      }
    />
  );

  if (prefix || suffix) {
    input = (
      <span
        className={`ant-input-affix-wrapper ant-input-number-affix ${
          restProps.disabled ? "ant-input-number-disabled" : ""
        }`}
      >
        {prefix && <span className="ant-input-prefix">{prefix}</span>}
        {input}
        {suffix && <span className="ant-input-suffix">{suffix}</span>}
      </span>
    );
  }

  if (popoverProps) {
    input = (
      <Popover overlayStyle={{ maxWidth: "400px" }} {...popoverProps}>
        {input}
      </Popover>
    );
  }

  if (addonBefore || addonAfter || per) {
    return (
      <Input.Group className={className} style={style}>
        {addonBefore && (
          <span className="ant-input-group-addon">{addonBefore}</span>
        )}
        {input}
        {addonAfter && (
          <span className="ant-input-group-addon">{addonAfter}</span>
        )}
        {per && <span className="ant-input-group-addon">{per}</span>}
      </Input.Group>
    );
  }

  return input;
}
