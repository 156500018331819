import { QueryHookOptions, useQuery } from "@apollo/client";
import {
  WorkOrderDailyReportQuery,
  WorkOrderDailyReportQueryVariables,
  WorkOrderDailyReportDocument,
} from "../../graphql";

export function useWorkOrderDailyReport(
  options?: QueryHookOptions<
    WorkOrderDailyReportQuery,
    WorkOrderDailyReportQueryVariables
  >
) {
  const { loading, data } = useQuery(WorkOrderDailyReportDocument, options);

  if (!data || !data.workOrderDailyReport || !data.workOrderDailyReport.items) {
    return { loading };
  }

  const items = data.workOrderDailyReport.items;
  const totalCount = data.workOrderDailyReport.totalCount;

  return { loading, items, totalCount };
}
