import { QueryHookOptions, useQuery } from "@apollo/client";
import {
  InputCostCenterReportQuery,
  InputCostCenterReportQueryVariables,
  InputCostCenterReportDocument,
} from "../../graphql";

export function useInputCostCenterReport(
  options?: QueryHookOptions<
    InputCostCenterReportQuery,
    InputCostCenterReportQueryVariables
  >
) {
  const { loading, data } = useQuery(InputCostCenterReportDocument, options);

  if (
    !data ||
    !data.inputCostCenterReport ||
    !data.inputCostCenterReport.items
  ) {
    return { loading };
  }

  const items = data.inputCostCenterReport.items;
  const totalCount = data.inputCostCenterReport.totalCount;

  return { loading, items, totalCount };
}
