import { cloneDeep } from "lodash";
import { CropFieldFragment } from "../../../lib/graphql";

export type FragmentWithGeoJson = Pick<
  CropFieldFragment,
  "id" | "name" | "geoJson"
>;

export function buildCropFieldFeatureCollection(items?: FragmentWithGeoJson[]) {
  return {
    type: "FeatureCollection" as GeoJSON.FeatureCollection["type"],
    features: !items
      ? []
      : items
          .filter((g) => !!g.geoJson)
          .flatMap((c) => {
            const feature = cloneDeep(
              c.geoJson as GeoJSON.FeatureCollection<GeoJSON.Polygon>
            );

            return feature.features.map((f) => ({
              ...f,
              properties: {
                id: c.id,
                name: c.name,
              } as Record<string, any>,
            }));
          }),
  };
}
