import { QueryHookOptions, useQuery } from "@apollo/client";
import {
  HarvestSummaryReportQuery,
  HarvestSummaryReportQueryVariables,
  HarvestSummaryReportDocument,
  HarvestCropFieldReportQuery,
  HarvestCropFieldReportQueryVariables,
  HarvestCropFieldReportDocument,
  HarvestDailyReportQuery,
  HarvestDailyReportQueryVariables,
  HarvestDailyReportDocument,
  HarvestOverviewReportQuery,
  HarvestOverviewReportDocument,
  HarvestOverviewReportQueryVariables,
} from "../../graphql";

export function useHarvestSummaryReport(
  options?: QueryHookOptions<
    HarvestSummaryReportQuery,
    HarvestSummaryReportQueryVariables
  >
) {
  const { loading, data } = useQuery(HarvestSummaryReportDocument, options);

  if (!data || !data.harvestSummaryReport || !data.harvestSummaryReport.items) {
    return { loading };
  }

  const items = data.harvestSummaryReport.items;
  const totalCount = data.harvestSummaryReport.totalCount;

  return { loading, items, totalCount };
}

export function useHarvestCropFieldReport(
  options?: QueryHookOptions<
    HarvestCropFieldReportQuery,
    HarvestCropFieldReportQueryVariables
  >
) {
  const { loading, data } = useQuery(HarvestCropFieldReportDocument, options);

  if (
    !data ||
    !data.harvestCropFieldReport ||
    !data.harvestCropFieldReport.items
  ) {
    return { loading };
  }

  const items = data.harvestCropFieldReport.items;
  const totalCount = data.harvestCropFieldReport.totalCount;

  return { loading, items, totalCount };
}

export function useHarvestDailyReport(
  options?: QueryHookOptions<
    HarvestDailyReportQuery,
    HarvestDailyReportQueryVariables
  >
) {
  const { loading, data } = useQuery(HarvestDailyReportDocument, options);

  if (!data || !data.harvestDailyReport || !data.harvestDailyReport.items) {
    return { loading };
  }

  const items = data.harvestDailyReport.items;
  const totalCount = data.harvestDailyReport.totalCount;

  return { loading, items, totalCount };
}

export function useHarvestOverviewReport(
  options?: QueryHookOptions<
    HarvestOverviewReportQuery,
    HarvestOverviewReportQueryVariables
  >
) {
  const { loading, data } = useQuery(HarvestOverviewReportDocument, options);

  return { loading, report: data?.harvestOverviewReport };
}
