import BasicLayout from "../../layouts/BasicLayout";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import {
  CROP_STAGE_COLOR_MAP,
  useActivityCostCenterReport,
  useActivityOptions,
  useCropFields,
  useCropStageTypeFormat,
  useCurrentUser,
  useCycleNumberOptions,
  useLocality,
  usePermissions,
} from "../../lib/hooks";
import routes from "../../lib/routes";
import React, { PropsWithChildren, useMemo, useState } from "react";
import Link from "next/link";
import { Button, Col, Row, Select, Spin, Typography } from "antd";
import {
  CropFieldFragment,
  CropFieldSummaryReportFragment,
  CropStageType,
  CurrencyFragment,
  CurrentTenantFragment,
  ActivityCostCenterReportFragment,
  WorkOrderType,
  ActivityFragment,
  LocalityFragment,
  PermissionsFragment,
} from "../../lib/graphql";

import {
  BLANK_SYMBOL,
  colors,
  formatDetailsLink,
  formatMoneyAmount,
  formatNumber,
  formatPer,
  formatProgress,
  formatTags,
  formatUnitValue,
  useCurrencySwitch,
  useEstimationUnitSwitch,
} from "../../lib/formats";
import { buildCropFieldFeatureCollection } from "../../components/agro/localities/utils";
import { mean as meanFn, merge, sum } from "lodash";
import { useCropFieldSummaryReport } from "../../lib/hooks/reports/cropFieldReport";
import { SelectField } from "../../components/form";
import dynamic from "next/dynamic";
import { Space } from "../../components/shared";
import { LayerProps } from "react-map-gl";
import { LayersProps, MapViewProps } from "../../components/shared/MapView";
import {
  symbolLayout,
  symbolPaint,
} from "../../components/shared/MapInput/utils";

const MapView = dynamic(() => import("../../components/shared/MapView"), {
  ssr: false,
  loading: () => <Spin />,
});

const messages = defineMessages({
  pageTitle: {
    id: "localities.map",
    defaultMessage: "Farm Map",
  },
  cropStagesLayer: {
    id: "cropStages",
    defaultMessage: "Crop Stages",
  },
  harvestYieldLayer: {
    id: "localities.map.harvestYield",
    defaultMessage: "Harvest Yield",
  },
});

const layerOptions = [
  {
    value: "cropStages",
    label: (
      <FormattedMessage
        id="localities.map.cropStages"
        defaultMessage="Crop Stages"
      />
    ),
  },
  {
    value: "yields",
    label: (
      <FormattedMessage
        id="localities.map.harvestYield"
        defaultMessage="Harvest Yield"
      />
    ),
  },
  {
    value: "costs",
    label: <FormattedMessage id="costs" defaultMessage="Costs" />,
  },
  {
    value: "activities",
    label: <FormattedMessage id="activities" defaultMessage="Activities" />,
  },
] as const;

const values = layerOptions.map((l) => l.value);

type layerValue = (typeof values)[0];

const layerFillOpacity = 0.8;

export function canAccessMap(permissions?: PermissionsFragment) {
  if (!permissions || !permissions.farmMap) return false;

  return Object.entries(permissions.farmMap)
    .filter(([k]) => k !== "__typename")
    .some(([, v]) => v);
}

export default function FarmMap() {
  const intl = useIntl();
  const { currentLocalityId, currentCropCycleIds, user, currentTenant } =
    useCurrentUser();

  const visibleLayers = layerOptions.filter(
    (l) =>
      user?.permissions?.farmMap?.[l.value] &&
      (l.value !== "yields" || currentTenant.features.advancedActivities)
  );

  const defaultValue = visibleLayers[0]?.value;

  const [layer, setLayer] = useState<layerValue>(defaultValue);
  const { showInEstimationUnits, showInEstimationUnitsSwitch } =
    useEstimationUnitSwitch();
  const [activity, setActivity] = useState<ActivityFragment>();
  const [cycleNumber, setCycleNumber] = useState<number>();
  const { currentCurrency, currencySwitch } = useCurrencySwitch();
  const { locality } = useLocality(currentLocalityId);

  function changeLayer(layer: layerValue) {
    setLayer(layer);
    setActivity(undefined);
    setCycleNumber(undefined);
  }

  return (
    <BasicLayout
      title={intl.formatMessage(messages.pageTitle)}
      breadcrumbs={[
        {
          path: routes.localities.index,
          breadcrumbName: intl.formatMessage(messages.pageTitle),
        },
      ]}
      authorize={canAccessMap}
    >
      {!user || !currentLocalityId ? (
        <Spin />
      ) : !locality?.geoJson ? (
        <AddFarmButton />
      ) : (
        <Space vertical>
          <Space>
            <Typography.Text>
              <FormattedMessage
                id="maps.selectLayer"
                defaultMessage="Select Layer"
              />
            </Typography.Text>

            <Select
              defaultValue={defaultValue}
              options={visibleLayers}
              onChange={changeLayer}
              style={{ minWidth: 150 }}
            />

            {layer === "activities" && (
              <Space>
                <SelectField
                  showGroups
                  optionsHook={useActivityOptions}
                  optionsHookParams={{
                    variables: {
                      filter: {
                        workOrderType: [WorkOrderType.FieldWork],
                      },
                    },
                  }}
                  placeholder={
                    <FormattedMessage
                      id="activities.select"
                      defaultMessage="Select activity"
                    />
                  }
                  onChange={(
                    val: string,
                    opts: ReturnType<typeof useActivityOptions>["options"]
                  ) => {
                    const activity = opts.find((o) => o.key === val)?.activity;
                    setActivity(activity);
                  }}
                  style={{ minWidth: 150 }}
                />

                <SelectField
                  optionsHook={useCycleNumberOptions}
                  onChange={setCycleNumber}
                  placeholder={
                    <FormattedMessage
                      id="cycleNumber.select"
                      defaultMessage="Select cycle number"
                    />
                  }
                  style={{ minWidth: 100 }}
                />
              </Space>
            )}

            {["costs", "activities", "yields"].includes(layer) &&
              currencySwitch}

            {layer === "yields" && showInEstimationUnitsSwitch}
          </Space>

          <div style={{ height: "70vh" }}>
            <Map
              currentCurrency={currentCurrency}
              locality={locality}
              cropCycleId={currentCropCycleIds}
              layer={layer}
              showInEstimationUnits={showInEstimationUnits}
              activity={activity}
              cycleNumber={cycleNumber}
            />
          </div>

          <Space>
            {user.permissions.locality?.write && (
              <Link
                href={routes.localities.edit(currentLocalityId)}
                legacyBehavior
              >
                <Button
                  type="primary"
                  href={routes.localities.edit(currentLocalityId)}
                  size="large"
                >
                  <FormattedMessage
                    id="localities.editMap"
                    defaultMessage="Edit farm map"
                  />
                </Button>
              </Link>
            )}

            {user.permissions.cropField?.write && (
              <Link href={routes.agro.cropFields.index} legacyBehavior>
                <Button
                  type="primary"
                  href={routes.agro.cropFields.index}
                  size="large"
                >
                  <FormattedMessage
                    id="cropFields.addMap"
                    defaultMessage="Edit crop field maps"
                  />
                </Button>
              </Link>
            )}
          </Space>
        </Space>
      )}
    </BasicLayout>
  );
}

const cropFieldLabels: LayerProps = {
  id: "crop-field-labels",
  type: "symbol",
  source: "cropFields",
  paint: symbolPaint,
  layout: symbolLayout,
};

function Map({
  locality,
  mapProps,
  layer,
  showInEstimationUnits,
  cropCycleId,
  currentCurrency,
  activity,
  cycleNumber,
}: {
  locality?: LocalityFragment;
  mapProps?: MapViewProps;
  layer: layerValue;
  showInEstimationUnits?: boolean;
  cropCycleId?: string[];
  currentCurrency: CurrencyFragment;
  activity?: ActivityFragment;
  cycleNumber?: number;
}) {
  const currencyCode = currentCurrency.isoCode;
  const showCost = usePermissions((p) => !!p.settings?.showCost);

  const { items, loading } = useCropFields({
    skip: !locality,
    variables: {
      filter: { localityId: locality ? [locality.id] : undefined },
      pageSize: 1000,
    },
  });

  const cropFieldSummaryLayer = ["yields", "costs"].includes(layer);

  const { items: cropFieldReportItems, loading: cropFieldsReportLoading } =
    useCropFieldSummaryReport({
      variables: {
        filter: {
          cropFieldId: items?.filter((i) => !!i.geoJson).map((i) => i.id),
          showInEstimationUnits,
          cropCycleId,
          currencyCode,
        },
        pageSize: 1000,
        showCost,
      },
      skip: loading || !cropFieldSummaryLayer,
    });

  const activitiesLayer = layer === "activities";

  const { loading: activityReportLoading, items: activityReportItems } =
    useActivityCostCenterReport({
      skip: loading || !activitiesLayer || !activity?.id,
      variables: {
        pageSize: 1000,
        filter: {
          cropFieldId: items?.filter((i) => !!i.geoJson).map((i) => i.id),
          activityId: activity?.id ? [activity.id] : undefined,
          cycleNumber: cycleNumber ? [cycleNumber] : undefined,
          cropCycleId,
          localityId: locality ? [locality.id] : undefined,
          currencyCode,
        },
        showCost,
      },
    });

  const cropFieldItems = useMemo(
    () =>
      cropFieldReportItems?.map((h) => ({
        ...h,
        harvestYield: h.harvestYield > 0 ? h.harvestYield : -1, // -1 is used show 0 values as NO DATA
      })),
    [cropFieldReportItems]
  );

  const layerConfig = useLayerConfig({
    layer,
    items,
    cropFieldItems,
    cropFieldsReportLoading,
    activityReportItems,
    activityReportLoading,
    loading,
    showInEstimationUnits,
    locality,
    currencyCode,
    activity,
  });

  const source = useSource({
    layer,
    items,
    cropFieldItems,
    cropFieldsReportLoading,
    activityReportItems,
    activityReportLoading,
    loading,
  });

  if (
    (loading && !items?.length) ||
    (cropFieldsReportLoading && !cropFieldItems?.length) ||
    (activityReportLoading && !activityReportItems?.length)
  )
    return <Spin />;

  // this is necessary to trigger legend update
  const currentLayer =
    layer === "costs"
      ? `costs-${currencyCode}`
      : layer === "yields"
      ? formatHarvestYieldLayerID(cropFieldItems?.[0]?.harvestUnit?.abbr)
      : layer;

  return (
    <MapView
      {...mapProps}
      sources={[
        {
          id: "cropFields",
          type: "geojson",
          data: source,
        },
      ]}
      layers={[cropFieldLabels, layerConfig].filter(Boolean) as LayersProps}
      currentLayer={currentLayer}
      geoJsonBoundaries={{ data: locality?.geoJson }}
      geoJsonLayers={[
        {
          data: locality?.geoJson,
          type: "line",
          paint: {
            "line-color": colors.successColor,
            "line-width": 2,
          },
        },
      ]}
    />
  );
}

function useLayerConfig({
  layer,
  items,
  cropFieldItems,
  cropFieldsReportLoading,
  activityReportItems,
  activityReportLoading,
  loading,
  showInEstimationUnits,
  locality,
  currencyCode,
  activity,
}: {
  layer: layerValue;
  items?: CropFieldFragment[];
  cropFieldItems?: CropFieldSummaryReportFragment[];
  cropFieldsReportLoading?: boolean;
  activityReportItems?: ActivityCostCenterReportFragment[];
  activityReportLoading?: boolean;
  loading?: boolean;
  showInEstimationUnits?: boolean;
  locality?: LocalityFragment;
  currencyCode: string;
  activity?: ActivityFragment;
}) {
  const intl = useIntl();
  const { formatCropStageType } = useCropStageTypeFormat();

  if (loading || cropFieldsReportLoading || activityReportLoading) {
    return undefined;
  }

  switch (layer) {
    case "yields":
      return harvestLayer({
        cropFields: items,
        items: cropFieldItems?.filter(
          (h) => !showInEstimationUnits || h.hasEstimation
        ),
        locality,
        formatMessage: intl.formatMessage,
        currencyCode,
      });
    case "cropStages":
      return cropStageLayer({
        cropFields: items,
        formatMessage: intl.formatMessage,
        formatCropStageType,
      });
    case "costs":
      return costsLayer({
        cropFields: items,
        currencyCode,
        items: cropFieldItems,
        formatMessage: intl.formatMessage,
      });
    case "activities":
      return activityLayer({
        activity,
        currencyCode,
        cropFields: items,
        activityItems: activityReportItems,
        formatMessage: intl.formatMessage,
      });
  }
}

function useSource({
  layer,
  items,
  cropFieldItems,
  cropFieldsReportLoading,
  activityReportItems,
  activityReportLoading,
  loading,
}: {
  layer: layerValue;
  items: CropFieldFragment[];
  cropFieldItems?: CropFieldSummaryReportFragment[];
  cropFieldsReportLoading?: boolean;
  activityReportItems?: ActivityCostCenterReportFragment[];
  activityReportLoading?: boolean;
  loading?: boolean;
}) {
  const cropFieldFeatures = useMemo(() => {
    if (loading) {
      return undefined;
    }

    return buildCropFieldFeatureCollection(items);
  }, [items, loading]);

  const cropStageSource = useMemo<typeof cropFieldFeatures>(() => {
    if (!cropFieldFeatures) return undefined;

    return {
      type: "FeatureCollection",
      features: cropFieldFeatures.features.map((feature) => {
        if (!items) return feature;

        const cropField = items.find((i) => i.id === feature.properties.id);

        return merge({}, feature, {
          properties: {
            kind: cropField?.cropStage?.kind,
          },
        });
      }),
    };
  }, [cropFieldFeatures, items]);

  const harvestYieldsSource = useMemo<typeof cropFieldFeatures>(() => {
    if (!cropFieldFeatures || cropFieldsReportLoading) {
      return undefined;
    }

    return {
      type: "FeatureCollection",
      features: cropFieldFeatures.features.map((feature) => {
        if (!cropFieldItems) return feature;

        const item = cropFieldItems.find(
          (i) => i.cropField.id === feature.properties.id
        );

        return merge({}, feature, {
          properties: {
            harvestYield: item?.harvestYield,
          },
        });
      }),
    };
  }, [cropFieldFeatures, cropFieldItems, cropFieldsReportLoading]);

  const costsSource = useMemo<typeof cropFieldFeatures>(() => {
    if (!cropFieldFeatures || cropFieldsReportLoading) {
      return undefined;
    }

    return {
      type: "FeatureCollection",
      features: cropFieldFeatures.features.map((feature) => {
        if (!cropFieldItems) return feature;

        const item = cropFieldItems.find(
          (i) => i.cropField.id === feature.properties.id
        );

        return merge({}, feature, {
          properties: {
            totalCost: item?.totalCost,
          },
        });
      }),
    };
  }, [cropFieldFeatures, cropFieldItems, cropFieldsReportLoading]);

  const activitiesSource = useMemo<typeof cropFieldFeatures>(() => {
    if (
      !cropFieldFeatures ||
      cropFieldsReportLoading ||
      activityReportLoading
    ) {
      return undefined;
    }

    return {
      type: "FeatureCollection",
      features: cropFieldFeatures.features.map((feature) => {
        if (!activityReportItems || !activityReportItems.length) return feature;

        const item = activityReportItems.find(
          (i) => i.cropField?.id === feature.properties.id
        );

        return merge({}, feature, {
          properties: {
            progress: item ? (100 * item.progress) / item.estimatedProgress : 0,
          },
        });
      }),
    };
  }, [
    activityReportItems,
    activityReportLoading,
    cropFieldFeatures,
    cropFieldsReportLoading,
  ]);

  switch (layer) {
    case "yields":
      return harvestYieldsSource;
    case "cropStages":
      return cropStageSource;
    case "costs":
      return costsSource;
    case "activities":
      return activitiesSource;
  }
}

function cropStageLayer({
  cropFields = [],
  formatMessage,
  formatCropStageType,
}: {
  cropFields?: CropFieldFragment[];
  formatMessage: ReturnType<typeof useIntl>["formatMessage"];
  formatCropStageType: ReturnType<
    typeof useCropStageTypeFormat
  >["formatCropStageType"];
}): LayersProps[0] {
  return {
    id: "cropStages",
    legend: true,
    beforeId: cropFieldLabels.id,
    type: "fill",
    source: "cropFields",
    paint: {
      "fill-color": [
        "match",
        ["get", "kind"],
        CropStageType.Development,
        CROP_STAGE_COLOR_MAP[CropStageType.Development],
        CropStageType.Production,
        CROP_STAGE_COLOR_MAP[CropStageType.Production],
        colors.disabledColor,
      ],
      "fill-opacity": layerFillOpacity,
    },
    metadata: {
      name: formatMessage(messages.cropStagesLayer),
      labels: {
        other: false,
        [CropStageType.Development]: formatCropStageType(
          CropStageType.Development
        ),
        [CropStageType.Production]: formatCropStageType(
          CropStageType.Production
        ),
      },
    },
    popup: (feature) => {
      const cropField = cropFields.find((c) => c.id === feature.properties?.id);

      if (!cropField) return null;

      return <CropStagesPopup cropField={cropField} />;
    },
  };
}

function formatHarvestYieldLayerID(unit?: string) {
  return `yields-${unit}`;
}

function roundToTwoDecimals(num: number) {
  return Math.round(num * 100) / 100;
}

function harvestLayer({
  cropFields = [],
  items = [],
  locality,
  formatMessage,
  currencyCode,
}: {
  cropFields?: CropFieldFragment[];
  items?: CropFieldSummaryReportFragment[];
  locality?: LocalityFragment;
  formatMessage: ReturnType<typeof useIntl>["formatMessage"];
  currencyCode: CurrentTenantFragment["currencyCode"];
}): LayersProps[0] {
  const { mean, stdDev } = distributionParameters(
    items.filter((i) => i.harvestYield > 0).map((i) => i.harvestYield)
  );

  const lowYieldBoundary = Math.max(roundToTwoDecimals(mean - stdDev), 0.01);
  const highYieldBoundary = Math.max(roundToTwoDecimals(mean + stdDev), 0.02);

  return {
    id: formatHarvestYieldLayerID(items[0]?.harvestUnit?.abbr),
    legend: true,
    beforeId: cropFieldLabels.id,
    type: "fill",
    source: "cropFields",
    paint: {
      "fill-color": [
        "step",
        ["number", ["get", "harvestYield"], -1],
        colors.disabledColor,
        0,
        colors.dangerColor,
        lowYieldBoundary,
        colors.warningColor,
        highYieldBoundary,
        colors.successColor,
      ],
      "fill-opacity": layerFillOpacity,
    },
    metadata: {
      name: `${formatMessage(messages.harvestYieldLayer)} (${
        items[0]?.harvestUnit?.abbr
      }/${locality?.areaUnit?.abbr})`,
      labels: {
        other: formatMessage({ id: "noData" }),
      },
    },
    popup: (feature) => {
      const cropField = cropFields.find((c) => c.id === feature.properties?.id);

      if (!cropField) return null;

      const item = items.find((i) => i.cropField?.id === cropField.id);

      return (
        <HarvestedPopup
          cropField={cropField}
          item={item}
          currencyCode={currencyCode}
        />
      );
    },
  };
}

function costsLayer({
  cropFields = [],
  items = [],
  currencyCode,
  formatMessage,
}: {
  cropFields?: CropFieldFragment[];
  items?: CropFieldSummaryReportFragment[];
  currencyCode: CurrentTenantFragment["currencyCode"];
  formatMessage: ReturnType<typeof useIntl>["formatMessage"];
}): LayersProps[0] {
  const { mean, stdDev } = distributionParameters(
    items.map((i) => i.totalCost || 0)
  );

  const lowCostBoundary = Math.max(roundToTwoDecimals(mean - stdDev), 0.01);
  const highCostBoundary = Math.max(roundToTwoDecimals(mean + stdDev), 0.02);

  return {
    id: `costs-${currencyCode}`,
    legend: true,
    beforeId: cropFieldLabels.id,
    type: "fill",
    source: "cropFields",
    paint: {
      "fill-color": [
        "step",
        ["number", ["get", "totalCost"], -1],
        colors.disabledColor,
        0,
        colors.dangerColor,
        lowCostBoundary,
        colors.warningColor,
        highCostBoundary,
        colors.successColor,
      ],
      "fill-opacity": layerFillOpacity,
    },
    metadata: {
      name: `${formatMessage({
        id: "costs",
        defaultMessage: "Costs",
      })} (${currencyCode})`,
      labels: {
        other: formatMessage({ id: "noData" }),
      },
    },
    popup: (feature) => {
      const cropField = cropFields.find((c) => c.id === feature.properties?.id);

      if (!cropField) return null;

      const item = items.find((i) => i.cropField?.id === cropField.id);

      if (!item) return <Popup cropField={cropField} />;

      return (
        <CostsPopup
          cropField={cropField}
          item={item}
          currencyCode={currencyCode}
        />
      );
    },
  };
}

function activityLayer({
  activity,
  activityItems = [],
  cropFields = [],
  currencyCode,
  formatMessage,
}: {
  activity?: ActivityFragment;
  activityItems?: ActivityCostCenterReportFragment[];
  cropFields?: CropFieldFragment[];
  currencyCode: CurrentTenantFragment["currencyCode"];
  formatMessage: ReturnType<typeof useIntl>["formatMessage"];
}): LayersProps[0] {
  return {
    id: "activities",
    legend: true,
    beforeId: cropFieldLabels.id,
    type: "fill",
    source: "cropFields",
    paint: {
      "fill-color": [
        "step",
        ["number", ["get", "progress"], -1],
        colors.disabledColor,
        0.1,
        colors.dangerColor,
        30,
        colors.warningColor,
        85,
        colors.successColor,
      ],
      "fill-opacity": layerFillOpacity,
    },
    metadata: {
      name: `${formatMessage({ id: "progress" })} (%)`,
      labels: {
        other: formatMessage({ id: "noData" }),
      },
    },
    popup: (feature) => {
      const item = activityItems.find(
        (h) => h.cropField?.id === feature.properties?.id
      );

      const cropField = cropFields.find((c) => c.id === feature.properties?.id);

      if (!cropField) return null;

      if (!activity)
        return (
          <Popup cropField={cropField}>
            <Typography.Title level={4}>
              <FormattedMessage
                id="localityMap.selectActivity"
                defaultMessage="Select an activity to see statistics"
              />
            </Typography.Title>
          </Popup>
        );

      if (!item) return <Popup cropField={cropField} />;

      return (
        <ActivitiesPopup
          activity={activity}
          cropField={cropField}
          item={item}
          currencyCode={currencyCode}
        />
      );
    },
  };
}

function distributionParameters(values: number[]) {
  const mean = meanFn(values);
  const stdDev = Math.sqrt(
    sum(values.map((i) => Math.pow(i - mean, 2))) / values.length
  );

  return { mean, stdDev };
}

function CropStagesPopup({ cropField }: { cropField: CropFieldFragment }) {
  return (
    <Popup cropField={cropField}>
      <Typography.Text type="secondary">
        <FormattedMessage id="cropFields.effectivePlants" />
      </Typography.Text>

      <Typography.Title level={5} style={{ margin: 0, fontWeight: "normal" }}>
        {formatNumber(cropField.effectivePlants) || BLANK_SYMBOL}
      </Typography.Title>
    </Popup>
  );
}

function HarvestedPopup({
  cropField,
  item,
  currencyCode,
}: {
  cropField: CropFieldFragment;
  item?: CropFieldSummaryReportFragment;
  currencyCode: CurrentTenantFragment["currencyCode"];
}) {
  const { currentLocality } = useCurrentUser();

  return (
    <Popup cropField={cropField}>
      <Space vertical>
        <div>
          <Row>
            <Col sm={12} xs={24}>
              <Typography.Text type="secondary">
                <FormattedMessage id="workOrders.harvested" />
              </Typography.Text>
              <Typography.Title
                level={5}
                style={{ margin: 0, fontWeight: "normal" }}
              >
                {item
                  ? formatUnitValue(item.harvestedAmount, {
                      abbr: item.harvestUnit.abbr,
                    })
                  : BLANK_SYMBOL}
              </Typography.Title>
            </Col>

            <Col sm={12} xs={24}>
              <Typography.Text type="secondary">
                <FormattedMessage id="yield" defaultMessage="Yield" />
              </Typography.Text>
              <Typography.Title
                level={5}
                style={{ margin: 0, fontWeight: "normal" }}
              >
                {item
                  ? formatUnitValue(
                      Math.max(item.harvestYield, 0),
                      { abbr: item.harvestUnit.abbr },
                      currentLocality?.areaUnit?.abbr
                    )
                  : BLANK_SYMBOL}
              </Typography.Title>
            </Col>

            {item?.estimatedAmount && (
              <Col sm={12} xs={24}>
                <Typography.Text type="secondary">
                  <FormattedMessage id="harvestEstimates.estimatedHarvest" />
                </Typography.Text>
                <Typography.Title
                  level={5}
                  style={{ margin: 0, fontWeight: "normal" }}
                >
                  {formatUnitValue(item.estimatedAmount, {
                    abbr: item.harvestUnit.abbr,
                  })}
                </Typography.Title>
              </Col>
            )}

            {item?.estimatedYield && (
              <Col sm={12} xs={24}>
                <Typography.Text type="secondary">
                  <FormattedMessage id="harvestEstimates.amount" />
                </Typography.Text>
                <Typography.Title
                  level={5}
                  style={{ margin: 0, fontWeight: "normal" }}
                >
                  {formatUnitValue(
                    item.estimatedYield,
                    { abbr: item.harvestUnit.abbr },
                    currentLocality?.areaUnit?.abbr
                  )}
                </Typography.Title>
              </Col>
            )}

            <Col sm={12} xs={24}>
              <Typography.Text type="secondary">
                <FormattedMessage id="cost" defaultMessage="Cost" />
              </Typography.Text>
              <Typography.Title
                level={5}
                style={{ margin: 0, fontWeight: "normal" }}
              >
                {item
                  ? formatPer({
                      value: formatMoneyAmount(
                        (item.totalCost || 0) / item.harvestedAmount,
                        currencyCode
                      ),
                      per: item.harvestUnit.abbr,
                    })
                  : BLANK_SYMBOL}
              </Typography.Title>
            </Col>

            <Col sm={12} xs={24}>
              <Typography.Text type="secondary">
                <FormattedMessage id="cost" defaultMessage="Cost" />
              </Typography.Text>
              <Typography.Title
                level={5}
                style={{ margin: 0, fontWeight: "normal" }}
              >
                {item
                  ? formatPer({
                      value: formatMoneyAmount(
                        (item.totalCost || 0) / cropField.effectiveArea,
                        currencyCode
                      ),
                      per: currentLocality?.areaUnit?.abbr,
                    })
                  : BLANK_SYMBOL}
              </Typography.Title>
            </Col>
          </Row>
        </div>
      </Space>
    </Popup>
  );
}

function CostsPopup({
  cropField,
  item,
  currencyCode,
}: {
  cropField: CropFieldFragment;
  item: CropFieldSummaryReportFragment;
  currencyCode: string;
}) {
  const { currentLocality } = useCurrentUser();

  return (
    <Popup cropField={cropField}>
      <Space vertical>
        <div>
          <Typography.Text type="secondary">
            <FormattedMessage id="totalCost" />
          </Typography.Text>

          <Row>
            <Col sm={12} xs={24}>
              <Typography.Title
                level={5}
                style={{ margin: 0, fontWeight: "normal" }}
              >
                {formatMoneyAmount(item.totalCost, currencyCode)}
              </Typography.Title>
            </Col>
            <Col sm={12} xs={24}>
              <Typography.Title
                level={5}
                style={{ margin: 0, fontWeight: "normal" }}
              >
                {formatPer({
                  value: formatMoneyAmount(
                    (item.totalCost || 0) / cropField.effectiveArea,
                    currencyCode
                  ),
                  per: currentLocality?.areaUnit?.abbr,
                })}
              </Typography.Title>
            </Col>
          </Row>

          <Typography.Text type="secondary">
            <FormattedMessage id="activities.laborCost" />
          </Typography.Text>

          <Row>
            <Col sm={12} xs={24}>
              <Typography.Title
                level={5}
                style={{ margin: 0, fontWeight: "normal" }}
              >
                {formatMoneyAmount(item.laborCost, currencyCode)}
              </Typography.Title>
            </Col>
            <Col sm={12} xs={24}>
              <Typography.Title
                level={5}
                style={{ margin: 0, fontWeight: "normal" }}
              >
                {formatPer({
                  value: formatMoneyAmount(
                    (item.laborCost || 0) / cropField.effectiveArea,
                    currencyCode
                  ),
                  per: currentLocality?.areaUnit?.abbr,
                })}
              </Typography.Title>
            </Col>
          </Row>

          <Typography.Text type="secondary">
            <FormattedMessage id="activities.inventoryCost" />
          </Typography.Text>

          <Row>
            <Col sm={12} xs={24}>
              <Typography.Title
                level={5}
                style={{ margin: 0, fontWeight: "normal" }}
              >
                {formatMoneyAmount(item.inventoryCost, currencyCode)}
              </Typography.Title>
            </Col>
            <Col sm={12} xs={24}>
              <Typography.Title
                level={5}
                style={{ margin: 0, fontWeight: "normal" }}
              >
                {formatPer({
                  value: formatMoneyAmount(
                    (item.inventoryCost || 0) / cropField.effectiveArea,
                    currencyCode
                  ),
                  per: currentLocality?.areaUnit?.abbr,
                })}
              </Typography.Title>
            </Col>
          </Row>
        </div>
      </Space>
    </Popup>
  );
}

function Popup({
  children,
  cropField,
  ...props
}: PropsWithChildren<{ cropField: CropFieldFragment }>) {
  const { formatCropStageType } = useCropStageTypeFormat();
  const { currentLocality } = useCurrentUser();

  return (
    <div style={{ width: 300 }} {...props}>
      <Typography.Title level={4}>
        {formatDetailsLink({
          id: cropField.id,
          route: routes.agro.cropFields.details,
          title: `${cropField.name} - ${formatCropStageType(
            cropField.cropStage.kind
          )}`,
        })}
      </Typography.Title>

      <Space vertical>
        <div>
          {formatTags([cropField.cropStage.name], "blue")}{" "}
          {formatTags(
            cropField.cropFieldVarieties.map((cv) => cv.cropVariety.name),
            "lime"
          )}
        </div>

        <div>
          <Typography.Text type="secondary">
            <FormattedMessage id="area" defaultMessage="Area" />
          </Typography.Text>
          <Typography.Title
            level={5}
            style={{ margin: 0, fontWeight: "normal" }}
          >
            {formatUnitValue(
              cropField.effectiveArea,
              currentLocality?.areaUnit
            )}
          </Typography.Title>
        </div>
      </Space>

      {children}
    </div>
  );
}

function ActivitiesPopup({
  activity,
  cropField,
  item,
  currencyCode,
}: {
  activity: ActivityFragment;
  cropField: CropFieldFragment;
  item: ActivityCostCenterReportFragment;
  currencyCode: string;
}) {
  const { currentLocality } = useCurrentUser();

  return (
    <Popup cropField={cropField}>
      <div>
        <Typography.Text type="secondary">
          <FormattedMessage id="progress" />
        </Typography.Text>

        <div>
          {!item.estimatedProgress
            ? formatUnitValue(item.progress, activity.progressUnit)
            : formatProgress(
                item.estimatedProgress,
                activity.progressUnit,
                item.progress,
                { supressOverload: true },
                { style: { fontSize: "1rem" } }
              )}
        </div>
      </div>

      <Row>
        <Col sm={12} xs={24}>
          <Typography.Text type="secondary">
            <FormattedMessage id="activities.laborEfficiency" />
          </Typography.Text>

          <Typography.Title
            level={5}
            style={{ margin: 0, fontWeight: "normal" }}
          >
            {formatUnitValue(
              item.laborEfficiency,
              activity.progressUnit,
              "dh"
            ) || BLANK_SYMBOL}
          </Typography.Title>
        </Col>

        <Col sm={12} xs={24}>
          <Typography.Text type="secondary">
            <FormattedMessage id="activities.machineEfficiency" />
          </Typography.Text>

          <Typography.Title
            level={5}
            style={{ margin: 0, fontWeight: "normal" }}
          >
            {formatUnitValue(
              item.machineEfficiency,
              activity.progressUnit,
              "h"
            ) || BLANK_SYMBOL}
          </Typography.Title>
        </Col>
      </Row>

      <div>
        <Typography.Text type="secondary">
          <FormattedMessage id="totalCost" />
        </Typography.Text>

        <Row>
          <Col sm={12} xs={24}>
            <Typography.Title
              level={5}
              style={{ margin: 0, fontWeight: "normal" }}
            >
              {formatMoneyAmount(item.totalCost, currencyCode)}
            </Typography.Title>
          </Col>

          <Col sm={12} xs={24}>
            <Typography.Title
              level={5}
              style={{ margin: 0, fontWeight: "normal" }}
            >
              {formatPer({
                value: formatMoneyAmount(
                  (item.totalCost || 0) / cropField.effectiveArea,
                  currencyCode
                ),
                per: currentLocality?.areaUnit?.abbr,
              })}
            </Typography.Title>
          </Col>
        </Row>
      </div>
    </Popup>
  );
}

function AddFarmButton() {
  const { currentLocality } = useCurrentUser();

  const href = currentLocality
    ? routes.localities.edit(currentLocality.id)
    : routes.localities.index;

  return (
    <Space vertical style={{ textAlign: "center" }}>
      <Typography.Text>
        <FormattedMessage id="localities.addMap.hint" defaultMessage="addMap" />
      </Typography.Text>

      <Link href={href} legacyBehavior>
        <Button type="primary" href={href} size="large">
          <FormattedMessage
            id="localities.addMap"
            defaultMessage="Add farm map"
          />
        </Button>
      </Link>
    </Space>
  );
}
