import React from "react";
import Icon from "@ant-design/icons";
import { useIntl, defineMessages } from "react-intl";
import { Dropdown } from "..";
import { locales, useCurrentLocale } from "../../../lib/hooks";
import { AvailableLocales } from "../../../lib/locale";

const messages = defineMessages({
  selectLocality: {
    id: "localities.select",
    defaultMessage: "Select Locality",
  },
  localeSwitched: {
    id: "locale.switch",
    defaultMessage: "switch",
  },
});

export default function LanguageMenu() {
  const { showSuccess } = useCurrentLocale();
  const intl = useIntl();

  const { currentLocale, setLocale } = useCurrentLocale();

  return (
    <Dropdown
      menu={{
        triggerSubMenuAction: "click",
        selectedKeys: [currentLocale],
        items: Object.entries(locales).map(([code, locale]) => ({
          key: code,
          onClick: () => {
            setLocale(code as AvailableLocales);
            showSuccess(
              intl.formatMessage(messages.localeSwitched, {
                language: locale.name,
              })
            );
          },
          icon: <span className="anticon">{locale.emoji}</span>,
          label: locale.name,
        })),
      }}
      placement="bottomLeft"
      className="menu-item hover"
    >
      <span data-language={true} style={{ display: "inline-flex" }}>
        <Icon
          component={() => <img src="/images/menu/language.svg" width="20" />}
        />
      </span>
    </Dropdown>
  );
}
