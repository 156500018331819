import { defineMessages, useIntl } from "react-intl";
import { useContinueOnboardingStep } from "../../../lib/hooks/onboarding";
import ProductTour, { IStep } from "../../shared/ProductTour";

const onboardingMessages = defineMessages({
  newTitle: {
    id: "onboarding.plannings.productTour.new.title",
    defaultMessage: "Plan new activity",
  },
  newContent: {
    id: "onboarding.plannings.productTour.new.content",
    defaultMessage:
      "Click here to plan an activity you do on your farm and lets get this demo started!",
  },
  createWOTitle: {
    id: "onboarding.plannings.productTour.createWO.title",
    defaultMessage: "Create work order",
  },
  createWOContent: {
    id: "onboarding.plannings.productTour.createWO.content",
    defaultMessage:
      "After reviewing your planned activity, click here to complete a day of work for this activity",
  },
  fieldsTitle: {
    id: "onboarding.planning.productTour.fields.title",
    defaultMessage: "Fields",
  },
  fieldsContent: {
    id: "onboarding.planning.productTour.fields.content",
    defaultMessage: "Select the fields you wish to work on",
  },
  workersTitle: {
    id: "onboarding.planning.productTour.workers.title",
    defaultMessage: "Workers",
  },
  workersContent: {
    id: "onboarding.planning.productTour.workers.content",
    defaultMessage: "Select the workers that will work on this activity",
  },
  inputsTitle: {
    id: "onboarding.planning.productTour.inputs.title",
    defaultMessage: "Inputs",
  },
  inputsContent: {
    id: "onboarding.planning.productTour.inputs.content",
    defaultMessage: "Select the inputs that will be used for this activity",
  },
  startTitle: {
    id: "onboarding.planning.productTour.start.title",
    defaultMessage: "Start Planning",
  },
  startContent: {
    id: "onboarding.planning.productTour.start.content",
    defaultMessage: "Plan this activity so you can work on it",
  },
  summaryTitle: {
    id: "onboarding.planning.productTour.summary.title",
    defaultMessage: "Summary",
  },
  summaryContent: {
    id: "onboarding.planning.productTour.summary.content",
    defaultMessage: "Review the planned activity",
  },
  workOrdersTitle: {
    id: "onboarding.planning.productTour.workOrders.title",
    defaultMessage: "Work Orders",
  },
  workOrdersContent: {
    id: "onboarding.planning.productTour.workOrders.content",
    defaultMessage:
      "Click here to see the work orders you have created for this activity",
  },
  dashboardTitle: {
    id: "onboarding.planning.productTour.dashboard.title",
    defaultMessage: "Dashboard",
  },
  dashboardContent: {
    id: "onboarding.planning.productTour.dashboard.content",
    defaultMessage: "Click here to see how your data on the dashboard",
  },
  reportsTitle: {
    id: "onboarding.planning.productTour.reports.title",
    defaultMessage: "Reports",
  },
  reportsContent: {
    id: "onboarding.planning.productTour.reports.content",
    defaultMessage:
      "Click here to view all reports such as activity report, expense report, and fields report",
  },
});

export function PlanningsProductTour() {
  const KEY = "onboarding:plannings";
  const intl = useIntl();
  const { loaded } = useContinueOnboardingStep();

  if (!loaded) return null;

  const steps = [
    {
      selector: "[data-new='true']",
      placement: "bottom-left",
      title: intl.formatMessage(onboardingMessages.newTitle),
      content: intl.formatMessage(onboardingMessages.newContent),
    },
  ] as IStep[];

  return (
    <ProductTour
      hotspot={false}
      localKey={KEY}
      steps={steps}
      stepText={() => ""}
    />
  );
}

export const NEW_PLANNING_KEY = "onboarding:planning:new";

export function NewPlanningProductTour() {
  const intl = useIntl();
  const { loaded } = useContinueOnboardingStep();

  if (!loaded) return null;

  const steps = [
    {
      selector: "[data-section='crop-fields']",
      placement: "bottom",
      title: intl.formatMessage(onboardingMessages.fieldsTitle),
      content: intl.formatMessage(onboardingMessages.fieldsContent),
    },
    {
      selector: "[data-section='positions']",
      placement: "bottom",
      title: intl.formatMessage(onboardingMessages.workersTitle),
      content: intl.formatMessage(onboardingMessages.workersContent),
    },
    {
      selector: "[data-section='inputs']",
      placement: "bottom",
      title: intl.formatMessage(onboardingMessages.inputsTitle),
      content: intl.formatMessage(onboardingMessages.inputsContent),
    },
    {
      selector: "#start-planning",
      placement: "top-right",
      title: intl.formatMessage(onboardingMessages.startTitle),
      content: intl.formatMessage(onboardingMessages.startContent),
      fixedBody: true,
    },
  ] as IStep[];

  return (
    <ProductTour hotspot={false} localKey={NEW_PLANNING_KEY} steps={steps} />
  );
}

export function CreateWOProductTour() {
  const KEY = "onboarding:plannings:create-wo";
  const intl = useIntl();
  const { loaded } = useContinueOnboardingStep();

  if (!loaded) return null;

  const steps = [
    {
      selector: "[data-action='start']",
      placement: "top-right",
      title: intl.formatMessage(onboardingMessages.createWOTitle),
      content: intl.formatMessage(onboardingMessages.createWOContent),
      fixedBody: true,
    },
  ] as IStep[];

  return (
    <ProductTour
      hotspot={false}
      localKey={KEY}
      steps={steps}
      stepText={() => ""}
    />
  );
}

export const PROGRESS_PLANNING_PRODUCT_TOUR_KEY =
  "onboarding:planning:in-progress";

export function ProgressPlanningProductTour() {
  const intl = useIntl();
  const { loaded } = useContinueOnboardingStep();

  if (!loaded) return null;

  const steps = [
    {
      selector: "[data-section='summary']",
      placement: "bottom",
      title: intl.formatMessage(onboardingMessages.summaryTitle),
      content: intl.formatMessage(onboardingMessages.summaryContent),
    },
    {
      selector: "[data-section='work-orders']",
      placement: "bottom",
      title: intl.formatMessage(onboardingMessages.workOrdersTitle),
      content: intl.formatMessage(onboardingMessages.workOrdersContent),
    },
    {
      selector: "[data-left-menu-item='dashboard']",
      placement: "right",
      title: intl.formatMessage(onboardingMessages.dashboardTitle),
      content: intl.formatMessage(onboardingMessages.dashboardContent),
      fixedBody: true,
    },
    {
      selector: "[data-left-menu-item='reports']",
      placement: "right",
      title: intl.formatMessage(onboardingMessages.reportsTitle),
      content: intl.formatMessage(onboardingMessages.reportsContent),
      fixedBody: true,
    },
  ] as IStep[];

  return (
    <ProductTour
      hotspot={false}
      localKey={PROGRESS_PLANNING_PRODUCT_TOUR_KEY}
      steps={steps}
    />
  );
}
