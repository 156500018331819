import { QueryHookOptions, useQuery } from "@apollo/client";
import {
  LaborSummaryReportQuery,
  LaborSummaryReportQueryVariables,
  LaborSummaryReportDocument,
  LaborActivityReportQuery,
  LaborActivityReportQueryVariables,
  LaborActivityReportDocument,
} from "../../graphql";

export function useLaborSummaryReport(
  options?: QueryHookOptions<
    LaborSummaryReportQuery,
    LaborSummaryReportQueryVariables
  >
) {
  const { loading, data } = useQuery(LaborSummaryReportDocument, options);

  if (!data || !data.laborSummaryReport || !data.laborSummaryReport.items) {
    return { loading };
  }

  const items = data.laborSummaryReport.items;
  const totalCount = data.laborSummaryReport.totalCount;

  return { loading, items, totalCount };
}

export function useLaborActivityReport(
  options?: QueryHookOptions<
    LaborActivityReportQuery,
    LaborActivityReportQueryVariables
  >
) {
  const { loading, data } = useQuery(LaborActivityReportDocument, options);

  if (!data || !data.laborActivityReport || !data.laborActivityReport.items) {
    return { loading };
  }

  const items = data.laborActivityReport.items;
  const totalCount = data.laborActivityReport.totalCount;

  return { loading, items, totalCount };
}
