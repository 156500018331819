import {
  QueryHookOptions,
  useQuery,
  MutationHookOptions,
  useMutation,
  useLazyQuery,
} from "@apollo/client";
import { merge } from "lodash";
import { useCallback } from "react";

import {
  CounterpartiesQuery,
  CounterpartiesQueryVariables,
  CounterpartiesDocument,
  CounterpartyUpdateMutation,
  CounterpartyUpdateMutationVariables,
  CounterpartyUpdateDocument,
  CounterpartyCreateMutation,
  CounterpartyCreateMutationVariables,
  CounterpartyCreateDocument,
  CounterpartyDiscardMutation,
  CounterpartyDiscardMutationVariables,
  CounterpartyDiscardDocument,
  CounterpartyQuery,
  CounterpartyQueryVariables,
  CounterpartyDocument,
  CounterpartyDestroyDocument,
  CounterpartyDestroyMutation,
  CounterpartyDestroyMutationVariables,
  CounterpartyBulkUpdateMutation,
  CounterpartyBulkUpdateMutationVariables,
  CounterpartyBulkUpdateDocument,
} from "../../graphql";

export function useCounterpartyOptions(
  queryOptions?: QueryHookOptions<
    CounterpartiesQuery,
    CounterpartiesQueryVariables
  >
) {
  const [load, { loading, data }] = useLazyQuery(
    CounterpartiesDocument,
    queryOptions
  );

  const options = data?.counterparties?.items?.map((c) => ({
    key: c.id,
    label: c.name,
    counterparty: c,
  }));

  const search = useCallback(
    (val: string | undefined) => {
      load(
        merge({}, options, {
          variables: { filter: { name: { match: val } } },
        })
      );
    },
    [load, options]
  );

  return {
    load,
    search,
    loading,
    options,
  };
}

export function useCounterparties(
  options?: QueryHookOptions<CounterpartiesQuery, CounterpartiesQueryVariables>
) {
  const { loading, data } = useQuery(CounterpartiesDocument, options);

  if (!data || !data.counterparties || !data.counterparties.items) {
    return { loading };
  }

  const items = data.counterparties.items;
  const totalCount = data.counterparties.totalCount;

  return { loading, items, totalCount };
}

export function useCounterparty(id: string) {
  const { data } = useQuery<CounterpartyQuery, CounterpartyQueryVariables>(
    CounterpartyDocument,
    {
      variables: { id },
    }
  );

  return data && data.counterparty;
}

export function useCounterpartyUpdate(
  options?: MutationHookOptions<
    CounterpartyUpdateMutation,
    CounterpartyUpdateMutationVariables
  >
) {
  return useMutation(CounterpartyUpdateDocument, options);
}

export function useCounterpartyCreate(
  options?: MutationHookOptions<
    CounterpartyCreateMutation,
    CounterpartyCreateMutationVariables
  >
) {
  return useMutation(CounterpartyCreateDocument, options);
}

export function useCounterpartyDiscard(
  options?: MutationHookOptions<
    CounterpartyDiscardMutation,
    CounterpartyDiscardMutationVariables
  >
) {
  return useMutation(CounterpartyDiscardDocument, options);
}

export function useCounterpartyDestroy(
  options?: MutationHookOptions<
    CounterpartyDestroyMutation,
    CounterpartyDestroyMutationVariables
  >
) {
  return useMutation(CounterpartyDestroyDocument, options);
}

export function useCounterpartyBulkUpdate(
  options?: MutationHookOptions<
    CounterpartyBulkUpdateMutation,
    CounterpartyBulkUpdateMutationVariables
  >
) {
  return useMutation(CounterpartyBulkUpdateDocument, options);
}
