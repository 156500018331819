import { useLazyQuery } from "@apollo/client";
import { merge } from "lodash";
import {
  WaterSourcesDocument,
  WaterSourcesQuery,
  WaterSourcesQueryVariables,
  SortDirection,
} from "../../graphql";

export function useWaterSourceOptions() {
  const [load, { loading, data }] = useLazyQuery<
    WaterSourcesQuery,
    WaterSourcesQueryVariables
  >(WaterSourcesDocument, { variables: { sort: { name: SortDirection.Asc } } });

  const search = (val?: string) => {
    load(
      merge({
        variables: { sort: undefined, filter: { name: { match: val } } },
      })
    );
  };

  const options =
    data?.waterSources?.items?.map((i) => ({
      key: i.id,
      label: i.name,
      waterSource: i,
    })) || [];

  return {
    load,
    search,
    loading,
    options,
  };
}
