import {
  MutationHookOptions,
  QueryHookOptions,
  useMutation,
  useQuery,
} from "@apollo/client";
import { DateTimeType } from "../../formats";
import {
  HolidayListCreateDocument,
  HolidayListCreateMutation,
  HolidayListCreateMutationVariables,
  HolidayListDestroyDocument,
  HolidayListDestroyMutation,
  HolidayListDestroyMutationVariables,
  HolidaysDocument,
  HolidaysQuery,
  HolidaysQueryVariables,
  HolidayListUpdateDocument,
  HolidayListUpdateMutation,
  HolidayListUpdateMutationVariables,
  WeekDaysDocument,
  WeekDaysQuery,
  WeekDaysQueryVariables,
  HolidayListsQuery,
  HolidayListsQueryVariables,
  HolidayListsDocument,
  HolidayListDocument,
  HolidayListQuery,
  HolidayListQueryVariables,
} from "../../graphql";

import dayjs from "dayjs";
import dayjsBusinessDays from "dayjs-business-days2";
import { useEffect, useState } from "react";

dayjs.extend(dayjsBusinessDays);

const workingWeekdays: Record<string, number> = {
  sun: 0,
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
};

export function useWeekDaysAndHolidays(year?: number) {
  const { items: holidays } = useHolidays({ variables: { filter: { year } } });
  const { items: weekDays } = useWeekDays();

  return { holidays, weekDays };
}

export function useDayJsHolidays(date?: DateTimeType) {
  const [loaded, setLoaded] = useState(false);
  const year = dayjs(date).year();
  const { holidays, weekDays } = useWeekDaysAndHolidays(year);

  useEffect(() => {
    if (holidays && weekDays) {
      const workingDays = weekDays.filter((d) => d.working).map((d) => d.day);
      if (workingDays.length > 0) dayjs.setWorkingWeekdays(workingDays);

      dayjs.setHolidays(holidays.map((h) => h.date));
      dayjs.setHolidayFormat(`YYYY-MM-DD`);

      setLoaded(true);
    }
  }, [holidays, weekDays, date]);

  return { loaded };
}

export function useWeekDays() {
  const { loading, data } = useQuery<WeekDaysQuery, WeekDaysQueryVariables>(
    WeekDaysDocument
  );

  return {
    loading,
    items: data?.weekDays?.items.map((i) => ({
      ...i,
      day: workingWeekdays[i.name.toLowerCase()],
    })),
  };
}

export function useHolidays(
  options?: QueryHookOptions<HolidaysQuery, HolidaysQueryVariables>
) {
  const { loading, data } = useQuery(HolidaysDocument, options);

  if (!data?.holidays?.items) {
    return { loading };
  }

  const items = data.holidays.items;
  const totalCount = data.holidays.totalCount;

  return { loading, items, totalCount };
}

export function useHolidayLists(
  options?: QueryHookOptions<HolidayListsQuery, HolidayListsQueryVariables>
) {
  const { loading, data } = useQuery(HolidayListsDocument, options);

  if (!data?.holidayLists?.items) {
    return { loading };
  }

  const items = data.holidayLists.items;
  const totalCount = data.holidayLists.totalCount;

  return { loading, items, totalCount };
}

export function useHolidayList(id?: string) {
  const { data } = useQuery<HolidayListQuery, HolidayListQueryVariables>(
    HolidayListDocument,
    { variables: { id: id || "" }, skip: id == "undefined" }
  );

  return data?.holidayList;
}

export function useHolidayListCreate(
  options?: MutationHookOptions<
    HolidayListCreateMutation,
    HolidayListCreateMutationVariables
  >
) {
  return useMutation(HolidayListCreateDocument, options);
}

export function useHolidayListUpdate(
  options?: MutationHookOptions<
    HolidayListUpdateMutation,
    HolidayListUpdateMutationVariables
  >
) {
  return useMutation(HolidayListUpdateDocument, options);
}

export function useHolidayListDestroy(
  options?: MutationHookOptions<
    HolidayListDestroyMutation,
    HolidayListDestroyMutationVariables
  >
) {
  return useMutation(HolidayListDestroyDocument, options);
}
