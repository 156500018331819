import React, { useState } from "react";
import {
  MutationProps,
  DestroyMutationVariables,
  WithIDType,
  DestroyMutationResult,
  useItemSidebarContext,
} from "../../../lib/hooks";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { ConfirmSidebar, ConfirmSidebarProps } from "../Sidebar";
import { useEffectOnce } from "react-use";
import { Spin } from "antd";
import { formatEntityWithName, rtfValues } from "../../../lib/formats";

interface DestroyItemConfig<T, TMutation, TMutationVariables>
  extends MutationProps<TMutation, TMutationVariables, DestroyMutationResult> {
  entityName: string;
  entityWithName?: string;
  mayDiscard?: boolean;
  item: T;
  onCancel(): void;
  onSuccess(): void;
}

const messages = defineMessages({
  destroy: { id: "destroy.header", defaultMessage: "destroy" },
  destroySuccess: { id: "destroy.success", defaultMessage: "success" },
});

export function DestroyItem<T extends WithIDType, TDestroyMutation>({
  item,
  entityName,
  entityWithName = formatEntityWithName(entityName, item),
  mayDiscard = true,
  mutation,
  onMutate,
  mutationOptions,
  onCancel,
  onSuccess,
}: DestroyItemConfig<T, TDestroyMutation, DestroyMutationVariables>) {
  const intl = useIntl();
  const { setCurrentAction } = useItemSidebarContext();
  const [mayDestroy, setMayDestroy] = useState(false);
  const [loading, setLoading] = useState(true);
  const [destroy] = mutation({
    ignoreResults: true,
    ...mutationOptions,
  });

  useEffectOnce(() => {
    destroy({ variables: { id: item.id, dryRun: true } }).then((result) => {
      if (!result.data) return;

      setLoading(false);
      setMayDestroy(!!onMutate(result.data)?.result);
    });
  });

  const onDestroy: ConfirmSidebarProps["onOk"] = ({
    showSuccess,
    showErrors,
  }) => {
    setLoading(true);
    destroy({ variables: { id: item.id } })
      .then((result) => {
        setLoading(false);
        if (!result.data) return;

        return onMutate(result.data);
      })
      .then((result) => {
        if (result?.errors?.length) {
          showErrors(result.errors);
        } else if (result?.result) {
          showSuccess(
            intl.formatMessage(messages.destroySuccess, {
              entityName: entityWithName,
              ...rtfValues,
            })
          );
        }
        onSuccess();
      });
  };

  const onDiscard = () => setCurrentAction("discard");

  return (
    <ConfirmSidebar
      title={intl.formatMessage(messages.destroy, { entityName })}
      onCancel={onCancel}
      okButtonProps={{
        danger: mayDestroy,
        loading,
        hidden: !mayDestroy && !mayDiscard,
      }}
      okText={
        mayDestroy || loading ? (
          <FormattedMessage id="destroy" defaultMessage="destroy" />
        ) : (
          <FormattedMessage id="discard" />
        )
      }
      onOk={mayDestroy ? onDestroy : onDiscard}
    >
      {mayDestroy ? (
        <FormattedMessage
          id="destroy.confirm"
          defaultMessage="confirm"
          values={{ entityName: entityWithName, ...rtfValues }}
        />
      ) : loading ? (
        <Spin />
      ) : mayDiscard ? (
        <FormattedMessage
          id="destroy.unable"
          defaultMessage="{entityName} is used. Unable to destroy permanently."
          values={{ entityName: entityWithName, ...rtfValues }}
        />
      ) : (
        <FormattedMessage
          id="destroy.unable.short"
          defaultMessage="{entityName} is used. Unable to destroy permanently."
          values={{ entityName: entityWithName, ...rtfValues }}
        />
      )}
    </ConfirmSidebar>
  );
}
